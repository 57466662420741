import { ApiError, AuthProvider } from "../types";
import { APIResponse, MoneyGramRequestParams } from "./common";
import { IndicativeRateEnabler } from "./get-indicative-rate";

export interface Client {
  lookupSender(params: ConsumerLookupParams): Promise<APIResponse<ConsumerLookupInfo> | ApiError>;
  getCountryList(): Promise<TypeInputSearchData[]>;
  getIndicativeRate: IndicativeRateEnabler;
}

/**
 * Sender information
 * @memberof ConsumerLookupResponse
 */
export interface SenderInfo {
  /**
   * Sender First name
   */
  SFN?: string;
  /**
   * Sender Middle name
   */
  SMN?: string;
  /**
   * Sender\'s Last name
   */
  SLN?: string;
  /**
   * Sender Address
   */
  SAD?: string;
  /**
   * Sender City
   */
  SCT?: string;
  /**
   * Sender State
   */
  SSTATE?: string;
  /**
   * Sender Zip code
   */
  SZC?: string;
  /**
   * Sender Country
   */
  SCY?: string;
  /**
   * Sender Home phone
   */
  SHP?: string;
  /**
   * Sender Date of birth
   */
  SDOB?: string;
  /**
   * Frequent customer card number
   */
  FREQCUSTCARDNUM?: string;
  /**
   * Consumer ID
   */
  CONID?: string;
  /**
   * Sender Transaction Email Notification Opt In
   */
  STXENOI?: string;
  /**
   * Sender Transaction SMS Notification Opt In
   */
  STXSNOI?: string;
  /**
   * Sender Marketing Email Notification Opt In
   */
  SMKENOI?: string;
  /**
   * Sender Marketing SMSNotification Opt In
   */
  SMKSNOI?: string;
  /**
   * Sender Email Address
   */
  SEA?: string;
  /**
   * Sender Mobile Phone
   */
  SMP?: string;
  /**
   * Receiver info
   */
  RECINF?: Array<ReceiverInfo>;
}

export interface ReceiverInfo {
  /**
   * Receiver Country
   */
  RECCTRY?: string;
  /**
   * Receiver First Name
   */
  RECFN?: string;
  /**
   * Receiver Middle Name
   */
  RECMN?: string;
  /**
   * Receiver Last name
   */
  RECLN?: string;
  /**
   * Send amount
   */
  SAMT?: string;
  /**
   * Delivery option
   */
  DELOPT?: string;
  /**
   * Send Currency
   */
  SCURR?: string;
  /**
   * Customer Receive Number
   */
  CUSTRECNUM?: string;
  /**
   * Account Number Last Four
   */
  ANLF?: string;
  /**
   * Receive Agent ID
   */
  RECAGTID?: string;
  /**
   * Receive Agent Name
   */
  RECAGTNME?: string;
  /**
   * Receive Agent Abbreviation
   */
  RECAGTABBR?: string;
  /**
   * Receive Currency
   */
  RECCURR?: string;
  /**
   * Payout Currency
   */
  POUTCURR?: string;
  /**
   * Customer has MG Plus Card
   */
  hasMGPlusCard: boolean;
}

/**
 * MoneyGram Consumer Lookup Request
 */
export interface ConsumerLookupRequest {
  /**
   * Frequent customer card number
   */
  FREQCUSTCARDNUM?: string;
  /**
   * Customer (sender) phone number
   */
  CUSTPH?: string;
  /**
   * Maximum number of senders to return (in the unusual case where more than one sender match the search criteria)
   */
  MXSNDTRET: string;
  /**
   * Maximum number of receivers to return (per sender)
   */
  MXRECTRET: string;
  /**
   * Sender first name
   */
  SFN?: string;
  /**
   * Sender last name
   */
  SLN?: string;
}

/**
 * MoneyGram Consumer Lookup Response
 */
export interface ConsumerLookupResponse {
  SINFO: Array<SenderInfo>;
}

export type Props = {
  rootUrl: string;
  authHeaders: AuthProvider;
};

/**
 * Input data format for the Journey Engine
 * <p>Copied from packages/component-lib-js/src/components/molecules/InputSearch/SearchComponent.tsx</p>
 */
export type TypeInputSearchData = {
  [key: string]: string;
  title: string;
  value: string;
};

export interface ConsumerLookupParams extends MoneyGramRequestParams {
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  plusRewardsCardNum?: string;
}

/**
 * constants for consumer lookup API - max senders to return and max recipients to return
 */
export const MAX_SENDERS_TO_RETURN = "3";
export const MAX_RECEIVERS_TO_RETURN = "6";

export type ConsumerLookupInfo = {
  userInfo: Array<SenderInfo>;
  numConsumersFound?: number;
};

export type Country = {
  CTRYC: string;
  CTRYNAME: string;
};

export type CountryResponse = {
  CTRYINF: Country[];
};
