import globalCache from "./globalVar";
import { CachingTypes } from "../types";

const memoryCaching = () => {
  const type = CachingTypes.IN_MEMORY;

  const set = (key: string, value: string): Promise<void> => {
    globalCache[key] = value;
    return Promise.resolve();
  };

  const get = (key: string): Promise<string | undefined> => {
    return Promise.resolve(globalCache[key]);
  };

  const remove = (key: string): Promise<void> => {
    delete globalCache[key];
    return Promise.resolve();
  };

  const clearAll = (): Promise<void> => {
    Object.keys(globalCache).map((key) => {
      delete globalCache[key];
      return key;
    });
    return Promise.resolve();
  };

  const setMany = (items: [string, string][]): Promise<void> => {
    items.map((item) => {
      const [key, value] = item;
      globalCache[key] = value;
      return item;
    });
    return Promise.resolve();
  };

  return Object.freeze({
    set,
    get,
    remove,
    setMany,
    clearAll,
    type,
  });
};

export default memoryCaching;
