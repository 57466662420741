import {
  AcquirerToInternalBankingMessages,
  AcquirerToInternalPaymentsMessages,
  GenericBankingTransactionDeclinedPrompt,
  GenericPaymentTransactionDeclinedPrompt,
} from "./mappings/acquirierMessageMappings";
import { AcquirerResponseCodes, AcquirerToInternalResponseCodes } from "./mappings/acquirierResponseCodes";
import { AccountBalanceItem } from "./types";
import eventTagMappings from "./mappings/eventTagMappings";
import pedResponseCodes, { SCA_CARD_CHANGED_COMBINED_CODE } from "./mappings/pedResponseCodes";
import balanceAmountTypes from "./mappings/balanceAmountTypeMappings";
import { CHANGE_PIN_FALLBACK_MESSAGE, DEFAULT_FALLBACK_MESSAGE, MESSAGE_MAPPINGS } from "./mappings/receiptMessages";
import { ActionToTransactionType } from "./mappings/transactionTypes";
import { MessagePrompt, Metadata, PedActions, PedResult, TransactionTypes, ReceiptLanguage } from "./types";
import bankingUndoCodes from "./mappings/bankingUndoCodes";

export const FALLBACK_RESULT_CODE = "07";
export const FALLBACK_RESPONSE_CODE = "24";

// total characters per line (aligns with C3Config value)
const RECEIPT_MAX_CHARACTER_WIDTH = 42;

// placeholder values returned by PED
const RECEIPT_TRANSACTION_ID_PLACEHOLDER = "$$$UserData1$$$";
const RECEIPT_OUTCOME_PLACEHOLDER = "$$$outcome$$$";
const RECEIPT_BALANCES_PLACEHOLDER = "$$$zgq$$$";

export const PAYMENT_ACTIONS = [PedActions.DebitX, PedActions.DebitY, PedActions.RefundX, PedActions.RefundY];

// actions to include receipt balances
export const BALANCES_DISPLAYED_FOR = [PedActions.BalanceEnquiryY];

export const ORDER_X_ACTIONS = [
  PedActions.BalanceEnquiryX,
  PedActions.ChangePinX,
  PedActions.DebitX,
  PedActions.DepositX,
  PedActions.RefundX,
  PedActions.WithdrawalX,
];

export const SUCCESSFUL_TRANSACTION_PROMPT = {
  id: "MSG40077",
  description: "The transaction has completed successfully.",
};

/**
 * Check if transaction was approved
 * @param response PedResult
 * @returns boolean
 */
export const isApproved = (response: PedResult): boolean => {
  return response.approved === true;
};

/**
 * Check if balances should be displayed on receipt
 * @param action PedActions
 * @returns boolean
 */
export const shouldIncludeReceiptBalances = (action: PedActions): boolean => {
  return BALANCES_DISPLAYED_FOR.includes(action);
};

/**
 * Check if action is order x
 * @param action PedActions
 * @returns boolean
 */
export const isOrderXAction = (action: PedActions): boolean => {
  return ORDER_X_ACTIONS.includes(action);
};

/**
 * Check if action is transactable
 * @param action PedActions
 * @returns boolean
 */
export const isNonTransactedAction = (action: PedActions): boolean => {
  return [PedActions.GetCardToken, PedActions.GetVersion, PedActions.Initialise].includes(action);
};

/**
 * Check if transaction requires banking undo
 * @param response PedResult
 * @returns boolean
 */
export const requiresUndo = (response: PedResult): boolean => {
  return bankingUndoCodes.includes(response.combinedCode);
};

/**
 * Extract Metadata from response
 * @param action PedActions
 * @param response PedResult
 * @returns
 */

export const extractMetadata = (action: PedActions, response: PedResult): Metadata => {
  const metadata: Metadata = {};

  metadata.transactionType = ActionToTransactionType[action];

  if (
    !isSCAFallback(response.combinedCode) &&
    response.acquirerResponseCode &&
    AcquirerToInternalResponseCodes[response.acquirerResponseCode]
  ) {
    metadata.responseCode = AcquirerToInternalResponseCodes[response.acquirerResponseCode].responseCode;
    metadata.resultCode = AcquirerToInternalResponseCodes[response.acquirerResponseCode].resultCode;
    return metadata;
  }

  if (pedResponseCodes[response.combinedCode]) {
    metadata.responseCode = pedResponseCodes[response.combinedCode].responseCode;
    metadata.resultCode = pedResponseCodes[response.combinedCode].resultCode;
    return metadata;
  }

  metadata.responseCode = FALLBACK_RESPONSE_CODE;
  metadata.resultCode = FALLBACK_RESULT_CODE;

  return metadata;
};

/**
 * Extract messaging prompt from response
 * @param type TransactionTypes
 * @param response PedResult
 * @returns
 */
export const extractMessaging = (type: TransactionTypes, response: PedResult): MessagePrompt | undefined => {
  if (isSCAFallback(response.combinedCode)) {
    return;
  }
  const messages =
    type === TransactionTypes.Banking ? AcquirerToInternalBankingMessages : AcquirerToInternalPaymentsMessages;
  if (response.acquirerResponseCode && messages[response.acquirerResponseCode]) {
    return messages[response.acquirerResponseCode];
  }
};

/**
 * Set fallback messaging when we don't have a mapping for the acquirer response code or ped response code
 * @param transactionType TransactionTypes
 * @returns MessagePrompt | undefined
 */
export const setFallbackMessaging = (transactionType: TransactionTypes): MessagePrompt | undefined => {
  switch (transactionType) {
    case TransactionTypes.Banking:
      return GenericBankingTransactionDeclinedPrompt;
    case TransactionTypes.Payments:
      return GenericPaymentTransactionDeclinedPrompt;
  }
};

/**
 * Get Event Tag Mapping
 * @param message string
 * @returns MessagePrompt | undefined
 */
export const getEventTagMapping = (message: string): MessagePrompt | undefined => {
  const cleanedMessage = message.replace(/\s{2,}/g, " ").trim();
  for (const key in eventTagMappings) {
    if (cleanedMessage.startsWith(key)) {
      return eventTagMappings[key];
    }
  }
};

/**
 * Process Worldline ticket response, ensuring that
 * the ticket is formatted correctly including removing any
 * placeholders that are not required for the transaction
 * @param ticket string
 * @param transactionId string
 * @param outcome string|undefined
 * @param balances AccountBalanceItem[]|undefined
 * @returns string
 */
export const replaceTicketPlaceholders = (
  ticket: string,
  transactionId: string,
  outcome?: string,
  balances?: AccountBalanceItem[]
): string => {
  ticket = ticket.replace(RECEIPT_TRANSACTION_ID_PLACEHOLDER, `NBIT Txn ID: ${transactionId}`);
  if (outcome) {
    ticket = ticket.replace(RECEIPT_OUTCOME_PLACEHOLDER, wrapText(outcome));
  } else {
    ticket = ticket.replace(`\n \n${RECEIPT_OUTCOME_PLACEHOLDER}`, "");
    ticket = ticket.replace(`${RECEIPT_OUTCOME_PLACEHOLDER}`, "");
  }

  if (balances?.length) {
    let balanceText = "";

    let currentItem = 0;
    for (const balance of balances) {
      const formattedCurrencyAmount = formatCurrencyAmount(balance.amount, balance.currency);
      const totalSpaceClaimed = Number(balance.amountType.length + formattedCurrencyAmount.length);

      let whitespace = Number(RECEIPT_MAX_CHARACTER_WIDTH - totalSpaceClaimed);
      let needsLineBreak = false;

      /**
       * we want to ensure values are aligned in proportion to space available,
       * any large balances should be present on a new line with a sufficient amount
       * of spacing to ensure it's aligned to previous line's label
       * 
       * example:
       * Account available balance
       *                          £1,234,567,891.20
         Account balance          £1,234,567,891.20
       */
      if (totalSpaceClaimed >= RECEIPT_MAX_CHARACTER_WIDTH) {
        whitespace = Number(balance.amountType.length);
        needsLineBreak = true;
      }

      currentItem++;
      balanceText += `${balance.amountType}${needsLineBreak ? "\n" : ""}${" ".repeat(
        whitespace
      )}${formattedCurrencyAmount}${currentItem !== balances.length ? "\n" : ""}`;
      needsLineBreak = false;
    }

    ticket = ticket.replace(RECEIPT_BALANCES_PLACEHOLDER, outcome ? balanceText : `\n${balanceText}`);
  } else {
    ticket = ticket.replace(`\n${RECEIPT_BALANCES_PLACEHOLDER}`, "");
    ticket = ticket.replace(`${RECEIPT_BALANCES_PLACEHOLDER}`, "");
  }

  // remove last new line character
  ticket = ticket.replace(/\n$/, "");

  return ticket;
};

/**
 * Extract balance information from PED result
 *
 * Format returned from PED:
 * {account type}|{balance amount type}|amount|curency;
 *
 * @param rawBalances string[]
 * @returns AccountBalanceItem[]
 */
export const extractAccountBalances = (rawBalances: string[]): AccountBalanceItem[] => {
  return rawBalances.map((balanceItem) => {
    const item = balanceItem.replace(";", "").split("|");
    return {
      accountType: item[0],
      amountType: balanceAmountTypes[item[1]] ?? item[1],
      amount: Number(item[2]),
      currency: item[3],
    };
  });
};

export const getReceiptOutcomeMessage = (
  pedAction: PedActions,
  c3CombinedCode: string,
  language: ReceiptLanguage,
  acquirerResponseCode?: AcquirerResponseCodes
): string => {
  const foundMappings = MESSAGE_MAPPINGS.filter((mapping) => mapping.transactionTypes.includes(pedAction));

  if (acquirerResponseCode) {
    const foundMapping = foundMappings.find(
      (mapping) => mapping.acquirerResponseCodes && mapping.acquirerResponseCodes.includes(acquirerResponseCode)
    );
    if (foundMapping) {
      return foundMapping.messages[language];
    }
  }

  const foundMapping = foundMappings.find(
    (mapping) => mapping.c3CombinedErrorCodes && mapping.c3CombinedErrorCodes.includes(c3CombinedCode)
  );

  if (foundMapping) {
    return foundMapping.messages[language];
  }

  // change pin requires its own fallback
  if (pedAction === PedActions.ChangePinY) {
    return CHANGE_PIN_FALLBACK_MESSAGE[language];
  }

  // no message mapping found
  return DEFAULT_FALLBACK_MESSAGE[language];
};

/**
 * Formatted currency amount
 * @param pence number
 * @returns string
 */
export const formatCurrencyAmount = (pence: number, currency: string): string => {
  const amount = Number(Number(pence / 100).toFixed(2));
  return new Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: currency,
  }).format(amount);
};

/**
 * Check if is SCA fallback
 * @param response PedResult
 * @returns boolean
 */
export const isSCAFallback = (c3CombinedCode: string) => SCA_CARD_CHANGED_COMBINED_CODE == c3CombinedCode;

/**
 * Wrap text to 42 characters ensuring words
 * are not split between lines
 *
 * @param text string
 * @returns string
 */
export const wrapText = (text: string) => {
  if (text.length < RECEIPT_MAX_CHARACTER_WIDTH) {
    return text;
  }

  let lastSpaceIndex = text.lastIndexOf(" ", RECEIPT_MAX_CHARACTER_WIDTH - 1);
  if (lastSpaceIndex !== -1 && lastSpaceIndex < RECEIPT_MAX_CHARACTER_WIDTH) {
    return `${text.slice(0, lastSpaceIndex)}\n${wrapText(text.slice(lastSpaceIndex + 1))}`;
  } else {
    lastSpaceIndex = text.lastIndexOf(" ", RECEIPT_MAX_CHARACTER_WIDTH);
    if (lastSpaceIndex !== -1) {
      return `${text.slice(0, lastSpaceIndex)}\n${wrapText(text.slice(lastSpaceIndex + 1))}`;
    }
  }
};
