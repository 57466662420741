import {
  SmartMeterVendTypeActions,
  AuthProvider,
} from "postoffice-product-journey-api-clients";
import {
  ApiClientsConfig,
  FulfillerResponse,
  FulfillmentItem,
  GenericFulfiller,
  Callbacks,
  FulfilmentProcessorResponse
} from "../../types";
import { FulfilmentStateEnum, TransactionsApiInterface } from "../../openapi/transaction-api-v3";
import { updateFulfilmentTokens } from "../helpers";
import { respond } from "../../helpers";
import { process as processSmartMeterVend } from "./actions/genericSmartMeter";


export const SmartMeterFulfiller = (
  client: TransactionsApiInterface,
  apiConfig: ApiClientsConfig["billpay"],
  basketID: string,
  callbacks: Callbacks,
  deviceToken: string,
  userToken: string
): GenericFulfiller => {
  const authHeaders: AuthProvider = async () => {
    return new Promise((resolve) => {
      resolve({ Authorization: deviceToken });
    });
  };
  
  
  const fulfill = async (item: FulfillmentItem): Promise<FulfillerResponse> => {
    let response: FulfilmentProcessorResponse = {
      fulfilment: {
        status: FulfilmentStateEnum.Failure,
        tokens: {
          Ecode: ""   
        }
      }      
    };
    try {
    
      const fulfilmentAction = item?.basketItem?.tokens?.fulfilmentAction;
      const smartMeterFulfillerActions = Object.values(SmartMeterVendTypeActions);

      if (!fulfilmentAction || !smartMeterFulfillerActions.includes(fulfilmentAction as SmartMeterVendTypeActions)) {
        throw new Error(`Unknown Smart Meter fulfiller action: ${fulfilmentAction || "unknown"}`);
      }
        
      switch (fulfilmentAction) {
        case SmartMeterVendTypeActions.PAYGEN_GENERIC:
        case SmartMeterVendTypeActions.SME:
        case SmartMeterVendTypeActions.PAYZONE:
          response = await processSmartMeterVend(callbacks, apiConfig, authHeaders, item?.basketItem);
          break;
        default:
          throw new Error(`Unknown fulfilment action: ${fulfilmentAction || "unknown"}`);
      }

      const fulfilmentStatus = response.fulfilment?.status ?? FulfilmentStateEnum.Failure;
      const fulfilmentTokens = response.fulfilment?.tokens ?? {};
      const isSuccessful = fulfilmentStatus === FulfilmentStateEnum.Success;

      const entryId = item?.basketItem?.entryID.toString();
      const updated = await updateFulfilmentTokens(
        client,
        basketID,
        entryId,
        fulfilmentStatus,
        fulfilmentTokens,
        userToken
      );

      return respond(isSuccessful && updated, {
        result: fulfilmentTokens,
        networkError: !updated,
        ...(response.receipts && {
          receipts: response.receipts,
        })
      });

    } catch (errorMessage) {
      const entryId = item?.basketItem?.entryID.toString();
      throw errorMessage;
    }        
  };
  return Object.freeze({ fulfill });
};
