import { axiosClient } from "../lib/axiosWrapper";

import { requestGiftCardProps, activationResponse, Props, Client } from "./types";
import { parseActivationResponse } from "./helpers";

const callGiftCardActivation = async (props: Props, requestProps: requestGiftCardProps) => {
  const { rootUrl, authHeaders } = props;
  const {
    txId,
    additionalTxnFields,
    transactionAmount,
    merchantTerminalId,
    retrievalReferenceNumber,
    localTransactionDate,
    merchantLocation,
    pointOfServiceEntryMode,
    primaryAccountNumber,
  } = requestProps;
  const txAmount = transactionAmount.split(".");
  const response = await axiosClient.post({
    url: `${rootUrl}/giftcard`,
    headers: await authHeaders(),
    params: {
      body: {
        activationRequest: {
          txId,
          primaryAccountNumber,
          transactionAmount: txAmount,
          localTransactionDate,
          pointOfServiceEntryMode,
          retrievalReferenceNumber,
          merchantTerminalId,
          merchantLocation,
          additionalTxnFields,
        },
      },
    },
  });
  return response;
};

export const buildClient = (props: Props): Client => {
  const giftCardActivation = async (requestProps: requestGiftCardProps): Promise<activationResponse | any> => {
    try {
      const response = await callGiftCardActivation(props, requestProps);
      return parseActivationResponse(response) as unknown as activationResponse;
    } catch (err) {
      return Promise.reject({ message: "invalid gift card activation" });
    }
  };
  return Object.freeze({ giftCardActivation });
};
