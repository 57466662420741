import { get as getIndexDB, set as setIndexDB, del, setMany as setManyIndexDB, clear } from "idb-keyval";
import { CachingTypes, Caching } from "../types";

const indexdDBCaching = (): Caching => {
  const type = CachingTypes.INDEXED_DB;

  const set = (key: string, value: string): Promise<void> => {
    return setIndexDB(key, value);
  };

  const get = (key: string): Promise<string | undefined> => {
    return getIndexDB(key);
  };

  const remove = (key: string): Promise<void> => {
    return del(key);
  };

  const clearAll = clear;

  const setMany = (items: [string, string][]): Promise<void> => {
    return setManyIndexDB(items);
  };

  return Object.freeze({
    set,
    get,
    remove,
    setMany,
    clearAll,
    type,
  });
};

export default indexdDBCaching;
