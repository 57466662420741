import {
  getDirectDebitApprovalProps,
  PassportIDMap,
  PassportPaperSelTableItem,
  SelectedDocumentsProps,
  v5CValidationCharacterProps,
  VedRatesSelTableItem,
} from "./types";
import moment from "moment";
import { documentList, v5CExpandedMark, v5CndividualCharacterValue, v5CRemainderValue } from "./constant";
export const parseJson = (json: string) => {
  try {
    if (typeof json === "string") {
      return JSON.parse(json);
    }
    return null;
  } catch (ex) {
    return null;
  }
};

export const fetchDocumentList = (list: string[]) => {
  const count = {};
  list.forEach((element) => {
    if (documentList[element] === undefined) {
      throw null;
    }
    count[documentList[element]] = (count[documentList[element]] || 0) + 1;
  });
  let finalString = "";
  for (const key in count) {
    if (count[key] > 1) {
      finalString += key + " (" + count[key] + ")\n";
    } else {
      finalString += key + "\n";
    }
  }
  return finalString;
};

export const fetchVRMValidRegex = () => {
  return [
    /^[A-Z]{2}[0-9]$/,
    /^[A-Z]{3}[0-9]$/,
    /^[0-9][A-Z]{2}$/,
    /^[0-9][A-Z]{3}$/,
    /^[A-Z]{2}[0-9]{2}$/,
    /^[0-9]{2}[A-Z]{2}$/,
    /^[A-Z][0-9][A-Z]{3}$/,
    /^[A-Z]{3}[0-9][A-Z]$/,
    /^[A-Z]{3}[0-9]{2}$/,
    /^[A-Z]{2}[0-9]{3}$/,
    /^[0-9]{2}[A-Z]{3}$/,
    /^[0-9]{3}[A-Z]{2}$/,
    /^[0-9]{4}[A-Z]{2}$/,
    /^[A-Z][0-9]{2}[A-Z]{3}$/,
    /^[A-Z]{3}[0-9]{2}[A-Z]$/,
    /^[A-Z]{3}[0-9]{3}$/,
    /^[0-9]{3}[A-Z]{3}$/,
    /^[A-Z]{2}[0-9]{4}$/,
    /^[A-Z][0-9]{3}[A-Z]{2}$/,
    /^[A-Z][0-9]{3}[A-Z]{3}$/,
    /^[A-Z]{3}[0-9]{3}[A-Z]$/,
    /^[A-Z][0-9]$/,
    /^[A-Z][0-9]{2}$/,
    /^[A-Z][0-9]{3}$/,
    /^[A-Z][0-9]{4}$/,
    /^[A-Z]{3}[0-9]{4}$/,
    /^[0-9][A-Z]$/,
    /^[0-9]{2}[A-Z]$/,
    /^[0-9]{3}[A-Z]$/,
    /^[0-9]{4}[A-Z]$/,
    /^[A-Z]{2}[0-9]{2}[A-Z]{3}$/,
  ];
};

export const currencyFormatter = (price: string): string => {
  return "£" + Number(price).toFixed(2);
};

export const getPassportSelTable = (
  paperPassports,
  passportItemIDS: string[],
  passportIDMapping: PassportIDMap
): PassportPaperSelTableItem[] => {
  const sortedPassportType = paperPassports.sort(
    (a, b) => passportItemIDS.indexOf(a.itemID as string) - passportItemIDS.indexOf(b.itemID as string)
  );
  const selectionTable = sortedPassportType.map((item) => ({
    checked: "false",
    id: passportIDMapping[item.itemID as string],
    description: item.longName.trim(),
    productID: item.itemID,
    price: currencyFormatter(item.retailPrice as string),
  }));
  return selectionTable;
};
export const getExpiryDate = (taxationPeriod: number, currentDate) => {
  const expiryDate = currentDate.add(taxationPeriod, "months");
  const expiryMonth = moment(expiryDate).month() + 1;
  const expiryYear = moment(expiryDate).year().toString();
  return {
    expiryMonth: expiryMonth < 10 ? 0 + expiryMonth.toString() : expiryMonth.toString(),
    expiryYear: expiryYear.slice(-1),
  };
};
export const getLicenceSerialNo = (taxationPeriod: string, paymentType: string): string => {
  const prefix = "090";
  const checkDigit = "0";
  const serialNumber = paymentType + "000000";
  const expiryDate = getExpiryDate(parseInt(taxationPeriod), moment());
  return prefix + expiryDate.expiryYear + expiryDate.expiryMonth + serialNumber + checkDigit;
};

export const getVedRatesSelTable = (VehicleData: any): VedRatesSelTableItem[] => {
  let id = 21;
  let indicator: string;
  const selectionTable = VehicleData.VehicleTaxData.map(
    (item, count) => (
      (indicator = item.TaxClassCurrentIndicator === "Y" ? " " + "Y" : ""),
      {
        checked: "false",
        id: id + count,
        description: item.TaxClassCode + " " + item.TaxClassDescription.trim() + indicator,
        price: currencyFormatter(item.VEDRate as string),
        rowData: item,
      }
    )
  );
  return selectionTable;
};

export const getCurrentTaxClass = (VehicleData: any): VedRatesSelTableItem[] => {
  return VehicleData.VehicleTaxData.find((item) => {
    if (item.TaxClassCurrentIndicator === "Y") {
      return item;
    }
  });
};

export const checkDDNAFlag = (VehicleData: any, keyValue: any): any => {
  return VehicleData.VehicleTaxData.some((x) => {
    return x[keyValue].toString() === "DD NA";
  });
};

export const getExpandedRegistrationNo = (expandingRegistrationNoProps: v5CValidationCharacterProps): string => {
  const { vehicleRegistrationNo, matchedRegEx } = expandingRegistrationNoProps;
  if (vehicleRegistrationNo.length === 7) {
    return vehicleRegistrationNo;
  }
  let pointer = 0;
  const expandedMark = (v5CExpandedMark[matchedRegEx] || "").split("");
  const expendedRegistrationNo: string = expandedMark
    .map((value: string) => {
      if (value === "#") {
        return "#";
      }
      if (value === "0") {
        return 0;
      }
      return vehicleRegistrationNo[pointer++];
    })
    .join("");

  return expendedRegistrationNo.toUpperCase();
};

export const getValidationCharacter = (expendedRegistrationNo: string): string => {
  const divisor = 37;
  let total = 0;

  (expendedRegistrationNo || "").split("").forEach((character, index) => {
    const characterValue = v5CndividualCharacterValue[character];
    total += characterValue * (8 - index);
  });

  const remainder = total % divisor;
  const validationCharacter = v5CRemainderValue[remainder];

  return validationCharacter.toString();
};

export const getTxnId = (basketId: string) => {
  return basketId + moment().format("Hms");
};

export const getDirectDebitApprovalRequestBody = (getDirectDebitApprovalProps: getDirectDebitApprovalProps) => {
  const formatDOB = moment(getDirectDebitApprovalProps.Payer.DateOfBirth, "DD/MM/YYYY").format("YYYY-MM-DD");
  getDirectDebitApprovalProps.Payer.DateOfBirth = formatDOB;
  return {
    Channel: getDirectDebitApprovalProps.Channel,
    TransactionIdReference: getDirectDebitApprovalProps.TransactionIdReference,
    Description: getDirectDebitApprovalProps.Description,
    Payment: getDirectDebitApprovalProps.Payment,
    Payer: getDirectDebitApprovalProps.Payer,
    BankAccount: getDirectDebitApprovalProps.BankAccount,
    ConfirmationTermsAndConditions: getDirectDebitApprovalProps.ConfirmationTermsAndConditions,
  };
};

export const getCustomHeader = (applicationId: string, txnId: string) => {
  return {
    "X-GWS-Application-Id": applicationId,
    "X-GWS-Transaction-Date": moment().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
    "X-GWS-Transaction-Id": txnId,
  };
};

export const getDocumentCount = (selectedDocumentsProps: SelectedDocumentsProps) => {
  const { data, isDocumentList, isOtherDocument, isDCS } = selectedDocumentsProps;
  let count = 0;
  let documentString = "";
  let isOtherSelected = false;
  let otherQty = 0;
  let dcs_text = "";

  data.forEach((v) => {
    count = count + parseInt(v.qty);
    if (isDocumentList && v.qty > 0) {
      documentString += v.document;
      if (isDCS) {
        dcs_text += `<Sel><Text>${v.document}</Text><Tag>${v.document}</Tag><Value/></Sel>`;
        documentString += ",";
      } else {
        if (v.qty > 1) {
          documentString += " (" + v.qty + ")";
        }
        documentString += "\n";
      }
    }
    if (isOtherDocument) {
      if (v.document === "Other") {
        isOtherSelected = true;
        otherQty = v.qty;
      }
    }
  });

  return { count, documentString, isOtherSelected, otherQty, dcs_text };
};
