export const MANDATORY_LOST_DAMAGED_CARD_TOKENS = [
  "clerkId",
  "existingCardNumber",
  "accountNumber",
  "newCardNumber",
  "hasAccountUpdate",
  "englishLostOrDamagedReceiptContent",
  "englishReplacementCardReceiptContent",
  "replacementCardDeclinedPromptTitle",
  "replacementCardDeclinedPromptText",
  "timeoutDeclinedPromptTitle",
  "timeoutDeclinedPromptText",
  "systemUnavailablePromptTitle",
  "systemUnavailablePromptText",
  "cardSuspensionDeclinedPromptTitle",
  "cardSuspensionDeclinedPromptText",
  "accountUpdateDeclinedPromptTitle",
  "accountUpdateDeclinedPromptText",
  "transactionCompletePromptTitle",
  "transactionCompletePromptText",
  "updateCustomerAccountPayload",
];

export const MANDATORY_EDIT_ACCOUNT_TOKENS = [
  "englishReceiptContent",
  "welshReceiptContent",
  "accountUpdateDeclinedPromptTitle",
  "accountUpdateDeclinedPromptText",
  "transactionCompletePromptTitle",
  "transactionCompletePromptText",
  "updateCustomerAccountPayload",
  "clerkId",
  "hasAccountUpdate",
  "accountNumber",
  "systemUnavailablePromptTitle",
  "systemUnavailablePromptText",
];

export const MANDATORY_REQUEST_CLOSE_TOKENS = [
  "cyType",
  "enType",
  "englishRequestCloseReceiptContent",
  "welshRequestCloseReceiptContent",
  "requestCloseDeclinedPromptTitle",
  "requestCloseDeclinedPromptText",
  "requestCloseTimeoutPromptTitle",
  "requestCloseTimeoutPromptText",
  "transactionCompletePromptTitle",
  "transactionCompletePromptText",
  "dngAccountNumber",
  "clerkId"
];

export const MANDATORY_COMPLETE_CLOSE_TOKENS = [
  "cyType",
  "enType",
  "englishCompleteCloseReceiptContent",
  "welshCompleteCloseReceiptContent",
  "completeCloseDeclinedPromptTitle",
  "completeCloseDeclinedPromptText",
  "completeCloseTimeoutPromptTitle",
  "completeCloseTimeoutPromptText",
  "transactionCompletePromptTitle",
  "transactionCompletePromptText",
  "dngAccountNumber",
  "clerkId"
];
