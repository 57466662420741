import { AuthProvider } from "./../types";
import responseMocks from "./mock";

interface TransactionDetails {
  AccountNumber: string;
  STATUS: string;
  txId: string;
}

export interface ValidateParams {
  accountNumber: string;
}

export interface Client {
  validate(params: ValidateParams): Promise<Record<string, unknown>>;
}

export interface Props {
  rootUrl: string;
  authHeaders: AuthProvider;
}

const noRecord = () => {
  return Promise.resolve({
    valid: false,
  });
};

export const buildClient = (props: Props): Client => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { rootUrl, authHeaders } = props;

  const processMock = (request: ValidateParams): Promise<Record<string, unknown>> => {
    if (!responseMocks[request.accountNumber] || request.accountNumber === "12345678") {
      return noRecord();
    }
    return Promise.resolve(responseMocks[request.accountNumber]);
  };

  const validate = async (params: ValidateParams): Promise<Record<string, unknown>> => {
    return processMock(params);
  };

  return Object.freeze({ validate });
};
