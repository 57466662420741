import { GetAdditionalItemsParams, AdditionalItemsResponse } from "./types";
import { domCountries, parcelForceLabelPrintServices } from "./helpers";

export const prepareBasketItems = (params: GetAdditionalItemsParams) => {
  const {
    CarrierID,
    specialRequirement,
    postageMethod,
    CountryID,
    serviceValueInPence,
    prepaidStampValue,
    prepaidEnvelopeValue,
    totalPrePaid,
    FrankedProdNo,
    PrepaidStampProdNo,
    PrepaidLabelProdNo,
    PSServicePostageMethods,
    labelPrintNecessary,
  } = params;
  let method: Record<string, string | number | boolean | null> | undefined;
  let additionalItems: unknown[] = [];
  let rejectProdNos: AdditionalItemsResponse["rejectProdNos"];
  const prepaidStampValueInPence = prepaidStampValue ? Math.round(Number(prepaidStampValue) * 100) : 0;
  const prepaidEnvelopeValueInPence = prepaidEnvelopeValue ? Math.round(Number(prepaidEnvelopeValue) * 100) : 0;
  const totalPrePaidValueInPence = totalPrePaid ? Math.round(Number(totalPrePaid) * 100) : 0;

  if (specialRequirement === "FrankedV2" && FrankedProdNo) {
    method = PSServicePostageMethods.find((method) => method.PostageMethodID === "Label");
    rejectProdNos = {
      reject: String(method?.RejectProdNo),
      balancing: String(method?.BalancingProdNo),
    };
    additionalItems = [
      {
        itemID: String(FrankedProdNo),
        valueInPence: -serviceValueInPence,
        quantity: 1,
      },
    ];
  } else if (
    (CarrierID === "RM" && postageMethod === "underPaid" && !domCountries.includes(CountryID)) ||
    (CarrierID === "RM" && postageMethod === "prePaid")
  ) {
    let ReceiptProdNo: number;
    let BalancingProdNo: number;

    if (postageMethod === "underPaid" && !domCountries.includes(CountryID)) {
      method = PSServicePostageMethods.find((method) => method.PostageMethodID === "FP Label");
      ReceiptProdNo = Number(method?.ReceiptProdNo);
      BalancingProdNo = Number(method?.BalancingProdNo);
      rejectProdNos = {
        reject: String(method?.RejectProdNo),
        balancing: String(BalancingProdNo),
      };
    } else {
      method = PSServicePostageMethods.find((method) => method.PostageMethodID === "Label");
      ReceiptProdNo = Number(method?.ReceiptProdNo);
      BalancingProdNo = Number(method?.BalancingProdNo);
      rejectProdNos = {
        reject: String(method?.RejectProdNo),
        balancing: String(BalancingProdNo),
      };
    }

    if (prepaidStampValueInPence === Number(serviceValueInPence) && labelPrintNecessary === "false") {
      additionalItems = [
        {
          itemID: String(PrepaidStampProdNo),
          valueInPence: -prepaidStampValueInPence,
          quantity: 1,
        },
      ];
    } else if (prepaidStampValueInPence === Number(serviceValueInPence)) {
      additionalItems = [
        {
          itemID: String(PrepaidStampProdNo),
          valueInPence: -prepaidStampValueInPence,
          quantity: 1,
        },
        {
          itemID: String(ReceiptProdNo),
          valueInPence: Number(serviceValueInPence) - prepaidStampValueInPence,
          quantity: 1,
        },
        {
          itemID: String(BalancingProdNo),
          valueInPence: -(Number(serviceValueInPence) - prepaidStampValueInPence),
          quantity: 1,
        },
      ];
    } else if (prepaidEnvelopeValueInPence === Number(serviceValueInPence) && labelPrintNecessary === "false") {
      additionalItems = [
        {
          itemID: String(PrepaidLabelProdNo),
          valueInPence: -prepaidEnvelopeValueInPence,
          quantity: 1,
        },
      ];
    } else if (prepaidEnvelopeValueInPence === Number(serviceValueInPence)) {
      additionalItems = [
        {
          itemID: String(PrepaidLabelProdNo),
          valueInPence: -prepaidEnvelopeValueInPence,
          quantity: 1,
        },
        {
          itemID: String(ReceiptProdNo),
          valueInPence: Number(serviceValueInPence) - prepaidEnvelopeValueInPence,
          quantity: 1,
        },
        {
          itemID: String(BalancingProdNo),
          valueInPence: -(Number(serviceValueInPence) - prepaidEnvelopeValueInPence),
          quantity: 1,
        },
      ];
    } else if (prepaidStampValueInPence + prepaidEnvelopeValueInPence === Number(serviceValueInPence)) {
      additionalItems = [
        {
          itemID: String(PrepaidStampProdNo),
          valueInPence: -prepaidStampValueInPence,
          quantity: 1,
        },
        {
          itemID: String(PrepaidLabelProdNo),
          valueInPence: -prepaidEnvelopeValueInPence,
          quantity: 1,
        },
      ];
    } else if (
      prepaidEnvelopeValue &&
      prepaidEnvelopeValue !== "0.00" &&
      prepaidStampValue &&
      prepaidStampValue !== "0.00"
    ) {
      additionalItems = [
        {
          itemID: String(PrepaidStampProdNo),
          valueInPence: -prepaidStampValueInPence,
          quantity: 1,
        },
        {
          itemID: String(PrepaidLabelProdNo),
          valueInPence: -prepaidEnvelopeValueInPence,
          quantity: 1,
        },
        {
          itemID: String(ReceiptProdNo),
          valueInPence: Number(serviceValueInPence) - totalPrePaidValueInPence,
          quantity: 1,
        },
        {
          itemID: String(BalancingProdNo),
          valueInPence: -(Number(serviceValueInPence) - totalPrePaidValueInPence),
          quantity: 1,
        },
      ];
    } else if (
      (!prepaidEnvelopeValue || prepaidEnvelopeValue === "0.00") &&
      prepaidStampValue &&
      prepaidStampValue !== "0.00"
    ) {
      additionalItems = [
        {
          itemID: String(PrepaidStampProdNo),
          valueInPence: -prepaidStampValueInPence,
          quantity: 1,
        },
        {
          itemID: String(ReceiptProdNo),
          valueInPence: Number(serviceValueInPence) - prepaidStampValueInPence,
          quantity: 1,
        },
        {
          itemID: String(BalancingProdNo),
          valueInPence: -(Number(serviceValueInPence) - prepaidStampValueInPence),
          quantity: 1,
        },
      ];
    } else if (
      (!prepaidStampValue || prepaidStampValue === "0.00") &&
      prepaidEnvelopeValue &&
      prepaidEnvelopeValue !== "0.00"
    ) {
      additionalItems = [
        {
          itemID: String(PrepaidLabelProdNo),
          valueInPence: -prepaidEnvelopeValueInPence,
          quantity: 1,
        },
        {
          itemID: String(ReceiptProdNo),
          valueInPence: Number(serviceValueInPence) - prepaidEnvelopeValueInPence,
          quantity: 1,
        },
        {
          itemID: String(BalancingProdNo),
          valueInPence: -(Number(serviceValueInPence) - prepaidEnvelopeValueInPence),
          quantity: 1,
        },
      ];
    } else {
      additionalItems = [
        {
          itemID: String(ReceiptProdNo),
          valueInPence: serviceValueInPence,
          quantity: 1,
        },
        {
          itemID: String(BalancingProdNo),
          valueInPence: -serviceValueInPence,
          quantity: 1,
        },
      ];
    }
  } else {
    let ReceiptProdNo: number;
    let BalancingProdNo: number;

    if (
      CarrierID === "RM" ||
      ((CarrierID === "PF" || CarrierID === "GX") &&
        parcelForceLabelPrintServices.includes(String(PSServicePostageMethods[0].ServiceID)))
    ) {
      method = PSServicePostageMethods.find((method) => method.PostageMethodID === "Label");
      ReceiptProdNo = Number(method?.ReceiptProdNo);
      BalancingProdNo = Number(method?.BalancingProdNo);
      rejectProdNos = {
        reject: String(method?.RejectProdNo),
        balancing: String(BalancingProdNo),
      };
    } else {
      // if Parcelforce
      method = PSServicePostageMethods.find((method) => method.PostageMethodID === "Retail");
      ReceiptProdNo = Number(method?.ReceiptProdNo);
      BalancingProdNo = Number(method?.BalancingProdNo);
      rejectProdNos = {
        reject: String(method?.RejectProdNo),
        balancing: String(BalancingProdNo),
      };
    }

    additionalItems = [
      {
        itemID: String(ReceiptProdNo),
        valueInPence: serviceValueInPence,
        quantity: 1,
      },
      {
        itemID: String(BalancingProdNo),
        valueInPence: -serviceValueInPence,
        quantity: 1,
      },
    ];
  }

  const tokens = {
    altServiceId: method?.AltServiceIdentifier || " ",
    VATcode: method?.VATcode || " ",
    SatDel: method?.SatDelText || " ",
    labelTemplate: method?.LabelTemplate || " ",
    EnglishBrandIdentifier: method?.EnglishBrandIdentifier || " ",
    EnglishText: method?.EnglishText || " ",
    WelshBrandIdentifier: method?.WelshBrandIdentifier || " ",
    WelshText: method?.WelshText || " ",
    ServiceIdentifier: method?.ServiceIdentifier || " ",
  };

  return { additionalBasketItems: additionalItems, rejectProdNos, tokens };
};
