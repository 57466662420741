/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  buildImovoClient,
  RedeemVoucherRequest,
  RedeemVoucherResponse,
  AuthProvider,
} from "postoffice-product-journey-api-clients";
import { brokenPromises } from "../../helpers";
import { FulfilmentStateEnum } from "../../../openapi/transaction-api-v3";
import { ApiClientsConfig, BasketItemPayload, FulfilmentProcessorResponse, MessagingNotice } from "../../../types";
import { createCdpRequestHeader } from "../helpers";

export const process = async (
  basketId: string,
  apiConfig: ApiClientsConfig["imovo"],
  basketItem: BasketItemPayload,
  authProvider: AuthProvider
): Promise<FulfilmentProcessorResponse> => {
  const apiClient = buildImovoClient({
    authHeaders: authProvider,
    ...apiConfig,
  });


  const notice: MessagingNotice = <MessagingNotice>{};
  let hasError = false;

     // Make call to redeem endpoint to update voucher status
     const imovoRedeemResponseObject: RedeemVoucherResponse = await brokenPromises(
      apiClient.getRedeem({
        hdr: createCdpRequestHeader(basketId, basketItem.fulfilment?.clerkId as string)
      } as RedeemVoucherRequest)
    );

    const successfulRequest = imovoRedeemResponseObject.SuccessfulRequest as unknown as boolean;
    const updateCustomerResultStatusCode = {};

    // System Unavailable
    if (!hasError && successfulRequest === false) {
      hasError = true;
      notice.id = basketItem.fulfilment?.systemUnavailablePromptTitle ?? "";
      notice.description = basketItem.fulfilment?.systemUnavailablePromptText ?? "";
    }

    if (hasError) {
      return {
        notice,
        fulfilment: {
          status: FulfilmentStateEnum.Failure,
          tokens: {
            updateAccountApiResponseCode: String(updateCustomerResultStatusCode),
          },
        },
        receipts: [
          {
            template: "imovo/redemption-rejection",
            context: {
              type: "Redemption Rejected",
              receiptMessage: basketItem.fulfilment?.englishLostOrDamagedReceiptContent ?? "",
              entryId: basketItem.entryID,
              productNo: basketItem.itemID,
              
              currentDt: basketItem.tokens?.TIMESTAMP,
              branchName: basketItem.tokens?.BRANCH_NAME,
              branchAddress: basketItem.tokens?.BRANCH_ADDRESS,
              branchPostcode: basketItem.tokens?.BRANCH_POSTCODE,
              sessionId: "TBC",  
              txnID: basketItem.tokens?.POL_TRANSACTION_ID,
              branchID: basketItem.tokens?.BRANCH_CODE,
              nodeID: basketItem.tokens?.receiptReference,
              bp: "01", // Balance Period
              su: "BB", // Stock unit              
              Scheme: "iMovo Voucher Redemption",
              tokenType: "BC", // BC = Bar Code; MC = Magnetic Card
              entryType: "1", // 0 = bar-code; 1 = manual; 2 = magnetic card
              ref: `633729${basketItem.tokens?.PAN || ""}`, 
              prodNo: "50157", 
              customerRef: basketItem.tokens?.customerRefNumber,
              svc: basketItem.tokens?.clientSrvCode,
              rejectionReason: basketItem.fulfilment?.englishLostOrDamagedReceiptContent ?? "",
            },
          },
        ],        
      };
    }

  return {
    fulfilment: {
      status: FulfilmentStateEnum.Success,
      tokens: {
        ...(updateCustomerResultStatusCode !== undefined && {
          updateAccountApiResponseCode: String(updateCustomerResultStatusCode),
        }),
      },
    },
    receipts: [
      {
        template: "imovo/redeem-success",
        context: {
          type: "Redemption Successful",
          textMode: "true",

          // Some values might get removed from receipts in the future
          currentDt: basketItem.tokens?.TIMESTAMP,
          nodeID: basketItem.tokens?.receiptReference,
          bp: "01", // Balance Period
          su: "BB", // Stock unit
          branchName: basketItem.tokens?.BRANCH_NAME,
          branchID: basketItem.tokens?.BRANCH_CODE,
          branchAddress: basketItem.tokens?.BRANCH_ADDRESS,
          branchPostcode: basketItem.tokens?.BRANCH_POSTCODE,
          sessionId: "TBC",  // ToDo: Where to get it?
          txnID: basketItem.tokens?.POL_TRANSACTION_ID,
          Scheme: "iMovo Voucher Redemption",
          tokenType: "BC", // BC = Bar Code; MC = Magnetic Card
          entryType: "1", // 0 = bar-code; 1 = manual; 2 = magnetic card
          ref: `633729${basketItem.tokens?.PAN || ""}`, // ToDo: Double check format. Get PAN
          amount: `-${(+basketItem.valueInPence / 100).toFixed(2)}`,
          prodNo: "50157", // ToDo: Is this a static field?
          customerRef: basketItem.tokens?.customerRefNumber,
          totalToPay: `${(+basketItem.valueInPence / 100).toFixed(2)}`, // ToDo: Double check fees

          entryId: basketItem.entryID,
          productNo: basketItem.itemID,
        },
      },
    ],
    notice: {
      id: basketItem.fulfilment?.transactionCompletePromptTitle ?? "",
      description: basketItem.fulfilment?.transactionCompletePromptText ?? "",
    }
  };
};