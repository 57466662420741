import { AuthProvider } from "postoffice-product-journey-api-clients";
import { FulfilmentStateEnum, TransactionsApiInterface } from "../../openapi/transaction-api-v3";
import {
  ApiClientsConfig,
  BasketItemPayload,
  FulfillerResponse,
  FulfillmentItem,
  FulfilmentProcessorResponse,
  GenericFulfiller,
} from "../../types";
import { FmccActions } from "./types";
import { process as processCreateBranchOrder } from "./actions/createBranchOrder";
import { respond } from "../../helpers";
import { brokenPromises, updateFulfilmentTokens } from "../helpers";

export const FmccFulfiller = (
  client: TransactionsApiInterface,
  apiConfig: ApiClientsConfig["fmcc"],
  basketId: string,
  basketItem: BasketItemPayload,
  userToken: string,
  deviceToken: string
): GenericFulfiller => {
  const authHeaders: AuthProvider = async () => {
    return new Promise((resolve) => {
      resolve({ Authorization: deviceToken });
    });
  };

  const fulfill = async (item: FulfillmentItem): Promise<FulfillerResponse> => {
    if (!item.basketItem?.fulfilment?.action) {
      throw Error("fulfilment.action must be set in journey");
    }

    const fulfilmentAction = item.basketItem.fulfilment.action as FmccActions;

    let response: FulfilmentProcessorResponse;

    switch (fulfilmentAction) {
      case FmccActions.CreateBranchOrder:
        try {
          response =  await brokenPromises(processCreateBranchOrder(basketId, apiConfig, basketItem, authHeaders));
        } catch (err) {
          response = {fulfilment: {status: FulfilmentStateEnum.Success, tokens: {}}}
        }
        break;
      default:
        throw new Error(`Unknown fulfilment action: ${item.basketItem?.fulfilment?.action}`);
    }

    const fulfilmentStatus = response.fulfilment?.status ?? FulfilmentStateEnum.Failure;
    const fulfilmentTokens = response.fulfilment?.tokens ?? {};

    const updated = await updateFulfilmentTokens(
      client,
      basketId,
      String(basketItem.entryID),
      fulfilmentStatus,
      fulfilmentTokens,
      userToken
    );

    return respond(updated, {
      result: {},
    });
  };

  return Object.freeze({ fulfill });
};
