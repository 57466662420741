import axios, { AxiosError, AxiosResponse } from "axios";
import { ApiError } from "types";
import {
  Client,
  ConsumerLookupInfo,
  CountryResponse,
  ConsumerLookupResponse,
  Props,
  TypeInputSearchData,
  ConsumerLookupRequest,
  ConsumerLookupParams,
  MAX_RECEIVERS_TO_RETURN,
  MAX_SENDERS_TO_RETURN,
  SenderInfo,
} from "./structures";
import { APIRequest, MoneyGramRequestHeaders, APIResponse, MoneyGramRequestParams } from "./common";
import { buildGetIndicativeRateEnabler } from "./get-indicative-rate";

export const buildClient = (props: Props): Client => {
  const { rootUrl, authHeaders } = props;

  const lookupSender = async (params: ConsumerLookupParams): Promise<APIResponse<ConsumerLookupInfo> | ApiError> => {
    try {
      /**
       * safely construct request body - utilise spread operator to only populate fields required by corresponding workflow
       * API lists first four fields as optional; can lookup via name + phone number OR plusRewardsCardNumber
       */
      const reqBody: ConsumerLookupRequest = {
        ...(params.firstName && { SFN: params.firstName }),
        ...(params.lastName && { SLN: params.lastName }),
        ...(params.phoneNumber && { CUSTPH: params.phoneNumber }),
        ...(params.plusRewardsCardNum && { FREQCUSTCARDNUM: params.plusRewardsCardNum }),
        MXSNDTRET: MAX_SENDERS_TO_RETURN,
        MXRECTRET: MAX_RECEIVERS_TO_RETURN,
      };
      const req: APIRequest<ConsumerLookupRequest> = {
        hdr: extractHeaderInfo(params),
        body: reqBody,
      };
      const response = await axios.post(`${rootUrl}/moneygram/convenience/consumer/lookup`, req, {
        headers: await authHeaders(),
      });
      const responseData = response.data as APIResponse<ConsumerLookupResponse>;
      const responseDataWithPlusCardInfo: SenderInfo[] | undefined = responseData.body?.SINFO.map((res) => ({
        ...res,
        hasMGPlusCard: Boolean(res.FREQCUSTCARDNUM),
      }));

      return {
        hdr: responseData?.hdr,
        userInfo: responseDataWithPlusCardInfo,
        numCustomersFound: responseDataWithPlusCardInfo?.length,
        status: responseData?.status,
      } as APIResponse<ConsumerLookupInfo>;
    } catch (error: unknown) {
      return processError(error as AxiosError) as APIResponse<ConsumerLookupInfo> | ApiError;
    }
  };

  const getCountryList = async (): Promise<TypeInputSearchData[]> => {
    const response = (await axios.post(
      `${rootUrl}/moneygram/maintenance/country/list`,
      {
        hdr: {
          created: "2018-12-20T02:49:45",
          reqId: "271219",
          txId: "2008580-99-271219",
          branchId: "004948",
          ctrId: "99",
          clerkId: "MAN001",
        },
        body: { SEARTYP: "4" },
      },
      {
        headers: await authHeaders(),
      }
    )) as AxiosResponse<APIResponse<CountryResponse>>;

    const responseData = response.data.body?.CTRYINF;

    const formattedCountryList = responseData
      ? responseData.map((country) => {
          return {
            title: country.CTRYNAME,
            value: country.CTRYC,
          };
        })
      : [];
    return formattedCountryList;
  };

  const extractHeaderInfo = (params: MoneyGramRequestParams): MoneyGramRequestHeaders => {
    return {
      created: params.created,
      reqId: params.reqId,
      branchId: params.branchId,
      ctrId: params.ctrId,
      txId: params.txId,
      clerkId: params.clerkId,
    } as MoneyGramRequestHeaders;
  };

  /**
   * Resolve error based on status code
   * @param error AxiosError
   * @returns Promise<unknown>
   */
  const processError = (error: AxiosError): Promise<unknown> => {
    if (error.response?.status === 424) {
      return Promise.resolve(error.response?.data);
    }

    return Promise.reject({
      statusCode: error.response?.status || 500,
      ...(error.response?.data && error.response?.data),
    });
  };

  return Object.freeze({
    getCountryList,
    lookupSender,
    getIndicativeRate: buildGetIndicativeRateEnabler(rootUrl, authHeaders),
  });
};
