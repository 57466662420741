import { AuthProvider, EnablerAPIClientNames } from "../types";
import { axiosClient } from "../lib/axiosWrapper";
import cacheRegistry from "../lib/cache/registry";
import { CacheTypes } from "../lib/cache";
import {
  mapPipFormatResponse,
  domCountries,
  specialRequirementConsts,
  mapNullToString,
  parcelForceLabelPrintServices,
} from "./helpers";
import { prepareBasketItems } from "./prepareBasketItems";
import { prepareCopItems } from "./prepareCopItems";

import postageSaleFilterOptions from "./__fixtures__/postageSaleFilterOptions.json";
import pricingEngineStub from "./__fixtures__/pricingEngineStub.json";
import messageIdStub from "./__fixtures__/messageIdStub.json";

import {
  Districts,
  GetPipFormatParams,
  GetServiceParams,
  PostcodeCheckParams,
  GetPostageMethodParams,
  NonGuaranteedPostcode,
  GetCopFieldsParams,
  CopFieldsResponse,
  PipFormatResponse,
  PipFormatServiceSelectResponse,
  ServiceResponse,
  PostcodeCheckResponse,
  PostageMethodResponse,
  GetMessageIdParams,
  MessageIdResponse,
  GetAdditionalItemsParams,
  AdditionalItemsResponse,
  GetPostageMethodBracketParams,
  PSServicePostageMethod,
} from "./types";

const TTL = 120;
const cacheKey = "_postalData";
const cacheKeySuffix = {
  pipFormat: "pip-formats",
  districts: "districts",
};

export interface Client {
  getPipFormat(params: GetPipFormatParams): Promise<PipFormatServiceSelectResponse>;
  getDistrict(params: Districts): Promise<Record<string, unknown>>;
  getService(params: GetServiceParams): Promise<ServiceResponse>;
  checkDomesticPostcode(params: PostcodeCheckParams): Promise<PostcodeCheckResponse>;
  getServiceStub(params): Promise<ServiceResponse>;
  getPostageMethods(params: GetPostageMethodParams): Promise<PostageMethodResponse>;
  getPostageMethodBracket(params: GetPostageMethodBracketParams): Promise<PSServicePostageMethod>;
  getMessageId(params: GetMessageIdParams): Promise<MessageIdResponse>;
  getAdditionalItems(params: GetAdditionalItemsParams): Promise<AdditionalItemsResponse>;
  getCopFields(params: GetCopFieldsParams): Promise<CopFieldsResponse>;
}
export interface Props {
  rootUrl: string;
  authHeaders: AuthProvider;
}

export const buildClient = (props: Props): Client => {
  const { rootUrl, authHeaders } = props;

  const getPipFormat = async (params: GetPipFormatParams): Promise<PipFormatServiceSelectResponse> => {
    const { countryID, weight } = params;
    const pipFormatResponse = await axiosClient.get<PipFormatResponse[]>(
      {
        url: `${rootUrl}/postal/pip-formats`,
        headers: await authHeaders(),
      },
      { type: CacheTypes.TIMED, ttl: TTL, key: `${cacheKey}/${cacheKeySuffix.pipFormat}` }
    );
    const nonGuaranteedPostcodes = await axiosClient.get<NonGuaranteedPostcode[]>({
      url: `${rootUrl}/postal/non-guaranteed-postcodes`,
      headers: await authHeaders(),
    });
    return {
      ...mapPipFormatResponse({ pipFormatResponse, countryID, weight }),
      ...postageSaleFilterOptions, // Append filter options stub for postageSale service selection
      nonGuaranteedPostcodes, // Append non-guaranteed postcodes stub for postageSale service selection
    };
  };

  const getPostageMethods = async (params: GetPostageMethodParams): Promise<PostageMethodResponse> => {
    const { specialRequirement, countryID, carrierId, serviceBaseAmount, postageMethodsRefData } = params;

    const response: any = [];

    const printLabelObj = {
      title: "Print Label",
      value: "printLabel",
    };

    const prePaidObj = {
      title: "Pre-paid",
      value: "prePaid",
    };

    const underpaidObj = {
      title: "Underpaid label",
      value: "underPaid",
    };

    response.push(printLabelObj);
    if (specialRequirementConsts.includes(specialRequirement) || Number(serviceBaseAmount) === 0) {
      return response as unknown as PostageMethodResponse;
    }
    if (carrierId == "RM") {
      response.push(prePaidObj);
      if (!domCountries.includes(countryID)) {
        response.push(underpaidObj);
      }
    }
    return response as unknown as PostageMethodResponse;
  };

  const getPostageMethodBracket = async (params: GetPostageMethodBracketParams): Promise<PSServicePostageMethod> => {
    const { PSServicePostageMethods, postageMethod, CountryID, CarrierID, ServiceID } = params;
    let bracket: PSServicePostageMethod;
    if (CarrierID === "PF" && !parcelForceLabelPrintServices.includes(ServiceID)) {
      bracket = PSServicePostageMethods.find(
        (bracket) => bracket.PostageMethodID === "Retail"
      ) as PSServicePostageMethod;
    } else if (postageMethod === "underPaid" && !domCountries.includes(CountryID)) {
      bracket = PSServicePostageMethods.find(
        (bracket) => bracket.PostageMethodID === "FP Label"
      ) as PSServicePostageMethod;
    } else {
      bracket = PSServicePostageMethods.find(
        (bracket) => bracket.PostageMethodID === "Label"
      ) as PSServicePostageMethod;
    }
    return bracket as PSServicePostageMethod;
  };

  const getDistrict = async (params: Districts): Promise<Record<string, unknown>> => {
    const response = await axiosClient.get(
      {
        url: `${rootUrl}/postal/districts`,
        headers: await authHeaders(),
        params,
      },
      { type: CacheTypes.TIMED, ttl: TTL, key: `${cacheKey}/${cacheKeySuffix.districts}` }
    );
    return response as unknown as Record<string, unknown>;
  };

  const getService = async (params: GetServiceParams): Promise<ServiceResponse> => {
    const { specialReq } = params;
    const specialReqs: string[] = [];
    specialReq && specialReqs.push(String(specialReq));
    params.specialReq = JSON.stringify(specialReqs);

    const response = await axiosClient.get<any>({
      url: `${rootUrl}/postal/services`,
      headers: await authHeaders(),
      params,
    });

    // Convert null values to string ""
    // TODO: request refData not to send null value
    const upd_Services = mapNullToString(response.Services as unknown as any[]);
    response.Services = upd_Services.filter((service) => service.ServiceBaseAmount !== null);

    return response as unknown as ServiceResponse;
  };

  const getServiceStub = async (params: GetServiceParams): Promise<ServiceResponse> => {
    // Stubbed response until the services endpoint is ready to use
    return pricingEngineStub as ServiceResponse;
  };

  const checkDomesticPostcode = async (params: PostcodeCheckParams): Promise<PostcodeCheckResponse> => {
    const { postcode, country } = params;
    const outwardCode = postcode.replace(/\s+/g, "").slice(0, -3).toUpperCase();
    if (country === "GBR") {
      try {
        await axiosClient.get({
          url: `${rootUrl}/postal/districts?districtId=${outwardCode}`,
          headers: await authHeaders(),
        });
        return true as unknown as PostcodeCheckResponse;
      } catch (err) {
        return false as unknown as PostcodeCheckResponse;
      }
    } else if (country === "JEY") {
      return (outwardCode.slice(0, 2) === "JE") as unknown as PostcodeCheckResponse;
    } else if (country === "GGY") {
      return (outwardCode.slice(0, 2) === "GY") as unknown as PostcodeCheckResponse;
    } else {
      return false as unknown as PostcodeCheckResponse;
    }
  };

  const getMessageId = async (params: GetMessageIdParams): Promise<MessageIdResponse> => {
    const { messageId } = params;
    const obj = messageIdStub.filter((item) => item.MessageId === messageId);
    const MessageIdText = obj[0].Text;
    const MessageIdTitle = obj[0].Title;
    return { MessageIdTitle, MessageIdText } as MessageIdResponse;
  };

  const getAdditionalItems = async (params: GetAdditionalItemsParams): Promise<AdditionalItemsResponse> => {
    return prepareBasketItems(params) as AdditionalItemsResponse;
  };

  const getCopFields = async (params: GetCopFieldsParams): Promise<CopFieldsResponse> => {
    return prepareCopItems(params);
  };

  cacheRegistry.register({
    name: EnablerAPIClientNames.postal,
    prefix: cacheKey,
    keys: Object.keys(cacheKeySuffix),
  });

  return Object.freeze({
    getPipFormat,
    getDistrict,
    getService,
    checkDomesticPostcode,
    getServiceStub,
    getPostageMethods,
    getPostageMethodBracket,
    getMessageId,
    getAdditionalItems,
    getCopFields,
  });
};
