/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  IngenicoPedClient,
  PedError,
  PedResult,
  requiresUndo,
  getReceiptOutcomeMessage,
  ReceiptLanguage,
  replaceTicketPlaceholders,
  AccountBalanceItem,
  AcquirerResponseCodes,
  FEE_ACCEPTED_MESSAGES,
  formatCurrencyAmount,
  INDETERMINATE_FALLBACK_MESSAGE,
  INDETERMINATE_FALLBACK_RECEIPT,
  PAYMENT_ACTIONS,
  PedActions,
} from "postoffice-peripheral-management-service";
import { FulfilmentStateEnum, TransactionsApiInterface } from "../../openapi/transaction-api-v3";

import { PedFulfillerT, FulfillmentItem, FulfillerResponse } from "../../types";
import { getConnectivityFailureContent, getCriticalFailureContent, mapFulfilmentTokens } from "./helpers";
import { getReceipts } from "./receipts";
import { PEDFulfillerActions, FulfillerActionsToPedAction } from "./types";

export type ReceiptTemplates = {
  [key: string]: string;
};

// ped rejects if its not approved but we
// handle in the same way when processing it
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const brokenPromises = async (promise: Promise<any>) => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return await promise;
  } catch (error) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return error;
  }
};

const respond = (approved: boolean, response: FulfillerResponse): Promise<FulfillerResponse> => {
  return approved ? Promise.resolve(response) : Promise.reject(response);
};

export const PEDFulfiller = (
  client: TransactionsApiInterface,
  basketID: string,
  userToken?: string,
  pedClient?: IngenicoPedClient
): PedFulfillerT => {
  /**
   * Update fulfilment tokens
   * In the case of a lack of network connectivity, axios will throw an exception,
   * and if we don't have an expected response, we receive an AxiosPromise object, which contains status. In
   * this case we swallow the exception and check for the, present of status code which we expect it to be
   * a 200 OK or presence of isAxiosError.
   * @param entryId string
   * @param status FulfilmentStateEnum
   * @param fulfilmentTokens Record<string, string>
   * @returns Promise<boolean>
   */
  const updateFulfilmentTokens = async (
    entryId: string,
    status: FulfilmentStateEnum,
    fulfilmentTokens: Record<string, string>
  ): Promise<boolean> => {
    const transactionApiFulfilmentResponse = await brokenPromises(
      client.updateBasketEntryFulfilment(basketID, entryId, userToken, {
        fulfilmentState: status,
        fulfilmentTokens,
      })
    );

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (transactionApiFulfilmentResponse.isAxiosError || transactionApiFulfilmentResponse?.status !== 200) {
      return false;
    }

    return true;
  };

  /**
   * Record fatal error
   * @param entryId       string
   * @param journeyTokens Record<string, string>
   * @param pedResponse   PedResult
   * @returns Promise<boolean>
   */
  const recordFatalError = async (
    entryId: string,
    journeyTokens?: Record<string, string>,
    pedResponse?: PedResult
  ): Promise<boolean> => {
    let panValue = FulfilmentStateEnum.Indeterminate as string;

    if (pedResponse?.tokenisedPan) {
      panValue = pedResponse.tokenisedPan;
    } else if (journeyTokens?.pan) {
      panValue = journeyTokens.pan;
    }

    return updateFulfilmentTokens(entryId, FulfilmentStateEnum.Indeterminate, {
      pan: panValue,
      responseCode: pedResponse?.metadata?.responseCode ?? FulfilmentStateEnum.Indeterminate,
      transactionResultCode: pedResponse?.metadata?.resultCode ?? FulfilmentStateEnum.Indeterminate,
      transactionType: pedResponse?.metadata?.transactionType?.toString() ?? FulfilmentStateEnum.Indeterminate,
      horizonTransactionID: journeyTokens?.horizonTransactionID ?? FulfilmentStateEnum.Indeterminate,
      methodOfEntry: (pedResponse?.entryMode as string) ?? FulfilmentStateEnum.Indeterminate,
      paymentId: (pedResponse?.paymentId as string) ?? FulfilmentStateEnum.Indeterminate,
    });
  };

  const fulfill = async (item: FulfillmentItem): Promise<FulfillerResponse> => {
    if (!pedClient) {
      await recordFatalError(item.basketItem?.entryID.toString(), item?.basketItem?.tokens);
      throw new Error("Device trigger is not defined");
    }

    // TODO: this will be passed in from reference data
    const language = ReceiptLanguage.English;
    const indeterminateFallbackReceipt = INDETERMINATE_FALLBACK_RECEIPT as string;
    const indeterminateFallbackMessage = INDETERMINATE_FALLBACK_MESSAGE as string;

    // default fulfillment status
    let fulfilmentTokens = {};

    const fulfilmentAction = item?.basketItem?.tokens?.fulfilmentAction;

    const pedFulfillerActions = Object.values(PEDFulfillerActions);
    if (!fulfilmentAction || !pedFulfillerActions.includes(fulfilmentAction as PEDFulfillerActions)) {
      throw new Error(`Unknown fulfiller action: ${fulfilmentAction || "unknown"}`);
    }
    const entryId = item.basketItem?.entryID.toString();
    const action = fulfilmentAction as PEDFulfillerActions;
    const isPaymentTransaction = PAYMENT_ACTIONS.includes(fulfilmentAction as PedActions);
    let result: PedResult | PedError;

    const transactionId = item.basketItem?.tokens?.horizonTransactionID;

    // this is only applicable on cash deposits / balance
    // enquiries it's enforced at PMS level and PED level
    const shouldSkipPin = item.basketItem?.tokens?.skipPin === "true";

    if (!transactionId) {
      throw new Error("horizonTransactionID is a mandatory token for banking / payments");
    }

    const amount = item.basketItem.valueInPence;

    switch (action) {
      case PEDFulfillerActions.BalanceEnquiry:
        result = await brokenPromises(pedClient.balanceEnquiry(transactionId, shouldSkipPin));
        break;
      case PEDFulfillerActions.CashDeposit:
        result = await brokenPromises(pedClient.deposit(amount, transactionId, shouldSkipPin));
        break;
      case PEDFulfillerActions.CashWithdrawal:
        result = await brokenPromises(pedClient.withdrawal(amount, transactionId));
        break;
      case PEDFulfillerActions.PinChange:
        result = await brokenPromises(pedClient.changePin(transactionId));
        break;
      case PEDFulfillerActions.Debit:
        result = await brokenPromises(pedClient.debit(amount, transactionId));
        break;
      case PEDFulfillerActions.Refund:
        result = await brokenPromises(pedClient.refund(amount, transactionId));
        break;
      default:
        throw new Error(`Unknown fulfiller action: ${fulfilmentAction || "unknown"}`);
    }

    const fallbackReceiptContent = replaceTicketPlaceholders(
      indeterminateFallbackReceipt,
      transactionId,
      indeterminateFallbackMessage
    );

    // if we don't have a result, we have a critical error, in the absence of a PED response,
    // we still need to inform the clerk and customer via receipts to ensure they're aware of the outcome
    if (!result || !result.combinedCode) {
      let failureContent = getCriticalFailureContent(isPaymentTransaction, item, action, fallbackReceiptContent);

      if (!(await recordFatalError(item.basketItem?.entryID.toString(), item?.basketItem?.tokens, result))) {
        failureContent = getConnectivityFailureContent(item, action, fallbackReceiptContent);
      }

      return respond(false, failureContent);
    }

    fulfilmentTokens = mapFulfilmentTokens(item, result, transactionId);

    // default status
    let state = FulfilmentStateEnum.Failure;

    if (result.approved) {
      state = FulfilmentStateEnum.Success;
    }

    /**
     * if a specific error code is returned by the PED, although
     * worldline in most cases will automatically invoke banking undo,
     * they recommend that we also call it to be sure it's processed
     * as quick as possible.
     *
     * Flow for undo:
     * Flag to TXNAPI -> Banking Undo Event Processor -> APPMOD -> Worldline
     */
    if (requiresUndo(result)) {
      state = FulfilmentStateEnum.Indeterminate;
      result.customerTicket = indeterminateFallbackReceipt;
      result.merchantTicket = indeterminateFallbackReceipt;
    }

    if (!(await updateFulfilmentTokens(entryId, state, fulfilmentTokens))) {
      return respond(false, getConnectivityFailureContent(item, action, fallbackReceiptContent));
    }

    const acquirerResponseCode = result.acquirerResponseCode as AcquirerResponseCodes;
    const pedAction = FulfillerActionsToPedAction[action];

    let outcomeMessage = getReceiptOutcomeMessage(pedAction, result.combinedCode, language, acquirerResponseCode);

    // include transaction processing fee message if present in ped response
    if (result.approved && result.transactionProcessingFeeAmount) {
      const processingFee = formatCurrencyAmount(Number(result.transactionProcessingFeeAmount), "GBP");
      outcomeMessage = FEE_ACCEPTED_MESSAGES[language].replace("%TransactionFee%", processingFee);
    } else if (result.approved) {
      outcomeMessage = "";
    }

    let balances: AccountBalanceItem[] = [];

    if (result.balances) {
      balances = result.balances;
    }

    if (result.merchantTicket) {
      result.merchantTicket = replaceTicketPlaceholders(result.merchantTicket, transactionId, outcomeMessage, balances);
    }

    if (result.customerTicket) {
      result.customerTicket = replaceTicketPlaceholders(result.customerTicket, transactionId, outcomeMessage, balances);
    }

    const response: FulfillerResponse = {
      result,
    };

    if (result.prompt !== undefined) {
      response.notice = result.prompt;
    }

    const receipts = getReceipts(item, action, result);

    if (receipts.length) {
      response.receipts = receipts;
    }

    return respond(result.approved as boolean, response);
  };

  return Object.freeze({ fulfill });
};

export default PEDFulfiller;
