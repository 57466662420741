import { AuthProvider } from "../types";
import { APIResponse, MoneyGramRequestParams, MoneyGramResponseHeaders } from "./common";
import axios, { AxiosError, AxiosResponse } from "axios";
import { EnablerResponse, handleError } from "./enabler";

export type IndicativeRateEnabler = (
  params: GetIndicativeRateParams
) => Promise<EnablerResponse<GetIndicativeRateResult>>;

export const buildGetIndicativeRateEnabler = (rootUrl: string, authHeaders: AuthProvider): IndicativeRateEnabler => {
  return async (params: GetIndicativeRateParams): Promise<EnablerResponse<GetIndicativeRateResult>> => {
    try {
      const response: AxiosResponse<APIResponse<IndicativeRateResponse>> = await axios.post(
        `${rootUrl}/moneygram/maintenance/country/currency/delopt`,
        {
          hdr: {
            created: "2018-12-20T02:49:45",
            reqId: "271219",
            txId: "2008580-99-271219",
            branchId: "004948",
            ctrId: "99",
            clerkId: "MAN001",
          },
          body: { CTRYC: params.countryCode },
        },
        {
          headers: await authHeaders(),
        }
      );

      const responseData = response.data.body?.CCDOI || [];
      const indicativeRateAvailable = responseData.some((opt) => opt.INDICRTEAVAIL);
      return {
        type: "SUCCESS",
        data: { indicativeRate: indicativeRateAvailable },
        metadata: response.data.hdr as MoneyGramResponseHeaders,
      };
    } catch (e) {
      return handleError(e as AxiosError);
    }
  };
};
export interface GetIndicativeRateParams extends MoneyGramRequestParams {
  countryCode: string;
}

export interface GetIndicativeRateResult {
  indicativeRate: boolean;
}

export interface IndicativeRateResponse {
  CCDOI: DeliveryOptionInfo[];
}

export interface DeliveryOptionInfo {
  INDICRTEAVAIL: boolean;
}
