import { generateCode128 } from "./generateCode128";
import { generateQRCode } from "./generateQRCode";

function escapeBarcode(code: string): string {
  const length = code.length.toString();
  return `\u001B|${length}E${code}`
}

export function generateBarcode(barcode: string, type: string): string {
  switch (type) {
    case "code128":
      return escapeBarcode(generateCode128(barcode));
    case "qrcode":
      return escapeBarcode(generateQRCode(barcode));
    default:
      return `Unrecognized barcode type: ${type}`;
  }
}
