import { PedActions } from "../types";

export const BALANCE_ENQUIRY_TRANSACTION_TYPE = 81;
export const WITHDRAWAL_TRANSACTION_TYPE = 83;
export const DEPOSIT_TRANSACTION_TYPE = 82;
export const CHANGE_PIN_TRANSACTION_TYPE = 86;
export const DEBIT_TRANSACTION_TYPE = 91;
export const REFUND_TRANSACTION_TYPE = 92;

export const ActionToTransactionType = {
  [PedActions.BalanceEnquiryX]: BALANCE_ENQUIRY_TRANSACTION_TYPE,
  [PedActions.BalanceEnquiryY]: BALANCE_ENQUIRY_TRANSACTION_TYPE,
  [PedActions.WithdrawalX]: WITHDRAWAL_TRANSACTION_TYPE,
  [PedActions.WithdrawalY]: WITHDRAWAL_TRANSACTION_TYPE,
  [PedActions.DepositX]: DEPOSIT_TRANSACTION_TYPE,
  [PedActions.DepositY]: DEPOSIT_TRANSACTION_TYPE,
  [PedActions.ChangePinX]: CHANGE_PIN_TRANSACTION_TYPE,
  [PedActions.ChangePinY]: CHANGE_PIN_TRANSACTION_TYPE,
  [PedActions.DebitX]: DEBIT_TRANSACTION_TYPE,
  [PedActions.DebitY]: DEBIT_TRANSACTION_TYPE,
  [PedActions.RefundX]: REFUND_TRANSACTION_TYPE,
  [PedActions.RefundY]: REFUND_TRANSACTION_TYPE,
};
