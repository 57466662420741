import axios from "axios";
import { axiosClient } from "../lib/axiosWrapper";
import { AuthProvider } from "../types";
import { Params, GetEuCountriesParams, ICountries, IPostalCountries, IPostalKahalaCountries } from "./types";

export interface Props {
  rootUrl: string;
  authHeaders: AuthProvider;
}

export interface Client {
  getCountry(params: Params): Promise<ICountries>;
  getCountryForOptions(params: Params): Promise<ICountries>;
  getPostalCountry(params: Params): Promise<IPostalCountries[]>;
  getPostalCountryForOptions(params: Params): Promise<IPostalKahalaCountries[]>;
  getPostalCountryForOptionsOmitUK(params: Params): Promise<IPostalKahalaCountries[]>;
  getEuCountries(getEuCountriesParams?: GetEuCountriesParams): Promise<IPostalCountries[]>;
}

export const buildClient = (props: Props): Client => {
  const { rootUrl: root, authHeaders } = props;

  // **WARNING** - this api will be deprecated in the next major version.
  const getCountry = async (params: Params): Promise<ICountries> => {
    console.warn("WARNING - This method will be deprecated in version 4.0.0, consider using getPostalCountry instead");
    const response = await axios.get(`${root}/product/countries`, {
      headers: await authHeaders(),
      params,
    });

    return response.data as ICountries;
  };

  // **WARNING** - this api will be deprecated in the next major version.
  const getCountryForOptions = async (params: Params): Promise<ICountries> => {
    console.warn(
      "WARNING - This method will be deprecated in version 4.0.0, consider using getPostalCountryForOptions instead"
    );
    const response = await axios.get(`${root}/product/countries`, {
      headers: await authHeaders(),
      params,
    });
    return response.data.map((item) => {
      return {
        title: item.countryName,
        value: item.countryCode,
        ...item,
      };
    });
  };

  const getPostalCountry = async (params: Params): Promise<IPostalCountries[]> => {
    const response = await axios.get(`${root}/postal/destinations`, {
      headers: await authHeaders(),
      params,
    });

    return response.data.map((item: IPostalCountries) => {
      return {
        title: item.SearchMe,
        value: item.CountryID,
        ...item,
      };
    });
  };

  const getPostalCountryForOptions = async (params: Params): Promise<IPostalKahalaCountries[]> => {
    const response = await axios.get(`${root}/postal/destinations`, {
      headers: await authHeaders(),
      params,
    });

    return response.data.map((item: IPostalKahalaCountries) => {
      return {
        title: item.KahalaCountryName,
        value: item.KahalaCountryCode,
        countryName: item.KahalaCountryName,
        iso2Code: item.KahalaCountryCode,
        countryCode: item.KahalaCountryCode,
        ...item,
      };
    });
  };

  const getPostalCountryForOptionsOmitUK = async (params: Params): Promise<IPostalKahalaCountries[]> => {
    const response = (await axiosClient.get({
      url: `${root}/postal/destinations`,
      headers: await authHeaders(),
      params,
    })) as unknown as IPostalKahalaCountries[];

    return response
      .filter((item: IPostalKahalaCountries) => item.KahalaCountryCode !== "GB")
      .map((item: IPostalKahalaCountries) => {
        return {
          title: item.KahalaCountryName,
          value: item.KahalaCountryCode,
          countryName: item.KahalaCountryName,
          iso2Code: item.KahalaCountryCode,
          countryCode: item.KahalaCountryCode,
          ...item,
        };
      });
  };

  const getEuCountries = async (getEuCountriesParams?: GetEuCountriesParams): Promise<IPostalCountries[]> => {
    const response = await axios.get(`${root}/postal/destinations`, {
      headers: await authHeaders(),
    });

    const eftaCountryIds = ["Switzerland", "Liechenstein", "Norway", "Iceland"];

    return response.data
      .filter((item: IPostalCountries) => {
        if (item.EU) {
          if (getEuCountriesParams?.omitUk && item.CountryID === "United Kingdom") {
            return false;
          }
          return true;
        }
        if (getEuCountriesParams?.includeEfta && eftaCountryIds.includes(item.CountryID)) {
          return true;
        }
        return false;
      })
      .map((item: IPostalCountries) => {
        return {
          title: item.SearchMe,
          value: item.CountryID,
          ...item,
        };
      });
  };

  return Object.freeze({
    getCountry,
    getCountryForOptions,
    getPostalCountry,
    getPostalCountryForOptions,
    getPostalCountryForOptionsOmitUK,
    getEuCountries,
  });
};
