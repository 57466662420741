import { isError } from "../../helpers";
import WebSocketAsPromised from "websocket-as-promised";
import { SupportedServices, Response } from "../../types";

import { AdminActions, adminClient, AdminClientProps, AdminResponse } from "./types";

export const buildAdminClient = (
  requestHandler: WebSocketAsPromised["sendRequest"],
  props?: AdminClientProps
): adminClient => {
  const performAction = async (action: AdminActions): Promise<AdminResponse> => {
    if (props?.useMock) {
      if (action === AdminActions.GetVersion) {
        return {
          release: "Mock 1.0.0",
          hash: "e0e3a2b6471d044a53a7757994b51dd33c6b3ec90e1aca21cebc8e2ae79d6d9b",
          built: "1671022575",
        };
      }
    }

    const response = (await requestHandler({
      service: SupportedServices.Admin,
      action,
    })) as Response;

    if (isError(response)) {
      return Promise.reject(response);
    }

    return response.result as AdminResponse;
  };

  return Object.freeze({
    getVersion: () => performAction(AdminActions.GetVersion),
  });
};
