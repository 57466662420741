import {
  buildDropAndGoClient,
  AuthProvider,
  UpdateDngAccountResponse,
  UpdateDngAccStatusReqBodyUpdateDnGAccountStatusToEnum,
  UpdateDngAccStatusRequest,
} from "postoffice-product-journey-api-clients";
import { brokenPromises, isMatchingCode } from "../../helpers";
import { FulfilmentStateEnum } from "../../../openapi/transaction-api-v3";
import { ApiClientsConfig, BasketItemPayload, FulfilmentProcessorResponse, MessagingNotice } from "../../../types";
import { createCdpRequestHeader } from "../helpers";
import { MANDATORY_REQUEST_CLOSE_TOKENS } from "../mandatoryTokens";

export const process = async (
  basketId: string,
  apiConfig: ApiClientsConfig["dropandgo"],
  basketItem: BasketItemPayload,
  authProvider: AuthProvider
): Promise<FulfilmentProcessorResponse> => {
  const apiClient = buildDropAndGoClient({
    authHeaders: authProvider,
    ...apiConfig,
  });

  const missingJourneyTokens: string[] = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const requiredToken of MANDATORY_REQUEST_CLOSE_TOKENS) {
    if (!basketItem.fulfilment || !basketItem.fulfilment[requiredToken]) {
      missingJourneyTokens.push(requiredToken);
    }
  }

  if (missingJourneyTokens.length) {
    return {
      notice: {
        id: "Journey Error",
        description: `${missingJourneyTokens.join(", ")} key/values must be passed from journey`,
      },
      fulfilment: {
        status: FulfilmentStateEnum.Failure,
        tokens: {},
      },
    };
  }
  const notice: MessagingNotice = <MessagingNotice>{};
  let hasError = false;

  // 1. Update customer details
  const updateCustomerResult: UpdateDngAccountResponse = await brokenPromises(
    apiClient.updateAccountStatus({
      hdr: createCdpRequestHeader(basketId, basketItem.fulfilment?.clerkId as string),
      body: {
        dnGAccountNumber: basketItem.fulfilment?.dngAccountNumber as string,
        updateDnGAccountStatusTo: UpdateDngAccStatusReqBodyUpdateDnGAccountStatusToEnum.PendingClosure,
      },
    } as UpdateDngAccStatusRequest)
  );

  if (updateCustomerResult.status?.rcode === undefined) {
    hasError = true;
    notice.id = basketItem.fulfilment?.systemUnavailablePromptTitle ?? "";
    notice.description = basketItem.fulfilment?.systemUnavailablePromptText ?? "";
  }

  const updateCustomerResultStatusCode = updateCustomerResult.status?.rcode as unknown as number;

  // System Unavailable
  if (!hasError && !isMatchingCode(updateCustomerResultStatusCode, [0, 1, 2, 38, 43, 82, 84])) {
    hasError = true;
    notice.id = basketItem.fulfilment?.systemUnavailablePromptTitle ?? "";
    notice.description = basketItem.fulfilment?.systemUnavailablePromptText ?? "";
  }

  // Account Update Declined
  if (!hasError && isMatchingCode(updateCustomerResultStatusCode, [1, 2])) {
    hasError = true;
    notice.id = basketItem.fulfilment?.requestCloseDeclinedPromptTitle ?? "";
    notice.description = basketItem.fulfilment?.requestCloseDeclinedPromptText ?? "";
  }

  if (!hasError && isMatchingCode(updateCustomerResultStatusCode, [38, 43, 82, 84])) {
    hasError = true;
    notice.id = basketItem.fulfilment?.requestCloseTimeoutPromptTitle ?? "";
    notice.description = basketItem.fulfilment?.requestCloseTimeoutPromptText ?? "";
  }

  if (hasError) {
    return {
      notice,
      fulfilment: {
        status: FulfilmentStateEnum.Failure,
        tokens: {
          ...(updateCustomerResultStatusCode !== undefined && {
            updateAccountApiResponseCode: String(updateCustomerResultStatusCode),
          }),
        },
      },
    };
  }
  return {
    notice: {
      id: basketItem.fulfilment?.transactionCompletePromptTitle ?? "",
      description: basketItem.fulfilment?.transactionCompletePromptText ?? "",
    },
    fulfilment: {
      status: FulfilmentStateEnum.Success,
      tokens: {
        updateAccountApiResponseCode: String(updateCustomerResultStatusCode),
      },
    },
    receipts: [
      {
        template: "mails/dropandgo",
        context: {
          enType: basketItem.fulfilment?.enType,
          cyType: basketItem.fulfilment?.cyType,
          enReceiptMessage: basketItem.fulfilment?.englishRequestCloseReceiptContent,
          cyReceiptMessage: basketItem.fulfilment?.welshRequestCloseReceiptContent,
          entryId: basketItem.entryID,
          productNo: basketItem.itemID,
        },
      },
    ],
  };
};
