import { StorageRecord, StorageOptions, META } from "./types";

export type { StorageRecord, StorageOptions };

const KEY_SEPARATOR = "/";

export const buildKey = (...parts: string[]): string => {
  return parts.join(KEY_SEPARATOR);
};

export const recordKey = (key: string, options?: StorageOptions): string => {
  if (options && options.prefix) {
    return buildKey(options.prefix, key);
  }
  return key;
};

export const scopeVersion = (options?: StorageOptions): string => {
  if (options && options.version) {
    return options.version;
  }
  return META.DEFAULT_VERSION;
};
