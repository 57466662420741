export enum LoadingStatus {
  SMARTMETER_REQUEST_INITIATE = "smartMeterRequestInitiate",
  SMARTMETER_RETRY_CANCEL = "smartMeterRetryCancel",
  SMARTMETER_CONFIRM = "smartMeterConfirm",
}
  
export const StaticContent = {
  LoadingMessage: "Sending transaction details to Smart Metering system.",
  FailedTitle: "Transaction Failed",
  FailedText: "Transaction failed due to network communication issue. \nClick 'Retry' to try again or cancel transaction to cancel vend.",
  ConfirmationText: "The Vend has been confirmed on the Smart Metering system \nsuccessfully.\nThe customers meter will be charged.",
  CancelLabel: "Cancel transaction",
  RetryLabel: "Retry",
  OkayLabel: "Okay",
}
  
export enum promptResponseEnum {
  CANCEL = "cancel",
  RETRY = "retry",
  CONFIRM = "okay"
}

export const SME_RETRY_COUNT = 3;
