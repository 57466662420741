import localStorageCaching from "./asyncstorage";
import indexdDBCaching from "./indexeddb";
import memoryCaching from "./memoryCaching";

import { Caching, META, StorageOptions, StorageRecord, IStorage } from "./types";
import { buildKey, recordKey, scopeVersion } from "./helper";

const isWeb: boolean = typeof window !== "undefined" && window.indexedDB !== undefined;

const StorageAdapter = (inMemory: boolean) => {
  if (inMemory === true) {
    return memoryCaching();
  }

  if (isWeb) {
    return indexdDBCaching();
  }
  return localStorageCaching();
};

const storage = (options: StorageOptions): IStorage => {
  const adapter: Caching = StorageAdapter(options.inMemory as boolean);
  const { type } = adapter;

  const setRecord = (record: StorageRecord): Promise<void> => {
    return adapter.set(recordKey(record.id, options), JSON.stringify(record), options.chunkSize);
  };

  const getRecord = async (key: string): Promise<StorageRecord | null> => {
    const itemKey = recordKey(key, options);
    const record = await adapter.get(itemKey);
    if (record) {
      return JSON.parse(record) as StorageRecord;
    }

    return null;
  };

  const removeRecord = (key: StorageRecord | string): Promise<void> => {
    const keyId = typeof key === "string" ? key : key.id;
    return adapter.remove(recordKey(keyId, options));
  };

  const clearAllRecords = (): Promise<void> => {
    return adapter.clearAll();
  };

  const setRecords = async (records: StorageRecord[]): Promise<void> => {
    const items: [string, string][] = records.map((r) => [recordKey(r.id, options), JSON.stringify(r)]);
    await adapter.setMany(items);

    if (options && options.prefix) {
      await adapter.set(buildKey(META.VERSIONS, options.prefix), scopeVersion(options));
    }
  };

  return Object.freeze({
    setRecord,
    getRecord,
    removeRecord,
    clearAllRecords,
    setRecords,
    type,
  });
};

export default storage;
