// TODO: This should be moved to its own package...
import {
  parcelRefNumberHyphenated,
  getRightBottomColumn,
  getAddress,
  getVATcolumn,
  getVATrow,
  generateTwoDBarcode,
  generateOneDBarcode,
  config,
} from "../helpers";
import { MonarchHeadLabelParams } from "../types";
import { toCanvas, toBuffer } from "bwip-js";
import zplImageConvert from "@replytechnologies/zpl-image-convert";
import Jimp from "jimp";

/*
 Label width = 4 inchs / 101mm / 1216 dots
 1mm = 12.16 dots
 2mm tolerance = 25 dots (rounded up)
*/

// TODO - New type
export const generateMonarchHeadLabel = async (params): Promise<string> => {
  const { labelTemplate } = params;

  if (labelTemplate === "postageLabel2d") {
    return await getMonarchsHeadWithTwoDBarcode(params);
  }

  return getMonarchsHead(params);
};

export const getMonarchsHeadWithTwoDBarcode = async (params: MonarchHeadLabelParams): Promise<string> => {
  const { satDel, upuTrackingNumber, service, barCodeReq, prn } = params;
  const prnHyphenated = parcelRefNumberHyphenated(prn);
  const twoDBarcode = generateTwoDBarcode(params, prn);

  let code128 = "";
  let vat = "";

  if (twoDBarcode === typeof null || twoDBarcode === null) {
    throw new Error("Barcode generation failed");
  }

  if (barCodeReq === "true" && upuTrackingNumber) {
    code128 = generateOneDBarcode(upuTrackingNumber);
    vat = getVATcolumn(params);
  } else {
    vat = getVATrow(params);
  }

  let datamatrix;
  const barcodeConfig = {
    bcid: "datamatrix",
    text: twoDBarcode,
    scale: 3,
    c40headerlength: 40,
    format: "square",
    version: "44x44",
  };
  if (typeof window !== "undefined") {
    const canvas = document.createElement("canvas");
    await toCanvas(canvas, barcodeConfig);
    datamatrix = await zplImageConvert.encode(canvas.toDataURL("image/png"));
  } else {
    // When running for local testing via CLI using Node
    const buffer = await toBuffer(barcodeConfig);
    const png = await Jimp.read(buffer);
    datamatrix = await zplImageConvert.encode(png);
  }

  return `
${config()}

^FX --- Service Name ---
^CF0,52^FO88,24^FD${service}^FS^FS

${
  satDel !== "false"
    ? `
^FX --- Sat Del ---
^FO888,24,1^GB98,60,50,0^FS^FO876,35,1^CF0,52,46^FR^FDSAT^FS^CF0,33
`
    : ""
}

^FX --- Data Matrix bar code ---
^FO88,136${datamatrix}

^FX --- Bottom Left content ---
^CF,33,25
^FO88,475^FD${prnHyphenated}^FS

${code128}
${getAddress(params)}
${vat}
${getRightBottomColumn(params)}
^MMC,N
^XZ
`;
};

export const getMonarchsHead = (params: MonarchHeadLabelParams): string => {
  const { satDel, upuTrackingNumber, service, barCodeReq, altServiceId } = params;

  let code128 = "";
  let vat = "";

  if (barCodeReq === "true" && upuTrackingNumber) {
    code128 = generateOneDBarcode(upuTrackingNumber);
    vat = getVATcolumn(params);
  } else {
    vat = getVATrow(params);
  }

  return `
${config()}

^FX --- Service Name ---
^CF0,52^FO88,24^FD${service}^FS^FS

${
  satDel !== "false"
    ? `
^FX --- Sat Del ---
^FO888,24,1^GB98,60,50,0^FS^FO876,35,1^CF0,52,46^FR^FDSAT^FS^CF0,33
`
    : ""
}

^FX --- Indicia/Alt ID ---
^CF0,180^FO88,196^FD${altServiceId}^FS^FS

${code128}
${vat}
${getAddress(params)}
${getRightBottomColumn(params)}
^MMC,N
^XZ
`;
};
