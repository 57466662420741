import axios from "axios";
import { axiosClient } from "../lib/axiosWrapper";
import { AuthProvider } from "../types";
import {
  InsuranceRateProps,
  InsuranceRateDetail,
  LevelCoverQuoteProps,
  Quote,
  IncreasingCoverQuoteProps,
  TravelInsuranceQuoteProps,
  TravelInsuranceQuote,
} from "./types";

export interface Client {
  getInsurerRateByAgeAndSmokingStatus(insuranceRateProps: InsuranceRateProps): Promise<InsuranceRateDetail>;
  getLevelCoverQuote(levelCoverQuoteProps: LevelCoverQuoteProps): Promise<Quote[]>;
  getIncreasingCoverQuote(increasingCoverQuoteProps: IncreasingCoverQuoteProps): Promise<Quote[]>;
  getTravelInsuranceQuote(travelInsuranceQuoteProps: TravelInsuranceQuoteProps): Promise<TravelInsuranceQuote>;
}
export interface Props {
  rootUrl: string;
  authHeaders: AuthProvider;
}

export const buildClient = (props: Props): Client => {
  const { rootUrl, authHeaders } = props;
  const getInsurerRateByAgeAndSmokingStatus = async (
    insuranceRateProps: InsuranceRateProps
  ): Promise<InsuranceRateDetail> => {
    const { age, smokingStatus } = insuranceRateProps;
    return await axiosClient.get<InsuranceRateDetail>({
      url: `${rootUrl}/insurance/over50/rate?age=${age}&smoker=${smokingStatus}`,
      headers: await authHeaders(),
    });
  };
  const getLevelCoverQuote = async (levelCoverQuoteProps: LevelCoverQuoteProps): Promise<Quote[]> => {
    const { age, premium, policyFee, rate, paymentTerm } = levelCoverQuoteProps;
    const response = axiosClient.post({
      url: `${rootUrl}/insurance/over50/quote/level-cover`,
      headers: await authHeaders(),
      params: {
        age,
        premium,
        rate,
        policyFee,
        paymentTerm,
      },
    });
    return response as unknown as Quote[];
  };
  const getIncreasingCoverQuote = async (props: IncreasingCoverQuoteProps): Promise<Quote[]> => {
    const { age, premium, policyFee, rate, paymentTerm, annualIncreaseOfPremium } = props;
    const response = await axiosClient.post({
      url: `${rootUrl}/insurance/over50/quote/increasing-cover`,
      headers: await authHeaders(),
      params: {
        age,
        premium,
        rate,
        policyFee,
        paymentTerm,
        annualIncreaseOfPremium,
      },
    });
    return response as unknown as Quote[];
  };

  const getTravelInsuranceQuote = async (props: TravelInsuranceQuoteProps): Promise<TravelInsuranceQuote> => {
    const { hdr, body, type } = props;
    hdr.created = new Date().toISOString().slice(0, -5);
    hdr.txId = `${hdr.branchId}-${hdr.ctrId}-${hdr.reqId}`;

    const response = await axiosClient.post({
      url: `${rootUrl}/travel-insurance/poms/${type}`,
      headers: await authHeaders(),
      params: { hdr, body },
    });
    return response as unknown as TravelInsuranceQuote;
  };

  return Object.freeze({
    getInsurerRateByAgeAndSmokingStatus,
    getLevelCoverQuote,
    getIncreasingCoverQuote,
    getTravelInsuranceQuote,
  });
};
