import moment from "moment";

import { BasketDataItems, DespatchForTableParams, DespatchForTableReturn, itemObj } from "./types";

const CarrierMapping = {
  RM: "Royal Mail",
  PF: "Parcelforce",
};

const StatusMapping = {
  BRANCH_CHECKED_IN: "Booked in",
  READY_FOR_CARRIER_COLLECTION: "Ready for carrier collection",
  EXPIRED_COLLECTION: "Time expired",
};

const ItemIdMapping: Record<string, string> = {
  RM: "6312",
  PF: "6318",
};

const getAdditionalItems = (carrierId: string): Array<itemObj> => {
  return [
    {
      valueInPence: 0,
      itemID: ItemIdMapping[carrierId],
      quantity: 1,
    },
  ];
};

const fixEpoch = (epoch: number): number => {
  if (`${epoch}`.replace("-", "").length <= 11) {
    return epoch * 1000;
  }
  return 0;
};

const epochToDate = (epoch: number): Date => new Date(fixEpoch(epoch));

const capitalise = (text: string): string => text.substring(0, 1).toUpperCase() + text.substring(1).toLowerCase();

const getMapping = (key: string, dictionaryLookup: { [key: string]: string }) => {
  if (key.trim() === "") return "";
  const mapValue: string = dictionaryLookup[key];
  return mapValue || capitalise(key.split("_").join(" "));
};

const getAddress = (addressLine1?: string, postcode?: string, country?: string) => {
  const notEmptyOrUndefined = (value: string | undefined) => value?.trim() !== undefined && value.trim() !== "";
  const fullAddress: string[] = [];
  [addressLine1, postcode, country].forEach((element) => {
    if (notEmptyOrUndefined(element)) {
      fullAddress.push(element?.trim() as string);
    }
  });
  return fullAddress.join(", ");
};

const getAcceptedDateTime = (acceptanceDate: number) => {
  return moment(acceptanceDate * 1000)
    .utcOffset(0)
    .format("DD/MM/YYYY HH:mm");
};

export const despatchOptions = (params: DespatchForTableParams): DespatchForTableReturn => {
  const { data, type, statusList, basketData } = params;
  if (type && type === "itemReport") {
    if (statusList) {
      return data
        .filter((item) => statusList.includes(item.status))
        .map((item, index) => {
          return {
            carrierName: getMapping(item.carrierId.trim(), CarrierMapping),
            formattedAcceptanceDate: epochToDate(item.acceptanceDate).toLocaleDateString("en-GB"),
            id: `${index + 1}`,
            statusName: getMapping(item.status, StatusMapping),
            ...item,
          };
        });
    }
    return data.map((item, index) => {
      return {
        carrierName: getMapping(item.carrierId.trim(), CarrierMapping),
        formattedAcceptanceDate: epochToDate(item.acceptanceDate).toLocaleDateString("en-GB"),
        id: `${index + 1}`,
        statusName: getMapping(item.status, StatusMapping),
        ...item,
      };
    });
  }
  if (type && type === "returnToCarrier") {
    const trackingIds: string[] = [];
    if (basketData && basketData.length > 0) {
      basketData.forEach((item: BasketDataItems) => {
        trackingIds.push(item.basket.duplicateBarcodeIdentifier);
      });
    }
    return data
      .filter((item) => !trackingIds.includes(item.trackingId))
      .map((item) => {
        const transformedData = {
          checked: "true",
          carrierName: getMapping(item.carrierId.trim(), CarrierMapping),
          formattedAcceptanceDate: epochToDate(item.acceptanceDate).toLocaleDateString("en-GB"),
          ...item,
        };
        return { ...transformedData, additionalItems: getAdditionalItems(item.carrierId) };
      });
  }
  return data.map((item) => {
    return {
      checked: "true",
      carrierName: getMapping(item.carrierId.trim(), CarrierMapping),
      formattedAcceptanceDate: epochToDate(item.acceptanceDate).toLocaleDateString("en-GB"),
      address:
        item.serviceName === "Local Collect Rtns" || item.carrierId === "PF"
          ? ""
          : getAddress(item.addressLine1, item.postcode, item.country),
      acceptanceDateTime: getAcceptedDateTime(item.acceptanceDate),
      ...item,
    };
  });
};
