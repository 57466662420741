// TODO - this is stubbed code, to be removed or replaced when actual Remote fulfilment code is built

import { retry } from "ts-retry-promise";
import { FulfillmentClient, FulfillmentResponse } from "./fulfillment-api";
import { RemoteFulfillerT, FulfillmentItem } from "../../types";

const DEFAULT_TIMEOUT_MILLISECONDS = 30_000;

const RemoteFulfiller = (basketId: string, fulfilmentApiClient?: FulfillmentClient): RemoteFulfillerT => {
  const timeout = DEFAULT_TIMEOUT_MILLISECONDS;
  const fulfilmentIdGuid = "xxxxxx"; // TODO: generate a proper GUID

  const fulfill = (basketItem: FulfillmentItem) => {
    return retry(
      async (): Promise<FulfillmentResponse> => {
        const result = await fulfilmentApiClient?.checkFulfillmentStatus(basketId, fulfilmentIdGuid);

        if (!result || !result.fulfilled) {
          // TODO: entryID will be set at root level soon so the optional chaining will be able to be removed
          throw new Error(`Not yet fulfilled entry ${basketItem.basketItem?.tokens?.entryID || "<no id set>"}`);
        }

        return result;
      },
      { timeout, retries: 3 }
    );
  };

  return Object.freeze({ fulfill });
};

export default RemoteFulfiller;
