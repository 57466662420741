import {
    BillPayClient,
    AuthProvider,
    RequestVendResponse,
    ConfirmVendResponse,
    SmartMeterVendTypeActions,
    CancelVendResponse
  } from "postoffice-product-journey-api-clients";
  
  import { FulfilmentStateEnum } from "../../../openapi/transaction-api-v3";
  import {
    ApiClientsConfig,
    BasketItemPayload,
    FulfilmentProcessorResponse,
    Callbacks,
  } from "../../../types";
  import { promptResponseEnum, SME_RETRY_COUNT } from "../types";
  import {
    getRequestVend,
    getConfirmVend,
    createApiClient,
    getCancelVend
  } from "../api";
  import { getPaidReceipts, getFailedReceipts } from "../receipt";
  import {
    retryCancelPrompt,
    confirmationPrompt,
    showLoadingPrompt,
    hideLoadingPrompt
  } from "../prompt";
  
  export const process = async (
    callbacks: Callbacks,
    apiConfig: ApiClientsConfig["billpay"],
    authProvider: AuthProvider,
    basketItem: BasketItemPayload,
  ): Promise<FulfilmentProcessorResponse> => {
  
  const apiClient: BillPayClient = createApiClient(apiConfig, authProvider);  
  const fulfilmentAction = basketItem?.tokens?.fulfilmentAction;
  const action = fulfilmentAction as SmartMeterVendTypeActions;
  let requestVendResult: RequestVendResponse = {
    fuelType: "",
    mpxn: "",
    retrievalReference: "",
    transactionReference: "",
    transactionId: "",
    utrn: "",
    utrnCreationDate: "",
    created: "",
    reqId: "",
    txId: "",
    ecode: "",
    etext: "",
    rcode: "",
    receiptText: "",
    responseCode: "",
    responseMessage: ""
  }
  let fulfillerResponse: FulfilmentProcessorResponse = {
    fulfilment: { 
      status: FulfilmentStateEnum.Failure,
      tokens: {
        Ecode: "0"
      }
    }
  }; 
  let fulfilmentTokens = {
    Ecode: "",
    MPxN: "",
    UTRN: "",
    retrievalReference: "",
    TransactionId: "",
  };
  let hasRequestError = false;
  let hasConfirmError = false;
  let hasCancelError = false;
  let userResponse: promptResponseEnum = promptResponseEnum.RETRY;
  let retryCount = 0;
  const requestVendProcess = async () => {
    hasRequestError = false;
    retryCount += 1;
    showLoadingPrompt(callbacks);
    requestVendResult = await getRequestVend(action, basketItem, apiClient);
    if (!requestVendResult || !requestVendResult.utrn || !requestVendResult.retrievalReference) {
      hasRequestError = true;
    }
    if (hasRequestError && retryCount < SME_RETRY_COUNT) {
      hideLoadingPrompt(callbacks);
      userResponse = await retryCancelPrompt(callbacks);
      if (userResponse === promptResponseEnum.RETRY) {
        await requestVendProcess();
      }
    }
  }
  // calling request vend process
  await requestVendProcess();
  if (!hasRequestError) {
    let confirmVendResult: ConfirmVendResponse;
    retryCount = 0
    const confirmVendProcess = async () => {
      hasConfirmError = false;
      retryCount += 1;
      confirmVendResult = await getConfirmVend(
        action,
        basketItem,
        requestVendResult.retrievalReference,
        requestVendResult.transactionReference,
        apiClient
      );
      if(confirmVendResult.ecode && confirmVendResult.ecode !== "0") {
        hasConfirmError = true;
      }
      if (hasConfirmError && retryCount < SME_RETRY_COUNT) {
        userResponse = await retryCancelPrompt(callbacks);  
        if (userResponse === promptResponseEnum.RETRY) {
          await confirmVendProcess();
        }    
      }
    }
    // calling confirm vend process
    await confirmVendProcess();  
    if (!hasConfirmError) {
      hideLoadingPrompt(callbacks);
      fulfilmentTokens = {
        Ecode: requestVendResult.ecode,
        MPxN: requestVendResult.mpxn,
        UTRN: requestVendResult.utrn,
        retrievalReference: requestVendResult.retrievalReference,
        TransactionId: requestVendResult.transactionId,
      };
      userResponse= await confirmationPrompt(callbacks);  
      if (userResponse === promptResponseEnum.CONFIRM) {
        const receiptData = getPaidReceipts(basketItem, requestVendResult.utrn);
        fulfillerResponse = {
          fulfilment: {
            status: FulfilmentStateEnum.Success,
            tokens: fulfilmentTokens,
          },            
          receipts: [receiptData]
        };
      }
    }
  }  
  /**
   * Todo
   * Will remove below hardcoded data
   * This is added for passing fulfilment in cancel case
   * In future release it will be removed
   */
  if (userResponse === promptResponseEnum.CANCEL || ((hasRequestError || hasConfirmError) && retryCount === SME_RETRY_COUNT) ) {
    hideLoadingPrompt(callbacks);
    let cancelVendResult: CancelVendResponse;
    retryCount = 0;
    const cancelVendProcess = async () => {
      hasCancelError = false;
      retryCount += 1;
      cancelVendResult = await getCancelVend(
        action,
        basketItem,
        requestVendResult.retrievalReference,
        requestVendResult.transactionReference,
        apiClient
      );
      if (cancelVendResult.ecode === undefined) {
        hasCancelError = true;
      }
      if(!hasCancelError && cancelVendResult.ecode !== "0") {
        hasCancelError = true;
      }
      if (hasCancelError && action === SmartMeterVendTypeActions.SME && retryCount < SME_RETRY_COUNT) {
        userResponse = await retryCancelPrompt(callbacks);
        if (userResponse === promptResponseEnum.RETRY) {
          await cancelVendProcess();
        }
      }
    }
    if(!hasRequestError){
      await cancelVendProcess();
    }
    fulfilmentTokens = {
      Ecode: "0",
      MPxN: "0000000000000",
      UTRN: "11111111111111111111",
      retrievalReference: "2001490825",
      TransactionId: "1211281128",
    };
    const receiptData = getFailedReceipts(basketItem);
    fulfillerResponse = {
      fulfilment: {
        status: FulfilmentStateEnum.Success,
        tokens: fulfilmentTokens,
      },            
      receipts: [receiptData]
    };
  }  
  return fulfillerResponse;
};
  