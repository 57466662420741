import axios from "axios";
import moment from "moment";
import { AuthProvider } from "../types";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnyEstimated = Record<string, any>;
export type EstimatedDeliveryDateParams = {
  originPostalCode: string;
  packageAcceptanceDateTime: string;
  destinationCountryCode: string;
  destinationPostalCode: string;
};
export interface Client {
  getEstimatedDeliveryDate(params: EstimatedDeliveryDateParams): Promise<AnyEstimated>;
}
export type Props = {
  rootUrl: string;
  myx?: string;
  authHeaders: AuthProvider;
};

export const buildClient = (props: Props): Client => {
  const { rootUrl, authHeaders } = props;

  const getEstimatedDeliveryDate = async ({
    originPostalCode,
    packageAcceptanceDateTime,
    destinationCountryCode,
    destinationPostalCode,
  }: EstimatedDeliveryDateParams): Promise<AnyEstimated> => {
    const packageDateTimeFormat = moment.utc(packageAcceptanceDateTime, "DD/MM/YYYY").format(); // packageDateTime + "T11:11:11Z";
    const response = await axios.get(`${rootUrl}/kahala/v1/guaranteedate`, {
      headers: await authHeaders(),
      params: {
        origin_postal_code: originPostalCode,
        package_acceptance_date_and_time: packageDateTimeFormat,
        destination_country_code: destinationCountryCode,
        destination_postal_code: destinationPostalCode,
      },
      validateStatus: () => true,
    });
    return response as AnyEstimated;
  };

  return Object.freeze({ getEstimatedDeliveryDate });
};
