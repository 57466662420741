import { AuthProvider } from "../types";

export interface Client {
  createBranchOrder(createBranchOrderProps: CreateBranchOrderProps): Promise<CreateBranchOrderSuccess>;
}

export type Props = {
  rootUrl: string;
  authHeaders: AuthProvider;
};

export interface CreateBranchOrderProps {
  transactionID: string;
  transactionType: string;
  orderDetail: CreateBranchOrderPropsOrderDetail;
  branchCode: string;
  tillUserID: string;
  tillID: string;
}

export interface CreateBranchOrderPropsOrderDetail {
  productCode: string;
  currencyCode: string;
  baseTransactionAmount: number;
  foreignTransactionAmount: number;
  exchangeRate: string;
  spotRate: string;
}

export interface CreateBranchOrderRequestParamsOrderDetail extends CreateBranchOrderPropsOrderDetail {
  productType: OrderDetailProductType;
  type?: OrderDetailType;
}

export interface CreateBranchOrderRequestParams extends CreateBranchOrderProps {
  transactionEndDateTime: string;
  orderFulfilmentType: OrderFulfilmentType;
  orderDetail: CreateBranchOrderRequestParamsOrderDetail;
}

export interface CreateBranchOrderResponse {
  orderSummary: {
    collectionDetails: {
      branch: {
        branchCode: string;
      };
    };
    transactionID: string;
  };
}

export interface CreateBranchOrderSuccess {
  transactionID: string;
}

export enum OrderFulfilmentType {
  BRANCH = "Branch",
}

export enum OrderDetailProductType {
  NOTES = "Notes",
}

export enum OrderDetailType {
  FULFILLED = "Fulfilled",
}
