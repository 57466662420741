import axios from "axios";
import { AuthProvider } from "../types";
import { axiosClient } from "../lib/axiosWrapper";
import { getBarcodeType } from "tokeniser-api/helpers";
import {
  convertForV2Transactions,
  productParamslabels2go,
  productParamsFromBarcode,
  productParams,
  QueryParams,
  ParsedBarcode,
} from "./helpers";

export type Params = () => Promise<{ [key: string]: string }>;
export type AnyProduct = Record<string, any>;
export type ProductItem = { [key: string]: string | [] | boolean | number | unknown | ProductItem };

export type GetProductPFCAParams = { serviceId: string; barcodeType: string; trackingNumber: string };
export type GetProductLabels2GoParams = { parsedBarcode: ParsedBarcode; parsedQRcode: ParsedBarcode };
export type GetProductParams = {
  parsedBarcode: ParsedBarcode;
  journeyType: string;
  isFeePayable: string;
  bookInRequired: string;
};
export type GetProductServicesParams = { serviceType: string; serviceName: string };

export interface service {
  Name: string;
  Code: string;
}
export interface productService {
  Reason: string;
  Event: string;
}

export interface Client {
  getProductPFCA(params: GetProductPFCAParams): Promise<AnyProduct>;
  getProductLabels2Go(params: GetProductLabels2GoParams): Promise<AnyProduct>;
  getProduct(params: GetProductParams): Promise<AnyProduct>;
  getProducts(params: QueryParams): Promise<AnyProduct>;
  getProductServices(params: GetProductServicesParams): Promise<AnyProduct>;
}
export interface Props {
  rootUrl: string;
  authHeaders: AuthProvider;
}

export const buildClient = (props: Props): Client => {
  const { rootUrl, authHeaders } = props;

  /**
   * @deprecated since api client version 4.x.x
   */
  const getProductPFCA = async (params: GetProductPFCAParams): Promise<AnyProduct> => {
    console.warn("Product API client: getProductPFCA is deprecated and will be removed in the next major version.");
    const { serviceId, barcodeType, trackingNumber } = params;
    const response: AnyProduct = await axiosClient.get({
      url: `${rootUrl}/product`,
      headers: await authHeaders(),
      params: { serviceId, barcodeType, trackingNumber },
    });
    const responseV2 = convertForV2Transactions(response);

    return responseV2 as AnyProduct;
  };

  /**
   * @deprecated since api client version 4.x.x
   */
  const getProductLabels2Go = async (params: GetProductLabels2GoParams): Promise<AnyProduct> => {
    console.warn(
      "Product API client: getProductLabels2Go is deprecated and will be removed in the next major version."
    );
    const { parsedBarcode, parsedQRcode } = params;

    const response: AnyProduct = await axiosClient.get({
      url: `${rootUrl}/product`,
      headers: await authHeaders(),
      params: productParamslabels2go(parsedBarcode, parsedQRcode),
    });
    const responseV2 = convertForV2Transactions(response);
    return responseV2 as AnyProduct;
  };

  /**
   * @deprecated since api client version 4.x.x
   */
  const getProduct = async (params: GetProductParams): Promise<AnyProduct> => {
    console.warn("Product API client: getProduct is deprecated and will be removed in the next major version.");
    const { parsedBarcode, journeyType, isFeePayable, bookInRequired } = params;
    const response: AnyProduct = await axiosClient.get({
      url: `${rootUrl}/product`,
      headers: await authHeaders(),
      params: productParamsFromBarcode(parsedBarcode, journeyType, isFeePayable, bookInRequired),
    });

    const responseV2 = convertForV2Transactions(response);
    return responseV2 as AnyProduct;
  };

  /**
   * @deprecated since api client version 4.x.x
   */
  const getProducts = async (params: QueryParams): Promise<{ products: AnyProduct[] }> => {
    console.warn("Product API client: getProducts is deprecated and will be removed in the next major version.");
    const response = await axiosClient.get({
      url: `${rootUrl}/product/query`,
      headers: await authHeaders(),
      params: productParams(params),
    });

    return { products: response as unknown as AnyProduct[] };
  };

  const getProductServices = async (params: GetProductServicesParams): Promise<AnyProduct> => {
    const { serviceType, serviceName } = params;
    const response: AnyProduct[] = await axiosClient.get({
      url: `${rootUrl}/postal/carrier-services/${serviceType}?service=${serviceName}`,
      headers: await authHeaders(),
    });

    let services = [];
    const serviceIds = response[0].IDs;
    if (serviceIds && serviceIds.length > 0) {
      services = serviceIds.map((item: service) => {
        return {
          title: item.Name,
          value: item.Code,
        };
      });
    } else {
      const productIds = response[0].Events;
      services = productIds.map((item: productService) => {
        return {
          title: item.Reason,
          value: item.Event,
        };
      });
    }
    return services as AnyProduct;
  };

  return Object.freeze({ getProductPFCA, getProductLabels2Go, getProduct, getProducts, getProductServices });
};
