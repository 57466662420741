import { BarcodeNameMapping } from "./data/customMappings";
import { ParsedQRCode, PatternMapping } from "./types";

// TODO: Remove me: once API giving correct data
export const getBarcodeType = (barcodeName: string): string => {
  return (BarcodeNameMapping[barcodeName] ?? barcodeName) as string;
};

export const getItemDetails = (
  itemID: string,
  items: Record<string, unknown>[]
): Record<string, unknown> | undefined => {
  return items.find((m) => m.itemID === itemID);
};

export const defaultL2GMapping = [
  {
    name: "RM Tracked Returns Labels2GO",
    pattern: "^Label.{86,}$",
    Constants: {
      Type: "Labels2Go",
    },
    fields: [],
    tokengroups: [],
  },
] as PatternMapping[];

export const validateLabels2GoType = (data: ParsedQRCode): ParsedQRCode => {
  switch (data.Label) {
    case "1":
    case "2": {
      const value = data;
      const senderDetails = `${String(value.SendersDetails)}`;
      if (String(value.SendersDetails).includes("\\&")) {
        value.SendersDetails = senderDetails.replace(/\\&| \\& /g, "\n");
      } else {
        switch (String(value.SendersDetails).length) {
          case 226:
            value.SendersDetails = (senderDetails.match(/.{1,72}/g) as string[])
              .map((address) => address.trim())
              .join("\n");
            break;
          case 218:
            value.SendersDetails = (senderDetails.match(/.{1,70}/g) as string[]).join("\n");
            break;
          case 188:
            value.SendersDetails = (senderDetails.match(/.{1,60}/g) as string[]).join("\n");
            break;
          default: {
            const senderVals = (senderDetails.match(/.{1,35}/g) as string[])
              .filter((address) => address.trim() !== "")
              .map((address) => address.trim());
            if (senderDetails.search(postcodeRegex) !== -1) {
              senderVals[senderVals.length - 1] = senderDetails.substring(senderDetails.search(postcodeRegex)).trim();
            }
            value.SendersDetails = senderVals.join("\n");
            break;
          }
        }
      }
      return value;
    }
    default: {
      const value = data;
      value.barcode = `Label${String(data.Label)}${String(data.barcode)}`;
      return value;
    }
  }
};

export const postcodeRegex = /[A-Z]{1,2}[0-9][A-Z0-9]? ?[0-9][A-Z]{2}$/;

export const validatePostcode = (values: string[], SDlen: number) => {
  return postcodeRegex.test(values[SDlen - 1].trim());
};
