import { BasketItemPayload, ReceiptTemplate } from "../../types";

export const MAILS_LABELS_REJECT_RECEIPT_TEMPLATE = "mails/reject-label-receipt";
export const RECEIPT_FOOTER_MESSAGE_1 =
  "*Please keep this rejected label receipt with the rejected postage label (if printed) for reconciliation purposes.";
export const RECEIPT_FOOTER_MESSAGE_2 =
  "*The total number of rejected label receipts on hand MUST agree with the Office Weekly Postage Labels report.";
export const REJECT_RECEIPT_PRINTING_MESSAGE = "Wait while the reject receipt is printed.";

export const getReceipts = (basketItem: BasketItemPayload, prn: string): ReceiptTemplate => {
  const receipt = {
    template: MAILS_LABELS_REJECT_RECEIPT_TEMPLATE,
    context: {
      value: basketItem.valueInPence,
      prn,
      messages: [RECEIPT_FOOTER_MESSAGE_1, RECEIPT_FOOTER_MESSAGE_2],
    },
    printingMessage: REJECT_RECEIPT_PRINTING_MESSAGE,
  };
  return receipt;
};
