const DEFAULT_DPI = 203;
export const scaleLabel = (zpl: string, dpi: number) => {
  if (dpi === DEFAULT_DPI) {
    return zpl;
  }
  const arr = zpl.split("^");
  for (let i = 0; i < arr.length; i++) {
    const prefix = arr[i].substring(0, 2);

    if (prefix === "FO" || prefix === "GB" || prefix === "GC" || prefix === "CF" || prefix === "FB") {
      const mapping = arr[i].substring(2).split(",");
      if (prefix !== "CF") {
        mapping[0] = String(Math.ceil((parseInt(mapping[0], 10) / DEFAULT_DPI) * dpi));
      }
      mapping[1] = String(Math.ceil((parseInt(mapping[1], 10) / DEFAULT_DPI) * dpi));
      if (prefix === "GB") {
        mapping[2] = String(Math.ceil((parseInt(mapping[2], 10) / DEFAULT_DPI) * dpi));
      }
      zpl = zpl.replace(arr[i], prefix + mapping);
    }
  }
  zpl = zpl.replace("^BY2,", `^BY${Math.ceil((2 / DEFAULT_DPI) * dpi)},`);
  zpl = zpl.replace("^LL1000", `^LL${Math.ceil((1000 / DEFAULT_DPI) * dpi)}`);
  zpl = zpl.replace("^BCN,140,N", `^BCN,${Math.ceil((140 / DEFAULT_DPI) * dpi)},N`);
  zpl = zpl.replace("^BXN,4,200", `^BXN,${Math.ceil((4 / DEFAULT_DPI) * dpi)},200`);

  return zpl;
};
