import { SupportedServices, Response, PosDisplayEvent } from "../../types";
import WebSocketAsPromised from "websocket-as-promised";
import {
  IngenicoPedClient,
  PedActions,
  PedInitialisationResult,
  PedResult,
  IngenicoPedClientProps,
  TransactionTypes,
  PedVersionResult,
} from "./types";
import { performAction as performSimulatorAction } from "./mock/index";
import pedResponseCodes from "./mappings/pedResponseCodes";
import {
  extractAccountBalances,
  extractMessaging,
  extractMetadata,
  isApproved,
  isNonTransactedAction,
  isOrderXAction,
  PAYMENT_ACTIONS,
  setFallbackMessaging,
  shouldIncludeReceiptBalances,
  SUCCESSFUL_TRANSACTION_PROMPT,
} from "./helpers";
import { isError } from "../../helpers";

export const buildClient = (
  props: IngenicoPedClientProps,
  requestHandler: WebSocketAsPromised["sendRequest"]
): IngenicoPedClient => {
  const { terminalId, clerkId, useMock } = props;

  const performAction = async (action: PedActions, params: Record<string, unknown>): Promise<PedResult> => {
    if (useMock) {
      return Promise.resolve(await performSimulatorAction(action));
    }

    if (params.amount) {
      params.amount = Math.abs(params.amount as number);
    }

    const response = (await requestHandler({
      service: SupportedServices.IngenicoPed,
      action,
      params: {
        terminalId,
        clerkId,
        ...params,
      },
    })) as Response;

    if (isError(response)) {
      return Promise.reject(response);
    }

    const transactionType = PAYMENT_ACTIONS.includes(action) ? TransactionTypes.Payments : TransactionTypes.Banking;
    const pedResponse = response.result as PedResult;

    if (!isOrderXAction(action) || !isNonTransactedAction(action)) {
      pedResponse.metadata = extractMetadata(action, pedResponse);
    }

    if (!isApproved(pedResponse)) {
      const prompt = extractMessaging(transactionType, pedResponse);

      if (prompt || !isNonTransactedAction(action)) {
        pedResponse.prompt = prompt;
      }

      // attempt to fallback to c3 response code errors
      if (!prompt && pedResponseCodes[pedResponse.combinedCode]) {
        pedResponse.prompt = {
          id: pedResponseCodes[pedResponse.combinedCode].id,
          description: pedResponseCodes[pedResponse.combinedCode].description,
        };
      }

      // assign fallback messaging
      if (!pedResponse.prompt) {
        pedResponse.prompt = setFallbackMessaging(transactionType);
      }

      return Promise.reject(pedResponse);
    }

    // receipt balances should only be displayed for certain transactions
    if (pedResponse.additionalAmounts && shouldIncludeReceiptBalances(action)) {
      pedResponse.balances = extractAccountBalances(pedResponse.additionalAmounts);
    }

    if (!isOrderXAction(action) || !isNonTransactedAction(action)) {
      pedResponse.prompt = SUCCESSFUL_TRANSACTION_PROMPT;
    }

    return Promise.resolve(pedResponse);
  };

  const debitCheck = async (amount: number, transactionId: string): Promise<PedResult> => {
    return performAction(PedActions.DebitX, { amount, transactionId });
  };

  const debit = async (amount: number, transactionId: string): Promise<PedResult> => {
    return performAction(PedActions.DebitY, {
      transactionId,
      amount,
    });
  };

  const withdrawalCheck = async (amount: number, transactionId: string): Promise<PedResult> => {
    return performAction(PedActions.WithdrawalX, {
      transactionId,
      amount,
    });
  };

  const withdrawal = async (amount: number, transactionId: string): Promise<PedResult> => {
    return performAction(PedActions.WithdrawalY, {
      transactionId,
      amount,
    });
  };

  const depositCheck = async (amount: number, transactionId: string): Promise<PedResult> => {
    return performAction(PedActions.DepositX, {
      transactionId,
      amount,
    });
  };

  const deposit = async (amount: number, transactionId: string, skipPin?: boolean): Promise<PedResult> => {
    return performAction(PedActions.DepositY, {
      transactionId,
      amount,
      skipPin,
    });
  };

  const balanceEnquiryCheck = async (transactionId: string): Promise<PedResult> => {
    return performAction(PedActions.BalanceEnquiryX, {
      transactionId,
    });
  };

  const balanceEnquiry = async (transactionId: string, skipPin?: boolean): Promise<PedResult> => {
    return performAction(PedActions.BalanceEnquiryY, {
      transactionId,
      skipPin,
    });
  };

  const changePinCheck = async (transactionId: string): Promise<PedResult> => {
    return performAction(PedActions.ChangePinX, {
      transactionId,
    });
  };

  const changePin = async (transactionId: string): Promise<PedResult> => {
    return performAction(PedActions.ChangePinY, {
      transactionId,
    });
  };

  const refundCheck = async (amount: number, transactionId: string): Promise<PedResult> => {
    return performAction(PedActions.RefundX, {
      transactionId,
      amount,
    });
  };

  const refund = async (amount: number, transactionId: string): Promise<PedResult> => {
    return performAction(PedActions.RefundY, {
      transactionId,
      amount,
    });
  };

  /**
   * Obtain tokenised PAN from WL AXIS platform
   * @param customerPresent - Indicates whether the customer is present
   * @returns {Promise<PedResult>} - Promise resolving to PedResult
   */

  const cardToken = async (customerPresent: boolean): Promise<PedResult> => {
    return performAction(PedActions.GetCardToken, {
      customerPresent,
    });
  };

  const initialise = async (): Promise<boolean> => {
    const response = (await requestHandler({
      service: SupportedServices.IngenicoPed,
      action: PedActions.Initialise,
      params: {
        terminalId,
        clerkId,
      },
    })) as Response;

    if (isError(response)) {
      return Promise.reject(response);
    }

    const pedResponse = response.result as PedInitialisationResult;

    if (!pedResponse.initialised) {
      throw new Error("Failed to initialise");
    }

    return pedResponse.initialised;
  };

  const version = async (): Promise<PedVersionResult> => {
    const response = (await requestHandler({
      service: SupportedServices.IngenicoPed,
      action: PedActions.GetVersion,
      params: {
        terminalId,
      },
    })) as Response;

    if (isError(response)) {
      return Promise.reject(response);
    }

    const pedResponse = response.result as PedVersionResult;

    return pedResponse;
  };

  const sendEvent = async (event: PosDisplayEvent["event"]): Promise<void> => {
    const response = (await requestHandler({
      service: SupportedServices.IngenicoPed,
      action: PedActions.POSEvent,
      params: {
        response: event,
      },
    })) as Response;

    if (isError(response)) {
      return Promise.reject(response);
    }
  };

  const abort = async (transactionId: string): Promise<PedResult> => {
    return performAction(PedActions.AbortX, {
      transactionId,
    });
  };

  return Object.freeze({
    debitCheck,
    debit,
    isApproved,
    initialise,
    withdrawalCheck,
    withdrawal,
    depositCheck,
    deposit,
    balanceEnquiryCheck,
    balanceEnquiry,
    changePinCheck,
    changePin,
    sendEvent,
    version,
    abort,
    refundCheck,
    refund,
    cardToken,
  });
};
