import { FulfilmentStateEnum, TransactionsApiInterface } from "../openapi/transaction-api-v3";

/**
 * Update fulfilment tokens
 * @param client TransactionsApiInterface,
 * @param entryId string
 * @param status FulfilmentStateEnum
 * @param fulfilmentTokens Record<string, string>
 * @param userToken string
 * @returns Promise<boolean>
 */
export const updateFulfilmentTokens = async (
  client: TransactionsApiInterface,
  basketId: string,
  entryId: string,
  status: FulfilmentStateEnum,
  fulfilmentTokens: Record<string, string>,
  userToken: string
): Promise<boolean> => {
  try {
    const result = await client.updateBasketEntryFulfilment(basketId, entryId, userToken, {
      fulfilmentState: status,
      fulfilmentTokens,
    });

    return result.status === 200;
  } catch (error) {
    return false;
  }
};

/**
 * In some cases, we don't care if an exception is thrown, we want to swallow
 * it and return the object response
 * @param promise Promise<any>
 * @returns Promise<any>
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const brokenPromises = async (promise: Promise<any>) => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return await promise;
  } catch (error) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return error;
  }
};

/**
 * Is matching code
 * @param errorCode number
 * @param expected number[]
 * @returns boolean
 */
export const isMatchingCode = (errorCode: number, expected?: number[]) => {
  if (!expected) {
    return false;
  }

  return expected.includes(Number(errorCode));
};

/**
 * Convert text value to boolean - i.e. from journey
 * @param string | number | boolean | null | undefined
 * @returns boolean
 */
export const primitiveToBoolean = (value: string | number | boolean | null | undefined): boolean => {
  if (typeof value === "string") {
    return value.toLowerCase() === "true" || !!+value;
  }

  return !!value;
};

/**
 * Check if the object contains all the keys passed as arguments
 * @param tokens object to check
 * @param mandatoryKeys keys to check
 * @returns true if the object contains all the keys
 */

export const checkProperties = (tokens: Record<string, unknown> | undefined, mandatoryKeys: string[]): boolean => {
  if (tokens === undefined) {
    return false;
  }

  // eslint-disable-next-line no-restricted-syntax
  for (const key of mandatoryKeys) {
    if (!tokens[key]) {
      return false;
    }
  }
  return true;
};

/** 
 * Generates a session id splitting the basket id to get last entry and appending the entry id to it.
 * @param basketId string
 * @param entryId string
 * @returns The session id as string.
 */
export const generateSessionId = (basketId: string, entryId: string): string => {
  const splitBasketId = basketId.split(/[ -]+/);
  if (splitBasketId.length !== 3) {
    throw new Error(`BasketId is not valid: ${basketId}`);
  }
  return `${splitBasketId[1]}-${splitBasketId[2]}${entryId}`;
};
