import AsyncStorage from "@react-native-async-storage/async-storage";
import { CachingTypes, Caching } from "../types";

const STORAGE_LIMIT = Math.floor(1.9 * 1024 * 1024); // 1.9MB
const CHUNK_KEY_SUFFIX = "chunks";

const localStorageCaching = (): Caching => {
  const byteSize = (str: string) => str.length;
  const type = CachingTypes.ASYNCSTORAGE;

  const set = async (key: string, value: string, size?: number): Promise<void> => {
    const chunkSize = size || STORAGE_LIMIT;

    if (byteSize(value) > chunkSize) {
      const chunks = createChunks(value, chunkSize);
      const chunkKeys: number[] = [];
      const results = [] as Promise<void>[];

      chunks.map((chunkVal: string, i: number) => {
        const cKey = i + 1;
        results.push(AsyncStorage.setItem(getChunkIndex(key, cKey), chunkVal));
        chunkKeys.push(cKey);
        return chunkVal;
      });

      await Promise.all(results);
      return AsyncStorage.setItem(getChunkKeyName(key), chunkKeys.join(","));
    }

    return AsyncStorage.setItem(key, value);
  };

  const get = async (key: string): Promise<string | null> => {
    const chunkKeysStr = await AsyncStorage.getItem(getChunkKeyName(key));

    if (chunkKeysStr) {
      const chunks: string[] = [];
      const results = [] as Promise<string | null>[];

      chunkKeysStr.split(",").map((i: string) => {
        results.push(AsyncStorage.getItem(getChunkIndex(key, i)));
        return i;
      });

      (await Promise.all(results)).forEach((v) => {
        chunks.push(v as string);
      });

      return chunks.join("");
    }

    return AsyncStorage.getItem(key);
  };

  const remove = async (key: string): Promise<void> => {
    const chunkKeysStr = await AsyncStorage.getItem(getChunkKeyName(key));

    if (chunkKeysStr) {
      const results = [] as Promise<void>[];
      chunkKeysStr.split(",").map((i: string) => {
        results.push(AsyncStorage.removeItem(getChunkIndex(key, i)));
        return i;
      });

      await Promise.all(results);
      return AsyncStorage.removeItem(getChunkKeyName(key));
    }

    return AsyncStorage.removeItem(key);
  };

  const clearAll = AsyncStorage.clear;

  const setMany = (items: [string, string][]): Promise<void> => {
    return AsyncStorage.multiSet(items);
  };

  const createChunks = (str: string, size: number): string[] => {
    const numChunks = Math.ceil(str.length / size);
    const chunks: string[] = new Array(numChunks) as string[];

    for (let i = 0, o = 0; i < numChunks; i += 1, o += size) {
      chunks[i] = str.substring(o, size * (i + 1));
    }

    return chunks;
  };

  const getChunkIndex = (name: string, index: number | string) => {
    return `${name}/${index}`;
  };

  const getChunkKeyName = (name: string) => {
    return `${name}/${CHUNK_KEY_SUFFIX}`;
  };

  return Object.freeze({
    set,
    get,
    remove,
    setMany,
    clearAll,
    type,
  });
};

export default localStorageCaching;
