/**
 * axiosWrapper/index.ts
 * This is axios wrapper file, it will have all of the methods that axios have.
 * Errors will be centralized from axios
 * Interceptors can be defined here in axios
 */

import axios, { AxiosRequestConfig, AxiosError, AxiosResponse } from "axios";
import { axiosETAGCache } from "axios-etag-cache";
import cache, { CacheConfigType, getFromCache, setToCache } from "../cache";
interface IRequestParams<T> {
  url: string;
  headers: AxiosRequestConfig["headers"] | { "if-none-match": string };
  params?: T;
  data?: T;
}

interface IHttpClient {
  get<T>(parameters: IRequestParams<T>): Promise<T>;
  post<T>(parameters: IRequestParams<T>): Promise<T>;
  put<T>(parameters: IRequestParams<T>): Promise<T>;
  delete<T>(parameters: IRequestParams<T>): Promise<T>;
}

const mapError = (err: AxiosError) => {
  let statusCode = err?.response?.status;
  if (statusCode === undefined && err?.message === "Network Error") {
    statusCode = 599;
  }
  return {
    statusCode: statusCode,
    message: err?.message,
    name: err?.name,
  };
};

class axiosWrapperClient implements IHttpClient {
  // FIX ME: Seems to be not working correctly, check and remove
  getWithCache<T, R = T>(parameters: IRequestParams<T>): Promise<R> {
    return new Promise<R>((resolve, reject) => {
      const { url, params, headers } = parameters;
      const options: AxiosRequestConfig = { headers, params };
      axiosETAGCache(axios)
        .get(url, options)
        .then((response: unknown) => {
          resolve(response as R);
        })
        .catch((err: AxiosError) => {
          reject(mapError(err));
        });
    });
  }

  get<T, R = T>(parameters: IRequestParams<T>, cacheOptions?: CacheConfigType): Promise<R> {
    return cache(async () => {
      const etagKey = `${cacheOptions?.key}/etag`;
      const etagValue = (await getFromCache(etagKey)) as string;

      return new Promise<R>((resolve, reject) => {
        this.doCall(
          parameters,
          (response: AxiosResponse, nextEtag: string): Promise<R> => {
            // Cache etag value for next request
            // cache only if caching is on
            if (cacheOptions?.key && nextEtag) {
              setToCache(etagKey, nextEtag);
            }
            return response.data;
          },
          etagValue
        )
          .then((result) => {
            resolve(result);
          })
          .catch((err: AxiosError) => {
            // 304, Not modified status is thrown in error
            if (etagValue && err.response?.status === 304) {
              resolve(this.getWithEtagCache(parameters, etagKey, cacheOptions));
            }
            reject(mapError(err));
          });
      });
    }, cacheOptions as CacheConfigType);
  }

  doCall<T, R = T>(
    parameters: IRequestParams<T>,
    onSuccess: (response: AxiosResponse, nextEtag: string) => Promise<R>,
    etag?: string
  ): Promise<R> {
    const { url, params, headers } = parameters;
    if (etag) {
      (headers as Record<string, unknown>)["if-none-match"] = etag;
    }

    const options: AxiosRequestConfig = {
      headers,
      params,
    };

    return new Promise<R>((resolve, reject) => {
      axios
        .get(url, options)
        .then((response) => {
          resolve(onSuccess(response, response.headers?.etag));
        })
        .catch((err: AxiosError) => {
          reject(err);
        });
    });
  }

  async getWithEtagCache<T, R = T>(
    parameters: IRequestParams<T>,
    etagKey: string,
    cacheOptions?: CacheConfigType
  ): Promise<R> {
    const cacheData = await getFromCache(cacheOptions?.key as string);
    if (cacheData) {
      return cacheData as R;
    }
    return this.doCall(parameters, (response: AxiosResponse, nextEtag: string) => {
      setToCache(cacheOptions?.key as string, response.data, cacheOptions);
      setToCache(etagKey, nextEtag);
      return response.data;
    });
  }

  post<T, R = T>(parameters: IRequestParams<T>): Promise<R> {
    return new Promise<R>((resolve, reject) => {
      const { url, params, headers } = parameters;
      const options: AxiosRequestConfig = { headers };
      axios
        .post(url, params, options)
        .then((response: { data: unknown }) => {
          resolve(response.data as R);
        })
        .catch((err: AxiosError) => {
          reject(mapError(err));
        });
    });
  }

  put<T, R = T>(parameters: IRequestParams<T>): Promise<R> {
    return new Promise<R>((resolve, reject) => {
      const { url, params, headers, data } = parameters;
      const options: AxiosRequestConfig = { headers, params };
      axios
        .put(url, data, options)
        .then((response: { data: unknown }) => {
          resolve(response.data as R);
        })
        .catch((err: AxiosError) => {
          reject(mapError(err));
        });
    });
  }

  delete<T, R = T>(parameters: IRequestParams<T>): Promise<R> {
    return new Promise<R>((resolve, reject) => {
      const { url, headers } = parameters;
      const options: AxiosRequestConfig = { headers };
      axios
        .delete(url, options)
        .then((response: { data: unknown }) => {
          resolve(response.data as R);
        })
        .catch((err: AxiosError) => {
          reject(mapError(err));
        });
    });
  }
}

export const axiosClient = new axiosWrapperClient();
