import { axiosClient } from "../lib/axiosWrapper";
import {
  Client,
  Props,
  CreateBranchOrderProps,
  CreateBranchOrderResponse,
  CreateBranchOrderSuccess,
  CreateBranchOrderRequestParams,
  OrderDetailProductType,
  OrderFulfilmentType,
  OrderDetailType,
} from "./structures";
import { v4 as uuidv4 } from "uuid";

export const buildClient = (props: Props): Client => {
  const { rootUrl, authHeaders } = props;

  const createBranchOrder = async (
    createBranchOrderProps: CreateBranchOrderProps
  ): Promise<CreateBranchOrderSuccess> => {
    const now = new Date();
    const createBranchOrderRequestParams: CreateBranchOrderRequestParams = {
      ...createBranchOrderProps,
      orderFulfilmentType: OrderFulfilmentType.BRANCH,
      transactionEndDateTime: now.toISOString().slice(0, -5),
      orderDetail: {
        ...createBranchOrderProps.orderDetail,
        productType: OrderDetailProductType.NOTES,
        type: OrderDetailType.FULFILLED,
      },
    };

    const headers = {
      ...(await authHeaders()),
    };
    headers["X-TransactionId"] = uuidv4();
    const response = await axiosClient
      .post<CreateBranchOrderResponse>({
        url: `${rootUrl}/fres-api/fmcc/create-branch-order`,
        params: createBranchOrderRequestParams as any,
        headers,
      })
      .catch((err) => {
        console.error(err);
        return Promise.reject(err);
      });

    return {
      transactionID: response?.orderSummary.transactionID,
    };
  };

  return Object.freeze({
    createBranchOrder,
  });
};
