export interface msrClient {
  listen(): Promise<void>;
}

export type MSRResponse = void;

export interface MSRClientProps {
  useMock?: boolean;
}

export enum MSRActions {
  Listen = "LISTEN",
}
