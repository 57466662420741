import {CreateBranchOrderFulfilmentProps} from "../fmcc/types"

export const areFulfilmentPropsValid = (fulfilment: Record<string, string>): boolean => {
  if (
    !fulfilment.transactionType ||
    !fulfilment.productCode ||
    !fulfilment.currencyCode ||
    !fulfilment.baseGbpAmount ||
    !fulfilment.fxAmount ||
    !fulfilment.exchangeRate ||
    !fulfilment.spotRate ||
    !fulfilment.userID
  ) {
    return false;
  }
  if (Number.isNaN(+fulfilment.baseGbpAmount) || Number.isNaN(+fulfilment.fxAmount)) {
    return false;
  }
  return true;
};

export const createBranchOrderRequestParams = (basketId: string, fulfilment: CreateBranchOrderFulfilmentProps) => {
  const explodedBasket = basketId.split("-");

  return {
    transactionID: basketId,
    transactionType: fulfilment.transactionType || "",
    orderDetail: {
      productCode: fulfilment.productCode || "",
      currencyCode: fulfilment.currencyCode || "",
      baseTransactionAmount: +(fulfilment.baseGbpAmount || 0),
      foreignTransactionAmount: +(fulfilment.fxAmount || 0),
      exchangeRate: fulfilment.exchangeRate || "",
      spotRate: fulfilment.spotRate || "",
    },
    branchCode: explodedBasket[0],
    tillUserID: fulfilment.userID || "",
    tillID: explodedBasket[1],
  };
};
