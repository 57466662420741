import { FindAddressResponse } from "../openapi/api";

export interface PostcodeAddress {
  title: string;
  value: string;
}

export interface Address {
  Description: string;
  Highlight: string;
  Id: string;
  Text: string;
  Type: string;
}

export const getAddressList = (response: FindAddressResponse): PostcodeAddress[] => {
  if (response.Addresses?.length) {
    return response.Addresses.map((value: object) => {
      const addr = value as Address;
      return {
        title: [addr.Text, addr.Description].join(", "),
        value: addr.Id,
      } as PostcodeAddress;
    });
  }
  return [] as PostcodeAddress[];
};
