import { AuthProvider } from "postoffice-product-journey-api-clients";
import { FulfilmentStateEnum, TransactionsApiInterface } from "../../openapi/transaction-api-v3";
import {
  ApiClientsConfig,
  BasketItemPayload,
  FulfillerResponse,
  FulfillmentItem,
  FulfilmentProcessorResponse,
  GenericFulfiller,
} from "../../types";
import { ImovoFulfillerActions } from "./types";
import { process as processRedeemRequest } from "./actions/redeem";
import { respond } from "../../helpers";
import { brokenPromises, updateFulfilmentTokens } from "../helpers";

export const ImovoFulfiller = (
  client: TransactionsApiInterface,
  apiConfig: ApiClientsConfig["imovo"],
  basketId: string,
  basketItem: BasketItemPayload,
  userToken: string,
  deviceToken: string
): GenericFulfiller => {
  const authHeaders: AuthProvider = async () => {
    return new Promise((resolve) => {
      resolve({ Authorization: deviceToken });
    });
  };

  const fulfill = async (item: FulfillmentItem): Promise<FulfillerResponse> => {
    if (!item.basketItem?.fulfilment?.action) {
      throw Error("fulfilment.action must be set in journey");
    }

    const fulfilmentAction = item.basketItem.fulfilment.action as ImovoFulfillerActions;

    let response: FulfilmentProcessorResponse;

    switch (fulfilmentAction) {
      case ImovoFulfillerActions.Redeem:
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        response = await brokenPromises(processRedeemRequest(basketId, apiConfig, basketItem, authHeaders));
        break;
      default:
        throw new Error(`Unknown fulfilment action: ${item.basketItem?.fulfilment?.action}`);
    }

    const fulfilmentStatus = response.fulfilment?.status ?? FulfilmentStateEnum.Failure;
    const fulfilmentTokens = response.fulfilment?.tokens ?? {};
    const isSuccessful = fulfilmentStatus === FulfilmentStateEnum.Success;

    const updated = await updateFulfilmentTokens(
      client,
      basketId,
      String(basketItem.entryID),
      fulfilmentStatus,
      fulfilmentTokens,
      userToken
    );

    return respond(isSuccessful && updated, {
      result: {},
      networkError: !updated,
      ...(response.receipts && {
        receipts: response.receipts,
      }),
      ...(response.notice && {
        notice: response.notice,
      }),
    });
  };

  return Object.freeze({ fulfill });
};