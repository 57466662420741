export interface adminClient {
  getVersion(): Promise<AdminGetVersionResponse>;
}

export type AdminGetVersionResponse = {
  release: string;
  hash: string;
  built: string;
};

export type AdminResponse = AdminGetVersionResponse;

export interface AdminClientProps {
  useMock?: boolean;
}

export enum AdminActions {
  GetVersion = "GET_VERSION",
}
