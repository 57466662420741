import { isError } from "../../helpers";
import WebSocketAsPromised from "websocket-as-promised";
import { SupportedServices, Response } from "../../types";

import { MSRResponse, MSRActions, msrClient, MSRClientProps } from "./types";

export const buildMSRClient = (
  requestHandler: WebSocketAsPromised["sendRequest"],
  props?: MSRClientProps
): msrClient => {
  const performAction = async (action: MSRActions): Promise<MSRResponse> => {
    if (props?.useMock) {
      return;
    }

    const response = (await requestHandler({
      service: SupportedServices.MagneticStripeCardReader,
      action,
    })) as Response;

    if (isError(response)) {
      return Promise.reject(response);
    }
  };

  return Object.freeze({
    listen: () => performAction(MSRActions.Listen),
  });
};
