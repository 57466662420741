import { GetCopFieldsParams, CopFieldsResponse } from "./types";

export const prepareCopItems = (params: GetCopFieldsParams): CopFieldsResponse => {
  const {
    basketItems,
    postageSaleUniqueJourneyID,
    conseqLossValue,
    conseqLossCost,
    maximumComp,
    compensationCost,
    totalPrePaid,
    reference,
    addressValidated, // To be used when PAF introduced - should be "N" or "Y" (I think...)
    serviceName,
    receiptAddress,
    itemValue,
    weight,
    ServiceBaseAmount,
    saturdayDeliveryCost, // To be used in R3
    conditionAccepted,
    copText,
    KahalaIncluded,
    PriorityV2,
    LastDespatch,
    repDespatch,
  } = params;

  let dgLongNames: string[] = [];
  if (basketItems) {
    const dangerousGoodsInBasket = basketItems.filter(
      (item) => item.basket?.postageSaleUniqueJourneyID === postageSaleUniqueJourneyID
    );
    if (dangerousGoodsInBasket.length > 0) {
      dgLongNames = dangerousGoodsInBasket.map((item) => item.basket?.Service);
    }
  }

  function getPence(value: string | number): number {
    return Math.round(Number(value) * 100);
  }
  function getPounds(value: string | number): string {
    return (Number(value) / 100).toFixed(2);
  }
  const postage =
    getPence(ServiceBaseAmount) + getPence(compensationCost) - (totalPrePaid ? getPence(totalPrePaid) : 0);

  let message: string = copText.Standard.EnglishText;
  if (Number(conseqLossValue) > 0) {
    message = copText.ConsLossFeeIncluded.EnglishText;
  } else if (Number(itemValue) > 0 && (compensationCost || maximumComp)) {
    message = copText.InsuranceFeeIncluded.EnglishText;
  } else if (KahalaIncluded === "true") {
    message = copText.KahalaIncluded.EnglishText;
  }

  return {
    serviceName,
    message,
    receiptAddress: `Address: ${receiptAddress.filter((str) => str !== "").join(", ")}`,
    weight: `Weight: ${weight}g`,
    postage: `Postage inc comp: £${getPounds(postage)}`,
    ...(Number(totalPrePaid) > 0 && { prepaidCost: `-£${Number(totalPrePaid).toFixed(2)}` }),
    ...(addressValidated && { addressValidated: "N" }), // Hardcoded as "N" until we introduce PAF
    ...(conditionAccepted && { conditionAccepted }),
    ...(reference && { reference }),
    ...(dgLongNames.length > 0 && { dgLongNames }),
    ...(Number(conseqLossValue) > 0 && { conseqLossValue: `Consq. Loss: £${Number(conseqLossValue).toFixed(2)}` }),
    ...(Number(conseqLossCost) > 0 && { conseqLossCost: `£${Number(conseqLossCost).toFixed(2)}` }),
    ...(Number(itemValue) > 0 && { itemValue: `Item Value: £${Number(itemValue).toFixed(2)}` }),
    ...(Number(itemValue) > 0 &&
      (compensationCost || maximumComp) && { maximumComp: `Maximum Comp: £${Number(maximumComp).toFixed(2)}` }),
    ...(Number(saturdayDeliveryCost) > 0 && { saturdayDeliveryCost: `£${Number(saturdayDeliveryCost).toFixed(2)}` }),
    ...(PriorityV2 && {
      afterLastCollectionMessage: `${LastDespatch === "true" ? "After" : "Before"} latest acceptance time`,
    }),
    repDespatch,
  };
};
