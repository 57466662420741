/* tslint:disable */
/* eslint-disable */
/**
 * CDP integrations drop & go
 * NBIT integrations with Post office Common Digital Platform (CDP) endpoint definitions for drop & go (dng) and order service. Please refer to the spec for latest information. <https://github.com/pol-onesource/spm/tree/master/integration-factory/services/drop-go-service/api/drop-go-service.yaml>.
 *
 * The version of the OpenAPI document: 1.0
 * Contact: po.contact@postoffice.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from "./configuration";
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "./common";
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "./base";

/**
 * Request body for assign dng card.
 * @export
 * @interface AssignDngCardReqBody
 */
export interface AssignDngCardReqBody {
  /**
   *
   * @type {string}
   * @memberof AssignDngCardReqBody
   */
  dnGAccountNumber: string;
  /**
   *
   * @type {string}
   * @memberof AssignDngCardReqBody
   */
  dnGCardNumber: string;
}
/**
 * request used to assign dng card
 * @export
 * @interface AssignDngCardRequest
 */
export interface AssignDngCardRequest {
  /**
   *
   * @type {HdrReq}
   * @memberof AssignDngCardRequest
   */
  hdr?: HdrReq;
  /**
   *
   * @type {AssignDngCardReqBody}
   * @memberof AssignDngCardRequest
   */
  body?: AssignDngCardReqBody;
}
/**
 * Response body for assign card.
 * @export
 * @interface AssignDngCardResBody
 */
export interface AssignDngCardResBody {
  /**
   *
   * @type {string}
   * @memberof AssignDngCardResBody
   */
  dnGCardNumber?: string;
}
/**
 * response used to send dng account details
 * @export
 * @interface AssignDngCardResponse
 */
export interface AssignDngCardResponse {
  /**
   *
   * @type {HdrResp}
   * @memberof AssignDngCardResponse
   */
  hdr?: HdrResp;
  /**
   *
   * @type {AssignDngCardResBody}
   * @memberof AssignDngCardResponse
   */
  body?: AssignDngCardResBody;
  /**
   *
   * @type {StatusResp}
   * @memberof AssignDngCardResponse
   */
  status?: StatusResp;
}
/**
 * Request body for create account details.
 * @export
 * @interface CreateDngAccountReqBody
 */
export interface CreateDngAccountReqBody {
  /**
   *
   * @type {string}
   * @memberof CreateDngAccountReqBody
   */
  dnGCardNumber: string;
  /**
   *
   * @type {string}
   * @memberof CreateDngAccountReqBody
   */
  companyRegistrationNumber?: string;
  /**
   *
   * @type {string}
   * @memberof CreateDngAccountReqBody
   */
  businessName?: string;
  /**
   *
   * @type {string}
   * @memberof CreateDngAccountReqBody
   */
  industry?: string;
  /**
   *
   * @type {string}
   * @memberof CreateDngAccountReqBody
   */
  trade?: string;
  /**
   *
   * @type {string}
   * @memberof CreateDngAccountReqBody
   */
  startDate?: string;
  /**
   *
   * @type {string}
   * @memberof CreateDngAccountReqBody
   */
  employees?: string;
  /**
   *
   * @type {string}
   * @memberof CreateDngAccountReqBody
   */
  addressLine1: string;
  /**
   *
   * @type {string}
   * @memberof CreateDngAccountReqBody
   */
  addressLine2?: string;
  /**
   *
   * @type {string}
   * @memberof CreateDngAccountReqBody
   */
  addressLine3?: string;
  /**
   *
   * @type {string}
   * @memberof CreateDngAccountReqBody
   */
  town?: string;
  /**
   *
   * @type {string}
   * @memberof CreateDngAccountReqBody
   */
  county?: string;
  /**
   *
   * @type {string}
   * @memberof CreateDngAccountReqBody
   */
  postcode: string;
  /**
   *
   * @type {string}
   * @memberof CreateDngAccountReqBody
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof CreateDngAccountReqBody
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof CreateDngAccountReqBody
   */
  contactName: string;
  /**
   *
   * @type {string}
   * @memberof CreateDngAccountReqBody
   */
  phoneNumber: string;
  /**
   *
   * @type {string}
   * @memberof CreateDngAccountReqBody
   */
  dateOfBirth?: string;
  /**
   *
   * @type {string}
   * @memberof CreateDngAccountReqBody
   */
  emailAddress?: string;
  /**
   *
   * @type {CreateDngAccountReqBodyMarketingOptions}
   * @memberof CreateDngAccountReqBody
   */
  marketing_Options?: CreateDngAccountReqBodyMarketingOptions;
}
/**
 *
 * @export
 * @interface CreateDngAccountReqBodyMarketingOptions
 */
export interface CreateDngAccountReqBodyMarketingOptions {
  /**
   *
   * @type {string}
   * @memberof CreateDngAccountReqBodyMarketingOptions
   */
  direct_Mail?: string;
  /**
   *
   * @type {string}
   * @memberof CreateDngAccountReqBodyMarketingOptions
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof CreateDngAccountReqBodyMarketingOptions
   */
  text?: string;
  /**
   *
   * @type {string}
   * @memberof CreateDngAccountReqBodyMarketingOptions
   */
  telephone?: string;
  /**
   *
   * @type {string}
   * @memberof CreateDngAccountReqBodyMarketingOptions
   */
  moi_Email?: string;
  /**
   *
   * @type {string}
   * @memberof CreateDngAccountReqBodyMarketingOptions
   */
  moi_Mobile_Number?: string;
}
/**
 * request used to retrive dng account details
 * @export
 * @interface CreateDngAccountRequest
 */
export interface CreateDngAccountRequest {
  /**
   *
   * @type {HdrReq}
   * @memberof CreateDngAccountRequest
   */
  hdr?: HdrReq;
  /**
   *
   * @type {CreateDngAccountReqBody}
   * @memberof CreateDngAccountRequest
   */
  body?: CreateDngAccountReqBody;
}
/**
 * Response body for account details.
 * @export
 * @interface CreateDngAccountResBody
 */
export interface CreateDngAccountResBody {
  /**
   *
   * @type {string}
   * @memberof CreateDngAccountResBody
   */
  dnGAccountNumber?: string;
  /**
   *
   * @type {string}
   * @memberof CreateDngAccountResBody
   */
  dnGAccountStatus?: string;
  /**
   *
   * @type {string}
   * @memberof CreateDngAccountResBody
   */
  dnGCardNumber?: string;
  /**
   *
   * @type {string}
   * @memberof CreateDngAccountResBody
   */
  currentBalance?: string;
}
/**
 * response used to send dng account details
 * @export
 * @interface CreateDngAccountResponse
 */
export interface CreateDngAccountResponse {
  /**
   *
   * @type {HdrResp}
   * @memberof CreateDngAccountResponse
   */
  hdr?: HdrResp;
  /**
   *
   * @type {CreateDngAccountResBody}
   * @memberof CreateDngAccountResponse
   */
  body?: CreateDngAccountResBody;
  /**
   *
   * @type {StatusResp}
   * @memberof CreateDngAccountResponse
   */
  status?: StatusResp;
}
/**
 * Request body for create OLM order.
 * @export
 * @interface CreateOLMOrderReqBody
 */
export interface CreateOLMOrderReqBody {
  /**
   *
   * @type {CreateOLMOrderReqBodyBranchAddress}
   * @memberof CreateOLMOrderReqBody
   */
  branchAddress: CreateOLMOrderReqBodyBranchAddress;
  /**
   *
   * @type {Array<CreateOLMOrderReqBodyLineItems>}
   * @memberof CreateOLMOrderReqBody
   */
  lineItems: Array<CreateOLMOrderReqBodyLineItems>;
  /**
   *
   * @type {string}
   * @memberof CreateOLMOrderReqBody
   */
  settlementValue: string;
  /**
   *
   * @type {string}
   * @memberof CreateOLMOrderReqBody
   */
  transactionCurrency: string;
  /**
   *
   * @type {string}
   * @memberof CreateOLMOrderReqBody
   */
  paymentCardType: string;
  /**
   *
   * @type {string}
   * @memberof CreateOLMOrderReqBody
   */
  totalOrderVolume: string;
  /**
   *
   * @type {string}
   * @memberof CreateOLMOrderReqBody
   */
  branchCode: string;
  /**
   *
   * @type {string}
   * @memberof CreateOLMOrderReqBody
   */
  counterId: string;
  /**
   *
   * @type {string}
   * @memberof CreateOLMOrderReqBody
   */
  counterTransactionId: string;
  /**
   *
   * @type {string}
   * @memberof CreateOLMOrderReqBody
   */
  dropAndGoAccountNumber: string;
}
/**
 *
 * @export
 * @interface CreateOLMOrderReqBodyBranchAddress
 */
export interface CreateOLMOrderReqBodyBranchAddress {
  /**
   *
   * @type {Array<string>}
   * @memberof CreateOLMOrderReqBodyBranchAddress
   */
  addressLine?: Array<string>;
}
/**
 *
 * @export
 * @interface CreateOLMOrderReqBodyLineItem
 */
export interface CreateOLMOrderReqBodyLineItem {
  /**
   *
   * @type {CreateOLMOrderReqBodyLineItemCreateOrderPrimary}
   * @memberof CreateOLMOrderReqBodyLineItem
   */
  createOrderPrimary?: CreateOLMOrderReqBodyLineItemCreateOrderPrimary;
  /**
   *
   * @type {Array<CreateOLMOrderReqBodyLineItemSecondary>}
   * @memberof CreateOLMOrderReqBodyLineItem
   */
  secondary?: Array<CreateOLMOrderReqBodyLineItemSecondary>;
}
/**
 *
 * @export
 * @interface CreateOLMOrderReqBodyLineItemCreateOrderPrimary
 */
export interface CreateOLMOrderReqBodyLineItemCreateOrderPrimary {
  /**
   *
   * @type {string}
   * @memberof CreateOLMOrderReqBodyLineItemCreateOrderPrimary
   */
  basketId?: string;
  /**
   *
   * @type {string}
   * @memberof CreateOLMOrderReqBodyLineItemCreateOrderPrimary
   */
  transactionId?: string;
  /**
   *
   * @type {string}
   * @memberof CreateOLMOrderReqBodyLineItemCreateOrderPrimary
   */
  skuId?: string;
  /**
   *
   * @type {string}
   * @memberof CreateOLMOrderReqBodyLineItemCreateOrderPrimary
   */
  quantity?: string;
  /**
   *
   * @type {string}
   * @memberof CreateOLMOrderReqBodyLineItemCreateOrderPrimary
   */
  grossPrice?: string;
  /**
   *
   * @type {string}
   * @memberof CreateOLMOrderReqBodyLineItemCreateOrderPrimary
   */
  service?: string;
  /**
   *
   * @type {string}
   * @memberof CreateOLMOrderReqBodyLineItemCreateOrderPrimary
   */
  format?: string;
  /**
   *
   * @type {string}
   * @memberof CreateOLMOrderReqBodyLineItemCreateOrderPrimary
   */
  weight?: string;
  /**
   *
   * @type {string}
   * @memberof CreateOLMOrderReqBodyLineItemCreateOrderPrimary
   */
  county?: string;
  /**
   *
   * @type {string}
   * @memberof CreateOLMOrderReqBodyLineItemCreateOrderPrimary
   */
  postcode?: string;
  /**
   *
   * @type {string}
   * @memberof CreateOLMOrderReqBodyLineItemCreateOrderPrimary
   */
  addressLine1?: string;
  /**
   *
   * @type {string}
   * @memberof CreateOLMOrderReqBodyLineItemCreateOrderPrimary
   */
  addressTown?: string;
  /**
   *
   * @type {string}
   * @memberof CreateOLMOrderReqBodyLineItemCreateOrderPrimary
   */
  prn?: string;
  /**
   *
   * @type {string}
   * @memberof CreateOLMOrderReqBodyLineItemCreateOrderPrimary
   */
  usePrnForTracking?: string;
}
/**
 *
 * @export
 * @interface CreateOLMOrderReqBodyLineItemSecondary
 */
export interface CreateOLMOrderReqBodyLineItemSecondary {
  /**
   *
   * @type {string}
   * @memberof CreateOLMOrderReqBodyLineItemSecondary
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof CreateOLMOrderReqBodyLineItemSecondary
   */
  grossPrice?: string;
}
/**
 *
 * @export
 * @interface CreateOLMOrderReqBodyLineItems
 */
export interface CreateOLMOrderReqBodyLineItems {
  /**
   *
   * @type {CreateOLMOrderReqBodyLineItem}
   * @memberof CreateOLMOrderReqBodyLineItems
   */
  lineItem?: CreateOLMOrderReqBodyLineItem;
}
/**
 * request used to post transaction to Dng account.
 * @export
 * @interface CreateOLMOrderRequest
 */
export interface CreateOLMOrderRequest {
  /**
   *
   * @type {HdrReq}
   * @memberof CreateOLMOrderRequest
   */
  hdr?: HdrReq;
  /**
   *
   * @type {CreateOLMOrderReqBody}
   * @memberof CreateOLMOrderRequest
   */
  body?: CreateOLMOrderReqBody;
}
/**
 * Response body for post transaction to Dng Account.
 * @export
 * @interface CreateOLMOrderResBody
 */
export interface CreateOLMOrderResBody {
  /**
   *
   * @type {string}
   * @memberof CreateOLMOrderResBody
   */
  originatorsTransactionReference: string;
}
/**
 * response recived for post transaction to Dng account.
 * @export
 * @interface CreateOLMOrderResponse
 */
export interface CreateOLMOrderResponse {
  /**
   *
   * @type {HdrResp}
   * @memberof CreateOLMOrderResponse
   */
  hdr?: HdrResp;
  /**
   *
   * @type {CreateOLMOrderResBody}
   * @memberof CreateOLMOrderResponse
   */
  body?: CreateOLMOrderResBody;
  /**
   *
   * @type {StatusResp}
   * @memberof CreateOLMOrderResponse
   */
  status?: StatusResp;
}
/**
 * Body for account details.
 * @export
 * @interface GetDngAccDetailsReqBody
 */
export interface GetDngAccDetailsReqBody {
  /**
   *
   * @type {string}
   * @memberof GetDngAccDetailsReqBody
   */
  dnGAccountNumber?: string;
  /**
   *
   * @type {string}
   * @memberof GetDngAccDetailsReqBody
   */
  dnGCardNumber?: string;
  /**
   *
   * @type {string}
   * @memberof GetDngAccDetailsReqBody
   */
  phoneNumber?: string;
  /**
   *
   * @type {string}
   * @memberof GetDngAccDetailsReqBody
   */
  emailAddress?: string;
}
/**
 * request used to retrive dng account details
 * @export
 * @interface GetDngAccDetailsRequest
 */
export interface GetDngAccDetailsRequest {
  /**
   *
   * @type {HdrReq}
   * @memberof GetDngAccDetailsRequest
   */
  hdr?: HdrReq;
  /**
   *
   * @type {GetDngAccDetailsReqBody}
   * @memberof GetDngAccDetailsRequest
   */
  body?: GetDngAccDetailsReqBody;
}
/**
 * Response body for account details.
 * @export
 * @interface GetDngAccDetailsResBody
 */
export interface GetDngAccDetailsResBody {
  /**
   *
   * @type {string}
   * @memberof GetDngAccDetailsResBody
   */
  dnGAccountNumber?: string;
  /**
   *
   * @type {string}
   * @memberof GetDngAccDetailsResBody
   */
  dnGAccountStatus?: string;
  /**
   *
   * @type {string}
   * @memberof GetDngAccDetailsResBody
   */
  currentBalance?: string;
  /**
   *
   * @type {string}
   * @memberof GetDngAccDetailsResBody
   */
  businessName?: string;
  /**
   *
   * @type {string}
   * @memberof GetDngAccDetailsResBody
   */
  industry?: string;
  /**
   *
   * @type {string}
   * @memberof GetDngAccDetailsResBody
   */
  trade?: string;
  /**
   *
   * @type {string}
   * @memberof GetDngAccDetailsResBody
   */
  startDate?: string;
  /**
   *
   * @type {string}
   * @memberof GetDngAccDetailsResBody
   */
  employees?: string;
  /**
   *
   * @type {string}
   * @memberof GetDngAccDetailsResBody
   */
  addressLine1?: string;
  /**
   *
   * @type {string}
   * @memberof GetDngAccDetailsResBody
   */
  addressLine2?: string;
  /**
   *
   * @type {string}
   * @memberof GetDngAccDetailsResBody
   */
  addressLine3?: string;
  /**
   *
   * @type {string}
   * @memberof GetDngAccDetailsResBody
   */
  town?: string;
  /**
   *
   * @type {string}
   * @memberof GetDngAccDetailsResBody
   */
  county?: string;
  /**
   *
   * @type {string}
   * @memberof GetDngAccDetailsResBody
   */
  postcode?: string;
  /**
   *
   * @type {string}
   * @memberof GetDngAccDetailsResBody
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof GetDngAccDetailsResBody
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof GetDngAccDetailsResBody
   */
  contactName?: string;
  /**
   *
   * @type {string}
   * @memberof GetDngAccDetailsResBody
   */
  phoneNumber?: string;
  /**
   *
   * @type {string}
   * @memberof GetDngAccDetailsResBody
   */
  emailAddress?: string;
  /**
   *
   * @type {string}
   * @memberof GetDngAccDetailsResBody
   */
  dateOfBirth?: string;
  /**
   *
   * @type {string}
   * @memberof GetDngAccDetailsResBody
   */
  companyRegistrationNumber?: string;
  /**
   *
   * @type {GetDngAccDetailsResBodyDnGCard}
   * @memberof GetDngAccDetailsResBody
   */
  dnGCard?: GetDngAccDetailsResBodyDnGCard;
  /**
   *
   * @type {GetDngAccDetailsResBodyMarketingOptions}
   * @memberof GetDngAccDetailsResBody
   */
  marketing_Options?: GetDngAccDetailsResBodyMarketingOptions;
}
/**
 *
 * @export
 * @interface GetDngAccDetailsResBodyDnGCard
 */
export interface GetDngAccDetailsResBodyDnGCard {
  /**
   *
   * @type {string}
   * @memberof GetDngAccDetailsResBodyDnGCard
   */
  dnGCardNumber?: string;
  /**
   *
   * @type {string}
   * @memberof GetDngAccDetailsResBodyDnGCard
   */
  dnGCardStatus?: string;
}
/**
 *
 * @export
 * @interface GetDngAccDetailsResBodyMarketingOptions
 */
export interface GetDngAccDetailsResBodyMarketingOptions {
  /**
   *
   * @type {string}
   * @memberof GetDngAccDetailsResBodyMarketingOptions
   */
  direct_Mail?: string;
  /**
   *
   * @type {string}
   * @memberof GetDngAccDetailsResBodyMarketingOptions
   */
  email?: string;
  /**
   *
   * @type {string}
   * @memberof GetDngAccDetailsResBodyMarketingOptions
   */
  text?: string;
  /**
   *
   * @type {string}
   * @memberof GetDngAccDetailsResBodyMarketingOptions
   */
  telephone?: string;
  /**
   *
   * @type {string}
   * @memberof GetDngAccDetailsResBodyMarketingOptions
   */
  moi_Mobile_Number?: string;
}
/**
 * response used to send dng account details
 * @export
 * @interface GetDngAccDetailsResponse
 */
export interface GetDngAccDetailsResponse {
  /**
   *
   * @type {HdrResp}
   * @memberof GetDngAccDetailsResponse
   */
  hdr?: HdrResp;
  /**
   *
   * @type {GetDngAccDetailsResBody}
   * @memberof GetDngAccDetailsResponse
   */
  body?: GetDngAccDetailsResBody;
  /**
   *
   * @type {StatusResp}
   * @memberof GetDngAccDetailsResponse
   */
  status?: StatusResp;
}
/**
 * header common across all request necessary for origin validation at CDP.
 * @export
 * @interface HdrReq
 */
export interface HdrReq {
  /**
   * Date time in ISO format ex:- 2015-04-24T00:00:01
   * @type {string}
   * @memberof HdrReq
   */
  created: string;
  /**
   * Request id ex:12345
   * @type {number}
   * @memberof HdrReq
   */
  reqId: string;
  /**
   * Branch id ex:123456
   * @type {number}
   * @memberof HdrReq
   */
  branchId: string;
  /**
   * Branch id ex:1
   * @type {number}
   * @memberof HdrReq
   */
  ctrId: string;
  /**
   * Txn id formed by combining branchId-ctrlId-reqId
   * @type {string}
   * @memberof HdrReq
   */
  txId: string;
  /**
   * Clerk id ex:MAN001
   * @type {string}
   * @memberof HdrReq
   */
  clerkId: string;
}
/**
 * header common across all request necessary for origin validation at CDP.
 * @export
 * @interface HdrResp
 */
export interface HdrResp {
  /**
   * Date time in ISO format ex:- 2022-05-26T05:25:37.896Z
   * @type {string}
   * @memberof HdrResp
   */
  created?: string;
  /**
   * Request id ex:12345
   * @type {number}
   * @memberof HdrResp
   */
  reqId?: string;
  /**
   * Txn id formed by combining branchId-ctrlId-reqId
   * @type {string}
   * @memberof HdrResp
   */
  txId?: string;
}
/**
 *
 * @export
 * @interface ModelError
 */
export interface ModelError {
  /**
   *
   * @type {string}
   * @memberof ModelError
   */
  message?: string;
}
/**
 * Request body for post transaction to Dng Account.
 * @export
 * @interface PostTxnToDngAccountReqBody
 */
export interface PostTxnToDngAccountReqBody {
  /**
   *
   * @type {string}
   * @memberof PostTxnToDngAccountReqBody
   */
  dnGAccountNumber: string;
  /**
   *
   * @type {string}
   * @memberof PostTxnToDngAccountReqBody
   */
  transactionType: PostTxnToDngAccountReqBodyTransactionTypeEnum;
  /**
   *
   * @type {string}
   * @memberof PostTxnToDngAccountReqBody
   */
  amount: string;
  /**
   *
   * @type {string}
   * @memberof PostTxnToDngAccountReqBody
   */
  timeStamp?: string;
  /**
   *
   * @type {string}
   * @memberof PostTxnToDngAccountReqBody
   */
  system: PostTxnToDngAccountReqBodySystemEnum;
  /**
   *
   * @type {string}
   * @memberof PostTxnToDngAccountReqBody
   */
  transactionReference: string;
  /**
   *
   * @type {string}
   * @memberof PostTxnToDngAccountReqBody
   */
  branchCode: string;
  /**
   *
   * @type {string}
   * @memberof PostTxnToDngAccountReqBody
   */
  counterId: string;
  /**
   *
   * @type {string}
   * @memberof PostTxnToDngAccountReqBody
   */
  transactionDescription: PostTxnToDngAccountReqBodyTransactionDescriptionEnum;
  /**
   *
   * @type {string}
   * @memberof PostTxnToDngAccountReqBody
   */
  paymentMethod?: string;
  /**
   *
   * @type {string}
   * @memberof PostTxnToDngAccountReqBody
   */
  cardNumber?: string;
  /**
   *
   * @type {string}
   * @memberof PostTxnToDngAccountReqBody
   */
  endMailsTimeout?: PostTxnToDngAccountReqBodyEndMailsTimeoutEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum PostTxnToDngAccountReqBodyTransactionTypeEnum {
  Credit = "CREDIT",
  Debit = "DEBIT",
}
/**
 * @export
 * @enum {string}
 */
export enum PostTxnToDngAccountReqBodySystemEnum {
  Online = "ONLINE",
  Branch = "BRANCH",
}
/**
 * @export
 * @enum {string}
 */
export enum PostTxnToDngAccountReqBodyTransactionDescriptionEnum {
  OnlineTopUp = "ONLINE_TOP_UP",
  CorrectionDebit = "CORRECTION_DEBIT",
  CorrectionCredit = "CORRECTION_CREDIT",
  BasketPayment = "BASKET_PAYMENT",
  InBranchTopUp = "IN_BRANCH_TOP_UP",
  AccountClosure = "ACCOUNT_CLOSURE",
  SpoiltLabel = "SPOILT_LABEL",
}
/**
 * @export
 * @enum {string}
 */
export enum PostTxnToDngAccountReqBodyEndMailsTimeoutEnum {
  True = "true",
  False = "false",
}

/**
 * request used to post transaction to Dng account.
 * @export
 * @interface PostTxnToDngAccountRequest
 */
export interface PostTxnToDngAccountRequest {
  /**
   *
   * @type {HdrReq}
   * @memberof PostTxnToDngAccountRequest
   */
  hdr?: HdrReq;
  /**
   *
   * @type {PostTxnToDngAccountReqBody}
   * @memberof PostTxnToDngAccountRequest
   */
  body?: PostTxnToDngAccountReqBody;
}
/**
 * Response body for post transaction to Dng Account.
 * @export
 * @interface PostTxnToDngAccountResBody
 */
export interface PostTxnToDngAccountResBody {
  /**
   *
   * @type {string}
   * @memberof PostTxnToDngAccountResBody
   */
  currentBalance: string;
  /**
   *
   * @type {string}
   * @memberof PostTxnToDngAccountResBody
   */
  originatorsTransactionReference: string;
}
/**
 * response recived for post transaction to Dng account.
 * @export
 * @interface PostTxnToDngAccountResponse
 */
export interface PostTxnToDngAccountResponse {
  /**
   *
   * @type {HdrResp}
   * @memberof PostTxnToDngAccountResponse
   */
  hdr?: HdrResp;
  /**
   *
   * @type {PostTxnToDngAccountResBody}
   * @memberof PostTxnToDngAccountResponse
   */
  body?: PostTxnToDngAccountResBody;
  /**
   *
   * @type {StatusResp}
   * @memberof PostTxnToDngAccountResponse
   */
  status?: StatusResp;
}
/**
 * status common across all request necessary for origin validation at CDP.
 * @export
 * @interface StatusResp
 */
export interface StatusResp {
  /**
   *
   * @type {string}
   * @memberof StatusResp
   */
  rcode?: string;
  /**
   *
   * @type {string}
   * @memberof StatusResp
   */
  ecode?: string;
  /**
   *
   * @type {string}
   * @memberof StatusResp
   */
  etext?: string;
}
/**
 * Request body for update dng account status.
 * @export
 * @interface UpdateDngAccStatusReqBody
 */
export interface UpdateDngAccStatusReqBody {
  /**
   *
   * @type {string}
   * @memberof UpdateDngAccStatusReqBody
   */
  dnGAccountNumber: string;
  /**
   *
   * @type {string}
   * @memberof UpdateDngAccStatusReqBody
   */
  updateDnGAccountStatusTo: UpdateDngAccStatusReqBodyUpdateDnGAccountStatusToEnum;
  /**
   *
   * @type {string}
   * @memberof UpdateDngAccStatusReqBody
   */
  reasonForClosure?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum UpdateDngAccStatusReqBodyUpdateDnGAccountStatusToEnum {
  PendingActivation = "PENDING_ACTIVATION",
  Active = "ACTIVE",
  PendingClosure = "PENDING_CLOSURE",
  Closed = "CLOSED",
}

/**
 * request used to update dng account status
 * @export
 * @interface UpdateDngAccStatusRequest
 */
export interface UpdateDngAccStatusRequest {
  /**
   *
   * @type {HdrReq}
   * @memberof UpdateDngAccStatusRequest
   */
  hdr?: HdrReq;
  /**
   *
   * @type {UpdateDngAccStatusReqBody}
   * @memberof UpdateDngAccStatusRequest
   */
  body?: UpdateDngAccStatusReqBody;
}
/**
 * response used to send dng account details
 * @export
 * @interface UpdateDngAccStatusResponse
 */
export interface UpdateDngAccStatusResponse {
  /**
   *
   * @type {HdrResp}
   * @memberof UpdateDngAccStatusResponse
   */
  hdr?: HdrResp;
  /**
   *
   * @type {StatusResp}
   * @memberof UpdateDngAccStatusResponse
   */
  status?: StatusResp;
}
/**
 * Request body for update account details.
 * @export
 * @interface UpdateDngAccountReqBody
 */
export interface UpdateDngAccountReqBody {
  /**
   *
   * @type {string}
   * @memberof UpdateDngAccountReqBody
   */
  dnGAccountNumber: string;
  /**
   *
   * @type {string}
   * @memberof UpdateDngAccountReqBody
   */
  businessName?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateDngAccountReqBody
   */
  industry?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateDngAccountReqBody
   */
  trade?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateDngAccountReqBody
   */
  startDate?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateDngAccountReqBody
   */
  employees?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateDngAccountReqBody
   */
  addressLine1: string;
  /**
   *
   * @type {string}
   * @memberof UpdateDngAccountReqBody
   */
  addressLine2?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateDngAccountReqBody
   */
  addressLine3?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateDngAccountReqBody
   */
  town?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateDngAccountReqBody
   */
  county?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateDngAccountReqBody
   */
  postcode: string;
  /**
   *
   * @type {string}
   * @memberof UpdateDngAccountReqBody
   */
  firstName?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateDngAccountReqBody
   */
  lastName?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateDngAccountReqBody
   */
  contactName: string;
  /**
   *
   * @type {string}
   * @memberof UpdateDngAccountReqBody
   */
  phoneNumber: string;
  /**
   *
   * @type {string}
   * @memberof UpdateDngAccountReqBody
   */
  dateOfBirth?: string;
  /**
   *
   * @type {string}
   * @memberof UpdateDngAccountReqBody
   */
  emailAddress?: string;
  /**
   *
   * @type {CreateDngAccountReqBodyMarketingOptions}
   * @memberof UpdateDngAccountReqBody
   */
  marketing_Options?: CreateDngAccountReqBodyMarketingOptions;
}
/**
 * request used to update dng account details
 * @export
 * @interface UpdateDngAccountRequest
 */
export interface UpdateDngAccountRequest {
  /**
   *
   * @type {HdrReq}
   * @memberof UpdateDngAccountRequest
   */
  hdr?: HdrReq;
  /**
   *
   * @type {UpdateDngAccountReqBody}
   * @memberof UpdateDngAccountRequest
   */
  body?: UpdateDngAccountReqBody;
}
/**
 * response used to send dng account details
 * @export
 * @interface UpdateDngAccountResponse
 */
export interface UpdateDngAccountResponse {
  /**
   *
   * @type {HdrResp}
   * @memberof UpdateDngAccountResponse
   */
  hdr?: HdrResp;
  /**
   *
   * @type {StatusResp}
   * @memberof UpdateDngAccountResponse
   */
  status?: StatusResp;
}
/**
 * Request body for update dng card status.
 * @export
 * @interface UpdateDngCardStatusReqBody
 */
export interface UpdateDngCardStatusReqBody {
  /**
   *
   * @type {string}
   * @memberof UpdateDngCardStatusReqBody
   */
  dnGCardNumber: string;
  /**
   *
   * @type {string}
   * @memberof UpdateDngCardStatusReqBody
   */
  updateDnGCardStatusTo: UpdateDngCardStatusReqBodyUpdateDnGCardStatusToEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum UpdateDngCardStatusReqBodyUpdateDnGCardStatusToEnum {
  Active = "ACTIVE",
  Suspended = "SUSPENDED",
}

/**
 * request used to update dng card status
 * @export
 * @interface UpdateDngCardStatusRequest
 */
export interface UpdateDngCardStatusRequest {
  /**
   *
   * @type {HdrReq}
   * @memberof UpdateDngCardStatusRequest
   */
  hdr?: HdrReq;
  /**
   *
   * @type {UpdateDngCardStatusReqBody}
   * @memberof UpdateDngCardStatusRequest
   */
  body?: UpdateDngCardStatusReqBody;
}
/**
 * response used to send dng account details
 * @export
 * @interface UpdateDngCardStatusResponse
 */
export interface UpdateDngCardStatusResponse {
  /**
   *
   * @type {HdrResp}
   * @memberof UpdateDngCardStatusResponse
   */
  hdr?: HdrResp;
  /**
   *
   * @type {StatusResp}
   * @memberof UpdateDngCardStatusResponse
   */
  status?: StatusResp;
}

/**
 * CDPIntegrationAPIApi - axios parameter creator
 * @export
 */
export const CDPIntegrationAPIApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get dng account details.
     * @summary This endpoint is used to assign dng card to account the equivalent CDP api is AssignDnGCard
     * @param {AssignDngCardRequest} [assignDngCardRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    assignDnGCard: async (
      assignDngCardRequest?: AssignDngCardRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/card/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(assignDngCardRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get dng account details.
     * @summary This endpoint is used to create dng account the equivalent CDP api is CreateDnGAccount
     * @param {CreateDngAccountRequest} [createDngAccountRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDnGAccount: async (
      createDngAccountRequest?: CreateDngAccountRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/account/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createDngAccountRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get dng account details.
     * @summary This endpoint is used to create olm order the equivalent CDP endpoint is CreateOlmOrder
     * @param {CreateOLMOrderRequest} [createOLMOrderRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOlmOrder: async (
      createOLMOrderRequest?: CreateOLMOrderRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/order/olm`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(createOLMOrderRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get dng account details.
     * @summary This endpoint is used to get dng account details the equivalent cdp api is GetDnGAccountDetails
     * @param {GetDngAccDetailsRequest} [getDngAccDetailsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDnGAccountDetails: async (
      getDngAccDetailsRequest?: GetDngAccDetailsRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/account/details`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        getDngAccDetailsRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get dng account details.
     * @summary This endpoint is used to update dng account status the equivalent CDP endpoint is PostTransactionToDngAccount
     * @param {PostTxnToDngAccountRequest} [postTxnToDngAccountRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postTransactionToDngAccount: async (
      postTxnToDngAccountRequest?: PostTxnToDngAccountRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/order/transaction`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        postTxnToDngAccountRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get dng account details.
     * @summary This endpoint is used to update dng account the equivalent CDP api is UpdateDnGAccount
     * @param {UpdateDngAccountRequest} [updateDngAccountRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDnGAccount: async (
      updateDngAccountRequest?: UpdateDngAccountRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/account/`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateDngAccountRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get dng account details.
     * @summary This endpoint is used to update dng account status the equivalent CDP api is UpdateDngAccountStatus
     * @param {UpdateDngAccStatusRequest} [updateDngAccStatusRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDngAccountStatus: async (
      updateDngAccStatusRequest?: UpdateDngAccStatusRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/account/status`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateDngAccStatusRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get dng account details.
     * @summary This endpoint is used to update dng account status the equivalent CDP api is UpdateDngCardStatus
     * @param {UpdateDngCardStatusRequest} [updateDngCardStatusRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDngCardStatus: async (
      updateDngCardStatusRequest?: UpdateDngCardStatusRequest,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/card/status`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        updateDngCardStatusRequest,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CDPIntegrationAPIApi - functional programming interface
 * @export
 */
export const CDPIntegrationAPIApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CDPIntegrationAPIApiAxiosParamCreator(configuration);
  return {
    /**
     * Get dng account details.
     * @summary This endpoint is used to assign dng card to account the equivalent CDP api is AssignDnGCard
     * @param {AssignDngCardRequest} [assignDngCardRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async assignDnGCard(
      assignDngCardRequest?: AssignDngCardRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AssignDngCardResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.assignDnGCard(assignDngCardRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get dng account details.
     * @summary This endpoint is used to create dng account the equivalent CDP api is CreateDnGAccount
     * @param {CreateDngAccountRequest} [createDngAccountRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createDnGAccount(
      createDngAccountRequest?: CreateDngAccountRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateDngAccountResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createDnGAccount(createDngAccountRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get dng account details.
     * @summary This endpoint is used to create olm order the equivalent CDP endpoint is CreateOlmOrder
     * @param {CreateOLMOrderRequest} [createOLMOrderRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createOlmOrder(
      createOLMOrderRequest?: CreateOLMOrderRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateOLMOrderResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createOlmOrder(createOLMOrderRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get dng account details.
     * @summary This endpoint is used to get dng account details the equivalent cdp api is GetDnGAccountDetails
     * @param {GetDngAccDetailsRequest} [getDngAccDetailsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDnGAccountDetails(
      getDngAccDetailsRequest?: GetDngAccDetailsRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDngAccDetailsResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDnGAccountDetails(getDngAccDetailsRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get dng account details.
     * @summary This endpoint is used to update dng account status the equivalent CDP endpoint is PostTransactionToDngAccount
     * @param {PostTxnToDngAccountRequest} [postTxnToDngAccountRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postTransactionToDngAccount(
      postTxnToDngAccountRequest?: PostTxnToDngAccountRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostTxnToDngAccountResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postTransactionToDngAccount(
        postTxnToDngAccountRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get dng account details.
     * @summary This endpoint is used to update dng account the equivalent CDP api is UpdateDnGAccount
     * @param {UpdateDngAccountRequest} [updateDngAccountRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateDnGAccount(
      updateDngAccountRequest?: UpdateDngAccountRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateDngAccountResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateDnGAccount(updateDngAccountRequest, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get dng account details.
     * @summary This endpoint is used to update dng account status the equivalent CDP api is UpdateDngAccountStatus
     * @param {UpdateDngAccStatusRequest} [updateDngAccStatusRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateDngAccountStatus(
      updateDngAccStatusRequest?: UpdateDngAccStatusRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateDngAccStatusResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateDngAccountStatus(
        updateDngAccStatusRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get dng account details.
     * @summary This endpoint is used to update dng account status the equivalent CDP api is UpdateDngCardStatus
     * @param {UpdateDngCardStatusRequest} [updateDngCardStatusRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateDngCardStatus(
      updateDngCardStatusRequest?: UpdateDngCardStatusRequest,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UpdateDngCardStatusResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateDngCardStatus(
        updateDngCardStatusRequest,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * CDPIntegrationAPIApi - factory interface
 * @export
 */
export const CDPIntegrationAPIApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = CDPIntegrationAPIApiFp(configuration);
  return {
    /**
     * Get dng account details.
     * @summary This endpoint is used to assign dng card to account the equivalent CDP api is AssignDnGCard
     * @param {AssignDngCardRequest} [assignDngCardRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    assignDnGCard(assignDngCardRequest?: AssignDngCardRequest, options?: any): AxiosPromise<AssignDngCardResponse> {
      return localVarFp.assignDnGCard(assignDngCardRequest, options).then((request) => request(axios, basePath));
    },
    /**
     * Get dng account details.
     * @summary This endpoint is used to create dng account the equivalent CDP api is CreateDnGAccount
     * @param {CreateDngAccountRequest} [createDngAccountRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDnGAccount(
      createDngAccountRequest?: CreateDngAccountRequest,
      options?: any
    ): AxiosPromise<CreateDngAccountResponse> {
      return localVarFp.createDnGAccount(createDngAccountRequest, options).then((request) => request(axios, basePath));
    },
    /**
     * Get dng account details.
     * @summary This endpoint is used to create olm order the equivalent CDP endpoint is CreateOlmOrder
     * @param {CreateOLMOrderRequest} [createOLMOrderRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOlmOrder(createOLMOrderRequest?: CreateOLMOrderRequest, options?: any): AxiosPromise<CreateOLMOrderResponse> {
      return localVarFp.createOlmOrder(createOLMOrderRequest, options).then((request) => request(axios, basePath));
    },
    /**
     * Get dng account details.
     * @summary This endpoint is used to get dng account details the equivalent cdp api is GetDnGAccountDetails
     * @param {GetDngAccDetailsRequest} [getDngAccDetailsRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDnGAccountDetails(
      getDngAccDetailsRequest?: GetDngAccDetailsRequest,
      options?: any
    ): AxiosPromise<GetDngAccDetailsResponse> {
      return localVarFp
        .getDnGAccountDetails(getDngAccDetailsRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get dng account details.
     * @summary This endpoint is used to update dng account status the equivalent CDP endpoint is PostTransactionToDngAccount
     * @param {PostTxnToDngAccountRequest} [postTxnToDngAccountRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postTransactionToDngAccount(
      postTxnToDngAccountRequest?: PostTxnToDngAccountRequest,
      options?: any
    ): AxiosPromise<PostTxnToDngAccountResponse> {
      return localVarFp
        .postTransactionToDngAccount(postTxnToDngAccountRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get dng account details.
     * @summary This endpoint is used to update dng account the equivalent CDP api is UpdateDnGAccount
     * @param {UpdateDngAccountRequest} [updateDngAccountRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDnGAccount(
      updateDngAccountRequest?: UpdateDngAccountRequest,
      options?: any
    ): AxiosPromise<UpdateDngAccountResponse> {
      return localVarFp.updateDnGAccount(updateDngAccountRequest, options).then((request) => request(axios, basePath));
    },
    /**
     * Get dng account details.
     * @summary This endpoint is used to update dng account status the equivalent CDP api is UpdateDngAccountStatus
     * @param {UpdateDngAccStatusRequest} [updateDngAccStatusRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDngAccountStatus(
      updateDngAccStatusRequest?: UpdateDngAccStatusRequest,
      options?: any
    ): AxiosPromise<UpdateDngAccStatusResponse> {
      return localVarFp
        .updateDngAccountStatus(updateDngAccStatusRequest, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get dng account details.
     * @summary This endpoint is used to update dng account status the equivalent CDP api is UpdateDngCardStatus
     * @param {UpdateDngCardStatusRequest} [updateDngCardStatusRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateDngCardStatus(
      updateDngCardStatusRequest?: UpdateDngCardStatusRequest,
      options?: any
    ): AxiosPromise<UpdateDngCardStatusResponse> {
      return localVarFp
        .updateDngCardStatus(updateDngCardStatusRequest, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * CDPIntegrationAPIApi - object-oriented interface
 * @export
 * @class CDPIntegrationAPIApi
 * @extends {BaseAPI}
 */
export class CDPIntegrationAPIApi extends BaseAPI {
  /**
   * Get dng account details.
   * @summary This endpoint is used to assign dng card to account the equivalent CDP api is AssignDnGCard
   * @param {AssignDngCardRequest} [assignDngCardRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CDPIntegrationAPIApi
   */
  public assignDnGCard(assignDngCardRequest?: AssignDngCardRequest, options?: AxiosRequestConfig) {
    return CDPIntegrationAPIApiFp(this.configuration)
      .assignDnGCard(assignDngCardRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get dng account details.
   * @summary This endpoint is used to create dng account the equivalent CDP api is CreateDnGAccount
   * @param {CreateDngAccountRequest} [createDngAccountRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CDPIntegrationAPIApi
   */
  public createDnGAccount(createDngAccountRequest?: CreateDngAccountRequest, options?: AxiosRequestConfig) {
    return CDPIntegrationAPIApiFp(this.configuration)
      .createDnGAccount(createDngAccountRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get dng account details.
   * @summary This endpoint is used to create olm order the equivalent CDP endpoint is CreateOlmOrder
   * @param {CreateOLMOrderRequest} [createOLMOrderRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CDPIntegrationAPIApi
   */
  public createOlmOrder(createOLMOrderRequest?: CreateOLMOrderRequest, options?: AxiosRequestConfig) {
    return CDPIntegrationAPIApiFp(this.configuration)
      .createOlmOrder(createOLMOrderRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get dng account details.
   * @summary This endpoint is used to get dng account details the equivalent cdp api is GetDnGAccountDetails
   * @param {GetDngAccDetailsRequest} [getDngAccDetailsRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CDPIntegrationAPIApi
   */
  public getDnGAccountDetails(getDngAccDetailsRequest?: GetDngAccDetailsRequest, options?: AxiosRequestConfig) {
    return CDPIntegrationAPIApiFp(this.configuration)
      .getDnGAccountDetails(getDngAccDetailsRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get dng account details.
   * @summary This endpoint is used to update dng account status the equivalent CDP endpoint is PostTransactionToDngAccount
   * @param {PostTxnToDngAccountRequest} [postTxnToDngAccountRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CDPIntegrationAPIApi
   */
  public postTransactionToDngAccount(
    postTxnToDngAccountRequest?: PostTxnToDngAccountRequest,
    options?: AxiosRequestConfig
  ) {
    return CDPIntegrationAPIApiFp(this.configuration)
      .postTransactionToDngAccount(postTxnToDngAccountRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get dng account details.
   * @summary This endpoint is used to update dng account the equivalent CDP api is UpdateDnGAccount
   * @param {UpdateDngAccountRequest} [updateDngAccountRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CDPIntegrationAPIApi
   */
  public updateDnGAccount(updateDngAccountRequest?: UpdateDngAccountRequest, options?: AxiosRequestConfig) {
    return CDPIntegrationAPIApiFp(this.configuration)
      .updateDnGAccount(updateDngAccountRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get dng account details.
   * @summary This endpoint is used to update dng account status the equivalent CDP api is UpdateDngAccountStatus
   * @param {UpdateDngAccStatusRequest} [updateDngAccStatusRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CDPIntegrationAPIApi
   */
  public updateDngAccountStatus(updateDngAccStatusRequest?: UpdateDngAccStatusRequest, options?: AxiosRequestConfig) {
    return CDPIntegrationAPIApiFp(this.configuration)
      .updateDngAccountStatus(updateDngAccStatusRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get dng account details.
   * @summary This endpoint is used to update dng account status the equivalent CDP api is UpdateDngCardStatus
   * @param {UpdateDngCardStatusRequest} [updateDngCardStatusRequest]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CDPIntegrationAPIApi
   */
  public updateDngCardStatus(updateDngCardStatusRequest?: UpdateDngCardStatusRequest, options?: AxiosRequestConfig) {
    return CDPIntegrationAPIApiFp(this.configuration)
      .updateDngCardStatus(updateDngCardStatusRequest, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
