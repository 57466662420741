import { axiosClient } from "../lib/axiosWrapper";
import { FeatureToggles, GetFeatureToggleParams, Props } from "./structures";
import { CacheTypes } from "../lib/cache";

const cacheKey = "_travelMoneyData/FeatureToggles";
const featureToggleCacheTTL = 60 * 12; // caching for 12 hours to respect network restrictions

// deprecated as it needs a change each time we add a new feature to toggle
// fetch all feature toggles available
export const getFeatureToggles =
  ({ rootUrl, authHeaders }: Props) =>
  async (): Promise<FeatureToggles> => {
    const response = await axiosClient
      .get<FeatureToggles>(
        { url: `${rootUrl}/travel-money/feature-toggle`, headers: await authHeaders() },
        { type: CacheTypes.TIMED, ttl: featureToggleCacheTTL, key: `${cacheKey}/travel-money` }
      )
      .catch((err) => {
        return { onDemandSell: false, onDemandBuy: false, onTravelMoneyCard: false };
      });

    return response;
  };

// fetch a single feature toggle state, with a defined fallback in case it doesn't exist or an error is thrown
export const getFeatureToggle =
  ({ rootUrl, authHeaders }: Props) =>
  async ({ toggleKey, defaultToggleState }: GetFeatureToggleParams): Promise<boolean> => {
    const defaultState = defaultToggleState ? defaultToggleState : false;

    const response = await axiosClient
      .get<FeatureToggles>(
        { url: `${rootUrl}/travel-money/feature-toggle`, headers: await authHeaders() },
        { type: CacheTypes.TIMED, ttl: featureToggleCacheTTL, key: `${cacheKey}/travel-money` }
      )
      .catch(() => {
        return defaultState;
      });

    if (response[toggleKey] === undefined) {
      return defaultState;
    }

    return response[toggleKey];
  };
