import axios, { AxiosError } from "axios";
import {
  AssignDngCardRequest,
  AssignDngCardResponse,
  CreateDngAccountRequest,
  CreateDngAccountResponse,
  CreateOLMOrderRequest,
  CreateOLMOrderResponse,
  GetDngAccDetailsRequest,
  GetDngAccDetailsResponse,
  PostTxnToDngAccountRequest,
  PostTxnToDngAccountResponse,
  UpdateDngAccountRequest,
  UpdateDngAccountResponse,
  UpdateDngAccStatusRequest,
  UpdateDngAccStatusResponse,
  UpdateDngCardStatusRequest,
  UpdateDngCardStatusResponse,
} from "../openapi/drop-and-go-api";
import { ApiError } from "../types";
import { Client, Props } from "./types";

export const buildClient = (props: Props): Client => {
  const { rootUrl, authHeaders } = props;

  const createAccount = async (params: CreateDngAccountRequest): Promise<CreateDngAccountResponse | ApiError> => {
    try {
      const response = await axios.post(`${rootUrl}/dropgo/account/`, params, {
        headers: await authHeaders(),
      });
      return response.data as CreateDngAccountResponse;
    } catch (error: unknown) {
      return processError(error as AxiosError) as CreateDngAccountRequest | ApiError;
    }
  };

  const editAccount = async (params: UpdateDngAccountRequest): Promise<UpdateDngAccountResponse | ApiError> => {
    try {
      const response = await axios.put(`${rootUrl}/dropgo/account/`, params, {
        headers: await authHeaders(),
      });
      return response.data as UpdateDngAccountResponse;
    } catch (error: unknown) {
      return processError(error as AxiosError) as UpdateDngAccountRequest | ApiError;
    }
  };

  const updateCardStatus = async (
    params: UpdateDngCardStatusRequest
  ): Promise<UpdateDngCardStatusResponse | ApiError> => {
    try {
      const response = await axios.put(`${rootUrl}/dropgo/card/status`, params, {
        headers: await authHeaders(),
      });
      return response.data as UpdateDngCardStatusResponse;
    } catch (error: unknown) {
      return processError(error as AxiosError) as UpdateDngCardStatusResponse | ApiError;
    }
  };

  /**
   * Update account status
   * @param params UpdateDngAccountRequest
   * @returns Promise<UpdateDngAccountResponse|ApiError>
   */
  const updateAccountStatus = async (
    params: UpdateDngAccStatusRequest
  ): Promise<UpdateDngAccStatusResponse | ApiError> => {
    try {
      const response = await axios.put(`${rootUrl}/dropgo/account/status`, params, {
        headers: await authHeaders(),
      });
      return response.data as UpdateDngAccStatusResponse;
    } catch (error: unknown) {
      return processError(error as AxiosError) as UpdateDngAccStatusResponse | ApiError;
    }
  };

  /**
   * Assign Card
   * @param params AssignDngCardRequest
   * @returns Promise<AssignDngCardResponse|ApiError>
   */
  const assignCard = async (params: AssignDngCardRequest): Promise<AssignDngCardResponse | ApiError> => {
    try {
      const response = await axios.put(`${rootUrl}/dropgo/card/`, params, {
        headers: await authHeaders(),
      });
      return response.data as AssignDngCardResponse;
    } catch (error: unknown) {
      return processError(error as AxiosError) as AssignDngCardResponse | ApiError;
    }
  };

  /**
   * Get account details
   * @param params GetDngAccDetailsRequest
   * @returns Promise<GetDngAccDetailsResponse|ApiError>
   */
  const getAccountDetails = async (params: GetDngAccDetailsRequest): Promise<GetDngAccDetailsResponse | ApiError> => {
    try {
      const response = await axios.post(`${rootUrl}/dropgo/account/details`, params, {
        headers: await authHeaders(),
      });
      return response.data as GetDngAccDetailsResponse;
    } catch (error: unknown) {
      return processError(error as AxiosError) as GetDngAccDetailsResponse | ApiError;
    }
  };

  /**
   * Create transaction entry
   * @param params PostTxnToDngAccountRequest
   * @returns Promise<PostTxnToDngAccountResponse|ApiError>
   */
  const createTransactionEntry = async (
    params: PostTxnToDngAccountRequest
  ): Promise<PostTxnToDngAccountResponse | ApiError> => {
    try {
      const response = await axios.post(`${rootUrl}/dropgo/order/transaction`, params, {
        headers: await authHeaders(),
      });
      return response.data as PostTxnToDngAccountResponse;
    } catch (error: unknown) {
      return processError(error as AxiosError) as PostTxnToDngAccountResponse | ApiError;
    }
  };

  const createOrder = async (params: CreateOLMOrderRequest): Promise<CreateOLMOrderResponse | ApiError> => {
    try {
      const response = await axios.post(`${rootUrl}/dropgo/order/olm`, params, {
        headers: await authHeaders(),
      });
      return response.data as CreateOLMOrderResponse;
    } catch (error: unknown) {
      return processError(error as AxiosError) as CreateOLMOrderResponse | ApiError;
    }
  };

  /**
   * Resolve error based on status code
   * @param error AxiosError
   * @returns Promise<unknown>
   */
  const processError = (error: AxiosError): Promise<unknown> => {
    if (error.response?.status === 424) {
      return Promise.resolve(error.response?.data);
    }

    return Promise.reject({
      statusCode: error.response?.status || 500,
      ...(error.response?.data && error.response?.data),
    });
  };

  return Object.freeze({
    createAccount,
    editAccount,
    getAccountDetails,
    updateCardStatus,
    updateAccountStatus,
    assignCard,
    createTransactionEntry,
    createOrder,
  });
};
