import { InternalToExternalMappingT } from "../types";

export enum AcquirerResponseCodes {
  // Authorised OK
  Approved = "00",
  NoReasonToDecline = "85",
  // Declined – Impound Card
  PickUp = "04",
  PickUpSpecialConditions = "07",
  ExpiredCardPickup = "33",
  SuspectedFraudPickup = "34",
  CardAcceptorContactAcquirerPickup = "35",
  RestrictedCardPickUp = "36",
  CardAcceptorCallAcquirersSecurityDepartmentPickup = "37",
  AllowablePinTriesExceededPickup = "38",
  LostCardPickUp = "41",
  StolenCardPickUp = "43",
  HardCapturePickUp = "67",
  CVCFailedCaptureCard = "N2",
  // Declined – Invalid PIN
  IncorrectPin = "55",
  // Declined – Insufficient Funds
  InsufficientFunds = "51",
  // Declined – Usage Violation (frequency)
  ExceedsWithdrawalFrequencyLimit = "65",
  // Declined – Usage Violation (amount)
  ExceedsWithdrawalAmountLimit = "61",
  // Declined – Transaction not supported
  InvalidTransaction = "12",
  // Declined – Transaction not supported
  TransactionNotPermittedToCardholder = "57",
  // Declined – Other
  ReferToCardIssuer = "01",
  ReferToCardIssuerSpecialConditions = "02",
  DoNotHonour = "05",
  InvalidAmount = "13",
  InvalidCardNumber = "14",
  NoCreditAccount = "39",
  NoUniversalAccount = "42",
  NoInvestmentAccount = "44",
  NoCheckingAccount = "52",
  NoSavingsAccount = "53",
  ExpiredCard = "54",
  SuspectedFraudDecline = "59",
  CardAcceptorContactAcquirerDecline = "60",
  RestrictedCard = "62",
  MasterCardSecurityViolation = "63",
  CardAcceptorContactAcquirerSecurity = "66",
  CVCFailedDoNotHonour = "N1",
  CVV2FailedDoNotHonour = "N7",
  CardAuthenticationFailure = "Q1",
  // Max PIN tries exceeded
  AllowableNumberOfPinTriesExceeded = "75",
  // Retry Txn, FI Unexpected Response Code
  InvalidMerchant = "03",
  Error = "06",
  HonourWithIdentification = "08",
  RequestInProgress = "09",
  ApprovedForPartialAmount = "10",
  ApprovedUpdateTrack3 = "16",
  CustomerCancellation = "17",
  CustomerDispute = "18",
  ReEnterTransaction = "19",
  InvalidTerminalId = "21",
  SuspectedMalfunction = "22",
  UnacceptableFee = "23",
  FileUpdateNotSupportedByReceiver = "24",
  UnableToLocateRecordOnFile = "25",
  DuplicateFileUpdateRecordOldRecordReplaced = "26",
  FileUpdateFieldEditError = "27",
  FileUpdateRecordLockedOut = "28",
  FileUpdateNotSuccesfulContactAcquirer = "29",
  CompletedPartially = "32",
  RequestedFunctionNotSupported = "40",
  OriginalAmountIncorrect = "64",
  ResponseReceivedTooLate = "68",
  LawViolation = "93",
  DuplicateTransmission = "94",
  ReconcileError = "95",
  PrePayTransactionFailed = "A0",
  PrePayTransactionRejected = "A1",
  InvalidMobilePhoneNumber = "A2",
  PrePayAccountLimitReached = "A3",
  PrePaySystemUnavailable = "A4",
  MNONotSupportedByIssuer = "A5",
  ApprovedVIP = "11",
  UnacceptablePin = "C0",
  PinChangeFailed = "C1",
  PinUnlockFailed = "C2",
  NoDataSuppliedByIssuer = "C5",
  FallbackNotAllowed = "Q2",
  // Retry Txn, System/Data Error
  InternalIngenicoError = "20",
  FormatError = "30",
  InvalidDate = "80",
  FINotAvailableForRouting = "92",
  SystemMalfunction = "96",
  // Try later, FI Unavailable - return card
  IssuerOrSwitchInoperative = "91",
  // Retry Txn, System Temp'y Unavailable
  NoSuchIssuer = "15",
  KeySynchronisationError = "76",
  CutoverInProgress = "90",
  OfflineDeclined = "Z1",
  UnableToGoOnline = "Z3",
}

export const AcquirerToInternalResponseCodes: InternalToExternalMappingT = {
  [AcquirerResponseCodes.Approved]: {
    resultCode: "01",
    responseCode: "01",
  },
  [AcquirerResponseCodes.NoReasonToDecline]: {
    resultCode: "01",
    responseCode: "01",
  },
  [AcquirerResponseCodes.PickUp]: {
    resultCode: "06",
    responseCode: "02",
    cardImpounded: true,
  },
  [AcquirerResponseCodes.PickUpSpecialConditions]: {
    resultCode: "06",
    responseCode: "02",
    cardImpounded: true,
  },
  [AcquirerResponseCodes.ExpiredCardPickup]: {
    resultCode: "06",
    responseCode: "02",
    cardImpounded: true,
  },
  [AcquirerResponseCodes.SuspectedFraudPickup]: {
    resultCode: "06",
    responseCode: "02",
    cardImpounded: true,
  },
  [AcquirerResponseCodes.CardAcceptorContactAcquirerPickup]: {
    resultCode: "06",
    responseCode: "02",
    cardImpounded: true,
  },
  [AcquirerResponseCodes.RestrictedCardPickUp]: {
    resultCode: "06",
    responseCode: "02",
    cardImpounded: true,
  },
  [AcquirerResponseCodes.CardAcceptorCallAcquirersSecurityDepartmentPickup]: {
    resultCode: "06",
    responseCode: "02",
    cardImpounded: true,
  },
  [AcquirerResponseCodes.AllowablePinTriesExceededPickup]: {
    resultCode: "06",
    responseCode: "02",
    cardImpounded: true,
  },
  [AcquirerResponseCodes.LostCardPickUp]: {
    resultCode: "06",
    responseCode: "02",
    cardImpounded: true,
  },
  [AcquirerResponseCodes.StolenCardPickUp]: {
    resultCode: "06",
    responseCode: "02",
    cardImpounded: true,
  },
  [AcquirerResponseCodes.HardCapturePickUp]: {
    resultCode: "06",
    responseCode: "02",
    cardImpounded: true,
  },
  [AcquirerResponseCodes.CVCFailedCaptureCard]: {
    resultCode: "06",
    responseCode: "02",
    cardImpounded: true,
  },
  [AcquirerResponseCodes.IncorrectPin]: {
    resultCode: "06",
    responseCode: "03",
  },
  [AcquirerResponseCodes.InsufficientFunds]: {
    resultCode: "06",
    responseCode: "04",
  },
  [AcquirerResponseCodes.ExceedsWithdrawalFrequencyLimit]: {
    resultCode: "06",
    responseCode: "05",
  },
  [AcquirerResponseCodes.ExceedsWithdrawalAmountLimit]: {
    resultCode: "06",
    responseCode: "06",
  },
  [AcquirerResponseCodes.InvalidTransaction]: {
    resultCode: "06",
    responseCode: "07",
  },
  [AcquirerResponseCodes.TransactionNotPermittedToCardholder]: {
    resultCode: "06",
    responseCode: "07",
  },
  [AcquirerResponseCodes.UnacceptableFee]: {
    resultCode: "07",
    responseCode: "23",
  },
  [AcquirerResponseCodes.ReferToCardIssuer]: {
    resultCode: "06",
    responseCode: "08",
  },
  [AcquirerResponseCodes.ReferToCardIssuerSpecialConditions]: {
    resultCode: "06",
    responseCode: "08",
  },
  [AcquirerResponseCodes.DoNotHonour]: {
    resultCode: "06",
    responseCode: "08",
  },
  [AcquirerResponseCodes.InvalidAmount]: {
    resultCode: "06",
    responseCode: "08",
  },
  [AcquirerResponseCodes.InvalidCardNumber]: {
    resultCode: "06",
    responseCode: "08",
  },
  [AcquirerResponseCodes.NoCreditAccount]: {
    resultCode: "06",
    responseCode: "08",
  },
  [AcquirerResponseCodes.NoCheckingAccount]: {
    resultCode: "06",
    responseCode: "08",
  },
  [AcquirerResponseCodes.NoUniversalAccount]: {
    resultCode: "06",
    responseCode: "08",
  },
  [AcquirerResponseCodes.NoInvestmentAccount]: {
    resultCode: "06",
    responseCode: "08",
  },
  [AcquirerResponseCodes.NoSavingsAccount]: {
    resultCode: "06",
    responseCode: "08",
  },
  [AcquirerResponseCodes.ExpiredCard]: {
    resultCode: "06",
    responseCode: "08",
  },
  [AcquirerResponseCodes.SuspectedFraudDecline]: {
    resultCode: "06",
    responseCode: "08",
  },
  [AcquirerResponseCodes.CardAcceptorContactAcquirerDecline]: {
    resultCode: "06",
    responseCode: "08",
  },
  [AcquirerResponseCodes.PrePaySystemUnavailable]: {
    resultCode: "07",
    responseCode: "23",
  },
  [AcquirerResponseCodes.RestrictedCard]: {
    resultCode: "06",
    responseCode: "08",
  },
  [AcquirerResponseCodes.MasterCardSecurityViolation]: {
    resultCode: "06",
    responseCode: "08",
  },
  [AcquirerResponseCodes.CardAcceptorContactAcquirerSecurity]: {
    resultCode: "06",
    responseCode: "08",
  },
  [AcquirerResponseCodes.CVCFailedDoNotHonour]: {
    resultCode: "06",
    responseCode: "08",
  },
  [AcquirerResponseCodes.CVV2FailedDoNotHonour]: {
    resultCode: "06",
    responseCode: "08",
  },
  [AcquirerResponseCodes.CardAuthenticationFailure]: {
    resultCode: "06",
    responseCode: "08",
  },
  [AcquirerResponseCodes.AllowableNumberOfPinTriesExceeded]: {
    resultCode: "06",
    responseCode: "09",
  },
  [AcquirerResponseCodes.InvalidMerchant]: {
    resultCode: "07",
    responseCode: "23",
  },
  [AcquirerResponseCodes.Error]: {
    resultCode: "07",
    responseCode: "23",
  },
  [AcquirerResponseCodes.HonourWithIdentification]: {
    resultCode: "07",
    responseCode: "23",
  },
  [AcquirerResponseCodes.RequestInProgress]: {
    resultCode: "07",
    responseCode: "23",
  },
  [AcquirerResponseCodes.ApprovedForPartialAmount]: {
    resultCode: "07",
    responseCode: "23",
  },
  [AcquirerResponseCodes.ApprovedVIP]: {
    resultCode: "07",
    responseCode: "23",
  },
  [AcquirerResponseCodes.ApprovedUpdateTrack3]: {
    resultCode: "07",
    responseCode: "23",
  },
  [AcquirerResponseCodes.CustomerCancellation]: {
    resultCode: "07",
    responseCode: "23",
  },
  [AcquirerResponseCodes.CustomerDispute]: {
    resultCode: "07",
    responseCode: "23",
  },
  [AcquirerResponseCodes.ReEnterTransaction]: {
    resultCode: "07",
    responseCode: "23",
  },
  [AcquirerResponseCodes.InvalidTerminalId]: {
    resultCode: "07",
    responseCode: "23",
  },
  [AcquirerResponseCodes.SuspectedMalfunction]: {
    resultCode: "07",
    responseCode: "23",
  },
  [AcquirerResponseCodes.FileUpdateNotSupportedByReceiver]: {
    resultCode: "07",
    responseCode: "23",
  },
  [AcquirerResponseCodes.UnableToLocateRecordOnFile]: {
    resultCode: "07",
    responseCode: "23",
  },
  [AcquirerResponseCodes.DuplicateFileUpdateRecordOldRecordReplaced]: {
    resultCode: "07",
    responseCode: "23",
  },
  [AcquirerResponseCodes.FileUpdateFieldEditError]: {
    resultCode: "07",
    responseCode: "23",
  },
  [AcquirerResponseCodes.FileUpdateRecordLockedOut]: {
    resultCode: "07",
    responseCode: "23",
  },
  [AcquirerResponseCodes.FileUpdateNotSuccesfulContactAcquirer]: {
    resultCode: "07",
    responseCode: "23",
  },
  [AcquirerResponseCodes.CompletedPartially]: {
    resultCode: "07",
    responseCode: "23",
  },
  [AcquirerResponseCodes.RequestedFunctionNotSupported]: {
    resultCode: "07",
    responseCode: "23",
  },
  [AcquirerResponseCodes.OriginalAmountIncorrect]: {
    resultCode: "07",
    responseCode: "23",
  },
  [AcquirerResponseCodes.ResponseReceivedTooLate]: {
    resultCode: "07",
    responseCode: "23",
  },
  [AcquirerResponseCodes.LawViolation]: {
    resultCode: "07",
    responseCode: "23",
  },
  [AcquirerResponseCodes.DuplicateTransmission]: {
    resultCode: "07",
    responseCode: "23",
  },
  [AcquirerResponseCodes.ReconcileError]: {
    resultCode: "07",
    responseCode: "23",
  },
  [AcquirerResponseCodes.PrePayTransactionFailed]: {
    resultCode: "07",
    responseCode: "23",
  },
  [AcquirerResponseCodes.PrePayTransactionRejected]: {
    resultCode: "07",
    responseCode: "23",
  },
  [AcquirerResponseCodes.InvalidMobilePhoneNumber]: {
    resultCode: "07",
    responseCode: "23",
  },
  [AcquirerResponseCodes.PrePayAccountLimitReached]: {
    resultCode: "07",
    responseCode: "23",
  },
  [AcquirerResponseCodes.MNONotSupportedByIssuer]: {
    resultCode: "07",
    responseCode: "23",
  },
  [AcquirerResponseCodes.UnacceptablePin]: {
    resultCode: "07",
    responseCode: "23",
  },
  [AcquirerResponseCodes.PinChangeFailed]: {
    resultCode: "07",
    responseCode: "23",
  },
  [AcquirerResponseCodes.PinUnlockFailed]: {
    resultCode: "07",
    responseCode: "23",
  },
  [AcquirerResponseCodes.NoDataSuppliedByIssuer]: {
    resultCode: "07",
    responseCode: "23",
  },
  [AcquirerResponseCodes.FallbackNotAllowed]: {
    resultCode: "07",
    responseCode: "23",
  },
  [AcquirerResponseCodes.InternalIngenicoError]: {
    resultCode: "07",
    responseCode: "24",
  },
  [AcquirerResponseCodes.FormatError]: {
    resultCode: "07",
    responseCode: "24",
  },
  [AcquirerResponseCodes.InvalidDate]: {
    resultCode: "07",
    responseCode: "24",
  },
  [AcquirerResponseCodes.FINotAvailableForRouting]: {
    resultCode: "07",
    responseCode: "24",
  },
  [AcquirerResponseCodes.SystemMalfunction]: {
    resultCode: "07",
    responseCode: "24",
  },
  [AcquirerResponseCodes.IssuerOrSwitchInoperative]: {
    resultCode: "07",
    responseCode: "26",
  },
  [AcquirerResponseCodes.NoSuchIssuer]: {
    resultCode: "07",
    responseCode: "27",
  },
  [AcquirerResponseCodes.KeySynchronisationError]: {
    resultCode: "07",
    responseCode: "27",
  },
  [AcquirerResponseCodes.CutoverInProgress]: {
    resultCode: "07",
    responseCode: "27",
  },
  [AcquirerResponseCodes.OfflineDeclined]: {
    resultCode: "07",
    responseCode: "27",
  },
  [AcquirerResponseCodes.UnableToGoOnline]: {
    resultCode: "07",
    responseCode: "27",
  },
};
