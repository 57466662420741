import { APIResponse, MoneyGramResponseHeaders } from "./common";
import { AxiosError } from "axios";

export interface EnablerSuccessResponse<T> {
  type: "SUCCESS";
  data: T;
  metadata: MoneyGramResponseHeaders;
}

export interface EnablerErrorResponse {
  type: "ERROR";
  message: string;
}

export type EnablerResponse<T> = EnablerSuccessResponse<T> | EnablerErrorResponse;

export const handleError = (error: AxiosError<APIResponse<never>>): EnablerErrorResponse => {
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    const responseStatusInfo = error.response.data.status;
    const rcode = responseStatusInfo?.rcode;
    const ecode = responseStatusInfo?.ecode;
    const errorMsg = responseStatusInfo?.etext;

    const message = buildErrorMessage(rcode, ecode, errorMsg);
    return { type: "ERROR", message };
  }

  // Any other types of error
  return { type: "ERROR", message: `${error}` };
};

export const buildErrorMessage = (rcode?: string, ecode?: string, errorMsg?: string): string => {
  return `Transaction failed due to the following reason:\nResponse Code: ${rcode}\nError code: ${ecode}\nError message: ${errorMsg}`;
};
