import { AxiosPromise } from "axios";
import { IngenicoPedClient, LabelPrinterClient } from "postoffice-peripheral-management-service";
import { DropAndGoFulfiller } from "./fulfillers/dropandgo";
import { ImovoFulfiller } from "./fulfillers/imovo";
import { LabelFulfiller } from "./fulfillers/label";
import { PrintStatusEnum } from "./fulfillers/label/types";
import { PEDFulfiller } from "./fulfillers/ped";
import { FulfillmentClient } from "./fulfillers/remote/fulfillment-api";
import RemoteFulfiller from "./fulfillers/remote/remote";
import { SmartMeterFulfiller } from "./fulfillers/smartmeter";
import { getToken, isNetworkError, populateRequestUdidToken, typecastBasketItemPayloadFields } from "./helpers";
import { FulfilmentStateEnum, TransactionsApiInterface } from "./openapi/transaction-api-v3";
import {
  ApiClientsConfig,
  BasketItemPayload,
  Callbacks,
  Fulfiller,
  FulfillerResponse,
  FulfillmentItem,
  FulfillmentProcessor,
  FulfilmentTypes,
  ProcessResponse,
  ProcessResponseEnum,
  TokenGetter,
  UserResponseEnum,
} from "./types";
import { FmccFulfiller } from "./fulfillers/fmcc";

export const CommitAndFulfillProcessor = (
  transactionsV3Client: TransactionsApiInterface,
  callbacks: Callbacks,
  apiConfig: ApiClientsConfig,
  userAuthTokenGetter: TokenGetter,
  deviceAuthTokenGetter: TokenGetter,
  fulfillmentClient?: FulfillmentClient,
  deviceCallbacks?: {
    ped: IngenicoPedClient;
    label: LabelPrinterClient;
  }
): FulfillmentProcessor => {
  const itemsToFulfill: FulfillmentItem[] = [];

  /**
   * Set basket entry to set fulfilment status
   * @param basketId string
   * @param entryId  string
   * @param status FulfilmentStateEnum
   * @returns Promise<AxiosPromise>
   */
  const setFulfilmentStatus = async (
    basketId: string,
    entryId: string,
    status: FulfilmentStateEnum,
    tokens?: Record<string, string>
  ): Promise<AxiosPromise> => {
    return transactionsV3Client.updateBasketEntryFulfilment(basketId, entryId, await getToken(userAuthTokenGetter), {
      fulfilmentState: status,
      ...(tokens && {
        fulfilmentTokens: tokens,
      }),
    });
  };

  const process = async (basketId: string, items: BasketItemPayload[]): Promise<ProcessResponse> => {
    let finalResult: ProcessResponse = {
      commitStatusAllItems: ProcessResponseEnum.NotDone,
    };

    let commitStatus: string = ProcessResponseEnum.NotDone;
    let fulfilmentStatus: string = ProcessResponseEnum.NotDone;

    if (items.length === 0) {
      return finalResult;
    }

    let modifiedItemPayload: BasketItemPayload | undefined;

    // The use of array iterators over for-of needs to be investigated, to see if it works
    // properly with await
    /* eslint-disable no-restricted-syntax */
    for (const item of items) {
      try {
        modifiedItemPayload = typecastBasketItemPayloadFields(item);

        const modifiedItemTokens = populateRequestUdidToken(modifiedItemPayload.tokens);
        modifiedItemPayload.tokens = modifiedItemTokens;

        // fulfilment object doesn't need to be sent to api but needed for fulfilment
        const commitPayload = modifiedItemPayload as Omit<BasketItemPayload, "fulfilment">;

        /* eslint-disable no-await-in-loop */
        const result = await transactionsV3Client.createBasketEntry(
          basketId,
          await getToken(userAuthTokenGetter),
          commitPayload
        );

        if (commitStatus !== ProcessResponseEnum.Failure) {
          commitStatus = ProcessResponseEnum.Success;
        }

        // Adding await on the commit callbacks as part of the mails labels fulfilment work
        // to ensure the broadcast channel is opened in CT before the label printing results
        // are broadcast to CT
        await callbacks.onCommitSuccess(modifiedItemPayload, result.data);

        if (
          result.data.fulfilmentRequired ||
          (modifiedItemPayload.userResponse &&
            (modifiedItemPayload.userResponse === UserResponseEnum.Retry ||
              modifiedItemPayload.userResponse === UserResponseEnum.Cancel))
        ) {
          itemsToFulfill.push(<FulfillmentItem>{
            basketItem: modifiedItemPayload,
            entry: result.data,
          });
        }
      } catch (error) {
        // TODO - replace with logging library once implemented
        console.log(`Error when committing item: ${error as string}`);

        commitStatus = ProcessResponseEnum.Failure;

        if (modifiedItemPayload) {
          await callbacks.onCommitError(modifiedItemPayload, error as Error);
        } else {
          await callbacks.onCommitError(item, error as Error);
        }
      }
    }

    finalResult = {
      commitStatusAllItems: commitStatus,
    };

    /* eslint-disable no-restricted-syntax */
    for (const item of itemsToFulfill) {
      try {
        const fulfiller = getFulfiller(
          basketId,
          item,
          transactionsV3Client,
          await getToken(userAuthTokenGetter),
          await getToken(deviceAuthTokenGetter),
          fulfillmentClient
        );
        /* eslint-disable no-await-in-loop */
        const response = await fulfiller.fulfill(item);

        if (fulfilmentStatus !== ProcessResponseEnum.Failure) {
          fulfilmentStatus = ProcessResponseEnum.Success;
        }

        if (response && callbacks.onFulfillmentSuccess) {
          if (response.result && response.result.result && response.result.result === PrintStatusEnum.EndFlow) {
            // Do not call a fulfilment callback in this case since a new reject label item will be added to the basket
            // the case of mails labels fulfilment functionality
            fulfilmentStatus = ProcessResponseEnum.InProgress;
            console.log("End the C&F Flow - user has clicked Retry or Cancel");
          } else {
            callbacks.onFulfillmentSuccess(item.basketItem, response);
          }
        }
      } catch (errorResponse) {
        // TODO - replace with logging library once implemented
        console.log(`Error when fulfilling item with entry id: ${item.basketItem.entryID}`);
        console.log("Error: ", errorResponse);

        fulfilmentStatus = ProcessResponseEnum.Failure;

        if (callbacks.onFulfillmentError) {
          let networkError = false;
          if (errorResponse instanceof Error) {
            if (isNetworkError(errorResponse)) {
              networkError = true;
            }
          } else {
            const fulfillerResponse = errorResponse as FulfillerResponse;
            if (fulfillerResponse.networkError) {
              networkError = fulfillerResponse.networkError;
            }
          }
          callbacks.onFulfillmentError(item.basketItem, errorResponse as FulfillerResponse | Error, networkError);
        }
      }
    }

    finalResult = {
      ...finalResult,
      fulfilmentStatusAllItems: fulfilmentStatus,
    };

    return Promise.resolve(finalResult);
  };

  const getFulfiller = (
    basketId: string,
    item: FulfillmentItem,
    transactionClient: TransactionsApiInterface,
    userAuthToken: string,
    deviceAuthToken: string,
    fulfillmentApiClient?: FulfillmentClient,
  ): Fulfiller => {
    if (!item.entry.fulfilmentType) {
      throw new Error("Fulfillment type has not been set");
    }

    const fulfilmentType = item.entry.fulfilmentType as unknown as FulfilmentTypes;

    switch (fulfilmentType) {
      case FulfilmentTypes.Ped:
        return PEDFulfiller(transactionClient, basketId, userAuthToken, deviceCallbacks?.ped);
      case FulfilmentTypes.Label:
        return LabelFulfiller(transactionClient, basketId, item.basketItem, userAuthToken, deviceCallbacks?.label);
      case FulfilmentTypes.DropAndGo:
        return DropAndGoFulfiller(
          transactionClient,
          apiConfig.dropandgo,
          basketId,
          item.basketItem,
          userAuthToken,
          deviceAuthToken
        );
      case FulfilmentTypes.Imovo:
        return ImovoFulfiller(
          transactionClient,
          apiConfig.imovo,
          basketId,
          item.basketItem,
          userAuthToken,
          deviceAuthToken
        );
      case FulfilmentTypes.Remote:
        // TODO - replace stubbed code
        return RemoteFulfiller(basketId, fulfillmentApiClient);
      case FulfilmentTypes.SmartMeter:
        return SmartMeterFulfiller(
          transactionClient,
          apiConfig.billpay,
          basketId,
          callbacks,
          deviceAuthToken,
          userAuthToken
        );
      case FulfilmentTypes.Fmcc:
        return FmccFulfiller(
          transactionClient,
          apiConfig.fmcc,
          basketId,
          item.basketItem,
          userAuthToken,
          deviceAuthToken
        );
      default:
        // Exception case where reject label item has been added to the basket- this item
        // does not require fulfilment, however we need to invoke the same process involved
        // with mails labels fulfilment
        if (item.basketItem.userResponse) {
          return LabelFulfiller(transactionClient, basketId, item.basketItem, userAuthToken, deviceCallbacks?.label);
        }
        throw new Error(`Unknown fulfillment type: ${item.entry.fulfilmentType || "unknown"}`);
    }
  };

  return Object.freeze({ process, setFulfilmentStatus });
};

export default CommitAndFulfillProcessor;
