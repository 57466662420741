import axios, { AxiosError } from "axios";
import { ApiError, AuthProvider } from "../types";

export interface BankAccountValidationResponse {
  accountNumber: string;
  address1: string;
  address2: string;
  bank: string;
  bankBic: string;
  branch: string;
  branchBic: string;
  chapsSupported: boolean;
  directDebitsAllowed: boolean;
  fasterPaymentsSupported: boolean;
  fax: string;
  iban: string;
  ok: boolean;
  phone: string;
  postcode: string;
  sortCode: string;
  statusInformation: string;
  town: string;
}

export interface Client {
  getBankAccountDetails(params: BankAccountLookupRequest): Promise<BankAccountValidationResponse | ApiError>;
}
export interface Props {
  rootUrl: string;
  version: string;
  authHeaders: AuthProvider;
}

export interface BankAccountLookupRequest {
  accountNumber: string;
  sortCode: string;
}

export const buildClient = (props: Props): Client => {
  const { rootUrl, version, authHeaders } = props;
  const getBankAccountDetails = async (
    params: BankAccountLookupRequest
  ): Promise<BankAccountValidationResponse | ApiError> => {
    try {
      const response = await axios.post(
        `${rootUrl}/bankvalidation/${version}/BankAccountValidation`,
        {
          accountNumber: params.accountNumber,
          sortCode: params.sortCode,
        },
        {
          headers: await authHeaders(),
        }
      );
      return response.data as BankAccountValidationResponse;
    } catch (error: unknown) {
      const err = error as AxiosError;
      return Promise.reject({
        statusCode: err.response?.status || 500,
        ...(err.response?.data?.message && { message: err.response?.data?.message }),
      });
    }
  };

  return Object.freeze({ getBankAccountDetails });
};
