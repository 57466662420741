/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  buildDropAndGoClient,
  AuthProvider,
  UpdateDngCardStatusReqBodyUpdateDnGCardStatusToEnum,
  UpdateDngCardStatusResponse,
  AssignDngCardResponse,
  UpdateDngAccountRequest,
  UpdateDngAccountResponse,
} from "postoffice-product-journey-api-clients";
import { brokenPromises, isMatchingCode, primitiveToBoolean } from "../../helpers";
import { FulfilmentStateEnum } from "../../../openapi/transaction-api-v3";
import { ApiClientsConfig, BasketItemPayload, FulfilmentProcessorResponse, MessagingNotice } from "../../../types";
import { createCdpRequestHeader } from "../helpers";
import { MANDATORY_LOST_DAMAGED_CARD_TOKENS } from "../mandatoryTokens";

export const process = async (
  basketId: string,
  apiConfig: ApiClientsConfig["dropandgo"],
  basketItem: BasketItemPayload,
  authProvider: AuthProvider
): Promise<FulfilmentProcessorResponse> => {
  const apiClient = buildDropAndGoClient({
    authHeaders: authProvider,
    ...apiConfig,
  });

  const missingJourneyTokens: string[] = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const requiredToken of MANDATORY_LOST_DAMAGED_CARD_TOKENS) {
    if (!basketItem.fulfilment || !basketItem.fulfilment[requiredToken]) {
      missingJourneyTokens.push(requiredToken);
    }
  }

  if (missingJourneyTokens.length) {
    return {
      notice: {
        id: "Journey Error",
        description: `${missingJourneyTokens.join(", ")} key/values must be passed from journey`,
      },
      fulfilment: {
        status: FulfilmentStateEnum.Failure,
        tokens: {},
      },
    };
  }

  const notice: MessagingNotice = <MessagingNotice>{};
  let hasError = false;

  // 1. Update customer details if needed
  const hasAccountUpdate = primitiveToBoolean(basketItem.fulfilment?.hasAccountUpdate);
  let updateCustomerResultStatusCode!: number;

  if (hasAccountUpdate) {
    const updateCustomerResult: UpdateDngAccountResponse = await brokenPromises(
      apiClient.editAccount({
        hdr: createCdpRequestHeader(basketId, basketItem.fulfilment?.clerkId as string),
        body: basketItem.fulfilment?.updateCustomerAccountPayload,
      } as UpdateDngAccountRequest)
    );

    if (updateCustomerResult.status?.rcode === undefined) {
      hasError = true;
      notice.id = basketItem.fulfilment?.systemUnavailablePromptTitle ?? "";
      notice.description = basketItem.fulfilment?.systemUnavailablePromptText ?? "";
    }

    updateCustomerResultStatusCode = updateCustomerResult.status?.rcode as unknown as number;

    // System Unavailable
    if (!hasError && !isMatchingCode(updateCustomerResultStatusCode, [0, 1, 2])) {
      hasError = true;
      notice.id = basketItem.fulfilment?.systemUnavailablePromptTitle ?? "";
      notice.description = basketItem.fulfilment?.systemUnavailablePromptText ?? "";
    }

    // Account Update Declined
    if (!hasError && isMatchingCode(updateCustomerResultStatusCode, [1, 2])) {
      hasError = true;
      notice.id = basketItem.fulfilment?.accountUpdateDeclinedPromptTitle ?? "";
      notice.description = basketItem.fulfilment?.accountUpdateDeclinedPromptText ?? "";
    }

    if (hasError) {
      return {
        notice,
        fulfilment: {
          status: FulfilmentStateEnum.Failure,
          tokens: {
            updateAccountApiResponseCode: String(updateCustomerResultStatusCode),
          },
        },
      };
    }
  }

  // 2. Spend card
  const suspendCardResult: UpdateDngCardStatusResponse = await brokenPromises(
    apiClient.updateCardStatus({
      hdr: createCdpRequestHeader(basketId, basketItem.fulfilment?.clerkId as string),
      body: {
        dnGCardNumber: basketItem.fulfilment?.existingCardNumber as string,
        updateDnGCardStatusTo: UpdateDngCardStatusReqBodyUpdateDnGCardStatusToEnum.Suspended,
      },
    })
  );

  if (suspendCardResult.status?.rcode === undefined) {
    hasError = true;
    notice.id = basketItem.fulfilment?.systemUnavailablePromptTitle ?? "";
    notice.description = basketItem.fulfilment?.systemUnavailablePromptText ?? "";
  }

  const suspendCardResultStatusCode = suspendCardResult.status?.rcode as unknown as number;

  // System Unavailable
  if (!hasError && !isMatchingCode(suspendCardResultStatusCode, [0, 1, 2, 38, 43, 82, 84])) {
    hasError = true;
    notice.id = basketItem.fulfilment?.systemUnavailablePromptTitle ?? "";
    notice.description = basketItem.fulfilment?.systemUnavailablePromptText ?? "";
  }

  // Card Suspension Declined
  if (!hasError && isMatchingCode(suspendCardResultStatusCode, [1, 2])) {
    hasError = true;
    notice.id = basketItem.fulfilment?.cardSuspensionDeclinedPromptTitle ?? "";
    notice.description = basketItem.fulfilment?.cardSuspensionDeclinedPromptText ?? "";
  }

  // Drop & Go System Timeout
  if (!hasError && isMatchingCode(suspendCardResultStatusCode, [38, 43, 82, 84])) {
    hasError = true;
    notice.id = basketItem.fulfilment?.timeoutDeclinedPromptTitle ?? "";
    notice.description = basketItem.fulfilment?.timeoutDeclinedPromptText ?? "";
  }

  if (hasError) {
    return {
      notice,
      fulfilment: {
        status: FulfilmentStateEnum.Failure,
        tokens: {
          suspendCardApiResponseCode: String(suspendCardResultStatusCode),
        },
      },
    };
  }

  // 3. Assign new card

  const assignCardResult: AssignDngCardResponse = await brokenPromises(
    apiClient.assignCard({
      hdr: createCdpRequestHeader(basketId, basketItem.fulfilment?.clerkId as string),
      body: {
        dnGCardNumber: basketItem.fulfilment?.newCardNumber as string,
        dnGAccountNumber: basketItem.fulfilment?.accountNumber as string,
      },
    })
  );

  if (assignCardResult.status?.rcode === undefined) {
    hasError = true;
    notice.id = basketItem.fulfilment?.systemUnavailablePromptTitle ?? "";
    notice.description = basketItem.fulfilment?.systemUnavailablePromptText ?? "";
  }

  const assignCardResultStatusCode = assignCardResult.status?.rcode as unknown as number;

  // System Unavailable
  if (!hasError && !isMatchingCode(assignCardResultStatusCode, [0, 1, 2, 38, 43, 82, 84])) {
    hasError = true;
    notice.id = basketItem.fulfilment?.systemUnavailablePromptTitle ?? "";
    notice.description = basketItem.fulfilment?.systemUnavailablePromptText ?? "";
  }

  // Replacement Card Declined
  if (!hasError && isMatchingCode(assignCardResultStatusCode, [1, 2])) {
    hasError = true;
    notice.id = basketItem.fulfilment?.replacementCardDeclinedPromptTitle ?? "";
    notice.description = basketItem.fulfilment?.replacementCardDeclinedPromptText ?? "";
  }

  // Drop & Go System Timeout
  if (!hasError && isMatchingCode(assignCardResultStatusCode, [38, 43, 82, 84])) {
    hasError = true;
    notice.id = basketItem.fulfilment?.timeoutDeclinedPromptTitle ?? "";
    notice.description = basketItem.fulfilment?.timeoutDeclinedPromptText ?? "";
  }

  if (hasError) {
    return {
      notice,
      fulfilment: {
        status: FulfilmentStateEnum.Failure,
        tokens: {
          assignCardApiResponseCode: String(assignCardResultStatusCode),
        },
      },
    };
  }

  return {
    fulfilment: {
      status: FulfilmentStateEnum.Success,
      tokens: {
        ...(updateCustomerResultStatusCode !== undefined && {
          updateAccountApiResponseCode: String(updateCustomerResultStatusCode),
        }),
        assignCardApiResponseCode: String(assignCardResultStatusCode),
        suspendCardApiResponseCode: String(suspendCardResultStatusCode),
      },
    },
    notice: {
      id: basketItem.fulfilment?.transactionCompletePromptTitle ?? "",
      description: basketItem.fulfilment?.transactionCompletePromptText ?? "",
    },
    receipts: [
      {
        template: "mails/dropandgo",
        context: {
          type: "Lost or Damaged Card",
          receiptMessage: basketItem.fulfilment?.englishLostOrDamagedReceiptContent ?? "",
          entryId: basketItem.entryID,
          productNo: basketItem.itemID,
        },
      },
      {
        template: "mails/dropandgo",
        context: {
          type: "Replacement Card",
          receiptMessage: basketItem.fulfilment?.englishReplacementCardReceiptContent ?? "",
          entryId: basketItem.entryID,
          productNo: basketItem.itemID,
        },
      },
    ],
  };
};
