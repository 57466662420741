import { AuthProvider } from "../types";
import { axiosClient } from "../lib/axiosWrapper";
import { getAddressList, PostcodeAddress } from "./helpers";
import {
  FindAddressRequest,
  FindAddressResponse,
  RetrieveAddressRequest,
  RetrieveAddressResponse,
} from "./openapi/api";

export interface Props {
  rootUrl: string;
  authHeaders: AuthProvider;
}

export interface Client {
  findAddress(params: FindAddressRequest): Promise<PostcodeAddress[]>;
  retrieveAddress(params: RetrieveAddressRequest): Promise<RetrieveAddressResponse>;
}

export const buildClient = (props: Props): Client => {
  const { rootUrl, authHeaders } = props;

  const findAddress = async (params: FindAddressRequest): Promise<PostcodeAddress[]> => {
    const { text, country, application } = params;
    const response = (await axiosClient.post({
      url: `${rootUrl}/postcode-lookup/address/findAddress`,
      headers: await authHeaders(),
      params: {
        text,
        country,
        application,
      },
    })) as FindAddressResponse;
    return getAddressList(response);
  };

  const retrieveAddress = async (params: RetrieveAddressRequest): Promise<RetrieveAddressResponse> => {
    const { addressId, application } = params;
    return (await axiosClient.post({
      url: `${rootUrl}/postcode-lookup/address/retrieveAddress`,
      headers: await authHeaders(),
      params: { addressId, application },
    })) as RetrieveAddressResponse;
  };

  return Object.freeze({ findAddress, retrieveAddress });
};
