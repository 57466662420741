/**
 * cache/index.ts
 * This is cache wrapper file, all caching mechanism will go here
 */

import storage from "postoffice-spm-async-storage";
import moment from "moment";
import cacheRegistry, { RegistryRecord } from "./registry";

export enum CacheTypes {
  /** This is a time based caching, along with type ttl (Time to live) is required
   * Once time is expired, data will be fetched from the original API call and data will be cached
   */
  TIMED = "TIMED",
  /** No time limit
   * it will be only removed once device is rebooted
   */
  UNTIMED = "UNTIMED",
}

export type CacheConfigType = {
  /**
   * Cache key name
   */
  key: string;
  /**
   * Caching type, supported types: TIMED | ETAG | DEFAULT
   * @type {string}
   */
  type?: string;
  /**
   * Number in minutes to use cached data exclusively. During this period OPTION requests with ETag will not be performed.
   *
   * Time to live when cache type is TIMED.
   * Example:
   *  ttl: 30 will use cache for 30 minutes.
   *  ttl: 60 * 12 will use cache for 12 hours
   */
  ttl?: number;
  /**
   * mapper functions, that will be iterated and modify the actal returns
   */
  mapper?: <T, P>(result: T) => P;
};

const cache = async <T>(callback: () => Promise<T>, options?: CacheConfigType): Promise<T> => {
  if (!options || !options.type) {
    return callback();
  }

  if (options.type === CacheTypes.TIMED) {
    if (!options.ttl || options.ttl < 1) {
      throw new Error(`ttl value should be greater than 0, found ${options.ttl}`);
    }
    const response = await storage.getRecord(options.key as string);
    if (response && response.data && moment(response.expirtyTime as string).isAfter(moment())) {
      return response.data as unknown as T;
    }
  } else if (options.type === CacheTypes.UNTIMED) {
    const response = await storage.getRecord(options.key as string);
    if (response && response.data) {
      return response.data as unknown as T;
    }
  }

  let result = await callback();

  if (options.mapper) {
    result = options.mapper<Awaited<T>, Awaited<T>>(result);
  }

  setToCache(options.key as string, result, options);

  return result;
};

export const getFromCache = async (key: string) => {
  const response = await storage.getRecord(key as string);
  return response && response.data;
};

export const setToCache = <T>(key: string, data: T, options?: CacheConfigType): Promise<void> | void => {
  if (!data) {
    return;
  }
  return storage.setRecord({
    id: key,
    data,
    expirtyTime: options?.type === CacheTypes.TIMED ? moment().add(options.ttl, "minutes") : 0,
  });
};

const clear = async (cacheRecord: RegistryRecord | undefined): Promise<void> => {
  if (!cacheRecord) {
    return;
  }
  const operations: Promise<void>[] = [];
  cacheRecord?.keys.forEach((k) => {
    operations.push(storage.removeRecord(`${cacheRecord.prefix}/${k}`));
    operations.push(storage.removeRecord(`${cacheRecord.prefix}/${k}/etag`));
  });
  if (!cacheRecord?.keys || cacheRecord?.keys.length === 0) {
    operations.push(storage.removeRecord(`${cacheRecord.prefix}`));
    operations.push(storage.removeRecord(`${cacheRecord.prefix}/etag`));
  }
  await Promise.all(operations);
  return;
};

export const clearCache = async (name?: string): Promise<void> => {
  if (name) {
    await clear(cacheRegistry.get(name) as RegistryRecord);
  } else {
    const allKeys = cacheRegistry.getAll();
    await Promise.all(
      Object.keys(allKeys).map((index) => {
        return clear(allKeys[index]);
      })
    );
  }

  return;
};

export default cache;
