import axios from "axios";
import { RefreshToken } from "../openapi/refresh-token-api";

export type AuthProvider = () => Promise<{ Authorization: string }>;
export type Params = () => Promise<{ [key: string]: string }>;

export interface Client {
  getRefreshToken(): Promise<RefreshToken>;
}

const client = (root: string, authHeaders: AuthProvider): Client => {
  const getRefreshToken = async (): Promise<RefreshToken> => {
    const response = await axios.get(`${root}/refresh-token/v1/guid`, {
      headers: await authHeaders(),
    });
    return response.data as RefreshToken;
  };
  return Object.freeze({ getRefreshToken });
};

export const mockClient = (data: { [key: string]: any }): Client => {
  const getRefreshToken = async (): Promise<RefreshToken> => {
    return new Promise((resolve) => {
      resolve(data as RefreshToken);
    });
  };

  return Object.freeze({ getRefreshToken });
};

export default client;
