import WebSocketAsPromised from "websocket-as-promised";
import { SupportedServices, Response } from "../../types";

import { ScannerResult, ScannerClient, ScannerActions, ScannerClientProps } from "./types";
import { isError } from "../../helpers";
import { performAction as performSimulatorAction } from "./mock/index";

export const buildScannerClient = (
  requestHandler: WebSocketAsPromised["sendRequest"],
  props?: ScannerClientProps
): ScannerClient => {
  const performAction = async (action: ScannerActions, params: Record<string, unknown>): Promise<ScannerResult> => {
    if (props?.useMock) {
      return Promise.resolve(performSimulatorAction(action));
    }

    const response = (await requestHandler({
      service: SupportedServices.Scanner,
      action,
      params,
    })) as Response & ScannerResult;

    if (isError(response)) {
      return Promise.reject(response);
    }

    return Promise.resolve(response);
  };

  return Object.freeze({
    connect: () => performAction(ScannerActions.Connect, {}),
    disconnect: () => performAction(ScannerActions.Disconnect, {}),
  });
};
