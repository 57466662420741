export const parseActivationResponse = (response) => {
  const giftCardActivationResponse = {};
  giftCardActivationResponse["authIdentificationResponse"] =
    response?.authIdentificationResponse || response?.body.authIdentificationResponse || "";
  giftCardActivationResponse["responseCode"] = response?.responseCode || response?.body.responseCode || "";
  giftCardActivationResponse["errorCode"] = response?.errorCode || response?.body.errorCode || "";
  giftCardActivationResponse["errorText"] = response?.errorText || response?.body.errorText || "";
  giftCardActivationResponse["responseMessage"] = response?.responseMessage || response.body.responseMessage;
  giftCardActivationResponse["additionalTxnFields"] =
    response?.additionalTxnFields || response.body.additionalTxnFields;
};
