// codes provided by wordline which require banking undo
// based on previous discussions with FJ on horizon use
// format: Error Code/Response Code
export default [
  "0000/6001",
  "0000/6002",
  "0100/",
  "0101/",
  "0102/",
  "0103/",
  "0201/",
  "0311/0016",
  "0311/0018",
  "0311/0021",
  "0311/0024",
  "0311/0070",
  "0311/1000",
  "0311/1008",
  "0311/2010",
  "0113/",
  "0113/0261",
  "0113/0262",
  "0113/0263",
  "0113/0264",
  "0113/0265",
  "0113/0266",
];
