export interface ScannerResult {
  requestId: string;
  service: string;
  action: ScannerActions;
  event: string;
  result?: {
    connected: boolean;
  };
  message?: string;
}

export interface ScannerClientProps {
  useMock?: boolean;
}
export interface ScannerClient {
  connect(): Promise<ScannerResult>;
  disconnect(): Promise<ScannerResult>;
}

export enum ScannerActions {
  Connect = "CONNECT",
  Disconnect = "DISCONNECT",
}

export enum ScannerEvent {
  SCANNER_ONLINE = "SCANNER_ONLINE",
  SCANNER_OFFLINE = "SCANNER_OFFLINE",
  SCANNER_ERROR = "SCANNER_ERROR",
}
