import { AuthProvider } from "../types";
import { axiosClient } from "../lib/axiosWrapper";

import { despatchOptions } from "./helpers";

import {
  AnyParcel,
  LastDespatchResponse,
  ParcelList,
  ParcelsItem,
  GetParcelsParams,
  PutParcelsParams,
  DespatchParams,
  DespatchForTableParams,
  DespatchForTableReturn,
  CarrierFilterParams,
} from "./types";

export interface Client {
  afterLastDespatch(params: DespatchParams): Promise<LastDespatchResponse>;
  updateLastDespatch(params: DespatchParams): Promise<LastDespatchResponse>;
  getParcels(params: GetParcelsParams): Promise<ParcelList>;
  putParcels(params: PutParcelsParams): Promise<AnyParcel>;
  prepareDespatchForTable(params: DespatchForTableParams): Promise<DespatchForTableReturn>;
  filterParcelsByCarrierId(params: CarrierFilterParams): Promise<ParcelsItem[]>;
}

export interface Props {
  rootUrl: string;
  authHeaders: AuthProvider;
}
export const buildClient = (props: Props): Client => {
  const { rootUrl, authHeaders } = props;

  const afterLastDespatch = async (params: DespatchParams): Promise<LastDespatchResponse> => {
    const { branchId, counterId, clerkId, carrierId } = params;
    const response = await axiosClient.get({
      url: `${rootUrl}/despatch/last-despatch`,
      headers: await authHeaders(),
      params: {
        branchId,
        counterId,
        clerkId,
        carrierId,
      },
    });
    return response as unknown as LastDespatchResponse;
  };

  const updateLastDespatch = async (props: DespatchParams): Promise<LastDespatchResponse> => {
    const { branchId, counterId, carrierId, clerkId } = props;
    const response = await axiosClient.put({
      url: `${rootUrl}/despatch/last-despatch`,
      headers: await authHeaders(),
      params: {
        branchId,
        counterId,
        carrierId,
        clerkId,
      },
      data: {
        afterLastDispatch: true,
      },
    });
    return response as unknown as LastDespatchResponse;
  };

  const getParcels = async (params: GetParcelsParams): Promise<ParcelList> => {
    const response = await axiosClient.get({
      url: `${rootUrl}/despatch/parcels`,
      headers: await authHeaders(),
      params,
    });
    return response as unknown as ParcelList;
  };

  const putParcels = async (params: PutParcelsParams): Promise<AnyParcel> => {
    const { branchId, counterId, clerkId, status, parcelList } = params;

    const updatedParcels: ParcelsItem[] = [];
    parcelList.forEach((item: ParcelsItem) => {
      const parcelitem = item;
      parcelitem.status = status as string; // update status
      updatedParcels.push(parcelitem);
    });

    const response = await axiosClient.put({
      url: `${rootUrl}/despatch/parcels`,
      headers: await authHeaders(),
      data: {
        parcels: updatedParcels,
        branchId,
        counterId,
        clerkId,
      },
    });
    return response as AnyParcel;
  };

  const prepareDespatchForTable = async (params: DespatchForTableParams): Promise<DespatchForTableReturn> => {
    const { data, type, statusList } = params;
    return despatchOptions({ data, type, statusList });
  };

  /**
   * Filter parcel list by carrier id
   */
  const filterParcelsByCarrierId = async (params: CarrierFilterParams): Promise<ParcelsItem[]> => {
    const { carrierId, parcelList } = params;

    const filterData: ParcelsItem[] = parcelList.filter((el) => el.carrierId === carrierId);
    return filterData;
  };

  return Object.freeze({
    getParcels,
    putParcels,
    afterLastDespatch,
    updateLastDespatch,
    prepareDespatchForTable,
    filterParcelsByCarrierId,
  });
};

export const mockClient = (data: { [key: string]: any }): Client => {
  const getParcels = async (params: GetParcelsParams): Promise<ParcelList> => {
    return new Promise((resolve) => {
      resolve(data.parcels);
    });
  };

  const putParcels = async (params: PutParcelsParams): Promise<AnyParcel> => {
    return new Promise((resolve) => {
      resolve(data.dispatchParcels as AnyParcel);
    });
  };

  const afterLastDespatch = async (params: DespatchParams): Promise<LastDespatchResponse> => {
    return new Promise((resolve) => {
      resolve(data.parcels as LastDespatchResponse);
    });
  };

  const updateLastDespatch = async (props: DespatchParams): Promise<LastDespatchResponse> => {
    return new Promise((resolve) => {
      resolve(data.parcels as LastDespatchResponse);
    });
  };

  const prepareDespatchForTable = async (params: DespatchForTableParams): Promise<DespatchForTableReturn> => {
    const { data, type, statusList } = params;
    return despatchOptions({ data, type, statusList });
  };

  const filterParcelsByCarrierId = async (params: CarrierFilterParams): Promise<ParcelsItem[]> => {
    const { carrierId, parcelList } = params;

    const filterData: ParcelsItem[] = parcelList.filter((el: ParcelsItem) => el.carrierId === carrierId);
    return filterData;
  };

  return Object.freeze({
    getParcels,
    putParcels,
    updateLastDespatch,
    afterLastDespatch,
    prepareDespatchForTable,
    filterParcelsByCarrierId,
  });
};
