import { HdrReq } from "postoffice-product-journey-api-clients";

/**
 * Create CDP Request Header
 * @param basketId string
 * @param clerkId string
 * @returns
 */
export const createCdpRequestHeader = (basketId: string, clerkId: string): HdrReq => {
  const explodedBasket = basketId.split("-");
  return {
    created: new Date().toISOString(),
    reqId: explodedBasket[2],
    branchId: explodedBasket[0],
    ctrId: explodedBasket[1],
    txId: basketId,
    clerkId,
  };
};
