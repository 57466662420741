/* tslint:disable */
/* eslint-disable */
/**
 * transactions
 * PO Transactions
 *
 * The version of the OpenAPI document: 0.3.0
 * Contact: po.contact@postoffice.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from "./configuration";
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "./common";
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "./base";

/**
 * Model to capture information of linked items
 * @export
 * @interface AdditionalItem
 */
export interface AdditionalItem {
  /**
   *
   * @type {string}
   * @memberof AdditionalItem
   */
  itemID: string;
  /**
   *
   * @type {string}
   * @memberof AdditionalItem
   */
  itemDescription?: string;
  /**
   *
   * @type {string}
   * @memberof AdditionalItem
   */
  itemType?: string;
  /**
   *
   * @type {number}
   * @memberof AdditionalItem
   */
  valueInPence: number;
  /**
   *
   * @type {number}
   * @memberof AdditionalItem
   * @deprecated
   */
  value?: number;
  /**
   *
   * @type {number}
   * @memberof AdditionalItem
   */
  quantity: number;
  /**
   *
   * @type {string}
   * @memberof AdditionalItem
   */
  receiptLine?: string;
  /**
   * place holder if any filed required in future, to be used a key/value pair in tokens object if token name is prepended with *, then it will be treated as personal data
   * @type {{ [key: string]: string; }}
   * @memberof AdditionalItem
   */
  tokens?: { [key: string]: string };
}
/**
 * Used to store basket information in database. This is also returned in get basket method
 * @export
 * @interface Basket
 */
export interface Basket {
  /**
   *
   * @type {BasketCore}
   * @memberof Basket
   */
  basketCore: BasketCore;
  /**
   *
   * @type {string}
   * @memberof Basket
   */
  basketOpenedBy?: string;
  /**
   *
   * @type {string}
   * @memberof Basket
   */
  basketClosedBy?: string;
  /**
   *
   * @type {string}
   * @memberof Basket
   */
  organizationCode?: string;
  /**
   *
   * @type {string}
   * @memberof Basket
   */
  organizationCodeVersion: string;
  /**
   *
   * @type {string}
   * @memberof Basket
   */
  deviceType: string;
  /**
   *
   * @type {string}
   * @memberof Basket
   */
  deviceID: string;
  /**
   *
   * @type {number}
   * @memberof Basket
   */
  basketSeqNum: number;
  /**
   *
   * @type {string}
   * @memberof Basket
   */
  nodeID: string;
  /**
   *
   * @type {string}
   * @memberof Basket
   */
  fadCode: string;
  /**
   *
   * @type {number}
   * @memberof Basket
   */
  BasketCreatedTime?: number;
  /**
   *
   * @type {number}
   * @memberof Basket
   */
  BasketClosedTime?: number;
}
/**
 * used to close basket in put method of basket resource.
 * @export
 * @interface BasketClosed
 */
export interface BasketClosed {
  /**
   * concatenation of FADcode (7 characters), nodeID (between 33 and 64) and basket Sequence Number provided by device
   * @type {string}
   * @memberof BasketClosed
   */
  basketID: string;
  /**
   *
   * @type {string}
   * @memberof BasketClosed
   */
  basketState: BasketStateEnum;
  /**
   *
   * @type {number}
   * @memberof BasketClosed
   */
  NumberOfEntries: number;
  /**
   *
   * @type {string}
   * @memberof BasketClosed
   */
  basketFulfilmentState?: BasketFulfilmentStateEnum;
}

/**
 * @export
 * @enum {string}
 */

/**
 * @export
 * @enum {string}
 */

/**
 * used to close basket in put method of basket resource.
 * @export
 * @interface BasketCore
 */
export interface BasketCore {
  /**
   * concatenation of FADcode (7 characters), nodeID (between 33 and 64) and basket Sequence Number provided by device
   * @type {string}
   * @memberof BasketCore
   */
  basketID: string;
  /**
   *
   * @type {string}
   * @memberof BasketCore
   */
  basketState: BasketStateEnum;
  /**
   *
   * @type {number}
   * @memberof BasketCore
   */
  NumberOfEntries: number;
  /**
   *
   * @type {string}
   * @memberof BasketCore
   */
  basketFulfilmentState?: BasketFulfilmentStateEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum BasketStateEnum {
  Bkc = "BKC",
  Bko = "BKO",
}
/**
 * @export
 * @enum {string}
 */
export enum BasketFulfilmentStateEnum {
  Success = "success",
  Failure = "failure",
  Pending = "pending",
  NotRequired = "notRequired",
}

/**
 *
 * @export
 * @interface BasketCreateResponse
 */
export interface BasketCreateResponse {
  /**
   *
   * @type {string}
   * @memberof BasketCreateResponse
   */
  fulfilmentType?: FulfilmentTypeEnum;
  /**
   *
   * @type {boolean}
   * @memberof BasketCreateResponse
   */
  fulfilmentRequired?: boolean;
}

/**
 * @export
 * @enum {string}
 */

/**
 * Used to store basket information in database. This is also returned in get basket method
 * @export
 * @interface BasketWithEntries
 */
export interface BasketWithEntries {
  /**
   *
   * @type {Basket}
   * @memberof BasketWithEntries
   */
  basket: Basket;
  /**
   *
   * @type {Array<Entry>}
   * @memberof BasketWithEntries
   */
  entries: Array<Entry>;
}
/**
 * Entry model used to store information in database
 * @export
 * @interface Entry
 */
export interface Entry {
  /**
   *
   * @type {string}
   * @memberof Entry
   */
  entryID?: string;
  /**
   *
   * @type {string}
   * @memberof Entry
   */
  RequestUDID?: string;
  /**
   *
   * @type {EntryCore}
   * @memberof Entry
   */
  entryCore?: EntryCore;
  /**
   *
   * @type {string}
   * @memberof Entry
   */
  fulfilmentType?: string;
  /**
   *
   * @type {Fulfilment}
   * @memberof Entry
   */
  fulfilment?: Fulfilment;
  /**
   *
   * @type {number}
   * @memberof Entry
   */
  transactionEndTime?: number;
  /**
   *
   * @type {string}
   * @memberof Entry
   */
  basketID?: string;
  /**
   *
   * @type {string}
   * @memberof Entry
   */
  fadCode?: string;
  /**
   *
   * @type {string}
   * @memberof Entry
   */
  entryUpdatedBy?: string;
  /**
   *
   * @type {string}
   * @memberof Entry
   */
  organizationCode?: string;
  /**
   *
   * @type {string}
   * @memberof Entry
   */
  organizationCodeVersion?: string;
  /**
   *
   * @type {string}
   * @memberof Entry
   */
  deviceType?: string;
  /**
   *
   * @type {string}
   * @memberof Entry
   */
  deviceID?: string;
  /**
   *
   * @type {string}
   * @memberof Entry
   */
  nodeID?: string;
  /**
   *
   * @type {number}
   * @memberof Entry
   */
  basketSeqNum?: number;
  /**
   * <Credence>The transmission source for the transaction. PG = Post & Go PS = Paystation  SPZ = Payzone SPM HX = Horizon CA = Camelot
   * @type {string}
   * @memberof Entry
   */
  transmissionSource?: TransmissionSourceEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum TransmissionSourceEnum {
  Pg = "PG",
  Ps = "PS",
  Pz = "PZ",
  Hx = "HX",
  Ca = "CA",
  Spz = "SPZ",
}

/**
 * used to capture basket entry information, used in post method of basket entry
 * @export
 * @interface EntryCore
 */
export interface EntryCore {
  /**
   *
   * @type {number}
   * @memberof EntryCore
   */
  transactionStartTime: number;
  /**
   *
   * @type {string}
   * @memberof EntryCore
   */
  itemID: string;
  /**
   *
   * @type {string}
   * @memberof EntryCore
   */
  itemDescription?: string;
  /**
   *
   * @type {string}
   * @memberof EntryCore
   */
  itemType?: string;
  /**
   *
   * @type {number}
   * @memberof EntryCore
   */
  quantity: number;
  /**
   *
   * @type {number}
   * @memberof EntryCore
   */
  entryID: number;
  /**
   *
   * @type {number}
   * @memberof EntryCore
   */
  valueInPence: number;
  /**
   *
   * @type {number}
   * @memberof EntryCore
   * @deprecated
   */
  value?: number;
  /**
   *
   * @type {string}
   * @memberof EntryCore
   */
  receiptLine?: string;
  /**
   * Placeholder for key values field we may require if token name is prepended with *, then it will be treated as personal data
   * @type {{ [key: string]: string; }}
   * @memberof EntryCore
   */
  tokens?: { [key: string]: string };
  /**
   *
   * @type {Array<AdditionalItem>}
   * @memberof EntryCore
   */
  additionalItems?: Array<AdditionalItem>;
  /**
   * <credence>Unique identifier to distinguish between all stock units within each branch
   * @type {string}
   * @memberof EntryCore
   */
  stockunitIdentifier?: string;
  /**
   * <credence>How each transaction is captured at the point of sale RDS maintained – Values:  0 = Barcode 1 = Keyboard/Screen (Manual) 2 = Magnetic Card 3 = Smart Card 4 = Fallback to Mag Swipe (Horizon) or Smart Key (PayStation) 5 = Scales 9 = Kiosk (Post & Go Only)
   * @type {number}
   * @memberof EntryCore
   */
  methodOfDataCapture?: number;
  /**
   * To show if reversals have taken place  Default ‘0’ ; ‘1’ shows that this is a linked reversed transaction; ‘2’ shows that this is a non linked reversed transaction
   * @type {number}
   * @memberof EntryCore
   */
  reversalIndicator?: ReversalIndicatorEnum;
  /**
   * <credence>To show when refunds have been provided
   * @type {string}
   * @memberof EntryCore
   */
  refundFlag?: RefundFlagEnum;
  /**
   * <credence>To show when transactions have been keyed after the event, i.e. In fall back mode
   * @type {string}
   * @memberof EntryCore
   */
  fallBackModeFlag?: FallBackModeFlagEnum;
  /**
   * <credence>Bar Code can Reference.  Only applicable to External Transaction Types
   * @type {string}
   * @memberof EntryCore
   */
  bcsReference?: string;
  /**
   * <CFS>Transaction Reference   Pouch Identifier  Transaction Correction Reference  Transaction Acknowledgment Reference  For Transaction Acknowledgements. This field contains the TA Reference Number.  NOTE: Assumed to be in scope for the completion of this document. Later phase design changes may alter this and subject to understanding of E2E process for TC’s and TA’s, may be remove
   * @type {string}
   * @memberof EntryCore
   */
  accountReferenceID?: string;
  /**
   * <CFS>Client Reference number (supplied by client). This will only be used for the actioned Transactions Correction being passed back to POL SAP against the client products.    Usage to be controlled via reference data
   * @type {string}
   * @memberof EntryCore
   */
  clientReference?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum ReversalIndicatorEnum {
  NUMBER_1 = 1,
  NUMBER_0 = 0,
  NUMBER_2 = 2,
}
/**
 * @export
 * @enum {string}
 */
export enum RefundFlagEnum {
  N = "N",
  Y = "Y",
}
/**
 * @export
 * @enum {string}
 */
export enum FallBackModeFlagEnum {
  Y = "Y",
  N = "N",
}

/**
 *
 * @export
 * @interface EntryResponse
 */
export interface EntryResponse {
  /**
   *
   * @type {string}
   * @memberof EntryResponse
   */
  fulfilmentAction?: string;
  /**
   *
   * @type {string}
   * @memberof EntryResponse
   */
  fulfilmentType?: string;
  /**
   *
   * @type {boolean}
   * @memberof EntryResponse
   */
  fulfilmentRequired?: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum FulfilmentTypeEnum {
  Remote = "Remote",
  Ped = "PED",
  None = "None",
  Label = "label",
  Payzone = "Payzone",
}

/**
 *
 * @export
 * @interface ErrorResp
 */
export interface ErrorResp {
  /**
   *
   * @type {string}
   * @memberof ErrorResp
   */
  errorCode?: string;
  /**
   *
   * @type {number}
   * @memberof ErrorResp
   */
  numberOfEntries?: number;
}
/**
 *
 * @export
 * @interface Fulfilment
 */
export interface Fulfilment {
  /**
   *
   * @type {string}
   * @memberof Fulfilment
   */
  fulfilmentState: FulfilmentStateEnum;
  /**
   *
   * @type {{ [key: string]: string; }}
   * @memberof Fulfilment
   */
  fulfilmentTokens?: { [key: string]: string };
}

/**
 * @export
 * @enum {string}
 */
export enum FulfilmentStateEnum {
  Success = "success",
  Failure = "failure",
  Indeterminate = "indeterminate",
  Pending = "pending",
  NotRequired = "notRequired",
}

/**
 *
 * @export
 * @interface LastseqnumberResponse
 */
export interface LastseqnumberResponse {
  /**
   *
   * @type {string}
   * @memberof LastseqnumberResponse
   */
  branchID: string;
  /**
   *
   * @type {number}
   * @memberof LastseqnumberResponse
   */
  nodeID: number;
  /**
   *
   * @type {number}
   * @memberof LastseqnumberResponse
   */
  lastSeqNumber: number;
}
/**
 * used to create basket in post method of basket resource.
 * @export
 * @interface NewBasket
 */
export interface NewBasket {
  /**
   * concatenation of FADcode (7 characters), nodeID (between 33 and 64) and basket Sequence Number provided by device
   * @type {string}
   * @memberof NewBasket
   */
  basketID: string;
}
/**
 *
 * @export
 * @interface Reversal
 */
export interface Reversal {
  /**
   *
   * @type {number}
   * @memberof Reversal
   */
  reversalTime?: number;
  /**
   *
   * @type {string}
   * @memberof Reversal
   */
  reversalStatus: ReversalStatusEnum;
  /**
   *
   * @type {string}
   * @memberof Reversal
   */
  reversedBy?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum ReversalStatusEnum {
  NotReversed = "NotReversed",
  Reversed = "Reversed",
}

/**
 * TransactionsApi - axios parameter creator
 * @export
 */
export const TransactionsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * This endpoint method is used to close basket or modify basket.
     * @summary This endpoint is used to close basket or modify basket
     * @param {string} [user]
     * @param {BasketClosed} [basketClosed]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    closeOrModifyBasket: async (
      user?: string,
      basketClosed?: BasketClosed,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/basket`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (user !== undefined && user !== null) {
        localVarHeaderParameter["User"] = String(user);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(basketClosed, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This endpoint is used to Create Unique basket
     * @summary This endpoint is used to Create Unique basket
     * @param {string} [user]
     * @param {NewBasket} [newBasket]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createBasket: async (
      user?: string,
      newBasket?: NewBasket,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/basket`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (user !== undefined && user !== null) {
        localVarHeaderParameter["User"] = String(user);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(newBasket, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This endpoint is used to create basket entry
     * @summary This endpoint is used to create basket entry
     * @param {string} basketId
     * @param {string} [user]
     * @param {EntryCore} [entryCore]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createBasketEntry: async (
      basketId: string,
      user?: string,
      entryCore?: EntryCore,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'basketId' is not null or undefined
      assertParamExists("createBasketEntry", "basketId", basketId);
      const localVarPath = `/basket/{basketId}/entry`.replace(`{${"basketId"}}`, encodeURIComponent(String(basketId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (user !== undefined && user !== null) {
        localVarHeaderParameter["User"] = String(user);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(entryCore, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get basket.
     * @summary This endpoint is used to get Basket
     * @param {string} basketId
     * @param {string} [user]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBasket: async (basketId: string, user?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'basketId' is not null or undefined
      assertParamExists("getBasket", "basketId", basketId);
      const localVarPath = `/basket/{basketId}`.replace(`{${"basketId"}}`, encodeURIComponent(String(basketId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (user !== undefined && user !== null) {
        localVarHeaderParameter["User"] = String(user);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This endpoint method is used to get basket entry
     * @summary This endpoint is used to get basket entry
     * @param {string} basketId
     * @param {string} entry
     * @param {string} [user]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBasketEntry: async (
      basketId: string,
      entry: string,
      user?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'basketId' is not null or undefined
      assertParamExists("getBasketEntry", "basketId", basketId);
      // verify required parameter 'entry' is not null or undefined
      assertParamExists("getBasketEntry", "entry", entry);
      const localVarPath = `/basket/{basketId}/entry/{entry}`
        .replace(`{${"basketId"}}`, encodeURIComponent(String(basketId)))
        .replace(`{${"entry"}}`, encodeURIComponent(String(entry)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (user !== undefined && user !== null) {
        localVarHeaderParameter["User"] = String(user);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This endpoint is used to get reversal status of an entry
     * @param {string} transactionId
     * @param {string} [user]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBasketEntryReversal: async (
      transactionId: string,
      user?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'transactionId' is not null or undefined
      assertParamExists("getBasketEntryReversal", "transactionId", transactionId);
      const localVarPath = `/transaction/{transactionId}/reversal`.replace(
        `{${"transactionId"}}`,
        encodeURIComponent(String(transactionId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (user !== undefined && user !== null) {
        localVarHeaderParameter["User"] = String(user);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This endpoint is used to get last basket
     * @param {string} branchID
     * @param {number} nodeID
     * @param {string} [user]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLastBasket: async (
      branchID: string,
      nodeID: number,
      user?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'branchID' is not null or undefined
      assertParamExists("getLastBasket", "branchID", branchID);
      // verify required parameter 'nodeID' is not null or undefined
      assertParamExists("getLastBasket", "nodeID", nodeID);
      const localVarPath = `/branch/{branchID}/node/{nodeID}/lastBasket`
        .replace(`{${"branchID"}}`, encodeURIComponent(String(branchID)))
        .replace(`{${"nodeID"}}`, encodeURIComponent(String(nodeID)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (user !== undefined && user !== null) {
        localVarHeaderParameter["User"] = String(user);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This endpoint is used to get the last sequence number used in baskedID for given branchId and nodeID. This api is rquired when device  is started /restarted to get last sequence number used so it can increment and use it creating new basket.
     * @summary This endpoint is used to get the last sequence number used in baskedID for given branchId and nodeID. This api is rquired when device  is started /restarted to get last sequence number used so it can increment and use it creating new basket.
     * @param {string} branchID
     * @param {number} nodeID
     * @param {string} [user]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLastSeqNumber: async (
      branchID: string,
      nodeID: number,
      user?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'branchID' is not null or undefined
      assertParamExists("getLastSeqNumber", "branchID", branchID);
      // verify required parameter 'nodeID' is not null or undefined
      assertParamExists("getLastSeqNumber", "nodeID", nodeID);
      const localVarPath = `/branch/{branchID}/node/{nodeID}/lastSeqNumber`
        .replace(`{${"branchID"}}`, encodeURIComponent(String(branchID)))
        .replace(`{${"nodeID"}}`, encodeURIComponent(String(nodeID)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (user !== undefined && user !== null) {
        localVarHeaderParameter["User"] = String(user);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This endpoint is used to post fulfilment of an entry
     * @param {string} basketId
     * @param {string} entry
     * @param {string} [user]
     * @param {Fulfilment} [fulfilment]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBasketEntryFulfilment: async (
      basketId: string,
      entry: string,
      user?: string,
      fulfilment?: Fulfilment,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'basketId' is not null or undefined
      assertParamExists("updateBasketEntryFulfilment", "basketId", basketId);
      // verify required parameter 'entry' is not null or undefined
      assertParamExists("updateBasketEntryFulfilment", "entry", entry);
      const localVarPath = `/basket/{basketId}/entry/{entry}/fulfilment`
        .replace(`{${"basketId"}}`, encodeURIComponent(String(basketId)))
        .replace(`{${"entry"}}`, encodeURIComponent(String(entry)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication bearerAuth required
      // http bearer authentication required
      await setBearerAuthToObject(localVarHeaderParameter, configuration);

      if (user !== undefined && user !== null) {
        localVarHeaderParameter["User"] = String(user);
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(fulfilment, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TransactionsApi - functional programming interface
 * @export
 */
export const TransactionsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TransactionsApiAxiosParamCreator(configuration);
  return {
    /**
     * This endpoint method is used to close basket or modify basket.
     * @summary This endpoint is used to close basket or modify basket
     * @param {string} [user]
     * @param {BasketClosed} [basketClosed]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async closeOrModifyBasket(
      user?: string,
      basketClosed?: BasketClosed,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.closeOrModifyBasket(user, basketClosed, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * This endpoint is used to Create Unique basket
     * @summary This endpoint is used to Create Unique basket
     * @param {string} [user]
     * @param {NewBasket} [newBasket]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createBasket(
      user?: string,
      newBasket?: NewBasket,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasketCreateResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createBasket(user, newBasket, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * This endpoint is used to create basket entry
     * @summary This endpoint is used to create basket entry
     * @param {string} basketId
     * @param {string} [user]
     * @param {EntryCore} [entryCore]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createBasketEntry(
      basketId: string,
      user?: string,
      entryCore?: EntryCore,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntryResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createBasketEntry(basketId, user, entryCore, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get basket.
     * @summary This endpoint is used to get Basket
     * @param {string} basketId
     * @param {string} [user]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBasket(
      basketId: string,
      user?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasketWithEntries>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBasket(basketId, user, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * This endpoint method is used to get basket entry
     * @summary This endpoint is used to get basket entry
     * @param {string} basketId
     * @param {string} entry
     * @param {string} [user]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBasketEntry(
      basketId: string,
      entry: string,
      user?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Entry>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBasketEntry(basketId, entry, user, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * This endpoint is used to get reversal status of an entry
     * @param {string} transactionId
     * @param {string} [user]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBasketEntryReversal(
      transactionId: string,
      user?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Reversal>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBasketEntryReversal(transactionId, user, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * This endpoint is used to get last basket
     * @param {string} branchID
     * @param {number} nodeID
     * @param {string} [user]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLastBasket(
      branchID: string,
      nodeID: number,
      user?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BasketWithEntries>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getLastBasket(branchID, nodeID, user, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * This endpoint is used to get the last sequence number used in baskedID for given branchId and nodeID. This api is rquired when device  is started /restarted to get last sequence number used so it can increment and use it creating new basket.
     * @summary This endpoint is used to get the last sequence number used in baskedID for given branchId and nodeID. This api is rquired when device  is started /restarted to get last sequence number used so it can increment and use it creating new basket.
     * @param {string} branchID
     * @param {number} nodeID
     * @param {string} [user]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLastSeqNumber(
      branchID: string,
      nodeID: number,
      user?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LastseqnumberResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getLastSeqNumber(branchID, nodeID, user, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * This endpoint is used to post fulfilment of an entry
     * @param {string} basketId
     * @param {string} entry
     * @param {string} [user]
     * @param {Fulfilment} [fulfilment]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateBasketEntryFulfilment(
      basketId: string,
      entry: string,
      user?: string,
      fulfilment?: Fulfilment,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateBasketEntryFulfilment(
        basketId,
        entry,
        user,
        fulfilment,
        options
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * TransactionsApi - factory interface
 * @export
 */
export const TransactionsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = TransactionsApiFp(configuration);
  return {
    /**
     * This endpoint method is used to close basket or modify basket.
     * @summary This endpoint is used to close basket or modify basket
     * @param {string} [user]
     * @param {BasketClosed} [basketClosed]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    closeOrModifyBasket(user?: string, basketClosed?: BasketClosed, options?: any): AxiosPromise<void> {
      return localVarFp.closeOrModifyBasket(user, basketClosed, options).then((request) => request(axios, basePath));
    },
    /**
     * This endpoint is used to Create Unique basket
     * @summary This endpoint is used to Create Unique basket
     * @param {string} [user]
     * @param {NewBasket} [newBasket]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createBasket(user?: string, newBasket?: NewBasket, options?: any): AxiosPromise<BasketCreateResponse> {
      return localVarFp.createBasket(user, newBasket, options).then((request) => request(axios, basePath));
    },
    /**
     * This endpoint is used to create basket entry
     * @summary This endpoint is used to create basket entry
     * @param {string} basketId
     * @param {string} [user]
     * @param {EntryCore} [entryCore]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createBasketEntry(
      basketId: string,
      user?: string,
      entryCore?: EntryCore,
      options?: any
    ): AxiosPromise<EntryResponse> {
      return localVarFp
        .createBasketEntry(basketId, user, entryCore, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get basket.
     * @summary This endpoint is used to get Basket
     * @param {string} basketId
     * @param {string} [user]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBasket(basketId: string, user?: string, options?: any): AxiosPromise<BasketWithEntries> {
      return localVarFp.getBasket(basketId, user, options).then((request) => request(axios, basePath));
    },
    /**
     * This endpoint method is used to get basket entry
     * @summary This endpoint is used to get basket entry
     * @param {string} basketId
     * @param {string} entry
     * @param {string} [user]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBasketEntry(basketId: string, entry: string, user?: string, options?: any): AxiosPromise<Entry> {
      return localVarFp.getBasketEntry(basketId, entry, user, options).then((request) => request(axios, basePath));
    },
    /**
     * This endpoint is used to get reversal status of an entry
     * @param {string} transactionId
     * @param {string} [user]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBasketEntryReversal(transactionId: string, user?: string, options?: any): AxiosPromise<Reversal> {
      return localVarFp
        .getBasketEntryReversal(transactionId, user, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * This endpoint is used to get last basket
     * @param {string} branchID
     * @param {number} nodeID
     * @param {string} [user]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLastBasket(branchID: string, nodeID: number, user?: string, options?: any): AxiosPromise<BasketWithEntries> {
      return localVarFp.getLastBasket(branchID, nodeID, user, options).then((request) => request(axios, basePath));
    },
    /**
     * This endpoint is used to get the last sequence number used in baskedID for given branchId and nodeID. This api is rquired when device  is started /restarted to get last sequence number used so it can increment and use it creating new basket.
     * @summary This endpoint is used to get the last sequence number used in baskedID for given branchId and nodeID. This api is rquired when device  is started /restarted to get last sequence number used so it can increment and use it creating new basket.
     * @param {string} branchID
     * @param {number} nodeID
     * @param {string} [user]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLastSeqNumber(
      branchID: string,
      nodeID: number,
      user?: string,
      options?: any
    ): AxiosPromise<LastseqnumberResponse> {
      return localVarFp.getLastSeqNumber(branchID, nodeID, user, options).then((request) => request(axios, basePath));
    },
    /**
     * This endpoint is used to post fulfilment of an entry
     * @param {string} basketId
     * @param {string} entry
     * @param {string} [user]
     * @param {Fulfilment} [fulfilment]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateBasketEntryFulfilment(
      basketId: string,
      entry: string,
      user?: string,
      fulfilment?: Fulfilment,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .updateBasketEntryFulfilment(basketId, entry, user, fulfilment, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * TransactionsApi - interface
 * @export
 * @interface TransactionsApi
 */
export interface TransactionsApiInterface {
  /**
   * This endpoint method is used to close basket or modify basket.
   * @summary This endpoint is used to close basket or modify basket
   * @param {string} [user]
   * @param {BasketClosed} [basketClosed]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionsApiInterface
   */
  closeOrModifyBasket(user?: string, basketClosed?: BasketClosed, options?: AxiosRequestConfig): AxiosPromise<void>;

  /**
   * This endpoint is used to Create Unique basket
   * @summary This endpoint is used to Create Unique basket
   * @param {string} [user]
   * @param {NewBasket} [newBasket]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionsApiInterface
   */
  createBasket(user?: string, newBasket?: NewBasket, options?: AxiosRequestConfig): AxiosPromise<BasketCreateResponse>;

  /**
   * This endpoint is used to create basket entry
   * @summary This endpoint is used to create basket entry
   * @param {string} basketId
   * @param {string} [user]
   * @param {EntryCore} [entryCore]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionsApiInterface
   */
  createBasketEntry(
    basketId: string,
    user?: string,
    entryCore?: EntryCore,
    options?: AxiosRequestConfig
  ): AxiosPromise<EntryResponse>;

  /**
   * Get basket.
   * @summary This endpoint is used to get Basket
   * @param {string} basketId
   * @param {string} [user]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionsApiInterface
   */
  getBasket(basketId: string, user?: string, options?: AxiosRequestConfig): AxiosPromise<BasketWithEntries>;

  /**
   * This endpoint method is used to get basket entry
   * @summary This endpoint is used to get basket entry
   * @param {string} basketId
   * @param {string} entry
   * @param {string} [user]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionsApiInterface
   */
  getBasketEntry(basketId: string, entry: string, user?: string, options?: AxiosRequestConfig): AxiosPromise<Entry>;

  /**
   * This endpoint is used to get reversal status of an entry
   * @param {string} transactionId
   * @param {string} [user]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionsApiInterface
   */
  getBasketEntryReversal(transactionId: string, user?: string, options?: AxiosRequestConfig): AxiosPromise<Reversal>;

  /**
   * This endpoint is used to get last basket
   * @param {string} branchID
   * @param {number} nodeID
   * @param {string} [user]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionsApiInterface
   */
  getLastBasket(
    branchID: string,
    nodeID: number,
    user?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<BasketWithEntries>;

  /**
   * This endpoint is used to get the last sequence number used in baskedID for given branchId and nodeID. This api is rquired when device  is started /restarted to get last sequence number used so it can increment and use it creating new basket.
   * @summary This endpoint is used to get the last sequence number used in baskedID for given branchId and nodeID. This api is rquired when device  is started /restarted to get last sequence number used so it can increment and use it creating new basket.
   * @param {string} branchID
   * @param {number} nodeID
   * @param {string} [user]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionsApiInterface
   */
  getLastSeqNumber(
    branchID: string,
    nodeID: number,
    user?: string,
    options?: AxiosRequestConfig
  ): AxiosPromise<LastseqnumberResponse>;

  /**
   * This endpoint is used to post fulfilment of an entry
   * @param {string} basketId
   * @param {string} entry
   * @param {string} [user]
   * @param {Fulfilment} [fulfilment]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionsApiInterface
   */
  updateBasketEntryFulfilment(
    basketId: string,
    entry: string,
    user?: string,
    fulfilment?: Fulfilment,
    options?: AxiosRequestConfig
  ): AxiosPromise<void>;
}

/**
 * TransactionsApi - object-oriented interface
 * @export
 * @class TransactionsApi
 * @extends {BaseAPI}
 */
export class TransactionsApi extends BaseAPI implements TransactionsApiInterface {
  /**
   * This endpoint method is used to close basket or modify basket.
   * @summary This endpoint is used to close basket or modify basket
   * @param {string} [user]
   * @param {BasketClosed} [basketClosed]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionsApi
   */
  public closeOrModifyBasket(user?: string, basketClosed?: BasketClosed, options?: AxiosRequestConfig) {
    return TransactionsApiFp(this.configuration)
      .closeOrModifyBasket(user, basketClosed, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This endpoint is used to Create Unique basket
   * @summary This endpoint is used to Create Unique basket
   * @param {string} [user]
   * @param {NewBasket} [newBasket]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionsApi
   */
  public createBasket(user?: string, newBasket?: NewBasket, options?: AxiosRequestConfig) {
    return TransactionsApiFp(this.configuration)
      .createBasket(user, newBasket, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This endpoint is used to create basket entry
   * @summary This endpoint is used to create basket entry
   * @param {string} basketId
   * @param {string} [user]
   * @param {EntryCore} [entryCore]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionsApi
   */
  public createBasketEntry(basketId: string, user?: string, entryCore?: EntryCore, options?: AxiosRequestConfig) {
    return TransactionsApiFp(this.configuration)
      .createBasketEntry(basketId, user, entryCore, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get basket.
   * @summary This endpoint is used to get Basket
   * @param {string} basketId
   * @param {string} [user]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionsApi
   */
  public getBasket(basketId: string, user?: string, options?: AxiosRequestConfig) {
    return TransactionsApiFp(this.configuration)
      .getBasket(basketId, user, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This endpoint method is used to get basket entry
   * @summary This endpoint is used to get basket entry
   * @param {string} basketId
   * @param {string} entry
   * @param {string} [user]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionsApi
   */
  public getBasketEntry(basketId: string, entry: string, user?: string, options?: AxiosRequestConfig) {
    return TransactionsApiFp(this.configuration)
      .getBasketEntry(basketId, entry, user, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This endpoint is used to get reversal status of an entry
   * @param {string} transactionId
   * @param {string} [user]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionsApi
   */
  public getBasketEntryReversal(transactionId: string, user?: string, options?: AxiosRequestConfig) {
    return TransactionsApiFp(this.configuration)
      .getBasketEntryReversal(transactionId, user, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This endpoint is used to get last basket
   * @param {string} branchID
   * @param {number} nodeID
   * @param {string} [user]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionsApi
   */
  public getLastBasket(branchID: string, nodeID: number, user?: string, options?: AxiosRequestConfig) {
    return TransactionsApiFp(this.configuration)
      .getLastBasket(branchID, nodeID, user, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This endpoint is used to get the last sequence number used in baskedID for given branchId and nodeID. This api is rquired when device  is started /restarted to get last sequence number used so it can increment and use it creating new basket.
   * @summary This endpoint is used to get the last sequence number used in baskedID for given branchId and nodeID. This api is rquired when device  is started /restarted to get last sequence number used so it can increment and use it creating new basket.
   * @param {string} branchID
   * @param {number} nodeID
   * @param {string} [user]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionsApi
   */
  public getLastSeqNumber(branchID: string, nodeID: number, user?: string, options?: AxiosRequestConfig) {
    return TransactionsApiFp(this.configuration)
      .getLastSeqNumber(branchID, nodeID, user, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This endpoint is used to post fulfilment of an entry
   * @param {string} basketId
   * @param {string} entry
   * @param {string} [user]
   * @param {Fulfilment} [fulfilment]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TransactionsApi
   */
  public updateBasketEntryFulfilment(
    basketId: string,
    entry: string,
    user?: string,
    fulfilment?: Fulfilment,
    options?: AxiosRequestConfig
  ) {
    return TransactionsApiFp(this.configuration)
      .updateBasketEntryFulfilment(basketId, entry, user, fulfilment, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
