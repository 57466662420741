// TODO: Add to tokeniser tokenMask response

export const RMSenderFields = {
  keyName: "SendersDetails",
  name: "SenderAddressDetail",
  fields: [
    {
      name: "Customer",
    },
    {
      name: "AddressLine1",
    },
    {
      name: "AddressLine2",
    },
    {
      name: "AddressLine3",
    },
    {
      name: "AddressLine4",
    },
    {
      name: "Town",
    },
    {
      name: "PostCode",
    },
  ],
};

export const amazonSenderFields = {
  keyName: "amazonDetails",
  name: "SenderAddressDetail",
  fields: [
    {
      name: "Customer",
    },
    {
      name: "AddressLine1",
    },
    {
      name: "AddressLine2",
    },
    {
      name: "PostCode",
    },
  ],
};

export const returnFields = {
  keyName: "RetDetails",
  name: "ReturnAddressDetail",
  fields: [
    {
      name: "Customer",
    },
    {
      name: "AddressLine1",
    },
    {
      name: "Town",
    },
    {
      name: "PostCode",
    },
  ],
};
