import {
  StaticContent,
  promptResponseEnum,
  LoadingStatus
} from "./types";
import { Callbacks, CustomModalSize } from "../../types";

/**
 * 
 * @returns Promise cancel|retry
 * return cancel for cancel label
 * return retry from retry label
 */
export const retryCancelPrompt = async (callbacks: Callbacks): Promise<promptResponseEnum> => {
  const waitForConfirmation = new Promise<promptResponseEnum>((resolve) => {      
    callbacks.onShowModel?.(LoadingStatus.SMARTMETER_RETRY_CANCEL, {
      contentSize: CustomModalSize.MEDIUM,
      icon: false,
      title: StaticContent.FailedTitle,
      content: StaticContent.FailedText,
      primaryButtonProps: {
        label: StaticContent.RetryLabel,
        onPress: () => {
          callbacks.onHideModel?.(LoadingStatus.SMARTMETER_RETRY_CANCEL);            
          resolve(promptResponseEnum.RETRY);
        }
      },
      secondaryButtonProps: {
        label: StaticContent.CancelLabel,
        onPress: () => {
          callbacks.onHideModel?.(LoadingStatus.SMARTMETER_RETRY_CANCEL);
          resolve(promptResponseEnum.CANCEL);
        },
      },
    }); 
  });
  const result = await waitForConfirmation;
  return result;
}


/**
 * 
 * @returns Promise okay
 * return okay from okay label
 */
export const confirmationPrompt = async (callbacks: Callbacks): Promise<promptResponseEnum> => {
  const waitForConfirmation = new Promise<promptResponseEnum>((resolve) => {     
    callbacks.onShowModel?.(LoadingStatus.SMARTMETER_CONFIRM, {
      contentSize: CustomModalSize.MEDIUM,
      icon: false,
      title: "",
      content: StaticContent.ConfirmationText,
      primaryButtonProps: {
        label: StaticContent.OkayLabel,
        onPress: () => {
          callbacks.onHideModel?.(LoadingStatus.SMARTMETER_CONFIRM);
          resolve(promptResponseEnum.CONFIRM);
        }
      }
    })    
  });
  const result = await waitForConfirmation;
  return result;
}

/**
 * Showing loading prompt
 */
export const showLoadingPrompt = (callbacks: Callbacks) => {
  callbacks.onShowModel?.(LoadingStatus.SMARTMETER_REQUEST_INITIATE, { title: "", content: StaticContent.LoadingMessage });
}

/**
 * Closing loading prompt
 */
export const hideLoadingPrompt = (callbacks: Callbacks) => {
  callbacks.onHideModel?.(LoadingStatus.SMARTMETER_REQUEST_INITIATE);
}
