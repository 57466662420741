// TODO - this is stubbed code, to be removed or replaced when actual Remote fulfilment code is built

export type AuthProvider = () => Promise<{
  Authorization: string;
}>;

export interface FulfillmentClient {
  checkFulfillmentStatus(basketId: string, fulfillmentId: string): Promise<FulfillmentResponse>;
}

export interface FulfillmentResponse {
  fulfilled: boolean;
  fulfillmentId: string;
  result?: Record<string, unknown>;
}

async function checkFulfillmentStatus(basketId: string, fulfillmentId: string): Promise<FulfillmentResponse> {
  return Promise.resolve({
    fulfilled: true,
    fulfillmentId,
  });
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const buildFulfillmentApiClient = (baseDomain: string, version: string, authProvider: AuthProvider) => {
  return {
    checkFulfillmentStatus,
  };
};
