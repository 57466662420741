import { AuthProvider } from "../types";
export interface Client {
  requestVend(requestVendProps: RequestVendProps): Promise<RequestVendResponse>;
  confirmVend(requestVendProps: RequestVendProps): Promise<ConfirmVendResponse>;
  cancelVend(requestVendProps: RequestVendProps): Promise<CancelVendResponse>;
}

export interface Props {
  rootUrl: string;
  authHeaders: AuthProvider;
}

export interface RequestVendProps {
  vendType: string;
  branchId?: string;
  ctrId?: string;
  clerkId?: string;
  PAN: string;
  monetaryClass: string;
  monetaryValue: string;
  retrievalReference?: string;
  transactionReference?: string;
}

export interface RequestVendResponse {
  fuelType: string;
  mpxn: string;
  retrievalReference: string;
  transactionReference: string;
  transactionId: string;
  utrn: string;
  utrnCreationDate: string;
  created: string;
  reqId: string;
  txId: string;
  ecode: string;
  etext: string;
  rcode: string;
  receiptText: string;
  responseCode: string;
  responseMessage: string;
}

export interface ConfirmVendResponse {
  created: string;
  reqId: string;
  txId: string;
  ecode: string;
  etext: string;
}

export interface CancelVendResponse {
  created: string;
  reqId: string;
  txId: string;
  ecode: string;
  etext: string;
}

export enum SmartMeterVendTypeActions {
  SME = "sme",
  PAYGEN_GENERIC = "paygen-generic",
  PAYZONE = "payzone",
  SME_CONFIRM = "sme-confirm",
  PAYGEN_GENERIC_CONFIRM = "paygen-generic-confirm",
  PAYZONE_CONFIRM = "payzone-confirm",
  SME_CANCEL = "sme-cancel",
  PAYGEN_GENERIC_CANCEL = "paygen-generic-cancel",
  PAYZONE_CANCEL = "payzone-cancel",
}
