export const parseVendResponse = (response) => {
  const billPayRes = {};
  billPayRes["fuelType"] = response?.fuelType || response?.body?.fuelType || "";
  billPayRes["mpxn"] = response?.mpxn || response?.body?.mpxn || "";
  billPayRes["receiptText"] = response?.receiptText || response?.body?.receiptText || "";
  billPayRes["responseCode"] = response?.responseCode || "";
  billPayRes["responseMessage"] = response?.responseMessage || "";
  billPayRes["retrievalReference"] = response?.retrievalReference || response?.body?.retrievalReference || "";
  billPayRes["transactionId"] = response?.transactionId || response?.body?.transactionID || "";
  billPayRes["utrn"] = response?.utrn || response?.body?.utrn || "";
  billPayRes["utrnCreationDate"] = response?.utrnCreationDate || response?.body?.utrnCreationDate || "";
  billPayRes["created"] = response?.hdr?.created || response?.created || "";
  billPayRes["reqId"] = response?.hdr?.reqId || response?.reqId || "";
  billPayRes["txId"] = response?.hdr?.txId || response?.txId || "";
  billPayRes["ecode"] = response?.status?.ecode || response?.ecode || response?.errorCode || "";
  billPayRes["etext"] = response?.status?.etext || response?.etext || "";
  billPayRes["rcode"] = response?.status?.rcode || response?.rcode || "";
  billPayRes["transactionReference"] = response?.transactionReference || "";
  return billPayRes;
};

export const parseConfirmAndCancelVendResponse = (response) => {
  const billPayRes = {};
  billPayRes["created"] = response?.hdr?.created || response?.created || "";
  billPayRes["reqId"] = response?.hdr?.reqId || response?.reqId || "";
  billPayRes["txId"] = response?.hdr?.txId || response?.txId || "";
  billPayRes["ecode"] = response?.status?.ecode || response?.ecode || response?.errorCode || "";
  billPayRes["etext"] = response?.status?.etext || response?.etext || response?.responseMessage || "";
  billPayRes["rcode"] = response?.status?.rcode || response?.rcode || response?.responseCode || "";
  return billPayRes;
};
