import { AuthProvider } from "../types";
import {
  parseJson,
  fetchDocumentList,
  fetchVRMValidRegex,
  getPassportSelTable,
  getLicenceSerialNo,
  getVedRatesSelTable,
  getExpandedRegistrationNo,
  getValidationCharacter,
  getCurrentTaxClass,
  checkDDNAFlag,
  getTxnId,
  getDirectDebitApprovalRequestBody,
  getCustomHeader,
  getDocumentCount,
} from "./helpers";
import {
  Json2DBarcodeProps,
  SelectedDocumentsProps,
  DocumentListProps,
  DCSOtherListProps,
  VRMNumberProps,
  ItemProps,
  PassportPaperProps,
  v5CValidationCharacterProps,
  v5cCTCProps,
  Client,
  Props,
  getDirectDebitApprovalProps,
  SelectedIrishPassportProps,
  passportListItem,
} from "./types";
import axios from "axios";
import { buildClient as buildTokeniserClient } from "../tokeniser-api/index";
import { CTCMockResponse } from "./fixtures/gov-id";
import { AxiosError } from "axios";
import moment from "moment";

export type { Props, Client };

export const buildClient = (props: Props): Client => {
  const { rootUrl, authHeaders } = props;
  const jsonStringParse = (json2DBarcodeProps: Json2DBarcodeProps): Promise<Record<string, unknown>> => {
    const { data } = json2DBarcodeProps;
    const parseData: unknown = parseJson(data) as unknown;
    if (parseData != null) {
      return Promise.resolve({ results: parseData });
    }
    return Promise.reject({ results: { error: "Invalid json string" } });
  };

  const validateDocumentCount = (selectedDocumentsProps: SelectedDocumentsProps): Promise<Record<string, unknown>> => {
    const { isDocumentList, isOtherDocument, isDCS } = selectedDocumentsProps;
    let dcs_token_text = "";
    const { count, documentString, isOtherSelected, otherQty, dcs_text } = getDocumentCount(selectedDocumentsProps);

    if (isDCS) {
      dcs_token_text = `<Type_of_Docs_Provided><Count>${count}</Count>${dcs_text}</Type_of_Docs_Provided>`;
    }
    return Promise.resolve({
      totalQuantity: count,
      ...(isDocumentList && { documentList: documentString }),
      ...(isOtherDocument && { isOtherSelected: isOtherSelected, otherQty: otherQty }),
      ...(isDCS && { dcs_sel_text: dcs_token_text }),
    });
  };

  const getDocumentList = (documentListProps: DocumentListProps): Promise<Record<string, unknown>> => {
    const { data } = documentListProps;
    if (data && data.length > 0) {
      try {
        const getList: unknown = fetchDocumentList(data) as unknown;
        if (getList != null) {
          return Promise.resolve({ results: getList });
        }
      } catch (ex) {
        return Promise.reject({ results: { error: "Invalid doc list" } });
      }
    }
    return Promise.reject({ results: { error: "Invalid doc list" } });
  };

  const totalDocumentCount = (documentListProps: DCSOtherListProps): Promise<Record<string, unknown>> => {
    const { data, otherQty } = documentListProps;
    let count = 0;
    let otherQtyChecked = false;
    if (data && data.length > 0) {
      data.split(",").forEach((x) => {
        if (x.trim() !== null && x.trim() !== "") {
          count = count + 1;
        }
      });
    }
    if (parseInt(otherQty) === count) {
      otherQtyChecked = true;
    }

    return Promise.resolve({ otherQtyChecked: otherQtyChecked });
  };

  const validateVRM = (vrmNumberProps: VRMNumberProps): Promise<Record<string, unknown>> => {
    const { data, isV5C } = vrmNumberProps;
    let isValid = true;
    const vrmRegex = fetchVRMValidRegex();

    for (let i = 0; i < vrmRegex.length; i++) {
      isValid = vrmRegex[i].test(data);
      if (isValid) {
        return Promise.resolve({
          isValid: isValid,
          ...(isV5C && { matchedRegEx: vrmRegex[i].toString() }),
        });
      }
    }
    return Promise.resolve({ isValid: isValid });
  };

  const getV5CVehicleDetails = async (v5cCTCProps: v5cCTCProps) => {
    const {
      barcode,
      taxationPeriod,
      vrm,
      paymentType,
      transactionType,
      surname,
      postcode,
      basketId,
      mockResponseCode,
      conditional,
    } = v5cCTCProps;
    if (conditional && mockResponseCode && mockResponseCode.length > 0) {
      const response = CTCMockResponse({ conditional, mockResponseCode });
      return Promise.resolve({ APIResponse: response, vedRatesSelTable: getVedRatesSelTable(response.VehicleData) });
    } else {
      const txnId = getTxnId(basketId);
      let header = await authHeaders();
      const customHeader = getCustomHeader("FrontOfficeCounters_TaxClassChangeEnquiry", txnId);

      const licenceSerialNumber = getLicenceSerialNo(taxationPeriod, paymentType);
      const body = {
        TransactionType: transactionType,
        BarcodeNumber: barcode,
        LicenceSerialNumber: licenceSerialNumber,
        ...(vrm && { VRM: vrm }),
        ...(surname && { Surname: surname }),
        ...(postcode && { Postcode: postcode }),
      };
      header = { ...header, ...customHeader };
      try {
        const response = await axios.post(`${rootUrl}/dvla-service/dvla/ctc`, body, { headers: header });
        const { VehicleData } = response.data;
        const vedRatesSelTable = getVedRatesSelTable(VehicleData);
        const isSingleDDNA = checkDDNAFlag(VehicleData, "VEDSingleDDRate");
        const isMonthlyDDNA = checkDDNAFlag(VehicleData, "VEDMonthlyDDRate");
        return Promise.resolve({
          statusCode: response?.status?.toString(),
          APIResponse: response.data,
          vedRatesSelTable: vedRatesSelTable,
          licenceSerialNumber: licenceSerialNumber,
          isSingleDDNA: isSingleDDNA,
          isMonthlyDDNA: isMonthlyDDNA,
        });
      } catch (error) {
        const err = error as AxiosError;
        return {
          statusCode: err.response?.status.toString() || "500",
          ...(err.response?.data?.Error && { error: err.response?.data?.Error }),
        };
      }
    }
  };

  const getVehicleDetailsFromVED = async (v5cCTCProps: v5cCTCProps) => {
    const { barcode, taxationPeriod, vrm, paymentType, transactionType, basketId, mockResponseCode, conditional } =
      v5cCTCProps;
    if (conditional && mockResponseCode && mockResponseCode.length > 0) {
      const response = CTCMockResponse({ conditional, mockResponseCode });
      return Promise.resolve({ APIResponse: response, vedRatesSelTable: getVedRatesSelTable(response.VehicleData) });
    } else {
      const txnId = getTxnId(basketId);
      let header = await authHeaders();
      const customHeader = getCustomHeader("FrontOfficeCounters_VEDEnquiry", txnId);

      const licenceSerialNumber = getLicenceSerialNo(taxationPeriod, paymentType);
      const body = {
        TransactionType: transactionType,
        BarcodeNumber: barcode,
        LicenceSerialNumber: licenceSerialNumber,
        ...(vrm && { VRM: vrm }),
      };
      header = { ...header, ...customHeader };
      try {
        const response = await axios.post(`${rootUrl}/dvla-service/dvla/ved`, body, { headers: header });
        const { VehicleData } = response.data;
        const currentTaxClass = getCurrentTaxClass(VehicleData);
        const isSingleDDNA = checkDDNAFlag(VehicleData, "VEDSingleDDRate");
        const isMonthlyDDNA = checkDDNAFlag(VehicleData, "VEDMonthlyDDRate");
        return Promise.resolve({
          statusCode: response?.status?.toString(),
          APIResponse: response.data,
          licenceSerialNumber: licenceSerialNumber,
          isSingleDDNA: isSingleDDNA,
          isMonthlyDDNA: isMonthlyDDNA,
          currentTaxClass: currentTaxClass,
        });
      } catch (error) {
        const err = error as AxiosError;
        return {
          statusCode: err.response?.status?.toString() || "500",
          ...(err.response?.data?.Error && { error: err.response?.data?.Error }),
        };
      }
    }
  };

  const getItemDetails = async (itemProps: ItemProps): Promise<any> => {
    const { data } = itemProps;
    const tokeniserClient = buildTokeniserClient({ rootUrl, authHeaders });
    const items = await tokeniserClient.getItems();
    const itemDetails = items.find((item) => item.itemID == data);
    return itemDetails;
  };

  const getPassportPaperPrices = async (passportPaperProps: PassportPaperProps): Promise<any> => {
    const tokeniserClient = buildTokeniserClient({
      rootUrl,
      authHeaders,
    });
    const { passportItemIDS, passportIDMapping, secureDeliveryID, checkSendID } = passportPaperProps;
    try {
      const items = await tokeniserClient.getItems();
      const paperPassports = items.filter((item) => passportItemIDS.includes(item.itemID as string));
      const secureDeliveryFee = items.find((item) => item.itemID === secureDeliveryID)?.retailPrice;
      const checkSendFee = items.find((item) => item.itemID === checkSendID)?.retailPrice;
      const selectionTable = getPassportSelTable(paperPassports, passportItemIDS, passportIDMapping);
      return Promise.resolve({
        table: selectionTable,
        secureDeliveryPOFee: secureDeliveryFee,
        checkSendPOFee: checkSendFee,
      });
    } catch (err) {
      return Promise.reject({ message: "Error in getPassportPaperType" });
    }
  };

  const v5CValidationCharacter = async (
    v5CValidationCharacterProps: v5CValidationCharacterProps
  ): Promise<Record<string, unknown>> => {
    const { vehicleRegistrationNo, matchedRegEx } = v5CValidationCharacterProps;
    if (!vehicleRegistrationNo.trim().length || !matchedRegEx.trim().length) {
      return Promise.reject({ message: "Invalid Input" });
    }

    const expendedRegistrationNo = getExpandedRegistrationNo({ vehicleRegistrationNo, matchedRegEx });
    const validationCharacter = getValidationCharacter(expendedRegistrationNo);

    return Promise.resolve({ validationCharacter: validationCharacter });
  };

  const getDirectDebitApproval = async (getDirectDebitApprovalProps: getDirectDebitApprovalProps) => {
    const txnId = getTxnId(getDirectDebitApprovalProps.basketId);
    let header = await authHeaders();
    const customHeader = getCustomHeader("FrontOfficeCounters_DDICapture", txnId);

    header = { ...header, ...customHeader };

    const body = getDirectDebitApprovalRequestBody(getDirectDebitApprovalProps);

    try {
      const response = await axios.post(`${rootUrl}/dvla-service/dvla/ddi`, body, { headers: header });
      const { data } = response;
      return Promise.resolve({
        statusCode: response?.status?.toString(),
        APIResponse: data,
      });
    } catch (error) {
      const err = error as AxiosError;
      return {
        statusCode: err.response?.status?.toString() || "500",
        ...(err.response?.data?.Error && { error: err.response?.data?.Error }),
      };
    }
  };

  const irishPassportList = async (
    selectedIrishPassportProps: SelectedIrishPassportProps
  ): Promise<Record<string, unknown>> => {
    const { data, itemId } = selectedIrishPassportProps;
    let count = 0;
    let totalItems: passportListItem[] = [];

    data.forEach((v) => {
      if (parseInt(v.qty) > 0) {
        count = count + parseInt(v.qty);
        let item = { itemId: itemId, document: v.document, price: v.price, qty: v.qty };
        totalItems.push(item);
      }
    });
    const res = await getItemDetails({ data: "5004" });
    let accCharge = { itemId: "5004", document: "Acc Charge", price: res.retailPrice, qty: count.toString() };
    totalItems.push(accCharge);
    return Promise.resolve({
      ...(totalItems && { irishPassportItemsList: totalItems }),
    });
  };

  return Object.freeze({
    jsonStringParse,
    validateDocumentCount,
    getDocumentList,
    totalDocumentCount,
    validateVRM,
    getItemDetails,
    getPassportPaperPrices,
    getV5CVehicleDetails,
    v5CValidationCharacter,
    getDirectDebitApproval,
    getVehicleDetailsFromVED,
    irishPassportList,
  });
};
