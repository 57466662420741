export type RegistryRecord = {
  name: string;
  prefix: string;
  keys: string[];
};

const cacheRegistrySingleton = (() => {
  const registry: Record<string, RegistryRecord> = {};

  const get = (name: string): RegistryRecord | undefined => {
    if (!registry[name]) {
      return undefined;
    }
    return registry[name];
  };

  const getAll = (): Record<string, RegistryRecord> => {
    return registry;
  };

  const register = (record: RegistryRecord): void => {
    if (!registry[record.name]) {
      registry[record.name] = record;
    }
    return;
  };

  const reset = (): void => {
    Object.keys(registry).forEach((key: string) => delete registry[key]);
  };

  return Object.freeze({ register, get, getAll, reset });
})();

export default cacheRegistrySingleton;
