import { MessageMapping } from "../types";
import { AcquirerResponseCodes } from "./acquirierResponseCodes";

export const GenericBankingTransactionDeclinedPrompt = {
  id: "MSG99619",
  description: "The transaction has been declined.",
};

export const GenericPaymentTransactionDeclinedPrompt = {
  id: "MSG40078",
  description: "The transaction has been declined.\n\nAdvise the customer to use an alternative method of payment.",
};

// PAYMENTS

export const AcquirerToInternalPaymentsMessages: MessageMapping = {
  [AcquirerResponseCodes.InternalIngenicoError]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.FormatError]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.InvalidDate]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.FINotAvailableForRouting]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.SystemMalfunction]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.SuspectedFraudDecline]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.CardAcceptorContactAcquirerDecline]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.RestrictedCard]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.SuspectedFraudDecline]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.CardAcceptorContactAcquirerDecline]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.RestrictedCard]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.MasterCardSecurityViolation]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.CardAcceptorContactAcquirerSecurity]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.CVCFailedDoNotHonour]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.CVV2FailedDoNotHonour]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.ExceedsWithdrawalAmountLimit]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.ExceedsWithdrawalFrequencyLimit]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.ReferToCardIssuer]: {
    id: "MSG01441",
    description:
      "Advise the customer that the transaction has been referred and that the PIN Pad may indicate a decline.",
  },
  [AcquirerResponseCodes.ReferToCardIssuerSpecialConditions]: {
    id: "MSG01441",
    description:
      "Advise the customer that the transaction has been referred and that the PIN Pad may indicate a decline.",
  },
  [AcquirerResponseCodes.CardAuthenticationFailure]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.PickUp]: {
    id: "MSG00628",
    description: "The transaction has been declined.\n\nAdvise the customer to contact their card issuer.",
  },
  [AcquirerResponseCodes.PickUpSpecialConditions]: {
    id: "MSG00628",
    description: "The transaction has been declined.\n\nAdvise the customer to contact their card issuer.",
  },
  [AcquirerResponseCodes.SuspectedFraudPickup]: {
    id: "MSG00628",
    description: "The transaction has been declined.\n\nAdvise the customer to contact their card issuer.",
  },
  [AcquirerResponseCodes.CardAcceptorContactAcquirerPickup]: {
    id: "MSG00628",
    description: "The transaction has been declined.\n\nAdvise the customer to contact their card issuer.",
  },
  [AcquirerResponseCodes.RestrictedCardPickUp]: {
    id: "MSG00628",
    description: "The transaction has been declined.\n\nAdvise the customer to contact their card issuer.",
  },
  [AcquirerResponseCodes.CardAcceptorCallAcquirersSecurityDepartmentPickup]: {
    id: "MSG00628",
    description: "The transaction has been declined.\n\nAdvise the customer to contact their card issuer.",
  },
  [AcquirerResponseCodes.LostCardPickUp]: {
    id: "MSG00628",
    description: "The transaction has been declined.\n\nAdvise the customer to contact their card issuer.",
  },
  [AcquirerResponseCodes.StolenCardPickUp]: {
    id: "MSG00628",
    description: "The transaction has been declined.\n\nAdvise the customer to contact their card issuer.",
  },
  [AcquirerResponseCodes.HardCapturePickUp]: {
    id: "MSG00628",
    description: "The transaction has been declined.\n\nAdvise the customer to contact their card issuer.",
  },
  [AcquirerResponseCodes.CVCFailedCaptureCard]: {
    id: "MSG00628",
    description: "The transaction has been declined.\n\nAdvise the customer to contact their card issuer.",
  },
  [AcquirerResponseCodes.InvalidAmount]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.InvalidCardNumber]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.NoCreditAccount]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.NoUniversalAccount]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.NoInvestmentAccount]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.NoCheckingAccount]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.NoSavingsAccount]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.IssuerOrSwitchInoperative]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.AllowableNumberOfPinTriesExceeded]: {
    id: "MSG00969",
    description:
      "The PIN is blocked.\n\nAdvise the customer NOT to remove the card until prompted to do so.\n\nThe customer may wish to contact the card issuer.",
  },
  [AcquirerResponseCodes.InvalidTransaction]: {
    id: "MSG99606",
    description:
      "The card does not support this type of transaction.\n\nAdvise the customer to use an alternative method of payment.",
  },
  [AcquirerResponseCodes.ExpiredCard]: {
    id: "MSG00810",
    description: "The supplied card has expired.",
  },
  [AcquirerResponseCodes.ExpiredCardPickup]: {
    id: "MSG00628",
    description: "The transaction has been declined.\n\nAdvise the customer to contact their card issuer.",
  },
  [AcquirerResponseCodes.TransactionNotPermittedToCardholder]: {
    id: "MSG99606",
    description:
      "The card does not support this type of transaction.\n\nAdvise the customer to use an alternative method of payment.",
  },
  [AcquirerResponseCodes.FINotAvailableForRouting]: {
    id: "MSG40078",
    description: "The transaction has been declined.\n\nAdvise the customer to use an alternative method of payment.",
  },
  [AcquirerResponseCodes.DoNotHonour]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.CustomerCancellation]: {
    id: "MSG99604",
    description: "The transaction has been cancelled.",
  },
  [AcquirerResponseCodes.InsufficientFunds]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.InvalidAmount]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.InvalidCardNumber]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.NoCreditAccount]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.NoUniversalAccount]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.NoCheckingAccount]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.NoSavingsAccount]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.NoInvestmentAccount]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.InvalidMerchant]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.Error]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.HonourWithIdentification]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.RequestInProgress]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.ApprovedForPartialAmount]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.ApprovedVIP]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.ApprovedUpdateTrack3]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.CustomerCancellation]: {
    id: "MSG99604",
    description: "The transaction has been cancelled.",
  },
  [AcquirerResponseCodes.CustomerDispute]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.ReEnterTransaction]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.InvalidTerminalId]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.SuspectedMalfunction]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.UnacceptableFee]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.FileUpdateNotSupportedByReceiver]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.UnableToLocateRecordOnFile]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.DuplicateFileUpdateRecordOldRecordReplaced]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.FileUpdateFieldEditError]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.FileUpdateRecordLockedOut]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.FileUpdateNotSuccesfulContactAcquirer]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.CompletedPartially]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.RequestedFunctionNotSupported]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.OriginalAmountIncorrect]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.ResponseReceivedTooLate]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.LawViolation]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.DuplicateTransmission]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.ReconcileError]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.PrePayTransactionFailed]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.PrePaySystemUnavailable]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.PrePayTransactionRejected]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.PrePayAccountLimitReached]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.MNONotSupportedByIssuer]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.UnacceptablePin]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.InvalidMobilePhoneNumber]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.PinChangeFailed]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.PinUnlockFailed]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.NoDataSuppliedByIssuer]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.FallbackNotAllowed]: GenericPaymentTransactionDeclinedPrompt,
  [AcquirerResponseCodes.AllowablePinTriesExceededPickup]: {
    id: "MSG00628",
    description: "The transaction has been declined.\n\nAdvise the customer to contact their card issuer.",
  },
};

// BANKING

export const AcquirerToInternalBankingMessages: MessageMapping = {
  [AcquirerResponseCodes.InternalIngenicoError]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.FormatError]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.InvalidDate]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.FINotAvailableForRouting]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.SystemMalfunction]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.CVCFailedDoNotHonour]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.CVV2FailedDoNotHonour]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.ReferToCardIssuer]: {
    id: "MSG01441",
    description:
      "Advise the customer that the transaction has been referred and that the PIN Pad may indicate a decline.",
  },
  [AcquirerResponseCodes.ReferToCardIssuerSpecialConditions]: {
    id: "MSG01441",
    description:
      "Advise the customer that the transaction has been referred and that the PIN Pad may indicate a decline.",
  },
  [AcquirerResponseCodes.CardAuthenticationFailure]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.PickUp]: {
    id: "MSG00628",
    description: "The transaction has been declined.\n\nAdvise the customer to contact their card issuer.",
  },
  [AcquirerResponseCodes.PickUpSpecialConditions]: {
    id: "MSG00628",
    description: "The transaction has been declined.\n\nAdvise the customer to contact their card issuer.",
  },
  [AcquirerResponseCodes.SuspectedFraudPickup]: {
    id: "MSG00628",
    description: "The transaction has been declined.\n\nAdvise the customer to contact their card issuer.",
  },
  [AcquirerResponseCodes.CardAcceptorContactAcquirerPickup]: {
    id: "MSG00628",
    description: "The transaction has been declined.\n\nAdvise the customer to contact their card issuer.",
  },
  [AcquirerResponseCodes.RestrictedCardPickUp]: {
    id: "MSG00628",
    description: "The transaction has been declined.\n\nAdvise the customer to contact their card issuer.",
  },
  [AcquirerResponseCodes.CardAcceptorCallAcquirersSecurityDepartmentPickup]: {
    id: "MSG00628",
    description: "The transaction has been declined.\n\nAdvise the customer to contact their card issuer.",
  },
  [AcquirerResponseCodes.LostCardPickUp]: {
    id: "MSG00628",
    description: "The transaction has been declined.\n\nAdvise the customer to contact their card issuer.",
  },
  [AcquirerResponseCodes.StolenCardPickUp]: {
    id: "MSG00628",
    description: "The transaction has been declined.\n\nAdvise the customer to contact their card issuer.",
  },
  [AcquirerResponseCodes.HardCapturePickUp]: {
    id: "MSG00628",
    description: "The transaction has been declined.\n\nAdvise the customer to contact their card issuer.",
  },
  [AcquirerResponseCodes.CVCFailedCaptureCard]: {
    id: "MSG00628",
    description: "The transaction has been declined.\n\nAdvise the customer to contact their card issuer.",
  },
  [AcquirerResponseCodes.InvalidAmount]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.InvalidCardNumber]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.NoCreditAccount]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.NoUniversalAccount]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.NoInvestmentAccount]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.NoCheckingAccount]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.NoSavingsAccount]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.PinChangeFailed]: {
    id: "MSG00987",
    description:
      "Advise the customer to remove their card.\n\nThe customer has failed to confirm the new PIN and has exceeded the maximum retries.",
  },
  [AcquirerResponseCodes.InvalidTransaction]: {
    id: "MSG99624",
    description: "The card does not support this type of transaction.",
  },
  [AcquirerResponseCodes.IncorrectPin]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.TransactionNotPermittedToCardholder]: {
    id: "MSG99624",
    description: "The card does not support this type of transaction.",
  },
  [AcquirerResponseCodes.InsufficientFunds]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.ExceedsWithdrawalAmountLimit]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.ExceedsWithdrawalFrequencyLimit]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.ExpiredCard]: {
    id: "MSG00810",
    description: "The supplied card has expired.",
  },
  [AcquirerResponseCodes.ExpiredCardPickup]: {
    id: "MSG00628",
    description: "The transaction has been declined.\n\nAdvise the customer to contact their card issuer.",
  },
  [AcquirerResponseCodes.AllowablePinTriesExceededPickup]: {
    id: "MSG00628",
    description: "The transaction has been declined.\n\nAdvise the customer to contact their card issuer.",
  },
  [AcquirerResponseCodes.FINotAvailableForRouting]: {
    id: "MSG99619",
    description: "The transaction has been declined.",
  },
  [AcquirerResponseCodes.DoNotHonour]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.InvalidAmount]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.InvalidCardNumber]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.NoCreditAccount]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.NoUniversalAccount]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.NoCheckingAccount]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.NoSavingsAccount]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.NoInvestmentAccount]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.AllowableNumberOfPinTriesExceeded]: {
    id: "MSG00969",
    description:
      "The PIN is blocked.\n\nAdvise the customer NOT to remove the card until prompted to do so.\n\nThe customer may wish to contact the card issuer.",
  },
  [AcquirerResponseCodes.IssuerOrSwitchInoperative]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.InvalidMerchant]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.Error]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.HonourWithIdentification]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.RequestInProgress]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.ApprovedForPartialAmount]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.ApprovedVIP]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.ApprovedUpdateTrack3]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.CustomerCancellation]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.CustomerDispute]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.ReEnterTransaction]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.InvalidTerminalId]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.SuspectedMalfunction]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.UnacceptableFee]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.FileUpdateNotSupportedByReceiver]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.UnableToLocateRecordOnFile]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.DuplicateFileUpdateRecordOldRecordReplaced]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.FileUpdateFieldEditError]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.FileUpdateRecordLockedOut]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.FileUpdateNotSuccesfulContactAcquirer]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.CompletedPartially]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.RequestedFunctionNotSupported]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.OriginalAmountIncorrect]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.ResponseReceivedTooLate]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.LawViolation]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.DuplicateTransmission]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.ReconcileError]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.PrePayTransactionFailed]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.PrePaySystemUnavailable]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.PrePayTransactionRejected]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.PrePayAccountLimitReached]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.MNONotSupportedByIssuer]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.UnacceptablePin]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.InvalidMobilePhoneNumber]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.PinChangeFailed]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.PinUnlockFailed]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.NoDataSuppliedByIssuer]: GenericBankingTransactionDeclinedPrompt,
  [AcquirerResponseCodes.FallbackNotAllowed]: GenericBankingTransactionDeclinedPrompt,
};
