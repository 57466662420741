export const documentList = {
  AAD: "AADHAAR",
  ADC: "Adoption Certificate",
  BAN: "Bank statement",
  BEN: "Benefit Statement",
  BOL: "Bank Opening Letter",
  BTO: "Birth Certificate (<12m)",
  BTR: "Birth Certificate (>12m)",
  COU: "Council tax statement",
  DLP: "Paper Driving Licence",
  DRI: "Driving Licence",
  DVL: "DVLA V5 or V5C/2",
  EDU: "Education letter",
  ESL: "Employment Sponsorship Letter",
  FAC: "Face capture",
  FIN: "Financial Statement",
  FIR: "Firearms Licence",
  FPS: "P45 or P60 Statement",
  HMF: "HM Forces ID Card",
  IPP: "Irish Passport Card",
  MCC: "Marriage/Civil Partnership Certificate",
  MOB: "Phone bill",
  MOR: "Mortgage Statement",
  NAT: "National ID",
  NEX: "Nexus Card",
  OTH: "Other",
  PAN: "PAN",
  PAS: "Passport",
  PCD: "PASS Card",
  POS: "Postal ID",
  PRO: "Professional ID",
  RES: "Residence Permit",
  SSS: "Social Security System Card",
  UTI: "Utility bills",
  VIS: "Immigration/Visa/Work Permit",
  VOT: "Voter ID",
};

export const v5CndividualCharacterValue = {
  "#": 0,
  "0": 10,
  "1": 1,
  "2": 2,
  "3": 3,
  "4": 4,
  "5": 5,
  "6": 6,
  "7": 7,
  "8": 8,
  "9": 9,
  A: 11,
  B: 12,
  C: 13,
  D: 14,
  E: 15,
  F: 16,
  G: 17,
  H: 18,
  I: 19,
  J: 20,
  K: 21,
  L: 22,
  M: 23,
  N: 24,
  O: 25,
  P: 26,
  Q: 27,
  R: 28,
  S: 29,
  T: 30,
  U: 31,
  V: 32,
  W: 33,
  X: 34,
  Y: 35,
  Z: 36,
};

export const v5CRemainderValue = {
  0: 0,
  1: 1,
  2: 2,
  3: 3,
  4: 4,
  5: 5,
  6: 6,
  7: 7,
  8: 8,
  9: 9,
  10: "A",
  11: "B",
  12: "C",
  13: "D",
  14: "E",
  15: "F",
  16: "G",
  17: "H",
  18: "-",
  19: "J",
  20: "K",
  21: "L",
  22: "M",
  23: "N",
  24: "+",
  25: "P",
  26: "Q",
  27: "R",
  28: "/",
  29: "T",
  30: "U",
  31: "V",
  32: "W",
  33: "X",
  34: "Y",
  35: "Z",
  36: "%",
};

export const v5CExpandedMark = {
  "/^[A-Z]{2}[0-9]$/": "#AA##9#",
  "/^[A-Z]{3}[0-9]$/": "AAA##9#",
  "/^[0-9][A-Z]{2}$/": "0009#AA",
  "/^[0-9][A-Z]{3}$/": "0009AAA",
  "/^[A-Z]{2}[0-9]{2}$/": "#AA##99",
  "/^[0-9]{2}[A-Z]{2}$/": "0099#AA",
  "/^[A-Z][0-9][A-Z]{3}$/": "A##9AAA",
  "/^[A-Z]{3}[0-9][A-Z]$/": "AAA##9Y",
  "/^[A-Z]{3}[0-9]{2}$/": "AAA#99#",
  "/^[A-Z]{2}[0-9]{3}$/": "#AA#999",
  "/^[0-9]{2}[A-Z]{3}$/": "0099AAA",
  "/^[0-9]{3}[A-Z]{2}$/": "0999#AA",
  "/^[0-9]{4}[A-Z]{2}$/": "9999#AA",
  "/^[A-Z][0-9]{2}[A-Z]{3}$/": "A#99AAA",
  "/^[A-Z]{3}[0-9]{2}[A-Z]$/": "AAA#99Y",
  "/^[A-Z]{3}[0-9]{3}$/": "AAA999#",
  "/^[0-9]{3}[A-Z]{3}$/": "0999AAA",
  "/^[A-Z]{2}[0-9]{4}$/": "#AA9999",
  "/^[A-Z][0-9]{3}[A-Z]{2}$/": "",
  "/^[A-Z][0-9]{3}[A-Z]{3}$/": "A999AAA",
  "/^[A-Z]{3}[0-9]{3}[A-Z]$/": "AAA999Y",
  "/^[A-Z][0-9]$/": "##A##9#",
  "/^[A-Z][0-9]{2}$/": "##A#99#",
  "/^[A-Z][0-9]{3}$/": "##A999#",
  "/^[A-Z][0-9]{4}$/": "##A9999",
  "/^[A-Z]{3}[0-9]{4}$/": "AAA9999",
  "/^[0-9][A-Z]$/": "0009##A",
  "/^[0-9]{2}[A-Z]$/": "0099##A",
  "/^[0-9]{3}[A-Z]$/": "0999##A",
  "/^[0-9]{4}[A-Z]$/": "9999##A",
  "/^[A-Z]{2}[0-9]{2}[A-Z]{3}$/": "AA99AAA",
};
