import { RedeemVoucherRequest, RedeemVoucherResponse } from "../openapi/imovo-api";

import { axiosClient } from "../lib/axiosWrapper";
import { CacheTypes } from "../lib/cache/index";
import { Client, Props, Enquiry } from "./types";

const cacheKey = "_enquiryData";
const cacheRedeemKey = "_redeemData";

export const buildClient = (props: Props): Client => {
  const { rootUrl, authHeaders } = props;

  const getEnquiry = async (): Promise<Enquiry> => {
    const response = await axiosClient
      .get<Enquiry>(
        { url: `${rootUrl}/i-movo/enquiry`, headers: await authHeaders() },
        { type: CacheTypes.TIMED, ttl: 1, key: `${cacheKey}/test-invoke-stage` }
      )
      .then((res) => {
        console.log("imovo API response", res);
        return res;
      })
      //eslint-disable-next-line
      .catch((err) => {
        console.log("imovo API error", err);
        return { SuccessfulRequest: false };
      });

    return response;
  };

  const getRedeem = async (params: RedeemVoucherRequest): Promise<RedeemVoucherResponse> => {
    const response = await axiosClient
      .get<RedeemVoucherResponse>(
        { url: `${rootUrl}/i-movo/redeem`, params, headers: await authHeaders() },
        { type: CacheTypes.TIMED, ttl: 1, key: `${cacheRedeemKey}/test-invoke-stage` }
      )
      .then((res) => {
        console.log("imovo API redeem endpoint response", res);
        return res;
      })
      //eslint-disable-next-line
      .catch((err) => {
        console.log("imovo API redeem endpoint API error", err);
        return { SuccessfulRequest: false };
      });

    return response;
  };

  return Object.freeze({ getEnquiry, getRedeem });
};
