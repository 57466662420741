// REMOVE ME: once API start giving correct name mapping
const pfcaMappings = {
  RM616: "pfca",
  RM617: "pfca",
  RM618: "pfca",
  RM619: "pfca",
  RM620: "pfca",
  RM621: "pfca",
  RM622: "pfca",
  RM623: "pfca",
  RM624: "pfca",
  RM625: "pfca",
  RM626: "pfca",
  RM627: "pfca",
  RM628: "pfca",
  RM629: "pfca",
  RM630: "pfca",
  RM631: "pfca",
  RM632: "pfca",
  RM633: "pfca",
  RM634: "pfca",
  RM635: "pfca",
  RM720: "pfca",
  RM721: "pfca",
};

const RMLocalCollectMapping = {
  "PS-RM101": "RMLocalCollect",
  "PS-RM102": "RMLocalCollect",
  "PS-RM103": "RMLocalCollect",
  "PS-RM104": "RMLocalCollect",
  "PS-RM11": "RMLocalCollect",
  "PS-RM111": "RMLocalCollect",
  "PS-RM112": "RMLocalCollect",
  "PS-RM113": "RMLocalCollect",
  "PS-RM114": "RMLocalCollect",
  "PS-RM12": "RMLocalCollect",
  "PS-RM121": "RMLocalCollect",
  "PS-RM122": "RMLocalCollect",
  "PS-RM123": "RMLocalCollect",
  "PS-RM124": "RMLocalCollect",
  "PS-RM13": "RMLocalCollect",
  "PS-RM131": "RMLocalCollect",
  "PS-RM132": "RMLocalCollect",
  "PS-RM134": "RMLocalCollect",
  "PS-RM14": "RMLocalCollect",
  "PS-RM142": "RMLocalCollect",
  "PS-RM143": "RMLocalCollect",
  "PS-RM151": "RMLocalCollect",
  "PS-RM152": "RMLocalCollect",
  "PS-RM153": "RMLocalCollect",
  "PS-RM154": "RMLocalCollect",
  "PS-RM163": "RMLocalCollect",
  "PS-RM164": "RMLocalCollect",
  "PS-RM171": "RMLocalCollect",
  "PS-RM173": "RMLocalCollect",
  "PS-RM174": "RMLocalCollect",
  "PS-RM181": "RMLocalCollect",
  "PS-RM182": "RMLocalCollect",
  "PS-RM183": "RMLocalCollect",
  "PS-RM184": "RMLocalCollect",
  "PS-RM191": "RMLocalCollect",
  "PS-RM192": "RMLocalCollect",
  "PS-RM193": "RMLocalCollect",
  "PS-RM194": "RMLocalCollect",
  "PS-RM201": "RMLocalCollect",
  "PS-RM202": "RMLocalCollect",
  "PS-RM203": "RMLocalCollect",
  "PS-RM204": "RMLocalCollect",
  "PS-RM211": "RMLocalCollect",
  "PS-RM212": "RMLocalCollect",
  "PS-RM213": "RMLocalCollect",
  "PS-RM214": "RMLocalCollect",
  "PS-RM22": "RMLocalCollect",
  "PS-RM221": "RMLocalCollect",
  "PS-RM222": "RMLocalCollect",
  "PS-RM223": "RMLocalCollect",
  "PS-RM224": "RMLocalCollect",
  "PS-RM23": "RMLocalCollect",
  "PS-RM231": "RMLocalCollect",
  "PS-RM232": "RMLocalCollect",
  "PS-RM233": "RMLocalCollect",
  "PS-RM234": "RMLocalCollect",
  "PS-RM24": "RMLocalCollect",
  "PS-RM241": "RMLocalCollect",
  "PS-RM242": "RMLocalCollect",
  "PS-RM243": "RMLocalCollect",
  "PS-RM244": "RMLocalCollect",
  "PS-RM251": "RMLocalCollect",
  "PS-RM252": "RMLocalCollect",
  "PS-RM253": "RMLocalCollect",
  "PS-RM254": "RMLocalCollect",
  "PS-RM255": "RMLocalCollect",
  "PS-RM261": "RMLocalCollect",
  "PS-RM262": "RMLocalCollect",
  "PS-RM263": "RMLocalCollect",
  "PS-RM264": "RMLocalCollect",
  "PS-RM271": "RMLocalCollect",
  "PS-RM272": "RMLocalCollect",
  "PS-RM273": "RMLocalCollect",
  "PS-RM274": "RMLocalCollect",
  "PS-RM281": "RMLocalCollect",
  "PS-RM282": "RMLocalCollect",
  "PS-RM283": "RMLocalCollect",
  "PS-RM284": "RMLocalCollect",
  "PS-RM291": "RMLocalCollect",
  "PS-RM292": "RMLocalCollect",
  "PS-RM293": "RMLocalCollect",
  "PS-RM294": "RMLocalCollect",
  "PS-RM301": "RMLocalCollect",
  "PS-RM302": "RMLocalCollect",
  "PS-RM303": "RMLocalCollect",
  "PS-RM304": "RMLocalCollect",
  "PS-RM31": "RMLocalCollect",
  "PS-RM311": "RMLocalCollect",
  "PS-RM312": "RMLocalCollect",
  "PS-RM313": "RMLocalCollect",
  "PS-RM314": "RMLocalCollect",
  "PS-RM321": "RMLocalCollect",
  "PS-RM322": "RMLocalCollect",
  "PS-RM323": "RMLocalCollect",
  "PS-RM324": "RMLocalCollect",
  "PS-RM331": "RMLocalCollect",
  "PS-RM332": "RMLocalCollect",
  "PS-RM333": "RMLocalCollect",
  "PS-RM334": "RMLocalCollect",
  "PS-RM341": "RMLocalCollect",
  "PS-RM342": "RMLocalCollect",
  "PS-RM343": "RMLocalCollect",
  "PS-RM344": "RMLocalCollect",
  "PS-RM351": "RMLocalCollect",
  "PS-RM352": "RMLocalCollect",
  "PS-RM353": "RMLocalCollect",
  "PS-RM354": "RMLocalCollect",
  "PS-RM361": "RMLocalCollect",
  "PS-RM362": "RMLocalCollect",
  "PS-RM363": "RMLocalCollect",
  "PS-RM364": "RMLocalCollect",
  "PS-RM365": "RMLocalCollect",
  "PS-RM366": "RMLocalCollect",
  "PS-RM371": "RMLocalCollect",
  "PS-RM372": "RMLocalCollect",
  "PS-RM373": "RMLocalCollect",
  "PS-RM374": "RMLocalCollect",
  "PS-RM375": "RMLocalCollect",
  "PS-RM376": "RMLocalCollect",
  "PS-RM377": "RMLocalCollect",
  "PS-RM378": "RMLocalCollect",
  "PS-RM379": "RMLocalCollect",
  "PS-RM381": "RMLocalCollect",
  "PS-RM382": "RMLocalCollect",
  "PS-RM383": "RMLocalCollect",
  "PS-RM384": "RMLocalCollect",
  "PS-RM385": "RMLocalCollect",
  "PS-RM386": "RMLocalCollect",
  "PS-RM387": "RMLocalCollect",
  "PS-RM388": "RMLocalCollect",
  "PS-RM389": "RMLocalCollect",
  "PS-RM391": "RMLocalCollect",
  "PS-RM401": "RMLocalCollect",
  "PS-RM402": "RMLocalCollect",
  "PS-RM403": "RMLocalCollect",
  "PS-RM411": "RMLocalCollect",
  "PS-RM412": "RMLocalCollect",
  "PS-RM413": "RMLocalCollect",
  "PS-RM414": "RMLocalCollect",
  "PS-RM415": "RMLocalCollect",
  "PS-RM416": "RMLocalCollect",
  "PS-RM417": "RMLocalCollect",
  "PS-RM418": "RMLocalCollect",
  "PS-RM419": "RMLocalCollect",
  "PS-RM421": "RMLocalCollect",
  "PS-RM422": "RMLocalCollect",
  "PS-RM423": "RMLocalCollect",
  "PS-RM424": "RMLocalCollect",
  "PS-RM425": "RMLocalCollect",
  "PS-RM426": "RMLocalCollect",
  "PS-RM427": "RMLocalCollect",
  "PS-RM428": "RMLocalCollect",
  "PS-RM429": "RMLocalCollect",
  "PS-RM431": "RMLocalCollect",
  "PS-RM432": "RMLocalCollect",
  "PS-RM433": "RMLocalCollect",
  "PS-RM434": "RMLocalCollect",
  "PS-RM435": "RMLocalCollect",
  "PS-RM436": "RMLocalCollect",
  "PS-RM437": "RMLocalCollect",
  "PS-RM438": "RMLocalCollect",
  "PS-RM439": "RMLocalCollect",
  "PS-RM441": "RMLocalCollect",
  "PS-RM442": "RMLocalCollect",
  "PS-RM443": "RMLocalCollect",
  "PS-RM444": "RMLocalCollect",
  "PS-RM445": "RMLocalCollect",
  "PS-RM446": "RMLocalCollect",
  "PS-RM447": "RMLocalCollect",
  "PS-RM448": "RMLocalCollect",
  "PS-RM449": "RMLocalCollect",
  "PS-RM451": "RMLocalCollect",
  "PS-RM452": "RMLocalCollect",
  "PS-RM453": "RMLocalCollect",
  "PS-RM454": "RMLocalCollect",
  "PS-RM455": "RMLocalCollect",
  "PS-RM456": "RMLocalCollect",
  "PS-RM457": "RMLocalCollect",
  "PS-RM458": "RMLocalCollect",
  "PS-RM459": "RMLocalCollect",
  "PS-RM461": "RMLocalCollect",
  "PS-RM462": "RMLocalCollect",
  "PS-RM463": "RMLocalCollect",
  "PS-RM464": "RMLocalCollect",
  "PS-RM465": "RMLocalCollect",
  "PS-RM466": "RMLocalCollect",
  "PS-RM467": "RMLocalCollect",
  "PS-RM468": "RMLocalCollect",
  "PS-RM469": "RMLocalCollect",
  "PS-RM471": "RMLocalCollect",
  "PS-RM472": "RMLocalCollect",
  "PS-RM481": "RMLocalCollect",
  "PS-RM482": "RMLocalCollect",
  "PS-RM483": "RMLocalCollect",
  "PS-RM484": "RMLocalCollect",
  "PS-RM485": "RMLocalCollect",
  "PS-RM486": "RMLocalCollect",
  "PS-RM487": "RMLocalCollect",
  "PS-RM488": "RMLocalCollect",
  "PS-RM489": "RMLocalCollect",
  "PS-RM491": "RMLocalCollect",
  "PS-RM492": "RMLocalCollect",
  "PS-RM493": "RMLocalCollect",
  "PS-RM494": "RMLocalCollect",
  "PS-RM495": "RMLocalCollect",
  "PS-RM496": "RMLocalCollect",
  "PS-RM497": "RMLocalCollect",
  "PS-RM498": "RMLocalCollect",
  "PS-RM499": "RMLocalCollect",
  "PS-RM501": "RMLocalCollect",
  "PS-RM502": "RMLocalCollect",
  "PS-RM503": "RMLocalCollect",
  "PS-RM51": "RMLocalCollect",
  "PS-RM511": "RMLocalCollect",
  "PS-RM512": "RMLocalCollect",
  "PS-RM513": "RMLocalCollect",
  "PS-RM514": "RMLocalCollect",
  "PS-RM515": "RMLocalCollect",
  "PS-RM52": "RMLocalCollect",
  "PS-RM521": "RMLocalCollect",
  "PS-RM53": "RMLocalCollect",
  "PS-RM531": "RMLocalCollect",
  "PS-RM532": "RMLocalCollect",
  "PS-RM533": "RMLocalCollect",
  "PS-RM534": "RMLocalCollect",
  "PS-RM535": "RMLocalCollect",
  "PS-RM536": "RMLocalCollect",
  "PS-RM537": "RMLocalCollect",
  "PS-RM538": "RMLocalCollect",
  "PS-RM539": "RMLocalCollect",
  "PS-RM54": "RMLocalCollect",
  "PS-RM541": "RMLocalCollect",
  "PS-RM542": "RMLocalCollect",
  "PS-RM543": "RMLocalCollect",
  "PS-RM544": "RMLocalCollect",
  "PS-RM545": "RMLocalCollect",
  "PS-RM546": "RMLocalCollect",
  "PS-RM547": "RMLocalCollect",
  "PS-RM548": "RMLocalCollect",
  "PS-RM551": "RMLocalCollect",
  "PS-RM561": "RMLocalCollect",
  "PS-RM562": "RMLocalCollect",
  "PS-RM563": "RMLocalCollect",
  "PS-RM564": "RMLocalCollect",
  "PS-RM565": "RMLocalCollect",
  "PS-RM566": "RMLocalCollect",
  "PS-RM567": "RMLocalCollect",
  "PS-RM568": "RMLocalCollect",
  "PS-RM569": "RMLocalCollect",
  "PS-RM571": "RMLocalCollect",
  "PS-RM581": "RMLocalCollect",
  "PS-RM582": "RMLocalCollect",
  "PS-RM583": "RMLocalCollect",
  "PS-RM591": "RMLocalCollect",
  "PS-RM592": "RMLocalCollect",
  "PS-RM593": "RMLocalCollect",
  "PS-RM594": "RMLocalCollect",
  "PS-RM601": "RMLocalCollect",
  "PS-RM602": "RMLocalCollect",
  "PS-RM603": "RMLocalCollect",
  "PS-RM604": "RMLocalCollect",
  "PS-RM605": "RMLocalCollect",
  "PS-RM606": "RMLocalCollect",
  "PS-RM607": "RMLocalCollect",
  "PS-RM608": "RMLocalCollect",
  "PS-RM609": "RMLocalCollect",
  "PS-RM61": "RMLocalCollect",
  "PS-RM611": "RMLocalCollect",
  "PS-RM612": "RMLocalCollect",
  "PS-RM613": "RMLocalCollect",
  "PS-RM614": "RMLocalCollect",
  "PS-RM62": "RMLocalCollect",
  "PS-RM621": "RMLocalCollect",
  "PS-RM622": "RMLocalCollect",
  "PS-RM63": "RMLocalCollect",
  "PS-RM631": "RMLocalCollect",
  "PS-RM632": "RMLocalCollect",
  "PS-RM633": "RMLocalCollect",
  "PS-RM634": "RMLocalCollect",
  "PS-RM635": "RMLocalCollect",
  "PS-RM636": "RMLocalCollect",
  "PS-RM637": "RMLocalCollect",
  "PS-RM638": "RMLocalCollect",
  "PS-RM639": "RMLocalCollect",
  "PS-RM64": "RMLocalCollect",
  "PS-RM641": "RMLocalCollect",
  "PS-RM642": "RMLocalCollect",
  "PS-RM643": "RMLocalCollect",
  "PS-RM651": "RMLocalCollect",
  "PS-RM652": "RMLocalCollect",
  "PS-RM661": "RMLocalCollect",
  "PS-RM662": "RMLocalCollect",
  "PS-RM663": "RMLocalCollect",
  "PS-RM664": "RMLocalCollect",
  "PS-RM665": "RMLocalCollect",
  "PS-RM666": "RMLocalCollect",
  "PS-RM667": "RMLocalCollect",
  "PS-RM668": "RMLocalCollect",
  "PS-RM669": "RMLocalCollect",
  "PS-RM701": "RMLocalCollect",
  "PS-RM71": "RMLocalCollect",
  "PS-RM711": "RMLocalCollect",
  "PS-RM72": "RMLocalCollect",
  "PS-RM721": "RMLocalCollect",
  "PS-RM722": "RMLocalCollect",
  "PS-RM73": "RMLocalCollect",
  "PS-RM731": "RMLocalCollect",
  "PS-RM732": "RMLocalCollect",
  "PS-RM74": "RMLocalCollect",
  "PS-RM741": "RMLocalCollect",
  "PS-RM742": "RMLocalCollect",
  "PS-RM751": "RMLocalCollect",
  "PS-RM752": "RMLocalCollect",
  "PS-RM753": "RMLocalCollect",
  "PS-RM754": "RMLocalCollect",
  "PS-RM755": "RMLocalCollect",
  "PS-RM756": "RMLocalCollect",
  "PS-RM757": "RMLocalCollect",
  "PS-RM758": "RMLocalCollect",
  "PS-RM759": "RMLocalCollect",
  "PS-RM761": "RMLocalCollect",
  "PS-RM762": "RMLocalCollect",
  "PS-RM763": "RMLocalCollect",
  "PS-RM764": "RMLocalCollect",
  "PS-RM765": "RMLocalCollect",
  "PS-RM766": "RMLocalCollect",
  "PS-RM767": "RMLocalCollect",
  "PS-RM768": "RMLocalCollect",
  "PS-RM769": "RMLocalCollect",
  "PS-RM771": "RMLocalCollect",
  "PS-RM772": "RMLocalCollect",
  "PS-RM773": "RMLocalCollect",
  "PS-RM774": "RMLocalCollect",
  "PS-RM775": "RMLocalCollect",
  "PS-RM776": "RMLocalCollect",
  "PS-RM777": "RMLocalCollect",
  "PS-RM778": "RMLocalCollect",
  "PS-RM779": "RMLocalCollect",
  "PS-RM781": "RMLocalCollect",
  "PS-RM782": "RMLocalCollect",
  "PS-RM783": "RMLocalCollect",
  "PS-RM784": "RMLocalCollect",
  "PS-RM785": "RMLocalCollect",
  "PS-RM786": "RMLocalCollect",
  "PS-RM787": "RMLocalCollect",
  "PS-RM788": "RMLocalCollect",
  "PS-RM789": "RMLocalCollect",
  "PS-RM791": "RMLocalCollect",
  "PS-RM792": "RMLocalCollect",
  "PS-RM793": "RMLocalCollect",
  "PS-RM794": "RMLocalCollect",
  "PS-RM795": "RMLocalCollect",
  "PS-RM796": "RMLocalCollect",
  "PS-RM797": "RMLocalCollect",
  "PS-RM798": "RMLocalCollect",
  "PS-RM799": "RMLocalCollect",
  "PS-RM801": "RMLocalCollect",
  "PS-RM802": "RMLocalCollect",
  "PS-RM803": "RMLocalCollect",
  "PS-RM804": "RMLocalCollect",
  "PS-RM805": "RMLocalCollect",
  "PS-RM806": "RMLocalCollect",
  "PS-RM807": "RMLocalCollect",
  "PS-RM808": "RMLocalCollect",
  "PS-RM809": "RMLocalCollect",
  "PS-RM81": "RMLocalCollect",
  "PS-RM82": "RMLocalCollect",
  "PS-RM83": "RMLocalCollect",
  "PS-RM84": "RMLocalCollect",
  "PS-RM901": "RMLocalCollect",
  "PS-RM902": "RMLocalCollect",
  "PS-RM903": "RMLocalCollect",
  "PS-RM904": "RMLocalCollect",
  "PS-RM905": "RMLocalCollect",
  "PS-RM906": "RMLocalCollect",
  "PS-RM907": "RMLocalCollect",
  "PS-RM908": "RMLocalCollect",
  "PS-RM909": "RMLocalCollect",
  "PS-RM91": "RMLocalCollect",
  "PS-RM911": "RMLocalCollect",
  "PS-RM912": "RMLocalCollect",
  "PS-RM913": "RMLocalCollect",
  "PS-RM914": "RMLocalCollect",
  "PS-RM915": "RMLocalCollect",
  "PS-RM916": "RMLocalCollect",
  "PS-RM917": "RMLocalCollect",
  "PS-RM918": "RMLocalCollect",
  "PS-RM919": "RMLocalCollect",
  "PS-RM92": "RMLocalCollect",
  "PS-RM921": "RMLocalCollect",
  "PS-RM922": "RMLocalCollect",
  "PS-RM923": "RMLocalCollect",
  "PS-RM924": "RMLocalCollect",
  "PS-RM925": "RMLocalCollect",
  "PS-RM926": "RMLocalCollect",
  "PS-RM927": "RMLocalCollect",
  "PS-RM928": "RMLocalCollect",
  "PS-RM929": "RMLocalCollect",
  "PS-RM93": "RMLocalCollect",
  "PS-RM931": "RMLocalCollect",
  "PS-RM932": "RMLocalCollect",
  "PS-RM933": "RMLocalCollect",
  "PS-RM934": "RMLocalCollect",
  "PS-RM935": "RMLocalCollect",
  "PS-RM936": "RMLocalCollect",
  "PS-RM937": "RMLocalCollect",
  "PS-RM938": "RMLocalCollect",
  "PS-RM939": "RMLocalCollect",
  "PS-RM94": "RMLocalCollect",
  "PS-RM941": "RMLocalCollect",
  "PS-RM942": "RMLocalCollect",
  "PS-RM943": "RMLocalCollect",
  "PS-RM944": "RMLocalCollect",
  "PS-RM945": "RMLocalCollect",
  "PS-RM946": "RMLocalCollect",
  "PS-RM947": "RMLocalCollect",
  "PS-RM948": "RMLocalCollect",
  "PS-RM949": "RMLocalCollect",
  "PS-RM951": "RMLocalCollect",
  "PS-RM952": "RMLocalCollect",
  "PS-RM953": "RMLocalCollect",
  "PS-RM954": "RMLocalCollect",
  "PS-RM956": "RMLocalCollect",
  "PS-RM957": "RMLocalCollect",
  "PS-RM958": "RMLocalCollect",
  "PS-RM959": "RMLocalCollect",
  "PS-RM961": "RMLocalCollect",
  "PS-RM962": "RMLocalCollect",
  "PS-RM963": "RMLocalCollect",
  "PS-RM964": "RMLocalCollect",
  "PS-RM965": "RMLocalCollect",
  "PS-RM966": "RMLocalCollect",
  "PS-RM967": "RMLocalCollect",
  "PS-RM968": "RMLocalCollect",
  "PS-RM969": "RMLocalCollect",
  "PS-RM971": "RMLocalCollect",
  "PS-RM972": "RMLocalCollect",
  "PS-RM973": "RMLocalCollect",
  "PS-RM974": "RMLocalCollect",
  "PS-RM975": "RMLocalCollect",
  "PS-RM976": "RMLocalCollect",
  "PS-RM977": "RMLocalCollect",
  "PS-RM978": "RMLocalCollect",
  "PS-RM979": "RMLocalCollect",
  "PS-RM981": "RMLocalCollect",
  "PS-RM982": "RMLocalCollect",
  "PS-RM983": "RMLocalCollect",
  "PS-RM984": "RMLocalCollect",
  "PS-RM985": "RMLocalCollect",
  "PS-RM986": "RMLocalCollect",
};

const PFLocalCollectMapping = {
  "PS-PF101": "PFLocalCollect",
  "PS-PF102": "PFLocalCollect",
  "PS-PF103": "PFLocalCollect",
  "PS-PF104": "PFLocalCollect",
  "PS-PF11": "PFLocalCollect",
  "PS-PF111": "PFLocalCollect",
  "PS-PF112": "PFLocalCollect",
  "PS-PF113": "PFLocalCollect",
  "PS-PF114": "PFLocalCollect",
  "PS-PF12": "PFLocalCollect",
  "PS-PF121": "PFLocalCollect",
  "PS-PF122": "PFLocalCollect",
  "PS-PF123": "PFLocalCollect",
  "PS-PF124": "PFLocalCollect",
  "PS-PF13": "PFLocalCollect",
  "PS-PF131": "PFLocalCollect",
  "PS-PF132": "PFLocalCollect",
  "PS-PF133": "PFLocalCollect",
  "PS-PF134": "PFLocalCollect",
  "PS-PF14": "PFLocalCollect",
  "PS-PF141": "PFLocalCollect",
  "PS-PF142": "PFLocalCollect",
  "PS-PF143": "PFLocalCollect",
  "PS-PF144": "PFLocalCollect",
  "PS-PF151": "PFLocalCollect",
  "PS-PF152": "PFLocalCollect",
  "PS-PF153": "PFLocalCollect",
  "PS-PF154": "PFLocalCollect",
  "PS-PF161": "PFLocalCollect",
  "PS-PF162": "PFLocalCollect",
  "PS-PF163": "PFLocalCollect",
  "PS-PF164": "PFLocalCollect",
  "PS-PF171": "PFLocalCollect",
  "PS-PF172": "PFLocalCollect",
  "PS-PF173": "PFLocalCollect",
  "PS-PF174": "PFLocalCollect",
  "PS-PF181": "PFLocalCollect",
  "PS-PF182": "PFLocalCollect",
  "PS-PF183": "PFLocalCollect",
  "PS-PF184": "PFLocalCollect",
  "PS-PF191": "PFLocalCollect",
  "PS-PF192": "PFLocalCollect",
  "PS-PF193": "PFLocalCollect",
  "PS-PF194": "PFLocalCollect",
  "PS-PF195": "PFLocalCollect",
  "PS-PF196": "PFLocalCollect",
  "PS-PF197": "PFLocalCollect",
  "PS-PF201": "PFLocalCollect",
  "PS-PF21": "PFLocalCollect",
  "PS-PF22": "PFLocalCollect",
  "PS-PF23": "PFLocalCollect",
  "PS-PF24": "PFLocalCollect",
  "PS-PF31": "PFLocalCollect",
  "PS-PF32": "PFLocalCollect",
  "PS-PF33": "PFLocalCollect",
  "PS-PF34": "PFLocalCollect",
  "PS-PF41": "PFLocalCollect",
  "PS-PF42": "PFLocalCollect",
  "PS-PF43": "PFLocalCollect",
  "PS-PF44": "PFLocalCollect",
  "PS-PF51": "PFLocalCollect",
  "PS-PF52": "PFLocalCollect",
  "PS-PF53": "PFLocalCollect",
  "PS-PF54": "PFLocalCollect",
  "PS-PF61": "PFLocalCollect",
  "PS-PF62": "PFLocalCollect",
  "PS-PF63": "PFLocalCollect",
  "PS-PF64": "PFLocalCollect",
  "PS-PF71": "PFLocalCollect",
  "PS-PF72": "PFLocalCollect",
  "PS-PF73": "PFLocalCollect",
  "PS-PF74": "PFLocalCollect",
  "PS-PF81": "PFLocalCollect",
  "PS-PF82": "PFLocalCollect",
  "PS-PF83": "PFLocalCollect",
  "PS-PF84": "PFLocalCollect",
  "PS-PF91": "PFLocalCollect",
  "PS-PF92": "PFLocalCollect",
  "PS-PF93": "PFLocalCollect",
  "PS-PF94": "PFLocalCollect",
};

const jgb61dBarcodeMapping = {
  RM754: "jgb61d",
  RM606: "jgb61d",
  RM607: "jgb61d",
  RM601: "jgb61d",
  RM749: "jgb61d",
  RM750: "jgb61d",
  RM548: "jgb61d",
  RM608: "jgb61d",
  RM609: "jgb61d",
  RM611: "jgb61d",
  RM602: "jgb61d",
  RM603: "jgb61d",
  RM745: "jgb61d",
  RM753: "jgb61d",
  RM604: "jgb61d",
  RM605: "jgb61d",
  RM612: "jgb61d",
  RM613: "jgb61d",
  RM755: "jgb61d",
  RM756: "jgb61d",
  RM757: "jgb61d",
  RM758: "jgb61d",
  RM751: "jgb61d",
  RM748: "jgb61d",
  RM735: "jgb61d",
  RM343: "jgb61d",
  RM593: "jgb61d",
  RM752: "jgb61d",
  RM521: "jgb61d",
  RM541: "jgb61d",
  RM542: "jgb61d",
  RM543: "jgb61d",
  RM544: "jgb61d",
  RM545: "jgb61d",
  RM546: "jgb61d",
  RM547: "jgb61d",
  RM614: "jgb61d",
};

// TODO: Remove me: once API giving correct data
// Tokeniser api is not giving barcode type
export const BarcodeNameMapping = {
  RM740: "jgb6",
  RM741: "jgb1",
  RM742: "jgb8",
  RM744: "jgb4",
  RM743: "jgb1d",
  AP6376: "Labels2Go",
  ...pfcaMappings,
  ...RMLocalCollectMapping,
  ...PFLocalCollectMapping,
  ...jgb61dBarcodeMapping,
};
