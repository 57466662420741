import { DeviceServerEvents, SupportedServices } from "../../../types";
import { v4 } from "uuid";
import { ScannerResult, ScannerActions } from "../types";

const mockResult = (action: ScannerActions): ScannerResult => {
  return {
    requestId: v4(),
    service: SupportedServices.Scanner,
    action,
    event: DeviceServerEvents.DEVICE_RESPONSE,
    result: {
      connected: true,
    },
    message: "",
  };
};

export const performAction = async (action: ScannerActions): Promise<ScannerResult> => {
  if (action === ScannerActions.Connect) {
    return Promise.resolve(mockResult(action));
  }
  return Promise.resolve({ ...mockResult(action), result: { connected: false } });
};
