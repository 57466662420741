export type AuthProvider = () => Promise<{ Authorization: string }>;

/*
  List of available enabler API clients that can be built.
  Enum is necessary to allow type system to properly overload and link input with output type in factory.
*/
export enum EnablerAPIClientNames {
  bankAccountValidation = "bankAccountValidation",
  paymentsAndBanking = "paymentsAndBanking",
  country = "country",
  despatch = "despatch",
  estimatedDeliveryDate = "estimatedDeliveryDate",
  product = "product",
  tokeniser = "tokeniser",
  travelMoney = "travelMoney",
  insurance = "insurance",
  governmentId = "governmentId",
  postal = "postal",
  postcodeLookup = "postcodeLookup",
  mails = "mails",
  billpay = "billpay",
  boeNotesExchange = "boeNotesExchange",
  pbne = "pbne",
  imovo = "imovo",
  payout = "payout",
  dropandgo = "dropandgo",
  giftcard = "giftcard",
  moneygram = "moneygram",
  fmcc = "fmcc",
  ekycVerification = "ekycVerification",
}

export interface ApiError {
  statusCode: number;
  message?: string;
}

export type RemoveAuthHeaders<T> = {
  [Property in keyof T as Exclude<Property, "authHeaders">]: T[Property];
};
