import { BasketItemPayload, ReceiptTemplate } from "../../types";

export const SMART_METERING_PAID_RECEIPT_TEMPLATE = "bill-pay/paid-receipt-smart-metering";
export const SMART_METERING_FAILED_RECEIPT_TEMPLATE = "bill-pay/failed-smart-metering-receipt"
export const PAID_RECEIPT_PRINTING_MESSAGE = "Wait while the customer receipt is printed.";
export const RECEIPT_FOOTER_MESSAGE =
  "*Please keep this rejected smart metering receipt (if printed) for reconciliation purposes.";
export const FAILED_RECEIPT_PRINTING_MESSAGE = "Wait while the failed receipt is printed.";


export const getPaidReceipts = (basketItem: BasketItemPayload, utrn: string): ReceiptTemplate => {
  const { fulfilment } = basketItem;
  const receipt = {
    template: SMART_METERING_PAID_RECEIPT_TEMPLATE,
    context: {
      value: basketItem.valueInPence,
      branchId: basketItem?.fulfilment?.branchID,
      ...fulfilment,
      utrnNo: utrn,
    },
    printingMessage: PAID_RECEIPT_PRINTING_MESSAGE,
  };
  return receipt;
};

export const getFailedReceipts = (basketItem: BasketItemPayload): ReceiptTemplate => {
  const { fulfilment } = basketItem;
  const receipt = {
    template: SMART_METERING_FAILED_RECEIPT_TEMPLATE,
    context: {
      value: basketItem.valueInPence,
      branchId: basketItem?.fulfilment?.branchID,
      ...fulfilment,      
      message: RECEIPT_FOOTER_MESSAGE
    },
    printingMessage: FAILED_RECEIPT_PRINTING_MESSAGE,
  };
  return receipt;
};
