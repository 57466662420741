/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import moment from "moment";
import { PedResult, PedError, formatCurrencyAmount } from "postoffice-peripheral-management-service";
import { FulfillmentItem, ReceiptTemplate } from "../../types";
import { PEDFulfillerActions } from "./types";

// online banking journey receipt
export const ONLINE_BANKING_RECEIPT_TEMPLATE = "banking/v2/online";
export const ONLINE_BANKING_FAILED_CASH_DEPOSIT_RECEIPT_TEMPLATE = "banking/v2/online-merchant-failed-cash-deposit";

export const CUSTOMER_PRINTING_MESSAGE = "Wait while the customer's receipt is printed.";
export const BRANCH_PRINTING_MESSAGE_FAILED_DEPOSIT =
  "Wait while the branch receipt is printed, for the customer to sign.";
export const FAILED_CASH_DEPOSIT_CLERK_CONFIRMATION_PROMPT_TEXT = `The transaction has been unsuccessful.\n\nPresent the receipt to the customer to sign. Then retain this receipt in branch for 2 months, return the cash to the customer, and press confirm.\n\nThe customer declined receipt will then print.`;

export type ReceiptTemplates = {
  [key: string]: string;
};

export const ReceiptTemplates: ReceiptTemplates = {
  [PEDFulfillerActions.BalanceEnquiry]: ONLINE_BANKING_RECEIPT_TEMPLATE,
  [PEDFulfillerActions.CashDeposit]: ONLINE_BANKING_RECEIPT_TEMPLATE,
  [PEDFulfillerActions.CashWithdrawal]: ONLINE_BANKING_RECEIPT_TEMPLATE,
  [PEDFulfillerActions.PinChange]: ONLINE_BANKING_RECEIPT_TEMPLATE,
  [PEDFulfillerActions.Debit]: ONLINE_BANKING_RECEIPT_TEMPLATE,
  [PEDFulfillerActions.Refund]: ONLINE_BANKING_RECEIPT_TEMPLATE,
};

export const getReceipts = (
  item: FulfillmentItem,
  action: PEDFulfillerActions,
  result: PedResult | PedError
): ReceiptTemplate[] => {
  let receipts: ReceiptTemplate[] = [];

  const defaultReceiptContext = {
    date: moment().format("DD/MM/YYYY"),
    time: moment().format("HH:mm:ss"),
    productName: item.basketItem?.tokens?.receiptItemName,
    textMode: true,
  };

  // if we've had a failed cash deposit, we need to print a receipt
  // for the customer to confirm they've been handed their cash back
  // this is accompanied by a clerk confirmation with instructions
  if (action === PEDFulfillerActions.CashDeposit && !result.approved) {
    receipts = [
      {
        template: ONLINE_BANKING_FAILED_CASH_DEPOSIT_RECEIPT_TEMPLATE,
        printingMessage: BRANCH_PRINTING_MESSAGE_FAILED_DEPOSIT,
        context: {
          ...defaultReceiptContext,
          merchantTicket: result.merchantTicket,
          amount: formatCurrencyAmount(item.basketItem.valueInPence, "GBP"),
        },
      },
      {
        template: ONLINE_BANKING_RECEIPT_TEMPLATE,
        printingMessage: `${CUSTOMER_PRINTING_MESSAGE}\n\nReturn cash and card with receipt.`,
        clerkConfirmation: {
          title: "MSG40057 - Transaction Declined - Signature Required",
          description: FAILED_CASH_DEPOSIT_CLERK_CONFIRMATION_PROMPT_TEXT,
          actionTitle: "Confirm",
        },
        context: {
          customerTicket: result.customerTicket,
          ...defaultReceiptContext,
        },
      },
    ];

    return receipts;
  }

  if (ReceiptTemplates[action]) {
    const debitOrRefundSuccess =
      (action === PEDFulfillerActions.Debit || action === PEDFulfillerActions.Refund) && result.approved;

    if (debitOrRefundSuccess) {
      return [];
    }
    receipts = [
      {
        template: ONLINE_BANKING_RECEIPT_TEMPLATE,
        printingMessage: CUSTOMER_PRINTING_MESSAGE,
        context: {
          customerTicket: result.customerTicket,
          ...defaultReceiptContext,
        },
      },
    ];
  }

  return receipts;
};
