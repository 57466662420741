import { PedError, PedResult, setFallbackMessaging, TransactionTypes } from "postoffice-peripheral-management-service";
import { FulfillmentItem } from "../../types";
import { NO_CONNECTIVITY_PROMPT } from "./prompts";
import { getReceipts } from "./receipts";
import { PEDFulfillerActions } from "./types";

export const LEGACY_PBS_FIELDS = {
  authorisation_code: " ",
  bankTransactionID: " ",
  merchantNumber: " ",
  routingGateway: " ",
  agentSlaInfo: " ",
  counterSlaInfo: " ",
  bankResponse: " ",
  bankValueFlag: " ",
  financialTransaction: " ",
};

/**
 * Get connectivity failure content
 * @param result PedResult | PedError
 * @param item FulfillmentItem
 * @param action PEDFulfillerActions
 * @param receiptContent string
 * @returns
 */
export const getConnectivityFailureContent = (
  item: FulfillmentItem,
  action: PEDFulfillerActions,
  receiptContent: string
) => {
  return {
    result: {},
    notice: NO_CONNECTIVITY_PROMPT,
    receipts: getReceipts(item, action, {
      responseCode: "",
      combinedCode: "",
      customerTicket: receiptContent,
      merchantTicket: receiptContent,
    }),
    networkError: true,
  };
};

/**
 * Get critical failure content
 * @param isPaymentTransaction boolean
 * @param item FulfillmentItem
 * @param action PEDFulfillerActions
 * @param receiptContent string
 * @returns
 */
export const getCriticalFailureContent = (
  isPaymentTransaction: boolean,
  item: FulfillmentItem,
  action: PEDFulfillerActions,
  receiptContent: string
) => {
  return {
    result: {},
    notice: setFallbackMessaging(isPaymentTransaction ? TransactionTypes.Payments : TransactionTypes.Banking),
    receipts: getReceipts(item, action, {
      responseCode: "",
      combinedCode: "",
      customerTicket: receiptContent,
      merchantTicket: receiptContent,
    }),
  };
};

/**
 * Map fulfilment tokens.
 * @param item FulfillmentItem
 * @param result PedResult | PedError
 * @param transactionId string
 * @returns
 */
export const mapFulfilmentTokens = (
  item: FulfillmentItem,
  result: PedResult | PedError,
  transactionId: string
): Record<string, unknown> => {
  return {
    ...LEGACY_PBS_FIELDS,
    pan: result.tokenisedPan ?? item?.basketItem?.tokens?.pan,
    responseCode: result.metadata?.responseCode,
    transactionResultCode: result.metadata?.resultCode,
    transactionType: result.metadata?.transactionType,
    horizonTransactionID: transactionId,
    cardImpounded: result.metadata?.cardImpounded ? "Y" : "N",
    acquirerResponseCode: result.acquirerResponseCode ?? "n/a",
    methodOfEntry: (result.entryMode as string) ?? "n/a",
    ...(result.paymentId && {
      paymentId: result.paymentId,
    }),
  };
};
