import { AuthProvider } from "../types";

export interface Client {
  getFeatureToggles(): Promise<FeatureToggles>;
  getFeatureToggle(props: GetFeatureToggleParams): Promise<boolean>;
  getSellCurrencies(sellCurrenciesProps: SellCurrenciesProps): Promise<SellCurrencies>;
  isFirstTransactionFor(isFirstTransactionForProps: IsFirstTransactionForProps): Promise<boolean>;
  getExchangeRates(exchangeRatesProps: ExchangeRatesProps): Promise<ExchangeRates>;
}

export type Props = {
  rootUrl: string;
  authHeaders: AuthProvider;
};

export type IsFirstTransactionForProps = {
  userId: string;
  transactionType: string;
};

export type SellCurrenciesProps = {
  branchId: string;
};

export type GetFeatureToggleParams = {
  toggleKey: string;
  defaultToggleState?: boolean;
};

export type ExchangeRatesProps = {
  branchId: number;
  currency: string;
  isSterlingAmount: boolean;
  amount: number;
  customerSuppliedRate?: number;
};

export type GetExchangeRateParams = {
  isSterlingAmount: boolean;
  amount: number;
  customerSuppliedRate?: number;
};

export interface ExchangeRateProfile {
  spotRate: number;
  effectiveRate: number;
  fxValue: number;
  gbpValue: number;
  commission: number;
  margin: number;
  disabled?: boolean;
}

export interface ExchangeRates {
  recommended: ExchangeRateProfile;
  upsell: ExchangeRateProfile;
  above: ExchangeRateProfile;
  below: ExchangeRateProfile;
}

export interface FeatureToggles {
  onDemandSell: boolean;
  onDemandBuy: boolean;
  onTravelMoneyCard: boolean;
}

export interface Currency {
  name: string;
  symbol: string;
  disabled: boolean;
  productID: string;
  popupTitle?: string;
  popupDescription?: string;
}

export interface SellCurrencies {
  AUD: Currency;
  BBD: Currency;
  BGN: Currency;
  CAD: Currency;
  CNY: Currency;
  HRK: Currency;
  CZK: Currency;
  DKK: Currency;
  XCD: Currency;
  EGP: Currency;
  EUR: Currency;
  HKD: Currency;
  HUF: Currency;
  ILS: Currency;
  JPY: Currency;
  MYR: Currency;
  MXN: Currency;
  TRY: Currency;
  NZD: Currency;
  NOK: Currency;
  PLN: Currency;
  RUB: Currency;
  SAR: Currency;
  SGD: Currency;
  ZAR: Currency;
  SEK: Currency;
  CHF: Currency;
  THB: Currency;
  AED: Currency;
  USD: Currency;
}

const defaultExchangeRateProfile: ExchangeRateProfile = {
  spotRate: 0,
  effectiveRate: 0,
  fxValue: 0,
  gbpValue: 0,
  commission: 0,
  margin: 0,
  disabled: true,
};

export const defaultExchangeRates: ExchangeRates = {
  recommended: defaultExchangeRateProfile,
  upsell: defaultExchangeRateProfile,
  above: defaultExchangeRateProfile,
  below: defaultExchangeRateProfile,
};

export const defaultSellCurrencies: SellCurrencies = {
  AUD: {
    name: "Australian Dollar",
    symbol: "$",
    disabled: true,
    productID: "1",
  },
  BBD: {
    name: "Barbados Dollar BBD",
    symbol: "$",
    disabled: true,
    productID: "2",
  },
  BGN: {
    name: "Bulgaria Lev",
    symbol: "лв",
    disabled: true,
    productID: "3",
  },
  CAD: {
    name: "Canada Dollar",
    symbol: "$",
    disabled: true,
    productID: "4",
  },
  CNY: {
    name: "China Yuan",
    symbol: "¥",
    disabled: true,
    productID: "5",
  },
  HRK: {
    name: "Croatia Kuna",
    symbol: "kn",
    disabled: true,
    productID: "6",
  },
  CZK: {
    name: "Czech Koruna",
    symbol: "Kč",
    disabled: true,
    productID: "7",
  },
  DKK: {
    name: "Denmark Krone",
    symbol: "kr",
    disabled: true,
    productID: "8",
  },
  XCD: {
    name: "East Caribbean Dollar",
    symbol: "$",
    disabled: true,
    productID: "9",
  },
  EGP: {
    name: "Egypt Pound",
    symbol: "£",
    disabled: true,
    productID: "11",
  },
  EUR: {
    name: "Euro",
    symbol: "€",
    disabled: true,
    productID: "12",
  },
  HKD: {
    name: "Hong Kong Dollar",
    symbol: "$",
    disabled: true,
    productID: "13",
  },
  HUF: {
    name: "Hungary Forint",
    symbol: "Ft",
    disabled: true,
    productID: "14",
  },
  ILS: {
    name: "Israel Shekel",
    symbol: "₪",
    disabled: true,
    productID: "16",
  },
  JPY: {
    name: "Japan Yen",
    symbol: "¥",
    disabled: true,
    productID: "16",
  },
  MYR: {
    name: "Malaysia Ringgit",
    symbol: "RM",
    disabled: true,
    productID: "17",
  },
  MXN: {
    name: "Mexico New Peso",
    symbol: "$",
    disabled: true,
    productID: "18",
  },
  TRY: {
    name: "New Turkey Lira",
    symbol: "₺",
    disabled: true,
    productID: "19",
  },
  NZD: {
    name: "New Zealand Dollar",
    symbol: "$",
    disabled: true,
    productID: "20",
  },
  NOK: {
    name: "Norway Krone",
    symbol: "kr",
    disabled: true,
    productID: "21",
  },
  PLN: {
    name: "Poland New Zloty",
    symbol: "zł",
    disabled: true,
    productID: "22",
  },
  RUB: {
    name: "Russian Ruble",
    symbol: "₽",
    disabled: true,
    productID: "23",
  },
  SAR: {
    name: "Saudi Riyal",
    symbol: "﷼",
    disabled: true,
    productID: "24",
  },
  SGD: {
    name: "Singapore Dollar",
    symbol: "$",
    disabled: true,
    productID: "25",
  },
  ZAR: {
    name: "South Africa Rand",
    symbol: "R",
    disabled: true,
    productID: "26",
  },
  SEK: {
    name: "Sweden Krona",
    symbol: "kr",
    disabled: true,
    productID: "27",
  },
  CHF: {
    name: "Switzerland Franc",
    symbol: "CHF",
    disabled: true,
    productID: "28",
  },
  THB: {
    name: "Thailand Baht",
    symbol: "฿",
    disabled: true,
    productID: "29",
  },
  AED: {
    name: "UAE Dirham",
    symbol: "د.إ",
    disabled: true,
    productID: "30",
  },
  USD: {
    name: "USA Dollar",
    symbol: "$",
    disabled: true,
    productID: "31",
  },
};
