export enum FmccActions {
  CreateBranchOrder = "createBranchOrder",
}

export interface CreateBranchOrderFulfilmentProps {
  transactionType: string,
  productCode: string,
  currencyCode: string
  baseGbpAmount: string
  fxAmount: string
  exchangeRate: string
  spotRate: string
  userID: string
  [key: string]: string
}
