import { MapPipFormatResponseProps, PipFormatResponse, UpdatedPipFormatResponse, MappedPipFormat } from "./types";

export const domCountries = ["United Kingdom", "Jersey", "Guernsey", "HM Forces Mail"];
export const specialRequirementConsts = ["FrankedV2", "BlindV2", "ConsignmentV2"];
export const parcelForceLabelPrintServices = ["3201", "4001", "4002"];

export const constants = {
  dom_large_parcel: "DomLargeParcel",
  int_printed: "IntPrinted",
  hm_forces: "HM Forces Mail",
  most_likely: "Most likely",
};

export const mapPipFormatResponse = (pipFormatProps: MapPipFormatResponseProps): MappedPipFormat => {
  const { pipFormatResponse, countryID, weight } = pipFormatProps;

  // filter pip formats out of response
  let pipFormats = pipFormatResponse.filter(
    (pipFormat: PipFormatResponse) => !specialRequirementConsts.includes(pipFormat.UIControlName)
  );

  // filter specialRequirements out of response
  let specialRequirements = pipFormatResponse.filter((requirement: PipFormatResponse) =>
    specialRequirementConsts.includes(requirement.UIControlName)
  );

  pipFormats = pipFormats.filter((format: PipFormatResponse) =>
    domCountries.includes(countryID) ? format.ShowForCountryID.length > 0 : format.ShowForCountryID.length === 0
  );

  pipFormats = pipFormats.map((pipFormat: PipFormatResponse) => {
    const updatedFormat = {
      title: pipFormat.UIText,
      value: pipFormat.UIControlName,
      disabled: pipFormat.WeightLTE < Number(weight),
      ...pipFormat,
    } as UpdatedPipFormatResponse;

    if (
      Number(weight) > pipFormat.LikelyIfWeightGT &&
      Number(weight) < pipFormat.WeightLTE &&
      countryID !== constants.hm_forces
    ) {
      updatedFormat.badge = constants.most_likely;
    }

    if (countryID === constants.hm_forces && pipFormat.UIControlName === constants.dom_large_parcel) {
      updatedFormat.disabled = true;
    }

    if (
      pipFormat.UIControlName === constants.int_printed &&
      (Number(weight) <= pipFormat.WeightGT || Number(weight) > pipFormat.WeightLTE)
    ) {
      updatedFormat.disabled = true;
    }

    return updatedFormat;
  });

  pipFormats.sort((a, b) => a.WeightLTE - b.WeightLTE);

  specialRequirements = specialRequirements.map((requirement: PipFormatResponse) => {
    return {
      title: requirement.UIText,
      value: requirement.UIControlName,
      disabled: countryID === constants.hm_forces || requirement.UIControlName === "ConsignmentV2",
      ...requirement,
    };
  });

  specialRequirements.push({ title: "None", value: "" } as UpdatedPipFormatResponse);

  return { pipFormats, specialRequirements };
};

export const mapNullToString = (items: any[]) => {
  return items.map((obj) => {
    const mapObjects = (o: Record<string, any>) => {
      Object.keys(o).forEach(function (key) {
        if (o[key] === null) {
          o[key] = "";
        } else if (Array.isArray(o[key])) {
          mapNullToString(o[key]);
        } else if (typeof o[key] === "object" && !Array.isArray(o[key]) && o[key] !== null) {
          mapObjects(o[key]);
        }
      });
      return o;
    };
    return mapObjects(obj);
  });
};
