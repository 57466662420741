import { ApiError, AuthProvider } from "../types";
import { IssuerSchemes, Items } from "../openapi/tokeniser-api";

export type Params = () => Promise<{ [key: string]: string }>;

export enum TransactionTypes {
  Payments = "payment",
  Banking = "banking",
}

export enum TransactionModes {
  Sale = "SC",
  Refund = "ER",
}

export enum PaymentSettlementTypes {
  Sale = "Sale",
  Bureau = "BDC",
}

export type SupportedBankingOperationTypes = "Cash Withdrawal" | "Cash Deposit" | "Change PIN" | "Balance Enquiry";

export interface TransactionIdResponse {
  transactionId: string;
}

export interface Client {
  paymentTokeniserLookup(params: PaymentTokeniserParams): Promise<PaymentTokeniserResult>;
  bankingTokeniserLookup(params: BankingTokeniserParams): Promise<BankingTokeniserResult>;
  getTransactionId(params: GetTransactionIdParams): Promise<TransactionIdResponse | ApiError>;
  objectsToArray(params: Record<string, unknown>): Promise<Record<string, unknown>>;
}

export interface GetTransactionIdParams {
  type: TransactionTypes;
}

export interface Props {
  rootUrl: string;
  authHeaders: AuthProvider;
}

export interface BankingTokeniserParams {
  operation: SupportedBankingOperationTypes;
  maskedPan: string;
}

export interface PaymentTokeniserParams {
  maskedPan: string;
  settlementType: PaymentSettlementTypes;
  transactionMode: TransactionModes;
}

export interface BankingTokeniserResult {
  tokenmask?: Record<string, unknown>;
  item?: Items;
  operation?: IssuerSchemes;
  isValidToken?: boolean;
  isValidOperation?: boolean;
}

export interface PaymentTokeniserResult {
  tokenMask?: Record<string, unknown>;
  item?: Items;
  scheme?: IssuerSchemes;
}
