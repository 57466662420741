const InvalidIndemnity = {
  valid: false,
};

const ValidIndemnity = {
  valid: true,
  accountName: "Existing Business Ltd",
};

const DatabaseReadError = {
  errorCode: 500,
  error: "Unable to communicate with SDCS service",
};

const DatabaseDecline = {
  errorCode: 500,
  error: "Database Decline",
};

export default {
  "12345678": InvalidIndemnity,
  "87654321": ValidIndemnity,
  "11111111": DatabaseReadError,
  "22222222": DatabaseDecline,
};
