import axios from "axios";

// FIXME: use openapi types for responses and ideally for requests

export type AuthProvider = () => Promise<{ Authorization: string }>;
export type Params = () => Promise<{ [key: string]: string }>;

export interface Client {
  lastSeqNumber(branchID: string, nodeID: string): Promise<LastSeqNumberResponse>;
  createBasket(basketID: string): Promise<CreateBasketResponse>;
  closeBasket(basketID: string): Promise<CloseBasketResponse>;
  createEntry(basketID: string, transactionData: Record<string, unknown>): Promise<CreateEntryResponse>;
  archiveBasket(basketID: string): Promise<ArchiveBasketResponse>;
  getBasket(basketID: string): Promise<BasketResponse>;
  getLastBasket(branchID: string, nodeID: string): Promise<BasketResponse>;
}

export interface LastSeqNumberResponse {
  branchID: string;
  nodeID: number;
  lastSeqNumber: number;
}
export interface CreateBasketResponse {
  message: string;
}
export interface CloseBasketResponse {
  message: string;
}

export interface CreateEntryResponse {
  message: string;
  FulfillmentRequired: boolean;
  FulfillmentName: string;
  FulfillmentAction: string;
  FufillmentType: "Local" | "Remote";
}

export interface CheckFufillmentResponse {
  fufilled: boolean;
  tokens?: Record<string, unknown>;
}

export interface ArchiveBasketResponse {
  message: string;
}

export interface AdditionalItem {
  itemID: string;
  value: number;
  quantity: number;
  receiptLine: string;
  tokens: { [k: string]: unknown };
}

export interface BasketEntry {
  transactionStartTime: number;
  itemId: string;
  quantity: number;
  entryType: string;
  value: number;
  receiptLine: string;
  tokens: { [k: string]: unknown };
  additionalItems: AdditionalItem[];
  stockunitIdentifier: string;
  methodOfDataCapture: number;
  reversalIndicator: number;
  refundFlag: string;
  fallBackModeFlag: string;
  bcsReference: string;
  bankResponse: string;
  bankValueFlag: string;
  accountReferenceID: string;
  clientReference: string;
}

export interface BasketResponse {
  basketCore: {
    basketID: string;
    basketState: string;
    NumberOfEntries: number;
  };
  entries: BasketEntry[];
  organizationCode: string;
  organizationCodeVersion: string;
  deviceType: string;
  deviceID: string;
  basketSeqNum: number;
  nodeID: string;
  fadCode: string;
}

export class TransactionAPIError extends Error {
  public statusCode?: number;

  constructor(prefix: string, error) {
    // AxiosError message or just Error
    const message = `${prefix}: ${String(error.response?.data?.message || error.message)}`;
    super(message);
    this.name = this.constructor.name;
    this.statusCode = error.response?.status;
    const messageLines = (this.message.match(/\n/g) || []).length + 1;
    this.stack =
      String(this.stack)
        .split("\n")
        .slice(0, messageLines + 1)
        .join("\n") +
      String("\n") +
      String(error.stack);
  }
}

const client = (root: string, version: string, authHeaders: AuthProvider): Client => {
  const cleanURL = (url: string): string => {
    return String(url).replace(/([^:])\/\//g, "$1/");
  };

  const getBasketUrl = () => {
    const basketUrl = `${root}/transactions/${version}/basket`;
    if (version === "v2") {
      return basketUrl;
    }
    return `${basketUrl}/`;
  };

  const lastSeqNumber = async (branchID: string, nodeID: string): Promise<LastSeqNumberResponse> => {
    try {
      const response = await axios.get<LastSeqNumberResponse>(
        cleanURL(`${root}/transactions/${version}/branch/${branchID}/node/${nodeID}/lastSeqNumber`),
        {
          headers: await authHeaders(),
        }
      );
      return response.data;
    } catch (err) {
      throw new TransactionAPIError("Get last sequence number", err);
    }
  };

  const getLastBasket = async (branchID: string, nodeID: string): Promise<BasketResponse> => {
    try {
      const response = await axios.get<BasketResponse>(
        cleanURL(`${root}/transactions/${version}/branch/${branchID}/node/${nodeID}/lastBasket`),
        {
          headers: await authHeaders(),
        }
      );
      return response.data;
    } catch (err) {
      throw new TransactionAPIError("Last Basket: ", err);
    }
  };

  const createBasket = async (basketID: string): Promise<CreateBasketResponse> => {
    const params = {
      basketID,
      basketState: "BKO",
    };
    try {
      const response = await axios.post<CreateBasketResponse>(cleanURL(getBasketUrl()), params, {
        headers: await authHeaders(),
      });
      return response.data;
    } catch (err) {
      throw new TransactionAPIError("Create basket", err);
    }
  };

  const getBasket = async (basketID: string): Promise<BasketResponse> => {
    try {
      const response = await axios.get<BasketResponse>(cleanURL(`${root}/transactions/${version}/basket/${basketID}`), {
        headers: await authHeaders(),
      });
      return response.data;
    } catch (err) {
      throw new TransactionAPIError("Get basket", err);
    }
  };

  const closeBasket = async (basketID: string): Promise<CloseBasketResponse> => {
    const params = {
      basketID,
      basketState: "BKC",
    };
    try {
      const response = await axios.put<CloseBasketResponse>(cleanURL(getBasketUrl()), params, {
        headers: await authHeaders(),
      });
      return response.data;
    } catch (err) {
      throw new TransactionAPIError("Close basket", err);
    }
  };

  const archiveBasket = async (basketID: string): Promise<ArchiveBasketResponse> => {
    const params = {
      basketID,
      basketState: "BKX",
    };
    try {
      const response = await axios.put<ArchiveBasketResponse>(cleanURL(getBasketUrl()), params, {
        headers: await authHeaders(),
      });
      return response.data;
    } catch (err) {
      throw new TransactionAPIError("Archive basket", err);
    }
  };

  // FIXME: We need a way to validate that transactionData matched expected structure.
  //        We could use openapi type and validate against it.
  const createEntry = async (
    basketID: string,
    transactionData: Record<string, unknown>
  ): Promise<CreateEntryResponse> => {
    try {
      const response = await axios.post<CreateEntryResponse>(
        cleanURL(`${root}/transactions/${version}/basket/${basketID}/entry`),
        transactionData,
        {
          headers: await authHeaders(),
        }
      );
      return response.data;
    } catch (err) {
      throw new TransactionAPIError("Create entry", err);
    }
  };

  return Object.freeze({
    lastSeqNumber,
    createBasket,
    closeBasket,
    createEntry,
    archiveBasket,
    getBasket,
    getLastBasket,
  });
};

// add here

export const mockClient = (data: { [key: string]: any }): Client => {
  const lastSeqNumber = async (branchID: string, nodeID: string): Promise<LastSeqNumberResponse> => {
    return new Promise((resolve) => resolve(data.lastSeqNumber));
  };
  const archiveBasket = async (basketID: string): Promise<ArchiveBasketResponse> => {
    return new Promise((resolve) => resolve(data.closeBasket));
  };
  const createBasket = async (basketID: string): Promise<CreateBasketResponse> => {
    return new Promise((resolve) => resolve(data.createBasket));
  };
  const closeBasket = async (basketID: string): Promise<CloseBasketResponse> => {
    return new Promise((resolve) => resolve(data.closeBasket));
  };
  const createEntry = (basketID: string, transactionData: Record<string, unknown>): Promise<CreateEntryResponse> => {
    return new Promise((resolve) => resolve(data.createEntry));
  };
  const getBasket = (basketID: string): Promise<BasketResponse> => {
    return new Promise((resolve) => resolve(data.getBasket));
  };

  const getLastBasket = (branchID: string, nodeID: string): Promise<BasketResponse> => {
    return new Promise((resolve) => resolve(data.getLastBasket));
  };

  return Object.freeze({
    lastSeqNumber,
    createBasket,
    closeBasket,
    createEntry,
    archiveBasket,
    getBasket,
    getLastBasket,
  });
};

export default client;
