import { AnyProduct, ProductItem } from "./index";
import { BarcodeNameMapping } from "../tokeniser-api/data/customMappings";

type Params = () => Promise<{
  [key: string]: string;
}>;
export interface ParsedBarcode {
  [key: string]: string | number;
}
export interface QueryParams {
  [key: string]: string | number;
}

// This method is for converting from product-api response to be usable with V2 transactions engine
export const convertForV2Transactions = (res: ProductItem): ProductItem => {
  if (res?.BasketItems) {
    const additionalItems: ProductItem[] = [];
    (res.BasketItems as ProductItem[]).forEach((item: ProductItem) => {
      additionalItems.push({
        itemID: item.ItemID,
        valueInPence: item.Value,
        quantity: item.Quantity,
      });
    });
    res.BasketItems = additionalItems;
  }

  if (res?.ReturnItems) {
    const additionalItems: ProductItem[] = [];
    (res.ReturnItems as ProductItem[]).forEach((item: ProductItem) => {
      additionalItems.push({
        itemID: item.ItemID,
        valueInPence: item.Value,
        quantity: item.Quantity,
      });
    });
    res.ReturnItems = additionalItems;
  }

  return res;
};

export const productParamslabels2go = (barcode: ParsedBarcode, QRcode: ParsedBarcode): Params => {
  let barcodeData = {};
  barcodeData = {
    barcodeType: String(`label${String(QRcode.Label)}`),
    trackingNumber: String(barcode.UPUTrackingNumber),
    format: String(barcode.Format),
    weight: String(barcode.ItemWeight),
    isoCode: String(barcode.DestinationCountry),
    prodNo: String(barcode.ProdNo),
  };
  return barcodeData as Params;
};

export const productParamsFromBarcode = (
  barcode: ParsedBarcode,
  journeyType: string,
  isFeePayable: string,
  bookInRequired: string
): Params => {
  let barcodeData = {};
  const barcodeType = (BarcodeNameMapping[barcode.name] ?? barcode.name) as string;
  switch (barcodeType) {
    case "jgb6":
    case "jgb61d":
      barcodeData = {
        barcodeType: String(barcodeType),
        trackingNumber: String(barcode.UPUTrackingNumber),
        format: String(barcode.Format),
        weight: String(barcode.ItemWeight),
        isoCode: String(barcode.DestinationCountry),
        prodNo: String(barcode.ProdNo),
      };
      break;
    case "pfca":
      barcodeData = {
        barcodeType: String(barcodeType),
        trackingNumber: String(barcode.customerRefNumber),
      };
      break;
    case "PFLocalCollect":
      barcodeData = {
        barcodeType: String(barcodeType),
        trackingNumber: String(barcode.barcode),
        tokengroups: String(barcode.groupname),
        journeyType: String(journeyType),
        isFeePayable: String(isFeePayable),
        bookInRequired: String(bookInRequired),
      };
      break;
    case "RMLocalCollect":
      barcodeData = {
        barcodeType: String(barcodeType),
        trackingNumber: String(barcode.barcode),
        tokengroups: String(barcode.groupname),
        journeyType: String(journeyType),
        bookInRequired: String(bookInRequired),
      };
      break;
    default:
      barcodeData = {
        barcodeType: String(barcodeType),
        trackingNumber: String(barcode.UPUTrackingNumber),
        format: String(barcode.Format),
        weight: String(barcode.ItemWeight),
        product: String(barcode.Product),
        isoCode: String(barcode.DestinationCountry),
        price: String(barcode.PricePaid),
        prodNo: String(barcode.ProdNo),
      };
  }
  return barcodeData as Params;
};

export const productParams = (params: QueryParams): Params => {
  const query = {};
  Object.keys(params).forEach((key) => {
    query[key] = String(params[key]);
  });

  return query as Params;
};
