import { MonarchHeadLabelParams } from "./types";

const additionalParams = {
  upuCountryId: "JGB",
  infoTypeId: 4,
  versionId: 2,
  versionNumber: "1",
  mailType: "A",
  channel: "2",
  mailPieceType: " ",
};

// This is the same as uniqueID
export const parcelRefNumberHyphenated = (prn: string): string => {
  const splitByFourSymbols = prn.match(/.{1,4}/g) || [];

  return splitByFourSymbols.join("-");
};

export const getUKFormattedPostcode = (postcode: string): string => {
  const cleanedPostcode = removeWhitespaceCharacters(postcode);

  if (cleanedPostcode.length >= 5) {
    let formattedPostcode = cleanedPostcode;

    formattedPostcode = addSpaceAt(cleanedPostcode, 2);

    if (cleanedPostcode.length >= 7) {
      formattedPostcode = addSpaceAt(cleanedPostcode, 4);
    } else if (cleanedPostcode.length >= 6) {
      formattedPostcode = addSpaceAt(cleanedPostcode, 3);
    }

    return formattedPostcode;
  } else {
    return cleanedPostcode;
  }
};

const removeWhitespaceCharacters = (postcode: string): string => {
  return postcode.replace(/\s/g, "");
};

const addSpaceAt = (postcode: string, index: number): string => {
  return postcode.slice(0, index) + " " + postcode.slice(index);
};

export const config = () => {
  return `
^FX --- Config ---
^XA
^POI
^PR1
^LH36,12
`;
};

export const getRightBottomColumn = (params: MonarchHeadLabelParams): string => {
  return `
^FX --- Date, Seg code ---
^CF0,33,25
^FO1140,306,1^FB130,3,0,R,0^FD${params.date}^FS
^FO1140,341,1^FB130,3,0,R,0^FD${params.weight ? parseInt(params.weight, 10) / 1000 : "0"}kg^FS
^FO1140,376,1^FB130,3,0,R,0^FH^CI28^FD _c2_a3${params.price}^FS

^FX --- Circle, Seg code ---
^FO1194,420,1^GC132,3,B^FS
^CF0,60,84^FO1104,462^FD${params.segCode}^FS
^FO1194,500,1^GB48,48,48,W^FS
`;
};

export const getVATcolumn = (params: MonarchHeadLabelParams): string => {
  const { vatCode, sessionID, fadCode } = params;

  return `
^FX --- VAT ---
^CF,33,25
^FO936,408^FD${vatCode && "VAT"} ${vatCode}^FS


^FX --- Bottom Right content ---
^CF,33,25
^FO936,448^FD${sessionID}^FS
^FO936,488^FDBR ${fadCode}^FS
`;
};

export const getVATrow = (params: MonarchHeadLabelParams): string => {
  const { vatCode, sessionID, fadCode } = params;
  return `
^FX --- Bottom Right content + VAT code Centered (no barcode) ---
^CF0,33,25^FO420,425^FB468,,,C^FDBR ${fadCode}    ${sessionID}    ${vatCode && "VAT"} ${vatCode}^FS
`;
};

// An excerpt of address to appear on the label.
// If postcode (zip code) is absent, print Town.
// Print premises (building name/number) and first 6 char of Street
// (if present). Below that, the CountryDisplayName (MAIL-1224)
// 40 symbols max to fit in the area
export const getAddress = (params: MonarchHeadLabelParams): string => {
  const { postcode, town, destAddress1, barCodeReq, destinationISOCode, destinationCountry } = params;
  
  let address = postcode ? getUKFormattedPostcode(postcode) : town;
  address = address + ", " + destAddress1;

  // 40 symbols max to fit in the area
  const numberOfSymbolsToTrim = (destinationCountry + address).length > 30 ? address.length - 30 : 0;
  address = numberOfSymbolsToTrim > 0 ? address.slice(0, -numberOfSymbolsToTrim) : address;

  // if we don't have a barcode address is printed to the right of 2D
  let addressPosition = 216;
  if (barCodeReq === "true") {
    addressPosition = 445;
  }
  const countryPosition = addressPosition + 40;
  return `
^FX --- Address ---
^CF0,33,25^FO420,${addressPosition}^FB468,,,C^FD${address}^FS
^CF0,33^FO420,${countryPosition}^FB468,,,C^FD${destinationISOCode === "GBR" ? "UK" : destinationCountry}^FS
`;
};

export const generateOneDBarcode = (upuTrackingNumber: string): string => {
  // the prefix, four digits, four digits, then the check-digit and suffix (currently always GB)
  const spaces = [2, 6, 10];
  const barcode = upuTrackingNumber.split("");

  for (let i = spaces.length - 1; i >= 0; i--) {
    barcode.splice(spaces[i], 0, " ");
  }
  // Barcode must be set to scale 3,3 within the ZPL BY command. This is to ensure the barcode is printed at the correct size.
  // Additionally the barcode in the ZPL must not include spaces as this will increase its size and cause print issues.
  return `
^FX--- Bar code ---
^BY3,3,228^FO420,136^BCN,228,N,N,N,A^FD${upuTrackingNumber}^FS
^CF,33,25
^FO546,388^FD${barcode.join("")}^FS
`;
};

export const poundsToPenceForBarcodes = (number: string): string => {
  const pence = Math.round(parseFloat(number) * 100);
  if (isNaN(pence)) {
    throw new Error("cannot get value in pence");
  }

  if (pence < 0) {
    throw new Error("cannot have negative value in barcode");
  }

  const paddedPence = pence.toString().padStart(5, "0");

  if (paddedPence.length > 5) {
    throw new Error("value too large for barcode");
  }

  return paddedPence;
};

export const generateTwoDBarcode = (journeyParams: MonarchHeadLabelParams, prnWithCheckDigit: string): string => {
  const { upuCountryId, infoTypeId, versionId, mailType, mailPieceType } = additionalParams;
  const {
    fadCode,
    serviceId: product,
    RMClass,
    RMFormat,
    date,
    upuTrackingNumber,
    weight,
    weightType,
    price,
    destAddress1,
    postcode,
    destinationISOCode,
    returnToSenderPostCode,
    requiredAtDelivery,
  } = journeyParams;
  const padString = " ";

  const formatDate = date.replace(/\//g, "");
  const formatPostcode = postcode.replace(/\s/g, "");
  const formatReturnToSenderPostCode = returnToSenderPostCode.replace(/\s/g, "");

  const limit = (line: string | number, limit = 0) => {
    const string = line.toString();
    return string.substring(0, limit);
  };

  const barcodeString =
    limit(upuCountryId.padEnd(4, padString), 4) +
    limit(infoTypeId, 1) +
    limit(versionId, 1) +
    limit(RMFormat.padEnd(2, padString), 2) +
    limit(RMClass.padEnd(1, padString), 1) +
    limit(mailType, 1) +
    limit(prnWithCheckDigit, 16) +
    limit(fadCode, 6) +
    limit(weight ? weight.padStart(7, "0") : "0000000", 7) +
    limit(weightType, 1) +
    limit(poundsToPenceForBarcodes(price), 5) +
    limit(formatDate.padEnd(6, padString), 6) +
    limit(product.padEnd(3, padString), 3) +
    limit(mailPieceType.padEnd(2, padString), 2) +
    limit(upuTrackingNumber.padEnd(13, padString), 13) +
    limit(destAddress1?.padEnd(35, padString), 35) +
    limit(formatPostcode.padEnd(9, padString), 9) +
    limit(destinationISOCode, 3) +
    limit(formatReturnToSenderPostCode.padEnd(7, padString), 7) +
    limit(requiredAtDelivery || padString, 1) +
    padString +
    padString;

  if (barcodeString.length > 126) {
    throw new Error("Barcode is not valid and not suitable for label generation");
  }

  return barcodeString;
};
