const CHANNEL = "2";
const VERSION_NUMBER = "1";

// TODO - i think this can be done more easily
export const luhn16 = (inputHex: string): string => {
  const codeArray = inputHex.split("");

  // step 1: code point mapping (hex2decimal)
  const hexToDecDouble: (string | number)[] = codeArray.map((hex: string) => parseInt(hex, 16)); // just a copy

  // step 2: double every other value starting from the end
  for (let i = hexToDecDouble.length - 1; i >= 0; i -= 2) {
    const initialValue = hexToDecDouble[i];
    const doubled = Number(hexToDecDouble[i]) * 2;

    // step 3: convert the double decimal to base 16
    hexToDecDouble[i] = doubled.toString(16);
    if (doubled.toString(16).length > 1) {
      // convert to hex every character of a string and save them in array
      const arr = doubled.toString(16).split("");

      // step 4: reduce by splitting down any resultant values over and summing them up
      hexToDecDouble[i] = parseInt(arr[0], 10) + parseInt(arr[1], 10);

      if (Number.isNaN(Number(hexToDecDouble[i]))) {
        hexToDecDouble[i] = initialValue;
      }
    } else if (Number.isNaN(Number(doubled.toString(16)))) {
      hexToDecDouble[i] = initialValue; // if not a number, revert back to decimal
    } else {
      hexToDecDouble[i] = doubled.toString(16);
    }
  }
  // step 5: sum up those digits
  const sum = hexToDecDouble.reduce((a, b) => parseInt(String(a), 10) + parseInt(String(b), 10));
  const checkDigit = ((16 - (Number(sum) % 16)) % 16).toString(16);

  return (inputHex + checkDigit).toUpperCase();
};

export const channelHex = (): string => {
  return parseInt(CHANNEL, 10).toString(16).toUpperCase().padStart(2, "0");
};

export const versionNumberHex = (): string => {
  return parseInt(VERSION_NUMBER, 10).toString(16).toUpperCase();
};

export const fadCodeHex = (fadcode: string): string => {
  let sixDigitFadCode = fadcode;
  if (fadcode.length === 7) {
    sixDigitFadCode = fadcode.slice(0, -1);
  }

  return parseInt(sixDigitFadCode, 10).toString(16).toUpperCase().padStart(5, "0");
};

export const terminalIDHex = (terminalID: string): string => {
  return terminalID.toString().toUpperCase().padStart(2, "0");
};

// USN derived by taking terminal session number and appending transaction number,
// and then taking the rightmost 6 digits to generate a 5 character Hex code (left padded with zeros if needed)
export const usnHex = (usn: string): string => {
  const unhyphen = usn.replace(/-/g, "");
  return parseInt(String(unhyphen).slice(-6), 10).toString(16).toUpperCase().padStart(5, "0");
};

export const parcelRefNumber = (fadcode: string, terminalID: string, usn: string): string => {
  const prn = channelHex() + versionNumberHex() + fadCodeHex(fadcode) + terminalIDHex(terminalID) + usnHex(usn);

  // Validate that all combined hex values are alpha numeric
  let validPRN = true;
  for (let i = 0, len = prn.length; i < len; i += len) {
    const code = prn.charCodeAt(i);
    if (
      !(code > 47 && code < 58) && // numeric (0-9)
      !(code > 64 && code < 91)
    ) {
      // upper alpha (A-Z)
      validPRN = false;
    }
  }

  // TODO - do something with error handling
  if (validPRN === false) {
    throw new Error("PRN not valid");
  }

  return luhn16(prn);
};
