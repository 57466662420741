export const PassportPaperItems = [
  {
    itemID: "21208",
    longName: " Application Successful ",
    retailPrice: "0",
  },
  {
    itemID: "21204",
    longName: "IPS Secure Delivery Fee",
    retailPrice: "5",
  },
  {
    itemID: "31689",
    longName: " CHILD Extension ",
    retailPrice: "58.5",
  },
  {
    itemID: "31687",
    longName: " ADULT Extension ",
    retailPrice: "85",
  },
  {
    itemID: "31683",
    longName: " Adult 50 Page Jumbo ",
    retailPrice: "95",
  },
  {
    itemID: "21212",
    longName: " Veteran pre 3-9-1929 ",
    retailPrice: "0",
  },
  {
    itemID: "21210",
    longName: " Child FirstChangeRenew ",
    retailPrice: "58.5",
  },
  {
    itemID: "21209",
    longName: " Adult FirstChangeRenew ",
    retailPrice: "85",
  },
  {
    itemID: "21207",
    longName: "IPS Rejection Fee",
    retailPrice: "16",
  },
  {
    itemID: "21203",
    longName: "IPS Checking Fee",
    retailPrice: "16",
  },
];

export const CTCMockConditionalResponse = {
  "8001": {
    Code: 8001,
    Description:
      "Overlap between previous and current licence. Check correct licence period selected and check customer requirements before continuing transaction.",
  },
  "8002": {
    Code: 8002,
    Description:
      "No Current MOT recorded for licence commencement. Do not continue transaction unless customer can produce a valid secure certificate or acceptable exemption document.",
  },
  "8003": {
    Code: 8003,
    Description:
      "VOSA link not available and no current MOT for licence commencement on DVLA record so MOT status cannot be verified. Check MOT certificate or MOT receipt is valid and proceed according to agreed counter checking arrangements.",
  },
  "8004": {
    Code: 8004,
    Description:
      "Customer is not allowed to re-license in their current tax class at the Post Office. If they wish to do so they must contact the DVLA",
  },
  "8005": {
    Code: 8005,
    Description:
      "Monthly Direct Debit is not available for this vehicle from Post Office. Select Prev to select a different method of payment or a single direct debit payment. ||Alternatively, if the customer wishes to pay via monthly Direct Debit, they may do so online at https://www.gov.uk/vehicle-tax",
  },
};

export const CTCMockResponse = (mockCTCProps = { conditional: false, mockResponseCode: "" }) => {
  const { conditional, mockResponseCode } = mockCTCProps;
  return {
    ...(conditional && { Condition: CTCMockConditionalResponse[mockResponseCode] }),
    VehicleData: {
      FeeData: {
        V62Fee: 5,
      },
      PhysicalData: {
        DrawbarTrailerWeight: 999,
        Engine: {
          CylinderCapacity: 1651,
        },
        MakeDescription: "Citroen",
        ModelDescription: "Xara Picasso",
        SeatingCapacity: 5,
        VehicleColour: "Green",
      },
      VRMData: {
        VRM: "GF53VZU",
        VRMValidationCharacter: "7",
      },
      VehicleTaxData: [
        {
          Currency: "GBP",
          LicenceDuration: 12,
          TaxBand: "D",
          TaxClassCode: "48",
          TaxClassCurrentIndicator: "Y",
          TaxClassDescription: "PETROL CAR",
          TaxClassEnglishAbbrev: "PC",
          VEDMonthlyDDRate: 99.75,
          VEDRate: 95,
          VEDSingleDDRate: 95,
          WheelPlanCode: "L",
          WheelPlanDutyDescription: "unused",
        },
      ],
    },
  };
};

export const DDIMockResponse = () => {
  return {
    status: 200,
    data: {
      FirstPaymentAmount: 165,
      FirstPaymentDate: "2002-09-24T00:00:00.000Z",
      MandateId: "001123456789012345001",
      SubsequentPaymentAmount: 165,
      SubsequentPaymentCount: 10,
      TransactionIdReference: "123456-12-123456789",
    },
  };
};

export const DDIMockErrorResponse = () => {
  return {
    response: {
      status: 400,
      data: {
        Error: {
          Code: "E1105",
          Message: "Bad Payload Format or Failed Validation",
          Source: "CNGWS",
        },
      },
    },
  };
};

export const CTCMock6001ErrorResponse = () => {
  return {
    response: {
      status: 400,
      data: {
        Error: {
          Code: "6001",
          Message: "Reject and advise customer to contact DVLA",
          Source: "DVLA",
        },
      },
    },
  };
};

export const CTC6002MockErrorResponse = () => {
  return {
    response: {
      status: 400,
      data: {
        Error: {
          Code: "6002",
          Message: "Reminder Form Expired. Reject and advise customer to provide suitable documentation.",
          Source: "DVLA",
        },
      },
    },
  };
};

export const CTC6003MockErrorResponse = () => {
  return {
    response: {
      status: 400,
      data: {
        Error: {
          Code: "6003",
          Message:
            "Duty is zero but licence period selected is 6 months. Do not issue licence as incorrect licence period selected. Please retry using 12 months disc.",
          Source: "DVLA",
        },
      },
    },
  };
};

export const CTC6004MockErrorResponse = () => {
  return {
    response: {
      status: 400,
      data: {
        Error: {
          Code: "6004",
          Message:
            "Licence expiry date not valid i.e. not 6 or 12 months from date of liability. Retry with an alternative licence expiry date or ask the customer to contact the DVLA.",
          Source: "DVLA",
        },
      },
    },
  };
};

export const CTC6005MockErrorResponse = () => {
  return {
    response: {
      status: 400,
      data: {
        Error: {
          Code: "6005",
          Message: "Reject and decide whether to re-try or not",
          Source: "DVLA",
        },
      },
    },
  };
};

export const CTC6006MockErrorResponse = () => {
  return {
    response: {
      status: 400,
      data: {
        Error: {
          Code: "6006",
          Message:
            "Reject and inform customer that details for the VRM provided could not be found. Clerk to decide whether to retry or not.",
          Source: "DVLA",
        },
      },
    },
  };
};

export const hmpoItemsList = [
  {
    itemID: "44380",
    itemName: "Starling Balance Enquiry",
    longName: "Starling Balance Enq BCA",
    mediumName: "Starling BE BCA",
    shortName: "StarlingBE",
    minimumValue: "0",
    maximumValue: "0",
  },
  {
    itemID: "44382",
    itemName: "Starling Bank Deposit",
    longName: "Starling Bank DepositBCA",
    mediumName: "Starling Dep BCA",
    shortName: "Starling D",
    minimumValue: "1",
    maximumValue: "20000",
  },
  {
    itemID: "44199",
    itemName: "Starling Cash Withdrawal",
    longName: "Starling Cash Wdwl PCA",
    mediumName: "Starling WdwlPCA",
    shortName: "StarlingCW",
    minimumValue: "0.01",
    maximumValue: "1000",
  },
  {
    itemID: "44201",
    itemName: "Starling Balance Enquiry",
    longName: "Starling Balance Enq PCA",
    mediumName: "Starling BE PCA",
    shortName: "StarlingBE",
    minimumValue: "0",
    maximumValue: "0",
  },
  {
    itemID: "44203",
    itemName: "Starling Bank Deposit",
    longName: "Starling Deposit PCA",
    mediumName: "Starling Dep PCA",
    shortName: "Starling D",
    minimumValue: "1",
    maximumValue: "20000",
  },
  {
    itemID: "49789",
    itemName: "HMPO New Appllication Tablet",
    longName: "HMPO New Appl Tab",
    mediumName: "HMPO New App Tab",
    shortName: "New AppTab",
    minimumValue: "16",
    maximumValue: "16",
    multipleValue: "16",
    retailPrice: "16",
    overridePriceAllowed: "N",
    voidable: "Y",
    existingReversalAllowed: "N",
    newReversalAllowed: "N",
    coreItem: "N",
    vatCode: "A",
    itemType: "APADC",
  },
  {
    itemID: "5004",
    itemName: "Irish Passport Acceptance Charge",
    longName: "Irish Passport Acc Chrge",
    mediumName: "Irish Acc Charge",
    shortName: "Irish Chge",
    minimumValue: "11",
    maximumValue: "11",
    multipleValue: "11",
    retailPrice: "11",
    overridePriceAllowed: "N",
    voidable: "Y",
    existingReversalAllowed: "Y",
    newReversalAllowed: "Y",
    coreItem: "N",
    vatCode: "D",
    itemType: "Base Level Item",
  },
];
