import { EkycForeignExchangeAddress } from "./structures";

export function reverseDate(date: string) {
  return date.split("/").reverse().join("-");
}

function removeBlanksFromAddress(address: EkycForeignExchangeAddress) {
  if (address.buildNum === "") delete address.buildNum;
  if (address.subPrem === "") delete address.subPrem;
  if (address.buildName === "") delete address.buildName;
  return address;
}

export function removeBlanksFromAddresses(addresses: EkycForeignExchangeAddress[]) {
  return addresses.map(removeBlanksFromAddress);
}

export function generateTransactionReference(sixDigitFADCode: string, nodeId: string) {
  if (!sixDigitFADCode) sixDigitFADCode = "000000";
  if (!nodeId) nodeId = "00";

  const date = new Date();
  const timeInSeconds = Math.floor(Date.now() / 1000);
  return `${sixDigitFADCode}-${nodeId}-${timeInSeconds}`;
}
