import {
  RequestVendResponse,
  ConfirmVendResponse,
  SmartMeterVendTypeActions as SmartMeterFulfillerActions,
  BillPayClient,
  AuthProvider,
  buildBillpayClient,
  CancelVendResponse,
} from "postoffice-product-journey-api-clients";
import {
  FulfillmentItem,
  ApiClientsConfig,
  BasketItemPayload
} from "../../types";

export const createApiClient = (apiConfig: ApiClientsConfig["billpay"], authProvider: AuthProvider) => {
  return buildBillpayClient({
    authHeaders: authProvider,
    ...apiConfig,
  });
}

export const getRequestVend = async(
  action: SmartMeterFulfillerActions,
  basketItem: BasketItemPayload,
  apiClient: BillPayClient
) => {
  try {
    const { tokens, fulfilment } = basketItem;
    const result = await apiClient.requestVend({
      vendType: action,
      branchId: fulfilment?.branchId.toString(),
      ctrId: fulfilment?.ctrId.toString(),
      clerkId: fulfilment?.clerkId.toString(),
      PAN: fulfilment?.PAN.toString() || "",
      monetaryClass: fulfilment?.monetaryClass.toString() || "",
      monetaryValue: tokens?.Amt.toString() || "",
    });
    return result;
  } catch (error) {
    return error as RequestVendResponse;
  }
};

export const getConfirmVend = async (
  action: SmartMeterFulfillerActions,
  basketItem: BasketItemPayload,
  retrievalReference: string,
  transactionReference: string,
  apiClient: BillPayClient
) => {
  try {
    const { tokens, fulfilment } = basketItem;
    const result = await apiClient.confirmVend({
      vendType: action.concat("-confirm"),
      branchId: fulfilment?.branchId.toString(),
      ctrId: fulfilment?.ctrId.toString(),
      clerkId: fulfilment?.clerkId.toString(),
      PAN: fulfilment?.PAN.toString() || "",
      monetaryClass: fulfilment?.monetaryClass.toString() || "",
      monetaryValue: tokens?.Amt.toString() || "",
      retrievalReference,
      transactionReference
    });
    return result;
  } catch (error) {
    return error as ConfirmVendResponse;
  }
};

export const getCancelVend = async (
  action: SmartMeterFulfillerActions,
  basketItem: BasketItemPayload,
  retrievalReference: string,
  transactionReference: string,
  apiClient: BillPayClient
) => {
  try {
    const { tokens, fulfilment } = basketItem;
    const result = await apiClient.cancelVend({
      vendType: action.concat("-cancel"),
      branchId: fulfilment?.branchId.toString(),
      ctrId: fulfilment?.ctrId.toString(),
      clerkId: fulfilment?.clerkId.toString(),
      PAN: fulfilment?.PAN.toString() || "",
      monetaryClass: fulfilment?.monetaryClass.toString() || "",
      monetaryValue: tokens?.Amt.toString() || "",
      retrievalReference,
      transactionReference
    });
    return result;
  } catch (error) {
    return error as CancelVendResponse;
  }
};
