/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  buildDropAndGoClient,
  AuthProvider,
  UpdateDngAccountRequest,
  UpdateDngAccountResponse,
} from "postoffice-product-journey-api-clients";
import { brokenPromises, isMatchingCode, primitiveToBoolean } from "../../helpers";
import { FulfilmentStateEnum } from "../../../openapi/transaction-api-v3";
import { ApiClientsConfig, BasketItemPayload, FulfilmentProcessorResponse, MessagingNotice } from "../../../types";
import { createCdpRequestHeader } from "../helpers";
import { MANDATORY_EDIT_ACCOUNT_TOKENS } from "../mandatoryTokens";

export const process = async (
  basketId: string,
  apiConfig: ApiClientsConfig["dropandgo"],
  basketItem: BasketItemPayload,
  authProvider: AuthProvider
): Promise<FulfilmentProcessorResponse> => {
  const apiClient = buildDropAndGoClient({
    authHeaders: authProvider,
    ...apiConfig,
  });

  const missingJourneyTokens: string[] = [];

  // eslint-disable-next-line no-restricted-syntax
  for (const requiredToken of MANDATORY_EDIT_ACCOUNT_TOKENS) {
    if (!basketItem.fulfilment || !basketItem.fulfilment[requiredToken]) {
      missingJourneyTokens.push(requiredToken);
    }
  }

  if (missingJourneyTokens.length) {
    return {
      notice: {
        id: "Journey Error",
        description: `${missingJourneyTokens.join(", ")} key/values must be passed from journey`,
      },
      fulfilment: {
        status: FulfilmentStateEnum.Failure,
        tokens: {},
      },
    };
  }

  const notice: MessagingNotice = <MessagingNotice>{};
  let hasError = false;

  // 1. Update customer details
  const hasAccountUpdate = primitiveToBoolean(basketItem.fulfilment?.hasAccountUpdate);
  let updateCustomerResultStatusCode!: number;

  if (hasAccountUpdate) {
    const updateCustomerResult: UpdateDngAccountResponse = await brokenPromises(
      apiClient.editAccount({
        hdr: createCdpRequestHeader(basketId, basketItem.fulfilment?.clerkId as string),
        body: basketItem.fulfilment?.updateCustomerAccountPayload,
      } as UpdateDngAccountRequest)
    );

    if (updateCustomerResult.status?.rcode === undefined) {
      hasError = true;
      notice.id = basketItem.fulfilment?.systemUnavailablePromptTitle ?? "";
      notice.description = basketItem.fulfilment?.systemUnavailablePromptText ?? "";
    }

    updateCustomerResultStatusCode = updateCustomerResult.status?.rcode as unknown as number;

    // System Unavailable
    if (!hasError && !isMatchingCode(updateCustomerResultStatusCode, [0, 1, 2])) {
      hasError = true;
      notice.id = basketItem.fulfilment?.systemUnavailablePromptTitle ?? "";
      notice.description = basketItem.fulfilment?.systemUnavailablePromptText ?? "";
    }

    // Account Update Declined
    if (!hasError && isMatchingCode(updateCustomerResultStatusCode, [1, 2])) {
      hasError = true;
      notice.id = basketItem.fulfilment?.accountUpdateDeclinedPromptTitle ?? "";
      notice.description = basketItem.fulfilment?.accountUpdateDeclinedPromptText ?? "";
    }

    if (hasError) {
      return {
        notice,
        fulfilment: {
          status: FulfilmentStateEnum.Failure,
          tokens: {
            updateAccountApiResponseCode: String(updateCustomerResultStatusCode),
          },
        },
      };
    }
  }

  return {
    fulfilment: {
      status: FulfilmentStateEnum.Success,
      tokens: {
        ...(updateCustomerResultStatusCode !== undefined && {
          updateAccountApiResponseCode: String(updateCustomerResultStatusCode),
        }),
      },
    },
    notice: {
      id: basketItem.fulfilment?.transactionCompletePromptTitle ?? "",
      description: basketItem.fulfilment?.transactionCompletePromptText ?? "",
    },
    receipts: [
      {
        template: "mails/dropandgo",
        context: {
          type: "Edit Account",
          receiptMessage: basketItem.fulfilment?.englishReceiptContent ?? "",
          entryId: basketItem.entryID,
          productNo: basketItem.itemID,
        },
      },
    ],
  };
};
