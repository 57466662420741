import {
  Mapper,
  ParsedPatternFields,
  PaddingPosition,
  PatternMapping,
  PatternMappingField,
  ParsedQRCode,
  JGB4MappedData,
} from "./types";
import { RMSenderFields, amazonSenderFields, returnFields } from "./data/labels2GoMapping";
import { postcodeRegex, validateLabels2GoType, validatePostcode } from "./helpers";
import { BarcodeNameMapping } from "./data/customMappings";

export const mapPattern = (barcode: string, mapping: PatternMapping[]): ParsedPatternFields | undefined => {
  const foundMapping: PatternMapping | undefined = mapping.find((m: PatternMapping) =>
    new RegExp(m.pattern).test(barcode)
  );

  if (foundMapping) {
    const mappedData = foundMapping.fields.reduce((memo: ParsedPatternFields, mappingField: PatternMappingField) => {
      const value = removePadding(
        barcode.slice(mappingField.start, mappingField.start + mappingField.length).trim(),
        mappingField
      );
      // eslint-disable-next-line no-param-reassign
      memo[mappingField.name] = value;
      return memo;
    }, foundMapping.Constants);

    mappedData.name = foundMapping.name;
    return mappedData;
  }

  return undefined;
};

const removePadding = (value: string, field: PatternMappingField): string => {
  if (!field.paddingSymbol) {
    return value;
  }
  switch (field.paddingPosition) {
    case PaddingPosition.START: {
      const regex = new RegExp(`^${field.paddingSymbol}*`);
      return value.replace(regex, "");
    }
    case PaddingPosition.END: {
      const regex = new RegExp(`${field.paddingSymbol}*$`);
      return value.replace(regex, "");
    }
    case PaddingPosition.BOTH: {
      const start = new RegExp(`^${field.paddingSymbol}*`);
      const end = new RegExp(`${field.paddingSymbol}*$`);
      return value.replace(start, "").replace(end, "");
    }
    default: {
      return value;
    }
  }
};

export const makeMapper = (mapping: PatternMapping[]): Mapper => {
  const call = async (token: string): Promise<ParsedPatternFields | undefined> => {
    const mappedPattern: ParsedPatternFields | undefined = mapPattern(token, mapping);
    if (mappedPattern) {
      return Promise.resolve(mappedPattern);
    }

    return undefined;
  };

  return Object.freeze({ call });
};

export const qrCodeParsor = (data: string, mapping: PatternMapping[]): ParsedQRCode | undefined => {
  try {
    const foundMapping: PatternMapping | undefined = mapping.find((m: PatternMapping) => {
      const returnValue = new RegExp(m.pattern).test(data);
      return returnValue;
    });

    const senderValuesFormat = (
      senderValues: string[],
      senderAddressType: typeof RMSenderFields | typeof amazonSenderFields
    ): Record<string, unknown> => {
      const senderValuesLength = senderValues.length;
      const sendersDetail = senderAddressType.fields.reduce((result, field, index) => {
        const sendersDetailResult = { ...result };
        if (field.name === "PostCode") {
          const validPostcode = validatePostcode(senderValues, senderValuesLength);
          if (validPostcode) {
            sendersDetailResult[field.name] = senderValues[senderValuesLength - 1].trim();
          } else {
            sendersDetailResult[field.name] = "";
          }
        } else if (!senderValues[index]) {
          sendersDetailResult[field.name] = "";
        } else {
          sendersDetailResult[field.name] = senderValues[index].trim();
        }
        return sendersDetailResult;
      }, {});
      return sendersDetail;
    };

    if (foundMapping) {
      const memo: ParsedQRCode = {};
      let senderValues: string[] = [];
      let senderDetailValues: Record<string, unknown> = {};

      const raw = data.replace("QRCode", "barcode");
      const text = raw.replace("||", "");

      // Split QR Code via | and memorise key values
      text.split("|").forEach((pair) => {
        const [key, value] = pair.split("=");
        memo[key] = value;
      });

      const detail = memo.SendersDetails.toString().trim();

      // RM Tracked QR Code Formats
      if (detail.includes("\\&")) {
        senderValues = detail.split("\\&");
        senderDetailValues = senderValuesFormat(senderValues, RMSenderFields);
      } else {
        switch (detail.length) {
          case 226: {
            senderValues = detail.match(/.{1,72}/g) as string[];
            const sendVals = senderValues.map((address) => address.substring(0, 35));
            senderDetailValues = senderValuesFormat(sendVals, amazonSenderFields);
            break;
          }
          case 218: {
            senderValues = detail.match(/.{1,70}/g) as string[];
            const sendVals = senderValues.map((address) => address.substring(0, 35));
            senderDetailValues = senderValuesFormat(sendVals, amazonSenderFields);
            break;
          }
          case 188: {
            senderValues = detail.match(/.{1,60}/g) as string[];
            const sendVals = senderValues.map((address) => address.substring(0, 35));
            senderDetailValues = senderValuesFormat(sendVals, amazonSenderFields);
            break;
          }
          default: {
            senderValues = detail.match(/.{1,35}/g) as string[];
            const sendVals = senderValues.filter((address) => address.trim() !== "");
            if (detail.search(postcodeRegex) !== -1) {
              sendVals[sendVals.length - 1] = detail.substring(detail.search(postcodeRegex));
            }
            senderDetailValues = senderValuesFormat(sendVals, RMSenderFields);
            break;
          }
        }
      }
      memo[RMSenderFields.name] = senderDetailValues;

      const retDetail = memo.RetDetails?.toString();
      if (retDetail) {
        const value: string[] = retDetail.split("\\&");
        const returnDetail = returnFields.fields.reduce((result, field, index) => {
          const returnDetailResult = { ...result };
          if (!value[index]) {
            returnDetailResult[field.name] = "";
          } else {
            returnDetailResult[field.name] = value[index];
          }
          return returnDetailResult;
        }, {});
        memo[returnFields.name] = returnDetail;
      }
      return validateLabels2GoType(memo);
    }
    return undefined;
  } catch (err) {
    console.error("QR code could not be parsed", err);
    return undefined;
  }
};

export const mappedJGB4BarcodeData = (parsedBarcode: ParsedPatternFields): JGB4MappedData => {
  return {
    Price: (parseInt(parsedBarcode.PricePaid as string) / 100).toFixed(2), // add 2 decimal points to price (from pence to pounds)
    Weight: (parseInt(parsedBarcode.Weight as string) / 1000).toFixed(3).padStart(8, "0"), //add 3 decimal points to weight (from g to kg)
    Postcode: parsedBarcode.DestinationPostCodePlusDPS as string,
    Building: parsedBarcode.BuildingNameNumber as string,
    FadCode: parsedBarcode.POLFADCode as string,
    ProdDate: (parsedBarcode.DateProduction as string).match(/.{2}/g)?.join("/") as string, //split 6-character date into groups of 2, rejoin with slash
    PRN:
      (parsedBarcode.Channel as string) +
      parsedBarcode["Version Number"] +
      parsedBarcode.FAD +
      parsedBarcode.Terminal +
      parsedBarcode.Session +
      parsedBarcode.CheckDigit,
    UPUTrackingNumber: parsedBarcode.UPUTrackingNumber as string,
  };
};

export const parse1DBarcode = (barcode: string, mapping: PatternMapping[]): ParsedPatternFields | undefined => {
  const foundMapping: PatternMapping | undefined = mapping.find((m: PatternMapping) =>
    new RegExp(m.pattern).test(barcode)
  );
  const memo: ParsedPatternFields = {};
  if (foundMapping) {
    const lookupval = getBarcodeType(foundMapping.name);
    if (foundMapping.tokengroups.includes(lookupval)) {
      memo.type = lookupval;
    } else {
      memo.type = "notsupported";
    }
    memo.name = foundMapping.name;
    memo.tokenid = foundMapping.Constants.TokenId;
    memo.barcode = barcode;
    memo.ExpiryPeriod = foundMapping.Constants.ExpiryPeriod;
    memo.Carrier = foundMapping.Constants.Carrier;
    memo.groupname = foundMapping.tokengroups.toString();
    memo.fields = foundMapping.fields;
    memo.CDId = foundMapping.Constants.CDId;
    return memo;
  }

  memo.type = "";
  return memo;
};

// TODO: Remove me: once API giving correct data
export const getBarcodeType = (barcodeName: string): string => {
  return (BarcodeNameMapping[barcodeName] ?? barcodeName) as string;
};
