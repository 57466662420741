export type Params = () => Promise<{ [key: string]: string }>;

export type JGB4MappedData = {
  Price: string;
  Weight: string;
  Postcode: string;
  Building: string;
  FadCode: string;
  ProdDate: string;
  PRN: string;
  UPUTrackingNumber: string;
};

export interface ParamsTokenise {
  barcode: string;
  shouldGetItems?: number;
  errorOnFailure?: boolean;
  skipIntegrityChecks?: boolean;
  tokenType?: string;
}

export interface MaybeTokeniseParams {
  typePrecedence: TokenType[];
  value: string;
}

export interface ParamsMail {
  barcode: string;
  shouldGetItems?: number;
}

export interface ParamsGetProduct {
  ProdNo: string;
  errorOnFailure?: boolean;
}

export interface ParsedPatternFields {
  [key: string]: string | number | ParsedPatternFields | PatternMappingField[];
}
export interface ParsedQRCode {
  [key: string]: string | Record<string, unknown>;
}

export interface Mapper {
  call(barcode: string): Promise<ParsedPatternFields | undefined>;
}

export interface PatternMapping {
  name: string;
  pattern: string;
  Constants: ParsedPatternFields;
  fields: PatternMappingField[];
  tokengroups: string[];
}

export interface PatternMappingField {
  start: number;
  length: number;
  name: string;
  paddingPosition?: PaddingPosition;
  paddingSymbol?: string;
}

export enum PaddingPosition {
  START = "start",
  END = "end",
  BOTH = "both",
}

export enum TokenType {
  BARCODE = "barcode",
  MAIL = "mail",
  BANKING = "banking",
  MAGCARD = "magcard",
  PAYMENT = "payment",
}
