export const LABEL_PRINTER_ERROR_CODES = {
  PAPER_OUT: "The printer appears to be out of paper. Check the printer and try again.",
  PAUSED: "The printer is paused. Check the printer and try again.",
  RIBBON_OUT: "The printer ribbon is out. Check the printer and try again.",
  HEAD_TOO_HOT: "The printer head is too hot. Try again after some time.",
  HEAD_OPEN: "The printer head is open. Check the printer and try again.",
  UNABLE_TO_LOCATE_PRINTER: "Printer not found. Check the connection and try again.",
};

/**
 * Map error message to a predefined error if it exists.
 * @param errorCode string
 * @returns string
 */
export const getMappedErrorCode = (errorCode: string) => {
  return LABEL_PRINTER_ERROR_CODES[errorCode] ?? errorCode;
};
