import { bytesToString } from "./shared";

export function generateQRCode(codeText: string): string {
  let code = "";

  // 1B 61 n - Select justification (0x01 = center)
  code += bytesToString(0x1b, 0x61, 0x01);

  // 1D 28 6B 04 00 31 41 n1 n2 - Select model for QR Code (0x32 0x00 model 2)
  code += bytesToString(0x1d, 0x28, 0x6b, 0x04, 0x00, 0x31, 0x41, 0x32, 0x00);
  // 1D 28 6B 03 00 31 42 n - Select QR Code barcode version (0x00 AUTO)
  code += bytesToString(0x1d, 0x28, 0x6b, 0x03, 0x00, 0x31, 0x42, 0x00);
  // 1D 28 6B 03 00 31 43 n - Set size for QR Code module (0x06 dots)
  code += bytesToString(0x1d, 0x28, 0x6b, 0x03, 0x00, 0x31, 0x43, 0x06);
  // 1D 28 6B 03 00 31 45 n - Set error correction level for QR Code (0x33 should be H according to docs, appears to be Q)
  code += bytesToString(0x1d, 0x28, 0x6b, 0x03, 0x00, 0x31, 0x45, 0x33);
  // 1D 28 6B pL pH 31 50 30 d1...dk - Store symbol data for QR Code (pL: (data length + 3) % 256, pH: (data length + 3) / 256, d: data)
  const pL = (codeText.length + 3) % 256;
  const pH = Math.floor((codeText.length + 3) / 256);
  code += bytesToString(0x1d, 0x28, 0x6b, pL, pH, 0x31, 0x50, 0x30);
  code += codeText;
  // 1D 28 6B 03 00 31 51 31 - Print symbol data for QR Code
  code += bytesToString(0x1d, 0x28, 0x6b, 0x03, 0x00, 0x31, 0x51, 0x31);

  // 1B 61 n - Select justification (0x00 = left)
  code += bytesToString(0x1b, 0x61, 0x00);

  return code;
}
