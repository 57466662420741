import storage from "postoffice-spm-async-storage";
import { ApiError, AuthProvider } from "types";
import {
  StampsProps,
  ParsedStampsMapping,
  PostagePaidCodeProps,
  VatCodeProps,
  ParsedPostagePiadCodeMapping,
  ProductItem,
  IssueToCustomerProps,
  OneDBarcodeParams,
  OneDBarcodeResponse,
  SpoiltLabelProps,
} from "./types";
import { axiosClient } from "../lib/axiosWrapper";
import { AxiosError } from "axios";

export interface Client {
  getStampsList(props: StampsProps): Promise<Record<string, unknown>>;
  getPostagePaidCodeList(props: PostagePaidCodeProps): Promise<Record<string, unknown>>;
  getDetailForVatCode(props: VatCodeProps): Promise<Record<string, unknown>>;
  issueToCustomerBookin(props: IssueToCustomerProps): Record<string, unknown>;
  getOneDBarcode(params: OneDBarcodeParams): Promise<OneDBarcodeResponse | ApiError>;
  getBalancePeriod(props: SpoiltLabelProps): Promise<Record<string, string>>;
}

export interface Props {
  rootUrl: string;
  authHeaders: AuthProvider;
}

export const buildClient = (props: Props): Client => {
  const { rootUrl, authHeaders } = props;
  const getStampsList = async (props: StampsProps): Promise<Record<string, unknown>> => {
    const stamps = await storage.getRecord("_config/mails/stamps/saleOfStamps");

    if (!stamps || !stamps.value) {
      return Promise.reject({
        statusCode: 422,
        message: "stamps file could not be found",
      });
    }

    const stampsString = stamps.value as string;
    let parsedStamps: ParsedStampsMapping[];
    try {
      parsedStamps = JSON.parse(stampsString);
    } catch (e) {
      return Promise.reject({
        statusCode: 422,
        message: `attempt to parse the stamps string to json failed with error: ${e}`,
      });
    }

    const { stampName } = props;
    const data: Record<string, string | boolean>[] = parsedStamps[stampName];
    if (data) {
      const filteredData = data
        .filter((element) => element.itemSellable === true && element.enabled === true)
        .map((item) => {
          return {
            title: `${item.itemDescription}\n${item.itemButtonPrice}`,
            value: item.itemButtonPrice,
            ...item,
          };
        });
      if (filteredData && filteredData.length > 0) {
        return Promise.resolve({ results: filteredData });
      }
      return Promise.resolve({ results: [] });
    }
    return Promise.resolve({ results: [] });
  };

  const getPostagePaidCodeList = async (props: PostagePaidCodeProps): Promise<Record<string, unknown>> => {
    const spoiltLabel = await storage.getRecord("_config/mails/spoiltlabel/spoiltLabel");

    if (!spoiltLabel || !spoiltLabel.value) {
      return Promise.reject({
        statusCode: 422,
        message: "spoiltLabel file could not be found",
      });
    }

    const spoiltLabelString = spoiltLabel.value as string;
    let parsedSpoiltLabel: ParsedPostagePiadCodeMapping[];
    try {
      parsedSpoiltLabel = JSON.parse(spoiltLabelString);
    } catch (e) {
      return Promise.reject({
        statusCode: 422,
        message: `attempt to parse the stamps string to json failed with error: ${e}`,
      });
    }

    const { carrierID, carrierCode } = props;
    const filteredData = parsedSpoiltLabel
      .filter((element) => element.CarrierID === carrierID && element.VATCode.startsWith(carrierCode))
      .map((item) => {
        return {
          title: item.VATCode.charAt(item.VATCode.length - 1),
          value: item.VATCode.charAt(item.VATCode.length - 1),
          ...item,
        };
      });
    if (filteredData && filteredData.length > 0) {
      return Promise.resolve({ results: filteredData });
    }
    return Promise.resolve({ results: [] });
  };

  const getDetailForVatCode = async (props: VatCodeProps): Promise<Record<string, unknown>> => {
    const spoiltLabel = await storage.getRecord("_config/mails/spoiltlabel/spoiltLabel");

    if (!spoiltLabel || !spoiltLabel.value) {
      return Promise.reject({
        statusCode: 422,
        message: "spoiltLabel file could not be found",
      });
    }

    const spoiltLabelString = spoiltLabel.value as string;
    let parsedSpoiltLabel: ParsedPostagePiadCodeMapping[];
    try {
      parsedSpoiltLabel = JSON.parse(spoiltLabelString);
    } catch (e) {
      return Promise.reject({
        statusCode: 422,
        message: `attempt to parse the stamps string to json failed with error: ${e}`,
      });
    }

    const { carrierID, vatCode } = props;
    const filteredData = parsedSpoiltLabel.find(
      (element) => element.CarrierID === carrierID && element.VATCode === vatCode
    );
    if (filteredData && Object.keys(filteredData).length > 0) {
      return Promise.resolve({ results: filteredData });
    }
    return Promise.resolve({ results: {} });
  };

  const issueToCustomerBookin = (props: IssueToCustomerProps): Record<string, unknown> => {
    const { product, tokens } = props;
    const returnProductData = { ...product };

    const basketItems = returnProductData.BasketItems;
    const returnItems = returnProductData.ReturnItems;

    if (tokens.pf) {
      const basketItemsIndex = basketItems.findIndex((item: ProductItem) => item.itemID === "6315");
      basketItems[basketItemsIndex] = {
        ...{ itemID: "6315", valueInPence: 0, quantity: 1, ...tokens.pf },
      };

      const returnItemsIndex = returnItems.findIndex((item: ProductItem) => item.itemID === "6315");
      returnItems[returnItemsIndex] = { ...{ itemID: "6315", valueInPence: 0, quantity: 1, ...tokens.pf } };
    }

    if (tokens.rm) {
      const basketItemsIndex = basketItems.findIndex((item: ProductItem) => item.itemID === "6309");
      basketItems[basketItemsIndex] = { ...{ itemID: "6309", valueInPence: 0, quantity: 1, ...tokens.rm } };

      const returnItemsIndex = returnItems.findIndex((item: ProductItem) => item.itemID === "6309");
      returnItems[returnItemsIndex] = { ...{ itemID: "6309", valueInPence: 0, quantity: 1, ...tokens.rm } };
    }

    return { results: returnProductData };
  };

  const getOneDBarcode = async (params: OneDBarcodeParams): Promise<OneDBarcodeResponse | ApiError> => {
    try {
      const response = await axiosClient.get({
        url: `${rootUrl}/onedbarcode/barcode`,
        headers: await authHeaders(),
        params,
      });
      return response as unknown as OneDBarcodeResponse;
    } catch (error) {
      const err = error as AxiosError;
      return Promise.reject({
        statusCode: err.response?.status || 500,
        ...(err.response?.data?.message && { message: err.response?.data?.message }),
      });
    }
  };

  const getBalancePeriod = (props: SpoiltLabelProps): Promise<Record<string, string>> => {
    const convertedDate = new Date(props.date);
    const basedate = convertedDate.getDate();
    let bp = "01";
    if (basedate >= 1 && basedate <= 7) {
      bp = "01";
    } else if (basedate >= 8 && basedate <= 14) {
      bp = "02";
    } else if (basedate >= 15 && basedate <= 21) {
      bp = "03";
    } else if (basedate >= 22 && basedate <= 28) {
      bp = "04";
    } else if (basedate >= 29 && basedate <= 31) {
      bp = "05";
    }
    return Promise.resolve({ bp });
  };

  return Object.freeze({
    getStampsList,
    getPostagePaidCodeList,
    getDetailForVatCode,
    issueToCustomerBookin,
    getOneDBarcode,
    getBalancePeriod,
  });
};
