import moment from "moment";
import { axiosClient } from "../lib/axiosWrapper";

import { parseConfirmAndCancelVendResponse, parseVendResponse } from "./helpers";
import {
  RequestVendProps,
  RequestVendResponse,
  ConfirmVendResponse,
  CancelVendResponse,
  SmartMeterVendTypeActions,
  Props,
  Client,
} from "./types";

const setHeaders = () => {
  const created = moment().format("YYYY-MM-DDTHH:mm:ss");
  const transactionDate = moment().format("YYYYMMDD");
  const transactionTime = moment().format("HHmmss");
  const reqId = moment().format("Hms");
  // const reqId = "13804";
  const transactionReference = moment().format("YYMMDD-DD-HHmmss");

  return { created, transactionDate, transactionTime, reqId, transactionReference };
};

// Smart Meter XML (Request, Cancel, Confirm)
const callRequestVendSmartMeterXML = async (props: Props, requestVendProps: RequestVendProps) => {
  const { rootUrl, authHeaders } = props;
  const { branchId, ctrId, clerkId, PAN, monetaryClass, monetaryValue } = requestVendProps;
  const { created, transactionDate, transactionTime, reqId, transactionReference } = setHeaders();

  const txId = branchId + "-" + ctrId + "-" + reqId;
  const vendAmount = monetaryValue.split(".");
  const response = await axiosClient.post({
    url: `${rootUrl}/smart-metering/vend/request`,
    headers: await authHeaders(),
    params: {
      hdr: {
        created,
        reqId,
        txId,
        branchId,
        ctrId,
        clerkId,
      },
      body: {
        PAN: PAN,
        monetaryClass,
        monetaryValue: vendAmount[0],
        transactionReference,
        transactionDate,
        transactionTime,
      },
    },
  });
  response["transactionReference"] = transactionReference;
  return response;
};

const callCancelVendSmartMeterXML = async (props: Props, requestVendProps: RequestVendProps) => {
  const { rootUrl, authHeaders } = props;
  const { branchId, ctrId, clerkId, PAN, monetaryClass, monetaryValue, retrievalReference, transactionReference } =
    requestVendProps;
  const { transactionDate, transactionTime, created, reqId } = setHeaders();

  const txId = branchId + "-" + ctrId + "-" + reqId;
  const vendAmount = monetaryValue.split(".");

  const response = axiosClient.post({
    url: `${rootUrl}/smart-metering/vend/cancel`,
    headers: await authHeaders(),
    params: {
      hdr: {
        created: created,
        reqId: reqId,
        txId: txId,
        branchId,
        ctrId,
        clerkId,
      },
      body: {
        PAN: PAN,
        monetaryClass: monetaryClass,
        monetaryValue: vendAmount[0],
        transactionReference: transactionReference,
        transactionDate: transactionDate,
        transactionTime: transactionTime,
        retrievalReference: retrievalReference,
      },
    },
  });
  return response;
};

const callConfirmVendSmartMeterXML = async (props: Props, requestVendProps: RequestVendProps) => {
  const { rootUrl, authHeaders } = props;
  const { branchId, ctrId, clerkId, PAN, monetaryClass, monetaryValue, retrievalReference, transactionReference } =
    requestVendProps;
  const { transactionDate, transactionTime, created, reqId } = setHeaders();

  const txId = branchId + "-" + ctrId + "-" + reqId;
  const vendAmount = monetaryValue.split(".");

  const response = axiosClient.post({
    url: `${rootUrl}/smart-metering/vend/confirm`,
    headers: await authHeaders(),
    params: {
      hdr: {
        created: created,
        reqId: reqId,
        txId: txId,
        branchId,
        ctrId,
        clerkId,
      },
      body: {
        PAN: PAN,
        monetaryClass: monetaryClass,
        monetaryValue: vendAmount[0],
        transactionReference: transactionReference,
        transactionDate: transactionDate,
        transactionTime: transactionTime,
        retrievalReference: retrievalReference,
      },
    },
  });
  return response;
};

// Smart Meter Paygen Generic (Request, Cancel, Confirm)
const callRequestVendSmartMeterPaygenGeneric = async (props: Props, requestVendProps: RequestVendProps) => {
  const { rootUrl, authHeaders } = props;
  const { PAN, monetaryClass, monetaryValue } = requestVendProps;
  const { transactionDate, transactionTime, transactionReference } = setHeaders();
  const vendAmount = monetaryValue.split(".");

  const response = await axiosClient.post({
    url: `${rootUrl}/smart-metering-paygen-generic/vend/request`,
    headers: await authHeaders(),
    params: {
      Pan: PAN,
      MonetaryClass: monetaryClass,
      MonetaryValue: vendAmount[0],
      TransactionReference: transactionReference,
      TransactionDate: transactionDate,
      TransactionTime: transactionTime,
    },
  });
  response["transactionReference"] = transactionReference;
  return response;
};

const callCancelVendSmartMeterPaygenGeneric = async (props: Props, requestVendProps: RequestVendProps) => {
  const { rootUrl, authHeaders } = props;
  const { PAN, monetaryClass, monetaryValue, retrievalReference, transactionReference } = requestVendProps;
  const { transactionDate, transactionTime } = setHeaders();
  const vendAmount = monetaryValue.split(".");

  const response = axiosClient.post({
    url: `${rootUrl}/smart-metering-paygen-generic/vend/cancel`,
    headers: await authHeaders(),
    params: {
      Pan: PAN,
      MonetaryClass: monetaryClass,
      MonetaryValue: vendAmount[0],
      TransactionReference: transactionReference,
      TransactionDate: transactionDate,
      TransactionTime: transactionTime,
      RetrievalReference: retrievalReference,
    },
  });
  return response;
};

const callConfirmVendSmartMeterPaygenGeneric = async (props: Props, requestVendProps: RequestVendProps) => {
  const { rootUrl, authHeaders } = props;
  const { PAN, monetaryClass, monetaryValue, retrievalReference, transactionReference } = requestVendProps;
  const { transactionDate, transactionTime } = setHeaders();
  const vendAmount = monetaryValue.split(".");

  const response = axiosClient.post({
    url: `${rootUrl}/smart-metering-paygen-generic/vend/confirm`,
    headers: await authHeaders(),
    params: {
      Pan: PAN,
      MonetaryClass: monetaryClass,
      MonetaryValue: vendAmount[0],
      TransactionReference: transactionReference,
      TransactionDate: transactionDate,
      TransactionTime: transactionTime,
      RetrievalReference: retrievalReference,
    },
  });
  return response;
};

// Smart Meter Payzone (Request, Cancel, Confirm)
const callRequestVendSmartMeterPayzone = async (props: Props, requestVendProps: RequestVendProps) => {
  const { rootUrl, authHeaders } = props;
  const { branchId, ctrId, clerkId, PAN, monetaryClass, monetaryValue } = requestVendProps;
  const { transactionDate, transactionTime, transactionReference, created, reqId } = setHeaders();

  const txId = branchId + "-" + ctrId + "-" + reqId;
  const vendAmount = monetaryValue.split(".");

  const response = await axiosClient.post({
    url: `${rootUrl}/smart-metering-payzone/vend/request`,
    headers: await authHeaders(),
    params: {
      hdr: {
        created: created,
        reqId: reqId,
        txId: txId,
        branchId,
        ctrId,
        clerkId,
      },
      body: {
        PAN: PAN,
        monetaryClass,
        monetaryValue: vendAmount[0],
        transactionReference,
        transactionDate,
        transactionTime,
      },
    },
  });
  response["transactionReference"] = transactionReference;
  return response;
};

const callCancelVendSmartMeterPayzone = async (props: Props, requestVendProps: RequestVendProps) => {
  const { rootUrl, authHeaders } = props;
  const { branchId, ctrId, clerkId, PAN, monetaryClass, monetaryValue, retrievalReference, transactionReference } =
    requestVendProps;
  const { transactionDate, transactionTime, created, reqId } = setHeaders();

  const txId = branchId + "-" + ctrId + "-" + reqId;
  const vendAmount = monetaryValue.split(".");

  const response = axiosClient.post({
    url: `${rootUrl}/smart-metering-payzone/vend/cancel`,
    headers: await authHeaders(),
    params: {
      hdr: {
        created: created,
        reqId: reqId,
        txId: txId,
        branchId,
        ctrId,
        clerkId,
      },
      body: {
        PAN,
        monetaryClass,
        monetaryValue: vendAmount[0],
        transactionReference,
        transactionDate,
        transactionTime,
        retrievalReference,
      },
    },
  });
  return response;
};

const callConfirmVendSmartMeterPayzone = async (props: Props, requestVendProps: RequestVendProps) => {
  const { rootUrl, authHeaders } = props;
  const { branchId, ctrId, clerkId, PAN, monetaryClass, monetaryValue, retrievalReference, transactionReference } =
    requestVendProps;
  const { transactionDate, transactionTime, created, reqId } = setHeaders();

  const txId = branchId + "-" + ctrId + "-" + reqId;
  const vendAmount = monetaryValue.split(".");

  const response = axiosClient.post({
    url: `${rootUrl}/smart-metering-payzone/vend/confirm`,
    headers: await authHeaders(),
    params: {
      hdr: {
        created: created,
        reqId: reqId,
        txId: txId,
        branchId,
        ctrId,
        clerkId,
      },
      body: {
        PAN,
        monetaryClass,
        monetaryValue: vendAmount[0],
        transactionReference,
        transactionDate,
        transactionTime,
        retrievalReference,
      },
    },
  });
  return response;
};

export const buildClient = (props: Props): Client => {
  const requestVend = async (requestVendProps: RequestVendProps): Promise<RequestVendResponse | any> => {
    const { vendType } = requestVendProps;
    try {
      if (vendType == SmartMeterVendTypeActions.SME) {
        const response = await callRequestVendSmartMeterXML(props, requestVendProps);
        return parseVendResponse(response) as unknown as RequestVendResponse;
      } else if (vendType == SmartMeterVendTypeActions.PAYGEN_GENERIC) {
        const response = await callRequestVendSmartMeterPaygenGeneric(props, requestVendProps);
        return parseVendResponse(response) as unknown as RequestVendResponse;
      } else if (vendType == SmartMeterVendTypeActions.PAYZONE) {
        const response = await callRequestVendSmartMeterPayzone(props, requestVendProps);
        return parseVendResponse(response) as unknown as RequestVendResponse;
      } else {
        return Promise.reject({ message: "invalid request vend type" });
      }
    } catch (err) {
      return Promise.reject({ message: "invalid request vend request" });
    }
  };

  const cancelVend = async (requestVendProps: RequestVendProps): Promise<CancelVendResponse> => {
    const { vendType } = requestVendProps;
    try {
      if (vendType == SmartMeterVendTypeActions.SME_CANCEL) {
        const response = await callCancelVendSmartMeterXML(props, requestVendProps);
        return parseConfirmAndCancelVendResponse(response) as unknown as CancelVendResponse;
      } else if (vendType == SmartMeterVendTypeActions.PAYGEN_GENERIC_CANCEL) {
        const response = await callCancelVendSmartMeterPaygenGeneric(props, requestVendProps);
        return parseConfirmAndCancelVendResponse(response) as unknown as CancelVendResponse;
      } else if (vendType == SmartMeterVendTypeActions.PAYZONE_CANCEL) {
        const response = await callCancelVendSmartMeterPayzone(props, requestVendProps);
        return parseConfirmAndCancelVendResponse(response) as unknown as CancelVendResponse;
      } else {
        return Promise.reject({ message: "invalid cancel vend type" });
      }
    } catch (err) {
      return Promise.reject({ message: "invalid cancel vend request" });
    }
  };

  const confirmVend = async (requestVendProps: RequestVendProps): Promise<ConfirmVendResponse> => {
    const { vendType } = requestVendProps;
    try {
      if (vendType == SmartMeterVendTypeActions.SME_CONFIRM) {
        const response = await callConfirmVendSmartMeterXML(props, requestVendProps);
        return parseConfirmAndCancelVendResponse(response) as unknown as ConfirmVendResponse;
      } else if (vendType == SmartMeterVendTypeActions.PAYGEN_GENERIC_CONFIRM) {
        const response = await callConfirmVendSmartMeterPaygenGeneric(props, requestVendProps);
        return parseConfirmAndCancelVendResponse(response) as unknown as ConfirmVendResponse;
      } else if (vendType == SmartMeterVendTypeActions.PAYZONE_CONFIRM) {
        const response = await callConfirmVendSmartMeterPayzone(props, requestVendProps);
        return parseConfirmAndCancelVendResponse(response) as unknown as ConfirmVendResponse;
      } else {
        return Promise.reject({ message: "invalid confirm vend type" });
      }
    } catch (err) {
      return Promise.reject({ message: "invalid confirm vend request" });
    }
  };

  return Object.freeze({ requestVend, cancelVend, confirmVend });
};
