import { axiosClient } from "../lib/axiosWrapper";
import {
  Client,
  Props,
  SellCurrenciesProps,
  SellCurrencies,
  defaultSellCurrencies,
  ExchangeRates,
  ExchangeRatesProps,
  defaultExchangeRates,
  IsFirstTransactionForProps,
  GetExchangeRateParams,
} from "./structures";
import { CacheTypes } from "../lib/cache/index";
import storage from "postoffice-spm-async-storage";
import { getFeatureToggles, getFeatureToggle } from "./feature-toggles";

const cacheKey = "_travelMoneyData";

export const buildClient = (props: Props): Client => {
  const { rootUrl, authHeaders } = props;

  const isFirstTransactionFor = async (isFirstTransactionForProps: IsFirstTransactionForProps): Promise<boolean> => {
    const { transactionType, userId } = isFirstTransactionForProps;
    const today = new Date().toDateString();

    const lastTransactionDateRecord = await storage.getRecord(`${userId}_${transactionType}_lastTransactionDate`);

    if (lastTransactionDateRecord?.value == today) {
      return false;
    }
    await storage.setRecord({ id: `${userId}_${transactionType}_lastTransactionDate`, value: today });
    return true;
  };

  const getSellCurrencies = async (sellCurrenciesProps: SellCurrenciesProps): Promise<SellCurrencies> => {
    const branchId = sellCurrenciesProps.branchId;
    const response = await axiosClient
      .get<SellCurrenciesProps, SellCurrencies>(
        {
          url: `${rootUrl}/foreign-exchange/sell/${branchId}/currencies`,
          headers: await authHeaders(),
        },
        { type: CacheTypes.TIMED, ttl: 720, key: `${cacheKey}/sell/${branchId}/currencies` }
      )
      //eslint-disable-next-line
      .catch((err) => {
        console.error(err);
        return defaultSellCurrencies;
      });

    return response;
  };

  const getExchangeRates = async (exchangeRatesProps: ExchangeRatesProps): Promise<ExchangeRates> => {
    const { branchId, currency, isSterlingAmount, amount, customerSuppliedRate } = exchangeRatesProps;
    const exchangeRates = await axiosClient
      .get<GetExchangeRateParams, ExchangeRates>(
        {
          url: `${rootUrl}/foreign-exchange/sell/${branchId}/calculate-rates/${currency}`,
          headers: await authHeaders(),
          params: {
            isSterlingAmount,
            amount,
            customerSuppliedRate,
          },
        },
        {
          type: CacheTypes.TIMED,
          ttl: 720,
          key: `${cacheKey}/sell/${branchId}/calculate-rates/${currency}?isSterlingAmount=${isSterlingAmount}&amount=${amount}&customerSuppliedRate=${customerSuppliedRate}`,
        }
      )
      //eslint-disable-next-line
      .catch((err) => {
        console.error(err);
        return defaultExchangeRates;
      });

    Object.keys(exchangeRates).forEach((key) => {
      if (!exchangeRates[key].effectiveRate) {
        exchangeRates[key].disabled = true;
      }
    });

    return exchangeRates;
  };

  return Object.freeze({
    getFeatureToggles: getFeatureToggles({ rootUrl, authHeaders }),
    getFeatureToggle: getFeatureToggle({ rootUrl, authHeaders }),
    getSellCurrencies,
    isFirstTransactionFor,
    getExchangeRates,
  });
};
