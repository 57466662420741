/* eslint-disable no-await-in-loop */
import {
  LabelAvailablePrinters,
  LabelPrinterClient,
  LabelPrinterPrintResult,
  generateMonarchHeadLabel,
} from "postoffice-peripheral-management-service";
import moment from "moment";
import { FulfilmentStateEnum, TransactionsApiInterface } from "../../openapi/transaction-api-v3";
import { BasketItemPayload, FulfillerResponse, FulfillmentItem, LabelFulfillerT, UserResponseEnum } from "../../types";
import { getReceipts } from "./receipt";
import { labelPrinterTimeout, PrintStatusEnum, MailsLabelsBroadcastChannel } from "./types";
import { parcelRefNumber } from "./prnGeneration";
import { checkProperties, generateSessionId } from "../helpers";

interface EventData {
  userResponse: string;
}

export const LabelFulfiller = (
  client: TransactionsApiInterface,
  basketID: string,
  basketItem: BasketItemPayload,
  userToken?: string,
  labelClient?: LabelPrinterClient
): LabelFulfillerT => {
  let fulfilmentTokens = {};

  const updateFulfilmentState = async (entryId: string, state: FulfilmentStateEnum, user?: string) => {
    try {
      const transactionApi = await client.updateBasketEntryFulfilment(basketID, entryId, user, {
        fulfilmentState: state,
        fulfilmentTokens,
      });

      // Error handling for updateBasketEntryFulfilment
      if (transactionApi.status !== 200) {
        throw new Error(`Failed to update fulfillment tokens - basketId: ${basketID} / entryId: ${entryId}`);
      }
    } catch (error) {
      // This will include network errors
      const errorResponse = error as Error;
      throw errorResponse;
    }
  };

  const fulfill = async (item: FulfillmentItem): Promise<FulfillerResponse> => {
    const mandatoryKeys = [
      "fadCode",
      "terminalID",
      "labelTemplate",
      "requiredAtDelivery",
      "returnToSenderPostCode",
      "destinationCountry",
      "destinationISOCode",
      "upuTrackingNumber",
      "RMServiceID",
      "weightType",
      "service",
      "postcode",
      "price",
      "vatCode",
      "segCode",
      "barCodeReq",
      "town",
      "weight",
      "satDel",
      "barCodeReq",
      "altServiceId",
      "firstAddressLine",
    ];

    const propertyCheck = checkProperties(item.basketItem?.tokens, mandatoryKeys);
    let labelPrinterResult: LabelPrinterPrintResult | undefined;
    let userResponse: UserResponseEnum;
    let labelPrintErrorFlag = false;
    let errorMessage = "";
    let eventData: EventData;
    let timeout: NodeJS.Timeout;
    let entryId: string;

    let response: FulfillerResponse = {
      result: { result: PrintStatusEnum.LabelPrintError },
    };

    if (basketItem.userResponse === UserResponseEnum.Cancel) {
      const originalItemEntryId = item.basketItem?.originalItemEntryId?.toString();

      if (originalItemEntryId) {
        await updateFulfilmentState(originalItemEntryId, FulfilmentStateEnum.Failure, userToken);
      }

      throw new Error(`Label printing has been cancelled - basketId: ${basketID}`);
    }

    // If we're in a retry flow, use the original item's entry ID to set fulfilment state
    // and not the reject label item's entry ID
    if (
      basketItem.userResponse &&
      basketItem.userResponse === UserResponseEnum.Retry &&
      item.basketItem?.originalItemEntryId
    ) {
      entryId = item.basketItem?.originalItemEntryId.toString();
    }
    // Else if we're not in a retry flow the original item's entry ID will be in the
    // item.basketItem?.entryID field
    else {
      entryId = item.basketItem?.entryID.toString();
    }

    if (!propertyCheck) {
      await updateFulfilmentState(entryId, FulfilmentStateEnum.Failure, userToken);

      throw new Error(`Label printing has been cancelled, mandatory tokens not met - basketId: ${basketID}`);
    }

    const currentDateTime = moment(new Date()).format("DD/MM/YY");

    const fadcode = item.basketItem.tokens?.fadCode ?? " ";
    const terminalID = item.basketItem.tokens?.terminalID ?? " ";
    const usn = `${basketID} + ${item.basketItem.entryID}`;
    const sessionID = generateSessionId(basketID, entryId);
    let prn = "";

    try {
      prn = parcelRefNumber(fadcode, terminalID, usn);
    } catch (error) {
      await updateFulfilmentState(entryId, FulfilmentStateEnum.Failure, userToken);
      throw error;
    }

    const receiptData = getReceipts(item.basketItem, prn);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    const mailsLabelChannel: BroadcastChannel = new BroadcastChannel(MailsLabelsBroadcastChannel);
    try {
      const printerArgs = {
        labelTemplate: item.basketItem.tokens?.labelTemplate ?? null,
        requiredAtDelivery: item.basketItem.tokens?.requiredAtDelivery ?? "",
        returnToSenderPostCode: item.basketItem.tokens?.returnToSenderPostCode ?? "",
        destinationCountry: item.basketItem.tokens?.destinationCountry ?? "",
        destinationISOCode: item.basketItem.tokens?.destinationISOCode ?? "",
        upuTrackingNumber: item.basketItem.tokens?.upuTrackingNumber ?? "",
        serviceId: item.basketItem.tokens?.RMServiceID ?? "",
        weightType: item.basketItem.tokens?.weightType ?? "",
        terminalID,
        prn,
        sessionID,
        fadCode: fadcode,
        service: item.basketItem.tokens?.service ?? "",
        postcode: item.basketItem.tokens?.postcode ?? "",
        price: item.basketItem.tokens?.price ?? "",
        date: currentDateTime,
        vatCode: item.basketItem.tokens?.vatCode ?? "",
        segCode: item.basketItem.tokens?.segCode ?? "",
        town: item.basketItem.tokens?.town ?? "",
        weight: item.basketItem.tokens?.weight ?? "",
        satDel: item.basketItem.tokens?.satDel ?? "",
        barCodeReq: item.basketItem.tokens?.barCodeReq ?? "",
        altServiceId: item.basketItem.tokens?.altServiceId ?? "",
        destAddress1: item.basketItem.tokens?.firstAddressLine ?? "",
      };

      // generate MH label template - May need some logic around carrier for this
      const labelTemplate = generateMonarchHeadLabel(printerArgs);

      timeout = setTimeout(() => {
        throw new Error(`Label printing has timed out - basketId: ${basketID}`);
      }, labelPrinterTimeout);

      // Call label printer - expects true/false
      labelPrinterResult = await labelClient?.print({
        label: labelTemplate,
        printer: LabelAvailablePrinters.MonarchsHead,
      });

      if (labelPrinterResult) {
        clearTimeout(timeout);
      }
    } catch (error) {
      console.log(`Error during label printing: ${error as string}`);
      labelPrintErrorFlag = true;
      errorMessage = error as string;
    }

    fulfilmentTokens = {
      copprn: prn,
    };

    if (labelPrintErrorFlag || labelPrinterResult?.printed === false) {
      mailsLabelChannel.postMessage({ status: PrintStatusEnum.LabelPrintError, errorMessage, receiptData });
    } else {
      mailsLabelChannel.postMessage({ status: PrintStatusEnum.LabelPrintComplete, receiptData });
    }

    // wait for user intervention
    // User responses : next/cancel/retry

    /* eslint-disable prefer-const */
    eventData = await awaitUserResponseFromCt(mailsLabelChannel);

    /* eslint-disable prefer-const */
    userResponse = eventData.userResponse.toLowerCase() as UserResponseEnum;

    // Check is user reponse is valid
    if (!Object.values(UserResponseEnum).includes(userResponse)) {
      mailsLabelChannel.close();
      await updateFulfilmentState(entryId, FulfilmentStateEnum.Failure, userToken);
      throw new Error("User response is not recognised");
    }

    // Success - update entry fulfillment
    if (userResponse === UserResponseEnum.Next) {
      response = {
        result: { result: PrintStatusEnum.LabelPrintComplete },
      };

      await updateFulfilmentState(entryId, FulfilmentStateEnum.Success, userToken);
    } else if (userResponse === UserResponseEnum.Cancel || userResponse === UserResponseEnum.Retry) {
      response = {
        result: { result: PrintStatusEnum.EndFlow },
      };
    }

    mailsLabelChannel.close();

    return response;
  };

  return Object.freeze({ fulfill });
};

const awaitUserResponseFromCt = async (channelName: BroadcastChannel): Promise<EventData> => {
  const channel = channelName;

  return new Promise<EventData>((resolve) => {
    channel.onmessage = async (event: MessageEvent) => {
      const eventData = (await event.data) as EventData;
      return resolve(eventData);
    };
  });
};
