import { PedActions } from "postoffice-peripheral-management-service";

export enum PEDFulfillerActions {
  BalanceEnquiry = "balance",
  CashDeposit = "deposit",
  CashWithdrawal = "withdrawal",
  PinChange = "pinChange",
  Debit = "debit",
  Refund = "refund",
}

export const FulfillerActionsToPedAction = {
  [PEDFulfillerActions.BalanceEnquiry]: PedActions.BalanceEnquiryY,
  [PEDFulfillerActions.CashDeposit]: PedActions.DepositY,
  [PEDFulfillerActions.CashWithdrawal]: PedActions.WithdrawalY,
  [PEDFulfillerActions.PinChange]: PedActions.ChangePinY,
  [PEDFulfillerActions.Debit]: PedActions.DebitY,
  [PEDFulfillerActions.Refund]: PedActions.RefundY,
};

export type ReceiptTemplates = {
  [key: string]: string;
};
