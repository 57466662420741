import { AuthProvider } from "../types";
import { axiosClient } from "../lib/axiosWrapper";
import { CacheTypes } from "../lib/cache/index";

/*
 * DTO that represents a request to Payout lambda function.
 */
export interface VoucherDetailsRequest {
  BarCode: string;
  ProdNo: string;
  SuccessfullRequest: boolean;
}

/*
  DTO that represents data returned from remote call.
*/
export interface PayoutVoucher {
  SuccessfullRequest: boolean;
  VoucherId?: string;
  ProductType?: string;
  SchemeId?: string;
  Message?: string;
  Message2?: string;
  Message3?: string;
  AdditionaTypesId?: string[];
  ExpirationDate?: string;
  Value?: string;
  ClientName?: string;
  CompanyName?: string;
  CampaignName?: string;
  PayeeName?: string;
  PayeeAddress?: string[];
  POItype?: string;
  State?: string;
}

export interface Client {
  getVoucherDetails(params: VoucherDetailsRequest): Promise<PayoutVoucher>;
}

export type Props = {
  rootUrl: string;
  authHeaders: AuthProvider;
};

// Remote response shall be temporary cached with that specific key:
const cacheKey = "_voucherData";

/*
  Actual method that performs remote call for Lambda.
*/
export const buildClient = (props: Props): Client => {
  const { rootUrl, authHeaders } = props;
  console.log("payout API request");

  const getVoucherDetails = async (params: VoucherDetailsRequest): Promise<PayoutVoucher> => {
    const response = await axiosClient
      .get<PayoutVoucher>(
        { url: `${rootUrl}/payout/voucherdetails`, params, headers: await authHeaders() },
        { type: CacheTypes.TIMED, ttl: 1, key: `${cacheKey}/test-invoke-stage` }
      )
      .then((res) => {
        console.log("payout API response", res);
        return res;
      })
      //eslint-disable-next-line
      .catch((err) => {
        console.log("payout API error", err);
        return { SuccessfullRequest: false };
      });

    return response;
  };

  return Object.freeze({ getVoucherDetails });
};
