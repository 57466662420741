import { Node } from "../openapi/journey-api";
import { AuthProvider } from "../types";
import { axiosClient } from "../lib/axiosWrapper";
import { CacheTypes } from "../lib/cache/index";
import cacheRegistry from "../lib/cache/registry";

const JourneyCacheKey = "_journeyData";

interface Client {
  get(journeyVersion?: string, params?: Record<string, string>): Promise<Node>;
}

interface Props {
  rootUrl: string;
  authHeaders: AuthProvider;
}

export type ClientBuilder = (props: Props) => Client;
export const buildClient = (props: Props): Client => {
  const { rootUrl, authHeaders } = props;

  const getJourney = async (journeyVersion, params): Promise<Node> => {
    if (journeyVersion === undefined) {
      journeyVersion = "";
    }
    let queryString = `${journeyVersion}`;
    if (params) {
      queryString = `${queryString}?${Object.keys(params)
        .map((lineItem) => {
          return `${lineItem}=${params[lineItem]}`;
        })
        .join("&")}`;
    }

    const response = await axiosClient.get<Node>(
      {
        url: `${rootUrl}/user-journey/${queryString}`,
        headers: await authHeaders(),
      },
      { type: CacheTypes.TIMED, ttl: 1, key: `${JourneyCacheKey}` }
    );
    return response;
  };

  return Object.freeze({ get: getJourney });
};

cacheRegistry.register({
  name: "journey",
  prefix: JourneyCacheKey,
  keys: [],
});
