/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { buildFmccClient, AuthProvider } from "postoffice-product-journey-api-clients";
import { FulfilmentStateEnum } from "../../../openapi/transaction-api-v3";
import { ApiClientsConfig, BasketItemPayload, FulfilmentProcessorResponse } from "../../../types";
import { createBranchOrderRequestParams, areFulfilmentPropsValid } from "../helpers";
import {CreateBranchOrderFulfilmentProps} from "../types"

export const process = async (
  basketId: string,
  apiConfig: ApiClientsConfig["fmcc"],
  basketItem: BasketItemPayload,
  authProvider: AuthProvider
): Promise<FulfilmentProcessorResponse> => {
  const apiClient = buildFmccClient({
    authHeaders: authProvider,
    ...apiConfig,
  });

  if (!basketItem.fulfilment || !areFulfilmentPropsValid) {
    return {
      fulfilment: {
        status: FulfilmentStateEnum.Failure,
        tokens: {},
      },
    };
  }

  await apiClient.createBranchOrder(createBranchOrderRequestParams(basketId, basketItem.fulfilment as CreateBranchOrderFulfilmentProps)).catch((err) => {
    console.log("Error when sending createBranchOrder request: ", err);
  });

  return {
    fulfilment: {
      status: FulfilmentStateEnum.Success,
      tokens: {},
    },
  };
};
