import { axiosClient } from "../lib/axiosWrapper";
import {
  Client,
  Props,
  EkycForeignExchangeResponse,
  EkycForeignExchangeParams,
  EkycVerifyRequestForeignExchangeProps,
} from "./structures";
import { generateTransactionReference, removeBlanksFromAddresses, reverseDate } from "./helpers";

export const buildClient = (props: Props): Client => {
  const { rootUrl, authHeaders } = props;

  const verifyRequestForeignExchange = async (
    ekycVerifyRequestProps: EkycVerifyRequestForeignExchangeProps
  ): Promise<EkycForeignExchangeResponse> => {
    const { sixDigitFADCode, nodeId, names, addresses, DOB, POID_Type, POID_Ref, POID_CountryCode, POID_DOE } =
      ekycVerifyRequestProps;

    const ekycParams = {
      transactionReference: generateTransactionReference(sixDigitFADCode, nodeId),
      names,
      addresses: removeBlanksFromAddresses(addresses),
      DOB: reverseDate(DOB),
      POID_Type,
      POID_Ref,
      POID_CountryCode,
      POID_DOE: reverseDate(POID_DOE),
    };

    const response = await axiosClient
      .post<EkycForeignExchangeParams, EkycForeignExchangeResponse>({
        url: `${rootUrl}/ekyc/verifyRequest`,
        params: ekycParams,
        headers: await authHeaders(),
      })
      .catch((err) => {
        console.error(err);
        return {
          decision: "N",
          eKYCPass: "N",
          pepPresent: "",
          sanctionList: [],
          sanctionsPresent: "",
          status: "failure",
          statusCode: err.statusCode,
          errorMessage: err.message,
        };
      });

    return response;
  };

  return Object.freeze({
    verifyRequestForeignExchange,
  });
};
