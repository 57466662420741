import { bytesToString } from "./shared";

function encodeForCodeSetC(digits: string): string {
  return bytesToString(
    ...(digits.match(/../g)?.map((val) => Number(val)) ?? [])
  );
}

function generateInnerBarcode(remainingBarcode: string): string {
  const innerMatches = remainingBarcode.matchAll(/(.*?)((?:\d{2}){3,})/g);
  let innerBarcode = "";
  for (const innerMatch of innerMatches) {
    remainingBarcode = remainingBarcode.substring(innerMatch[0].length);
    innerBarcode += "{B";
    innerBarcode += innerMatch[1].replace("{", "{{");
    innerBarcode += "{C";
    innerBarcode += encodeForCodeSetC(innerMatch[2]);
  }
  innerBarcode += "{B";
  innerBarcode += remainingBarcode;
  return innerBarcode;
}

function generateBarcodeData(barcodeText: string): string {
  let barcode = "";
  const initialMatch = barcodeText.match(/^(?:\d{2}){2,}/);
  const afterInitial = barcodeText.substring(initialMatch?.[0].length ?? 0);

  const endMatch = afterInitial.match(/(?:\d{2}){2,}$/);
  const withoutEnd = afterInitial.substring(
    0,
    afterInitial.length - (endMatch?.[0].length ?? 0)
  );

  if (initialMatch !== null && initialMatch.length > 0) {
    barcode += "{C";
    barcode += encodeForCodeSetC(initialMatch[0]);
  }
  if (withoutEnd.length > 0) {
    barcode += generateInnerBarcode(withoutEnd);
  }
  if (endMatch !== null && endMatch.length > 0) {
    barcode += "{C";
    barcode += encodeForCodeSetC(endMatch[0]);
  }

  return barcode;
}

export function generateCode128(barcodeText: string): string {
  let barcode = "";

  // generate the barcode data
  const barcodeString = generateBarcodeData(barcodeText);
  // { is an escape character, so the actual length of the barcode for density purposes shouldn't include it
  const length = barcodeString.replace(/{./g, "{").length;
  // Prefer to use the default density, but we can only show 15 symbols at that density
  const density = length < 16 ? 3 : length < 24 ? 2 : 1;
  const showHRI = false;

  // 1B 61 n - Select justification (0x01 = center)
  barcode += bytesToString(0x1b, 0x61, 0x01);
  // 1D 77 n - Select barcode width
  barcode += bytesToString(0x1d, 0x77, density);
  // 1D 68 n - Select barcode height (0x60 = 96dots)
  barcode += bytesToString(0x1d, 0x68, 0x60);
  // 1D 48 n - Select printing position of HRI characters (0x00 = not printed, 0x02 = below the barcode)
  barcode += bytesToString(0x1d, 0x48, showHRI ? 0x02 : 0x00);

  // 1D 6B m n - Print barcode (0x49 = code128; string length)
  barcode += bytesToString(0x1d, 0x6b, 0x49, barcodeString.length);

  // Add the actual barcode data
  barcode += barcodeString;

  // 1B 61 n - Select justification (0x00 = left)
  barcode += bytesToString(0x1b, 0x61, 0x00);
  return barcode;
}
