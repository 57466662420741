import { IssuerSchemes } from "../openapi/tokeniser-api";
import {
  BankingTokeniserParams,
  BankingTokeniserResult,
  Client,
  GetTransactionIdParams,
  PaymentTokeniserParams,
  PaymentTokeniserResult,
  Props,
  TransactionIdResponse,
  TransactionTypes,
} from "./types";
import { buildClient as buildTokeniserClient } from "../tokeniser-api/index";
import { PatternMapping } from "../tokeniser-api/types";
import { makeMapper } from "../tokeniser-api/mapper";
import axios, { AxiosError } from "axios";
import { ApiError } from "types";

const formatPan = (pan: string): string => {
  return `${pan.replace(/x/g, "0")}=`;
};

export const buildClient = (props: Props): Client => {
  const { rootUrl, authHeaders } = props;

  const objectsToArray = async (params: Record<string, unknown>): Promise<Record<string, unknown>> => {
    return Object.keys(params).map((k) => params[k]) as unknown as Record<string, unknown>;
  };

  const getTransactionId = async (params: GetTransactionIdParams): Promise<TransactionIdResponse | ApiError> => {
    const path = params.type === TransactionTypes.Payments ? "payments" : "banking";

    try {
      const response = await axios.get(`${rootUrl}/worldline-transaction-id-generator/${path}`, {
        headers: await authHeaders(),
      });

      return response.data as TransactionIdResponse;
    } catch (error: unknown) {
      const err = error as AxiosError;
      return Promise.reject({
        statusCode: err.response?.status || 500,
        ...(err.response?.data?.message && { message: err.response?.data?.message }),
      });
    }
  };

  const bankingTokeniserLookup = async (params: BankingTokeniserParams): Promise<BankingTokeniserResult> => {
    const maskedPan = formatPan(params.maskedPan);

    const tokeniserClient = buildTokeniserClient({
      rootUrl,
      authHeaders,
    });

    const tokenMaskResponse = (await tokeniserClient.tokenMask(TransactionTypes.Banking)) as PatternMapping[];

    const mappedToken = await makeMapper(tokenMaskResponse).call(maskedPan);

    if (!mappedToken) {
      return {
        isValidToken: false,
        isValidOperation: false,
      };
    }

    const issuerSchemes = await tokeniserClient.issuerSchemes();

    const operation = issuerSchemes
      .filter(
        (issuer: IssuerSchemes) =>
          issuer.issuerscheme === mappedToken.IssuerScheme && issuer.defname === params.operation
      )
      .shift();

    if (!operation) {
      return {
        isValidToken: true,
        isValidOperation: false,
        tokenmask: mappedToken,
      };
    }

    const items = await tokeniserClient.getItems();
    const item = items.find((item) => item.itemID === operation.prodno);

    return {
      item,
      operation,
      tokenmask: mappedToken,
      isValidToken: true,
      isValidOperation: true,
    };
  };

  const paymentTokeniserLookup = async (params: PaymentTokeniserParams): Promise<PaymentTokeniserResult> => {
    const maskedPan = formatPan(params.maskedPan);

    const tokeniserClient = buildTokeniserClient({
      rootUrl,
      authHeaders,
    });

    const tokenMaskResponse = (await tokeniserClient.tokenMask(TransactionTypes.Payments)) as PatternMapping[];

    const tokenMask = await makeMapper(tokenMaskResponse).call(maskedPan);

    if (!tokenMask) {
      return {};
    }

    const issuerSchemes = await tokeniserClient.issuerSchemes();

    const scheme = issuerSchemes
      .filter(
        (issuer: IssuerSchemes) =>
          issuer.issuerscheme === tokenMask.IssuerScheme &&
          issuer.mode === params.transactionMode &&
          params.settlementType == issuer.settlementType
      )
      .shift();

    if (!scheme) {
      return {};
    }

    const items = await tokeniserClient.getItems();
    const item = items.find((item) => item.itemID === scheme.prodno);

    return {
      item,
      tokenMask,
      scheme,
    };
  };

  return Object.freeze({ paymentTokeniserLookup, bankingTokeniserLookup, getTransactionId, objectsToArray });
};
