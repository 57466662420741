import { axiosClient } from "../lib/axiosWrapper";

export type AuthProvider = () => Promise<{ Authorization: string }>;
export type Params = () => Promise<{ [key: string]: string }>;

export interface DataRecords {
  id: string;
  value: string | DataRecords[];
}

export interface DataResponse {
  hash: string;
  data: DataRecords[];
  pages: number;
}

export interface Client {
  getConfig(page: string): Promise<DataResponse>;
  hash(): Promise<string>;
  data(): Promise<DataResponse>;
}

const client = (root: string, authHeaders: AuthProvider): Client => {
  const hash = async (): Promise<string> => {
    return axiosClient.get<string>({
      url: `${root}/configuration/hash`,
      headers: await authHeaders(),
    });
  };

  const data = async (): Promise<DataResponse> => {
    return axiosClient.get<DataResponse>({
      url: `${root}/configuration/data`,
      headers: await authHeaders(),
    });
  };

  const getConfig = async (page: string): Promise<DataResponse> => {
    return await axiosClient.getWithCache<DataResponse>({
      url: `${root}/configuration/config?page=${page}`,
      headers: await authHeaders(),
    });
  };

  return Object.freeze({ hash, data, getConfig });
};

export default client;
