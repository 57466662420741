import { AuthProvider } from "postoffice-product-journey-api-clients";
import { FulfilmentStateEnum, TransactionsApiInterface } from "../../openapi/transaction-api-v3";
import {
  ApiClientsConfig,
  BasketItemPayload,
  FulfillerResponse,
  FulfillmentItem,
  FulfilmentProcessorResponse,
  GenericFulfiller,
} from "../../types";
import { DropAndGoFulfillerActions } from "./types";
import { process as processLostOrDamagedCard } from "./actions/lostOrDamagedCard";
import { process as processEditAccount } from "./actions/editAccount";
import { process as processRequestClose } from "./actions/requestClose";
import { process as processCompleteClose } from "./actions/completeClose";
import { respond } from "../../helpers";
import { brokenPromises, updateFulfilmentTokens } from "../helpers";

export const DropAndGoFulfiller = (
  client: TransactionsApiInterface,
  apiConfig: ApiClientsConfig["dropandgo"],
  basketId: string,
  basketItem: BasketItemPayload,
  userToken: string,
  deviceToken: string
): GenericFulfiller => {
  const authHeaders: AuthProvider = async () => {
    return new Promise((resolve) => {
      resolve({ Authorization: deviceToken });
    });
  };

  const fulfill = async (item: FulfillmentItem): Promise<FulfillerResponse> => {
    if (!item.basketItem?.fulfilment?.action) {
      throw Error("fulfilment.action must be set in journey");
    }

    const fulfilmentAction = item.basketItem.fulfilment.action as DropAndGoFulfillerActions;

    let response: FulfilmentProcessorResponse;

    switch (fulfilmentAction) {
      case DropAndGoFulfillerActions.LostOrDamagedCard:
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        response = await brokenPromises(processLostOrDamagedCard(basketId, apiConfig, basketItem, authHeaders));
        break;
      case DropAndGoFulfillerActions.EditAccount:
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        response = await brokenPromises(processEditAccount(basketId, apiConfig, basketItem, authHeaders));
        break;
      case DropAndGoFulfillerActions.RequestClose:
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        response = await brokenPromises(processRequestClose(basketId, apiConfig, basketItem, authHeaders));
        break;
      case DropAndGoFulfillerActions.CompleteClose:
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        response = await brokenPromises(processCompleteClose(basketId, apiConfig, basketItem, authHeaders));
        break;
      default:
        throw new Error(`Unknown fulfilment action: ${item.basketItem?.fulfilment?.action}`);
    }

    const fulfilmentStatus = response.fulfilment?.status ?? FulfilmentStateEnum.Failure;
    const fulfilmentTokens = response.fulfilment?.tokens ?? {};
    const isSuccessful = fulfilmentStatus === FulfilmentStateEnum.Success;

    const updated = await updateFulfilmentTokens(
      client,
      basketId,
      String(basketItem.entryID),
      fulfilmentStatus,
      fulfilmentTokens,
      userToken
    );

    return respond(isSuccessful && updated, {
      result: {},
      networkError: !updated,
      ...(response.receipts && {
        receipts: response.receipts,
      }),
      ...(response.notice && {
        notice: response.notice,
      }),
    });
  };

  return Object.freeze({ fulfill });
};
