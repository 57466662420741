export interface LabelPrinterPrintResult {
  printed: boolean;
}

export interface LabelPrinterCalibrateResult {
  calibrated: boolean;
}

export interface LabelPrinterClient {
  print(props: LabelPrinterPrintProps): Promise<LabelPrinterPrintResult>;
  calibrate(): Promise<LabelPrinterCalibrateResult>;
  printConfigurationLabels(): Promise<LabelPrinterPrintResult>;
}

export interface LabelPrinterPrintProps {
  printer: LabelAvailablePrinters;
  label: string;
}

export interface LabelPrinterClientProps {
  useMock?: boolean;
}

export enum LabelPrinterActions {
  Print = "PRINT",
  Calibrate = "CALIBRATE",
  PrintConfigurationLabels = "PRINT_CONFIGURATION",
}

export enum LabelAvailablePrinters {
  RoyalMail = "label_printer_1",
  MonarchsHead = "label_printer_2",
}

/*
 * Token Mapping avaliable on confluence here:
 * https://pol-jira.atlassian.net/wiki/spaces/SPM/pages/10804855306/Postage+Sale+-+MH+Tokens+Mapping
 */

export interface MonarchHeadLabelParams {
  prn: string;
  barCodeReq: string;
  labelTemplate: string | null;
  requiredAtDelivery: string;
  returnToSenderPostCode: string;
  destinationCountry: string;
  destinationISOCode: string;
  upuTrackingNumber: string;
  serviceId: string;
  RMFormat: string;
  RMClass: string;
  weightType: string;
  terminalID: string;
  fadCode: string;
  service: string;
  postcode: string;
  destAddress1: string;
  weight: string;
  price: string;
  date: string;
  vatCode: string;
  sessionID: string;
  segCode: string;
  satDel: string;
  town: string;
  altServiceId: string;
}

export interface OlpLabelParams {
  qrcode: string;
  serviceName1: string;
  serviceName2: string;
  serviceName3: string;
  templateId: string;
  destinationCountry: string;
}
