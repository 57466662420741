import { AuthProvider } from "./../types";
import storage from "postoffice-spm-async-storage";
import { DataRecords } from "configuration-api";
import axios, { AxiosError } from "axios";
import { ApiError } from "../types";

import {
  ConfirmTransactionRequestBody,
  ConfirmTransactionResponseBody,
  ValidateTransactionRequestBody,
  ValidateTransactionResponseBody,
} from "../openapi/boe-note-exchange-api";

export interface Props {
  rootUrl: string;
  authHeaders: AuthProvider;
}

export interface Client {
  validate(params: ValidateTransactionRequestBody): Promise<ValidateTransactionResponseBody | ApiError>;
  confirm(params: ConfirmTransactionRequestBody): Promise<ConfirmTransactionResponseBody | ApiError>;
  getCountries(): Promise<Countries>;
}

export type CountryItem = {
  A2: string;
  A3: string;
  title: string;
  value: string;
  Id: string;
};

export type Countries = CountryItem[];

export const buildClient = (props: Props): Client => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { rootUrl, authHeaders } = props;

  const validate = async (
    params: ValidateTransactionRequestBody
  ): Promise<ValidateTransactionResponseBody | ApiError> => {
    try {
      const response = await axios.post(`${rootUrl}/boe-note-exchange/transaction/validate`, params, {
        headers: await authHeaders(),
      });
      return response.data as ValidateTransactionResponseBody;
    } catch (error: unknown) {
      return processError(error as AxiosError) as ValidateTransactionResponseBody | ApiError;
    }
  };

  const confirm = async (params: ConfirmTransactionRequestBody): Promise<ConfirmTransactionResponseBody | ApiError> => {
    try {
      const response = await axios.post(`${rootUrl}/boe-note-exchange/transaction/confirm`, params, {
        headers: await authHeaders(),
      });
      return response.data as ConfirmTransactionResponseBody;
    } catch (error: unknown) {
      return processError(error as AxiosError) as ConfirmTransactionResponseBody | ApiError;
    }
  };

  const getCountries = async (): Promise<Countries> => {
    const countries = (await storage.getRecord(
      "_config/banking/bank-of-england-notes-exchange/countries"
    )) as unknown as DataRecords;
    if (!countries.value) {
      return Promise.reject({
        statusCode: 500,
        message: "Unable to locate countries from storage",
      });
    }
    return JSON.parse(countries.value as string) as unknown as Countries;
  };

  /**
   * Resolve error based on status code
   * @param error AxiosError
   * @returns Promise<unknown>
   */
  const processError = (error: AxiosError): Promise<unknown> => {
    // business error include 400 and 412.
    if ([400, 412].includes(error.response?.status as number)) {
      return Promise.resolve(error.response?.data);
    }
    return Promise.reject({
      statusCode: error.response?.status || 500,
      ...(error.response?.data && error.response?.data),
    });
  };

  return Object.freeze({ validate, confirm, getCountries });
};
