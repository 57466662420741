import {
  AssignDngCardRequest,
  AssignDngCardResponse,
  CreateDngAccountRequest,
  CreateDngAccountResponse,
  CreateOLMOrderRequest,
  CreateOLMOrderResponse,
  GetDngAccDetailsRequest,
  GetDngAccDetailsResponse,
  PostTxnToDngAccountRequest,
  PostTxnToDngAccountResponse,
  UpdateDngAccountRequest,
  UpdateDngAccountResponse,
  UpdateDngAccStatusRequest,
  UpdateDngAccStatusResponse,
  UpdateDngCardStatusRequest,
  UpdateDngCardStatusResponse,
  UpdateDngAccStatusReqBodyUpdateDnGAccountStatusToEnum,
  PostTxnToDngAccountReqBodyTransactionTypeEnum,
  PostTxnToDngAccountReqBodySystemEnum,
  PostTxnToDngAccountReqBodyTransactionDescriptionEnum,
  PostTxnToDngAccountReqBodyEndMailsTimeoutEnum,
  UpdateDngCardStatusReqBodyUpdateDnGCardStatusToEnum,
  HdrReq,
  HdrResp,
} from "../openapi/drop-and-go-api";
import { Client } from "./types";

export type {
  AssignDngCardRequest,
  AssignDngCardResponse,
  CreateDngAccountRequest,
  CreateDngAccountResponse,
  CreateOLMOrderRequest,
  CreateOLMOrderResponse,
  GetDngAccDetailsRequest,
  GetDngAccDetailsResponse,
  PostTxnToDngAccountRequest,
  PostTxnToDngAccountResponse,
  UpdateDngAccountRequest,
  UpdateDngAccountResponse,
  UpdateDngAccStatusRequest,
  UpdateDngAccStatusResponse,
  UpdateDngCardStatusRequest,
  UpdateDngCardStatusResponse,
  HdrReq,
  HdrResp,
  Client as DropAndGoClient,
};

export {
  UpdateDngAccStatusReqBodyUpdateDnGAccountStatusToEnum,
  PostTxnToDngAccountReqBodyTransactionTypeEnum,
  PostTxnToDngAccountReqBodySystemEnum,
  PostTxnToDngAccountReqBodyTransactionDescriptionEnum,
  PostTxnToDngAccountReqBodyEndMailsTimeoutEnum,
  UpdateDngCardStatusReqBodyUpdateDnGCardStatusToEnum,
};
