// printer escape sequences to allow formatting of text
const ESC = String.fromCharCode(27);
const BOLD = ESC + "|bC";
const UNDERLINE = ESC + "|uC";
const ITALIC = ESC + "|iC";
const CUT = ESC + "|P";
const STOP_ESC = ESC + "|N";

export const constants = {
  ESC,
  BOLD,
  UNDERLINE,
  ITALIC,
  CUT,
  STOP_ESC,
};

export const stripEscapeChars = (text: string) => {
  try {
    [BOLD, UNDERLINE, ITALIC, CUT, STOP_ESC, "<b>", "</b>"].forEach(
      (escapeChar: string) => {
        text = text.split(escapeChar).join(""); // Remove escape sequence from string
      }
    );
    return text;
  } catch (err) {
    return text;
  }
};

export const wrapRightText = (
  leftText: string,
  rightText: string,
  printWidthChars: number
) => {
  if (rightText.length < printWidthChars - leftText.length) {
    return " " + rightText;
  }
  let lastSpaceIndex = rightText.lastIndexOf(
    " ",
    printWidthChars - 1 - leftText.length
  );
  if (
    lastSpaceIndex !== -1 &&
    lastSpaceIndex < printWidthChars - leftText.length
  ) {
    return ` ${rightText.slice(0, lastSpaceIndex)}\n${" ".repeat(
      leftText.length
    )}${wrapRightText(
      leftText,
      rightText.slice(lastSpaceIndex + 1),
      printWidthChars
    )}`;
  } else {
    lastSpaceIndex = rightText.lastIndexOf(
      " ",
      printWidthChars - leftText.length
    );
    if (lastSpaceIndex !== -1) {
      return `${rightText.slice(0, lastSpaceIndex)}\n${" ".repeat(
        leftText.length
      )}${wrapRightText(
        leftText,
        rightText.slice(lastSpaceIndex + 1),
        printWidthChars
      )}`;
    }
  }
};

export const twoColumnWrapRightText = (
  leftText: string,
  rightText: string,
  printWidthChars: number
) => {
  const leftLength = leftText.length;
  const rightLength = rightText.length;
  const totalLength = leftLength + rightLength;

  if (totalLength === printWidthChars) {
    return `${leftText}${rightText}`;
  } else if (totalLength > printWidthChars) {
    return (
      leftText +
      wrapRightText(`${leftText}`, `${rightText}`.trim(), printWidthChars)
    );
  } else {
    const padding = " ".repeat(printWidthChars - (leftLength + rightLength));
    return `${leftText}${padding}${rightText}`;
  }
};
