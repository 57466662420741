import storage from "postoffice-spm-async-storage";
import { DataRecords } from "../configuration-api";
import { axiosClient } from "../lib/axiosWrapper";
import { IssuerSchemes, Items, TokenMask } from "../openapi/tokeniser-api";
import { AuthProvider, EnablerAPIClientNames } from "../types";

import { CacheTypes } from "../lib/cache/index";
import cacheRegistry from "../lib/cache/registry";
import { defaultL2GMapping, getBarcodeType, getItemDetails } from "./helpers";
import { mapPattern, mappedJGB4BarcodeData, parse1DBarcode, qrCodeParsor } from "./mapper";
import {
  JGB4MappedData,
  MaybeTokeniseParams,
  ParamsGetProduct,
  ParamsMail,
  ParamsTokenise,
  ParsedPatternFields,
  PatternMapping,
  PatternMappingField,
  TokenType,
} from "./types";

const cacheKey = "_tokeniserData";

const MODULUS11_WEIGHT = "86423597";

const cacheKeySuffix = {
  mask: "mask",
  schemes: "schemes",
  items: "items",
  maskBarcode: "mask/barcode",
  maskMail: "mask/mail",
  maskBanking: "mask/banking",
  maskMagcard: "mask/magcard",
  maskPayment: "mask/payment",
};

export interface Client {
  tokenMask(tokenType: string): Promise<TokenMask[]>;
  tokenise(params: ParamsTokenise): Promise<Record<string, unknown>>;
  maybeTokenise(params: MaybeTokeniseParams): Promise<Record<string, unknown>>;
  mail(params: ParamsMail): Promise<Record<string, unknown>>;
  dangerousGoods(params: ParamsTokenise): Promise<Record<string, unknown>>;
  getProduct(params: ParamsGetProduct): Promise<Record<string, unknown>>;
  issuerSchemes(): Promise<IssuerSchemes[]>;
  getItems(): Promise<Items[]>;
  tokeniseJGB4(params: ParamsTokenise): Promise<Record<string, unknown>>;
  prepareDataForJGB4Barcode(params: ParsedPatternFields): Promise<Record<string, unknown>>;
  parseQRCode(params: ParamsTokenise): Promise<Record<string, unknown>>;
  validateBarcode(barcode, cdId: string, checkDataStart, checkDataLen, checkDigit: number): boolean;
}
export interface Props {
  rootUrl: string;
  authHeaders: AuthProvider;
  isMock?: boolean;
}

export type JGB4Tokens = {
  displayTokens: {
    Price: string;
    Weight: string;
    Postcode: string;
    Building: string;
    FadCode: string;
    ProdDate: string;
    PRN: string;
    UPUTrackingNumber: string;
  };
  parsedBarcode: ParsedPatternFields;
};

export const buildClient = (props: Props): Client => {
  const { rootUrl, authHeaders, isMock } = props;
  const tokeniserBasePath = isMock ? `${rootUrl}/tokeniser-mock` : `${rootUrl}/tokeniser`;

  const tokenMask = async (tokenType: string): Promise<TokenMask[]> => {
    return axiosClient.get<TokenMask[]>(
      {
        url: `${tokeniserBasePath}/tokenmask?tokenType=${tokenType}`,
        headers: await authHeaders(),
      },
      { type: CacheTypes.TIMED, ttl: 60 * 12, key: `${cacheKey}/${cacheKeySuffix.mask}/${tokenType}` }
    );
  };

  /**
   * Used by counter terminal to initiate a journey from a token scan
   *
   * Allows counter to check multiple token types for the entered value,
   * i.e. barcode -> magcard and vice versa.
   *
   * @param params MaybeTokeniseParams
   * @returns Promise<Record<string, unknown>>
   */
  const maybeTokenise = async (params: MaybeTokeniseParams): Promise<Record<string, unknown>> => {
    for (const tokenType of params.typePrecedence) {
      const tokenMaskResponse = (await tokenMask(tokenType)) as PatternMapping[];
      const mappedTokens = mapPattern(params.value, tokenMaskResponse) as ParsedPatternFields;

      if (!mappedTokens) {
        continue;
      }

      mappedTokens.Type = getBarcodeType(mappedTokens.name as string);

      const itemsResponse = await getItems();
      const itemDetails = getItemDetails(mappedTokens.ProdNo as string, itemsResponse as Record<string, unknown>[]);

      if (!itemDetails) {
        continue;
      }

      return { ...mappedTokens, ...itemDetails, rawBarcode: params.value };
    }

    return {};
  };

  // FIXME: look for nicer solution
  const tokenise = async (params: ParamsTokenise): Promise<Record<string, unknown>> => {
    const tokenType = params.tokenType ?? TokenType.BARCODE;
    const tokenMaskResponse = (await tokenMask(tokenType)) as PatternMapping[];
    const parsedBarcode = mapPattern(params.barcode, tokenMaskResponse) as ParsedPatternFields;

    if (!parsedBarcode && params.errorOnFailure) {
      return Promise.reject({
        statusCode: 422,
        message: `Unable to locate mask for barcode ${params.barcode}`,
      });
    }

    if (!params.skipIntegrityChecks) {
      let checkDataStart = 0,
        checkDataLen = 0;
      const cdField = tokenMaskResponse
        .find((mapping) => mapping.Constants.name == parsedBarcode.name)
        ?.fields.find((field) => field.name == "CheckData");
      if (cdField && parsedBarcode.CDId) {
        checkDataStart = cdField.start;
        checkDataLen = cdField.length;
        const isBarcodeValid = validateBarcode(
          params.barcode,
          parsedBarcode.CDId as string,
          checkDataStart,
          checkDataLen,
          parsedBarcode.CheckDigit as number
        );
        if (!isBarcodeValid) {
          return Promise.reject({
            message: `Barcode ${parsedBarcode.name} is invalid: check digit not found in ${params.barcode}`,
          });
        }
      }
    }

    // TODO: Remove me: once API giving correct data
    parsedBarcode.Type = getBarcodeType(parsedBarcode.name as string);

    if (params.shouldGetItems === 1) {
      const itemsResponse = await getItems();
      const itemDetails = getItemDetails(parsedBarcode.ProdNo as string, itemsResponse as Record<string, unknown>[]);
      // currently, if a product cannot be found against /items but exists
      // within tokenmasks, it will return the item as null. This optional flag
      // ensures that an exception is thrown which allows the journey to present
      // an appropriate error message for the end user
      if (params.errorOnFailure && !itemDetails) {
        return Promise.reject({
          statusCode: 422,
          message: `Unable to locate item ${parsedBarcode.ProdNo}`,
        });
      }
      return { ...parsedBarcode, ...itemDetails, rawBarcode: params.barcode };
    }
    return parsedBarcode;
  };

  const mail = async (params: ParamsMail): Promise<Record<string, unknown>> => {
    const tokenMaskResponse = (await tokenMask(TokenType.MAIL)) as PatternMapping[];
    const parsedBarcode = parse1DBarcode(params.barcode, tokenMaskResponse) as ParsedPatternFields;

    const checkData = (parsedBarcode.fields as PatternMappingField[]).find(
      (field) => field.name === "CheckData"
    ) as PatternMappingField;
    const checkDigit = (parsedBarcode.fields as PatternMappingField[]).find(
      (field) => field.name === "CheckDigit"
    ) as PatternMappingField;

    const isBarcodeValid = validateBarcode(
      String(params.barcode),
      String(parsedBarcode.CDId),
      Number(checkData.start),
      Number(checkData.length),
      Number(params.barcode[Number(checkDigit.start)])
    );
    if (!isBarcodeValid) {
      return Promise.reject({
        message: `Barcode ${parsedBarcode.name} is invalid: check digit ${parsedBarcode.CheckDigit} not found in ${params.barcode}`,
      });
    }

    if (params.shouldGetItems === 1) {
      const itemsResponse = await getItems();
      const itemDetails = getItemDetails(parsedBarcode.ProdNo as string, itemsResponse as Record<string, unknown>[]);
      return { ...parsedBarcode, ...itemDetails };
    }

    return parsedBarcode;
  };

  const dangerousGoods = async (params: ParamsTokenise): Promise<Record<string, unknown>> => {
    const dangerousGoodsTokens = (await storage.getRecord("_config/dangerousgoods/tokens")) as unknown as DataRecords;

    if (!dangerousGoodsTokens || !dangerousGoodsTokens.value) {
      return Promise.reject({
        statusCode: 422,
        message: "Dangerouse goods tokens file could not be found",
      });
    }

    const dangerousGoodsTokensString = (dangerousGoodsTokens.value as string).replace(/\\w/gi, "\\\\w");
    let parsedDangerousGoodsTokens: PatternMapping[];
    try {
      parsedDangerousGoodsTokens = JSON.parse(dangerousGoodsTokensString);
    } catch (e) {
      return Promise.reject({
        statusCode: 422,
        message: `attempt to parse the stamps string to json failed with error: ${e}`,
      });
    }

    const parsedBarcode = mapPattern(params.barcode, parsedDangerousGoodsTokens) as ParsedPatternFields;

    if (params.shouldGetItems === 1) {
      const itemsResponse = await getItems();
      const itemDetails = getItemDetails(parsedBarcode.ProdNo as string, itemsResponse as Record<string, unknown>[]);
      return { ...parsedBarcode, ...itemDetails, rawBarcode: params.barcode };
    }

    return parsedBarcode;
  };

  const getProduct = async (params: ParamsGetProduct): Promise<Record<string, unknown>> => {
    const itemsResponse = await getItems();
    const itemDetails = getItemDetails(params.ProdNo as string, itemsResponse as Record<string, unknown>[]);

    if (params.errorOnFailure && !itemDetails) {
      return Promise.reject({
        statusCode: 422,
        message: `Unable to locate item ${params.ProdNo}`,
      });
    }

    return itemDetails as Record<string, unknown>;
  };

  const issuerSchemes = async (): Promise<IssuerSchemes[]> => {
    return axiosClient.get<IssuerSchemes[]>(
      {
        url: `${tokeniserBasePath}/issuerschemes`,
        headers: await authHeaders(),
      },
      { type: CacheTypes.TIMED, ttl: 60 * 12, key: `${cacheKey}/${cacheKeySuffix.schemes}` }
    );
  };

  const getItems = async (): Promise<Items[]> => {
    return axiosClient.get<Items[]>(
      {
        url: `${tokeniserBasePath}/items`,
        headers: await authHeaders(),
      },
      { type: CacheTypes.TIMED, ttl: 60 * 12, key: `${cacheKey}/${cacheKeySuffix.items}` }
    );
  };

  const tokeniseJGB4 = async (params: ParamsTokenise): Promise<Record<string, unknown>> => {
    const parsedBarcode = (await tokenise(params)) as ParsedPatternFields;

    if (parsedBarcode.Type !== "jgb4") {
      return parsedBarcode;
    }

    const jgb4Tokens: JGB4Tokens = {
      displayTokens: mappedJGB4BarcodeData(parsedBarcode),
      parsedBarcode,
    };
    return jgb4Tokens;
  };

  const prepareDataForJGB4Barcode = async (params: ParsedPatternFields): Promise<Record<string, unknown>> => {
    const jgb4Tokens: JGB4MappedData = mappedJGB4BarcodeData(params);
    return jgb4Tokens;
  };

  const validateBarcode = (
    barcode: string,
    cdId: string,
    checkDataStart: number,
    checkDataLen: number,
    checkDigit: number
  ): boolean => {
    switch (cdId.toLowerCase()) {
      case "modulus11": {
        const modulus11CheckDigit = calculateModulus11CheckDigit(barcode, checkDataStart + 1, checkDataLen);
        return modulus11CheckDigit == Number("0x" + checkDigit);
      }
      case "luhn": {
        const luhnCheckDigit = calculateLuhnCheckDigit(barcode, checkDataStart, checkDataLen);
        return luhnCheckDigit == Number("0x" + checkDigit);
      }
      default:
        return false;
    }
  };

  const calculateLuhnCheckDigit = (barcode: string, start: number, length: number): number => {
    let checksum = 0;
    for (let i = 0; i < length; i++) {
      let digit = +barcode.substring(i + start - 1, i + start);
      if (i % 2 != length % 2) {
        digit *= 2;
        if (digit >= 10) {
          digit = (digit % 10) + 1;
        }
      }
      checksum += digit;
    }
    return (checksum * 9) % 10;
  };

  const calculateModulus11CheckDigit = (barcode: string, start: number, length: number): number => {
    let checksum = 0;
    for (let i = 0; i < length; i++) {
      const digit = +barcode.substring(i + start - 1, i + start);
      const w = +MODULUS11_WEIGHT[i + 8 - length];
      checksum += digit * w;
    }
    if (checksum % 11 == 0) {
      checksum += 6;
    }
    checksum = 11 - (checksum % 11);
    if (checksum == 10) checksum = 0;
    else if (checksum == 11) checksum = 5;
    return checksum;
  };

  const parseQRCode = (params: ParamsTokenise): Promise<Record<string, unknown>> => {
    const parsedBarcode = qrCodeParsor(params.barcode, defaultL2GMapping) as ParsedPatternFields;

    if (parsedBarcode) {
      return Promise.resolve(parsedBarcode);
    }
    return Promise.reject("Unable to recognise provided qrcode");
  };

  // Keys will be register like this:
  // _tokeniserData/mask, _tokeniserData/schemes, _tokeniserData/items
  cacheRegistry.register({
    name: EnablerAPIClientNames.tokeniser,
    prefix: cacheKey,
    keys: Object.keys(cacheKeySuffix),
  });

  return Object.freeze({
    tokenise,
    issuerSchemes,
    getItems,
    tokenMask,
    mail,
    dangerousGoods,
    getProduct,
    tokeniseJGB4,
    prepareDataForJGB4Barcode,
    parseQRCode,
    validateBarcode,
    maybeTokenise,
  });
};
